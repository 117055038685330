import { useState } from "react";
import ida from "../../iconos/salida-del-avion.png";
import vuelta from "../../iconos/llegada-del-avion.png";
import calendar from "../../iconos/calendar.png";
import left from "../../iconos/left.png";
import right from "../../iconos/right.png";
import { format } from "date-fns";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import NewCalendar from "../UI/NewCalendar";
import { AutoCompleteQuote } from "../UI/autoComplete/AutoCompleteQuote";

const checkValidDateText = (str) => {
  if (str.length !== 5) return false;

  const pattern = /^[0-9]{2}[a-zA-Z]+$/;
  return pattern.test(str);
};

export default function SearcherRow({
  index,
  inputState,
  type,
  routeOption,
  isLastIndex,
  useForm,
}) {
  const { register, setValue, watch } = useForm;
  const [inputValue, setInputValue] = inputState;
  const [openCalendar, toggleSalida] = useToggleVisibility();
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const [rangeDate, setRangeDate] = useState();

  const editValue = (e) => {
    const { name, value } = e.target;

    setInputValue((curr) => ({
      ...curr,
      [index]: {
        ...curr[index],
        [name]: value,
      },
    }));

    if (name.includes("departureDate") && watch(`departureDate${index}`)) {
      setValue(`departureDate${index}`, "");
    }

    if (name.includes("returnDate") && watch(`returnDate${index}`)) {
      setValue(`returnDate${index}`, "");
    }

    if (name.includes("departureDate") && checkValidDateText(value)) {
      if (
        isLastIndex &&
        !(routeOption === "roundtrip" || routeOption === "flexible")
      ) {
        return;
      }
      handleInputChange(
        `quote-input-${index}3`,
        routeOption === "roundtrip" || routeOption === "flexible"
          ? `quote-input-${index}4`
          : `quote-input-${index + 1}1`,
      );
    }
  };

  const handleInputChange = (inputID, nextInputID) => {
    const currentInput = document.getElementById(inputID);
    const nextInput = document.getElementById(nextInputID);

    currentInput.blur();
    nextInput?.focus();
  };

  const handleChangeRoutes = (e) => {
    e.preventDefault();

    const currentOricode = watch(`oricode${index}`);
    const currentDescode = watch(`descode${index}`);
    const currentOricodeInput = oricodeInput;
    const currentDescodeInput = descodeInput;

    if (currentOricode && currentDescode) {
      setValue(`oricode${index}`, currentDescode);
      setValue(`descode${index}`, currentOricode);
      setOricodeInput(currentDescodeInput);
      setDescodeInput(currentOricodeInput);
    }
  };

  return (
    <section className="col-span-5 grid grid-cols-5 gap-4 2xl:gap-8">
      <div className="relative flex col-span-3">
        <div className="w-full relative flex items-center">
          <img src={ida} alt="salida" className="absolute left-3 w-4 z-10" />
          <AutoCompleteQuote
            autoFocus={index === 1}
            quoteInputId={`quote-input-${index}1`}
            nextQuoteInputId={`quote-input-${index}2`}
            classname="border border-gray2 h-10 pl-10 rounded-md pr-2 w-full"
            name={`oricode${index}`}
            register={register}
            placeholder="COR EZE AEP SCL ASU"
            handleAction={(d) => setValue(`oricode${index}`, d)}
            inputState={[oricodeInput, setOricodeInput]}
            handleInputChange={handleInputChange}
          />
        </div>

        <button
          className="flex flex-col gap-1 justify-center h-fit my-auto rounded p-1 items-center bg-lightBlue mx-2"
          onClick={handleChangeRoutes}
          type="button"
        >
          <img src={right} className="w-8" alt="right" />
          <img src={left} alt="left" className="w-8" />
        </button>

        <div className="w-full relative flex items-center">
          <img
            src={vuelta}
            alt="llegada"
            className="absolute left-3 w-4 z-10"
          />
          <AutoCompleteQuote
            quoteInputId={`quote-input-${index}2`}
            nextQuoteInputId={`quote-input-${index}3`}
            classname="border border-gray2 h-10 pl-10 rounded-md pr-2 w-full"
            name={`descode${index}`}
            register={register}
            placeholder="EZE GIG MIA MAD FCO"
            handleAction={(d) => setValue(`descode${index}`, d)}
            inputState={[descodeInput, setDescodeInput]}
            handleInputChange={handleInputChange}
          />
        </div>
        {/* SEPARATOR */}
        <div className="border-l border-white h-10 absolute -right-2 2xl:-right-4" />
      </div>

      <div className="col-span-2 flex gap-2 2xl:gap-4">
        <div className="w-1/2 relative flex items-center">
          <img
            src={calendar}
            alt="calendar"
            className="absolute left-3 w-6 z-10 cursor-pointer p-1 rounded transition-colors hover:bg-lightBlue"
            onClick={() => toggleSalida()}
          />
          <input
            id={`quote-input-${index}3`}
            autoComplete="off"
            name={`departureDate${index}`}
            placeholder="13ABR 06JUN"
            onChange={editValue}
            className="border border-gray2 h-10 pl-10 rounded-md p-2 px-4 w-full"
            value={
              !watch(`departureDate${index}`)
                ? inputValue?.[`departureDate${index}`]
                : format(
                    new Date(watch(`departureDate${index}`) + "T00:00:00"),
                    "dd/MM/yyyy",
                  )
            }
          />
          <div className="absolute -left-full top-8">
            {openCalendar ? (
              <NewCalendar
                handlers={[
                  rangeDate,
                  (date) => {
                    setRangeDate(date);
                    if (
                      routeOption === "roundtrip" ||
                      routeOption === "flexible"
                    ) {
                      setInputValue((curr) => ({
                        ...curr,
                        [index]: {
                          [`departureDate${index}`]: date?.from
                            ? ""
                            : curr?.[index]?.[`departureDate${index}`],
                          [`returnDate${index}`]: date?.to
                            ? ""
                            : curr?.[index]?.[`returnDate${index}`],
                        },
                      }));
                      setValue(
                        `departureDate${index}`,
                        date?.from ? format(date?.from, "yyyy-MM-dd") : "",
                      );
                      setValue(
                        `returnDate${index}`,
                        date?.to ? format(date?.to, "yyyy-MM-dd") : "",
                      );
                      date?.from && date?.to && toggleSalida();
                    } else {
                      setInputValue((curr) => ({
                        ...curr,
                        [index]: {},
                      }));
                      setValue(
                        `departureDate${index}`,
                        date ? format(date, "yyyy-MM-dd") : "",
                      );
                      date && toggleSalida();
                    }
                  },
                ]}
                mode={
                  routeOption === "roundtrip" || routeOption === "flexible"
                    ? "range"
                    : "single"
                }
                numberOfMonths={2}
                close={toggleSalida}
              />
            ) : null}
          </div>
        </div>

        {(type === "roundtrip" || type === "flexible") && (
          <div className="w-1/2 relative flex items-center">
            <img
              src={calendar}
              alt="calendar"
              className="absolute left-3 w-6 z-10 cursor-pointer p-1 rounded transition-colors hover:bg-lightBlue"
              onClick={() => toggleSalida()}
            />
            <input
              id={`quote-input-${index}4`}
              autoComplete="off"
              name={`returnDate${index}`}
              placeholder="28ABR 07JUL"
              onChange={editValue}
              className="border border-gray2 h-10 pl-10 rounded-md p-2 px-4 w-full"
              value={
                !watch(`returnDate${index}`)
                  ? inputValue?.[`returnDate${index}`]
                  : format(
                      new Date(watch(`returnDate${index}`) + "T00:00:00"),
                      "dd/MM/yyyy",
                    )
              }
            />
          </div>
        )}
      </div>
    </section>
  );
}
