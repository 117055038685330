import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { packageBookSchema } from "../../../schemas/bookSchema";
import { useTranslation } from "react-i18next";
import { usePackages } from "../../../services/usePackages";
import { useTours } from "../../../services/useTours";
import { useFees } from "../../../services/useFees";
import { useEffect, useState } from "react";
import { bookPackage } from "../../../utils/bookPackage";
import { useBookings } from "../../../services/useBookings";
import QuoteBasicData from "./formComponents/QuoteBasicData";
import SelectorSection from "./formComponents/SelectorSection";
import PassengerSection from "./formComponents/PassengerSection";
import ContactInfo from "./formComponents/ContactInfo";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import SEO from "../../../utils/SEO";
import { useUserStore } from "../../../store/useUserStore";
import { useQuotes } from "../../../services/useQuotes";
import { MdInfo } from "react-icons/md";

export default function BookForm({ qto }) {
  //const { createBooking } = useBookings();ç
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { user } = useUserStore();
  const { packages, getPackagesStep2 } = usePackages();
  const { createBooking } = useBookings();
  const { updateQuote } = useQuotes();
  const { tours, getTours } = useTours();
  const { fees, getFees } = useFees();
  const { t } = useTranslation();
  const [passengerData, setPassengerData] = useState({
    ADT: 1,
    CHD: 0,
    INF: 0,
  });

  usePackages();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(packageBookSchema),
    defaultValues: {
      passengers: new Array(passengerData).fill({
        name: "",
        surname: "",
        birthday: "",
        docType: "IDENTITY_CARD",
        docNumber: "",
      }), // Start with one empty passenger object
    },
  });

  useEffect(() => {
    getPackagesStep2();
  }, [tourOP]);

  const onChangePackage = async (packageString) => {
    const packageSelected = JSON.parse(packageString);
    getTours(Number(packageSelected.id));
  };

  const onChangeTour = async (tourString) => {
    const tourSelected = JSON.parse(tourString);
    getFees(Number(tourSelected.id));
  };

  const onSubmit = async (data) => {
    try {
      // Parse package and tour data
      const parsedPackage = JSON.parse(data.package);
      const parsedTour = JSON.parse(data.tour);
      const parsedFee = JSON.parse(data.fee);

      updateQuote({
        id: qto.id,
        status: "book",
      }),
        bookPackage(
          parsedPackage,
          errors,
          createBooking,
          data.passengers,
          parsedFee,
          parsedTour,
          tourOP,
          user,
          t,
        );
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <>
      <SEO title={"bookForm"} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="my-10 max-w-[70vw] mt-4 justify-center bg-white rounded-lg p-8"
      >
        <Toaster />
        <div className="flex gap-2 mb-4">
          <MdInfo size={23} color="blue" />
          {t(`global.Booking_Form.book_help`)}
        </div>
        <div className="flex flex-col ">
          <h1 className="font-semibold text-xl">
            {t(`global.Booking_Form.ask_title`)}
          </h1>
          <div className="bg-lightGray p-2 rounded-md">
            <div className="flex flex-row italic">{qto.ask}</div>
          </div>
        </div>
        <div className="mt-8">
          <hr />{" "}
        </div>
        <div className="flex justify-self-start gap-8 items-start mt-8">
          <QuoteBasicData qto={qto} t={t} />
          <SelectorSection
            watch={watch}
            register={register}
            packages={packages}
            fees={fees}
            tours={tours}
            t={t}
            qto={qto}
            onChangePackage={onChangePackage}
            onChangeTour={onChangeTour}
            errors={errors}
          />
        </div>
        <div className="mt-8">
          <hr />{" "}
        </div>
        <ContactInfo
          t={t}
          qto={qto}
          passengerData={passengerData}
          setPassengerData={setPassengerData}
        />

        <PassengerSection
          watch={watch}
          register={register}
          numberOfPassengers={passengerData}
          t={t}
          passengerState={[passengerData, setPassengerData]}
          errors={errors}
        />
        <div className="mb-8 mt-8 ">
          <hr />{" "}
        </div>
        <div>
          <div className="flex flex-col ">
            <h1 className="font-semibold text-xl">
              {t(`global.Booking_Form.contact_info`)}
            </h1>
            {qto?.quote?.name && (
              <div className="bg-lightGray p-2 rounded-md">
                <div className="flex flex-row italic">{qto?.quote?.name}</div>
              </div>
            )}
            {qto?.quote?.email && (
              <div className="bg-lightGray p-2 rounded-md">
                <div className="flex flex-row italic">{qto?.quote?.email}</div>
              </div>
            )}
            {qto?.quote?.whatsapp && (
              <div className="bg-lightGray p-2 rounded-md">
                <div className="flex flex-row italic">
                  {qto?.quote?.whatsapp}
                </div>
              </div>
            )}
            {!qto?.quote?.name &&
              !qto?.quote?.email &&
              !qto?.quote?.whatsapp && (
                <div className="bg-lightGray p-2 rounded-md">
                  <div className="flex flex-row italic">
                    {" "}
                    {t(`global.Booking_Form.contact_info_missing`)}
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="relative">
          <button className="flex items-center justify-center ml-auto mt-8 bottom-0 right-0 text-lg w-full md:w-1/2  bg-darkBlue text-white hover:shadow-lg hover:bg-hoverBlue transition-all py-3 px-6 rounded-lg">
            {t(`global.Booking_Form.button`)}
          </button>
        </div>
      </form>
    </>
  );
}
