import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

export default function ToggleButton({ isVisible, toggleVisibility }) {
  return (
    <button
      className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
      onClick={() => toggleVisibility(!isVisible)}
    >
      {isVisible ? (
        <MdOutlineArrowDropUp size={30} />
      ) : (
        <MdOutlineArrowDropDown size={30} />
      )}
    </button>
  );
}
