import SEO from "../../utils/SEO";
import UsersTable from "./UsersTable";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { Login } from "../auth/Login";
import { useParams } from "react-router-dom";
import { useUsers } from "../../services/useUsers";
import { useEffect, useState } from "react";
import FormAddAgency from "./FormAddAgency";
import PanelSelector from "./PanelSelector";
import { useTranslation } from "react-i18next";
import RolePermissionTable from "./userPanel/RolePermissionTable";
import useAgencies from "../../services/useAgencies";
import { ModalGeneric } from "../UI/ModalGeneric";
import { Toaster } from "react-hot-toast";

export const PanelUser = () => {
  const { tour_op_id } = useParams();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { users, getUsersByTourOperator, deleteUser } = useUsers();
  const { agencies, getAgenciesByTourOperator } = useAgencies();
  const { t } = useTranslation();
  const [modalType, setModalType] = useState();
  const { agency_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData"),
  );

  useEffect(() => {
    getAgenciesByTourOperator();
  }, [tour_op_id]);

  const openModal = (type) => {
    toggleVisibility();
    setModalType(type);
  };

  const closeModalAgency = () => {
    toggleVisibility();
    getAgenciesByTourOperator();
  };

  const closeModalUsers = () => {
    toggleVisibility();
    getUsersByTourOperator();
  };

  return (
    <>
      <SEO title={"Administrador de Usuarios"} />
      <Toaster />
      <main className="relative font-body max-w-7xl mx-auto">
        <div className="mt-4">
          <PanelSelector type={"users"} />
        </div>
        <section className="my-8">
          <header className="flex justify-between items-center">
            <div>
              <h2 className="text-darkBlue font-[700] text-3xl">
                {t(`global.Admin_Users.users_title`)}
              </h2>
            </div>
            <div className="flex gap-4">
              {agency_id === 1 && (
                <button
                  className="border border-darkBlue text-darkBlue bg-white font-semibold py-2 px-4 rounded"
                  onClick={() => openModal("agency")}
                >
                  {t(`global.Admin_Users.add_agency`)}
                </button>
              )}
              <button
                className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
                onClick={() => openModal("user")}
              >
                {t(`global.Admin_Users.add_user`)}
              </button>
            </div>
          </header>

          <UsersTable
            useUsers={{ users, getUsersByTourOperator, deleteUser }}
            agencies={agencies}
          />
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {modalType === "agency" && (
            <FormAddAgency closeModal={closeModalAgency} />
          )}
          {modalType === "user" && (
            <Login justCreateAccount closeModal={closeModalUsers} />
          )}
        </ModalGeneric>

        <section>
          <header className="flex justify-between items-center">
            <div>
              <h2 className="text-darkBlue font-[700] text-3xl">
                {t(`global.Admin_Users.permissions`)}
              </h2>
            </div>
          </header>

          <RolePermissionTable />
        </section>

        <section className="mt-8">
          <h2 className="text-darkBlue font-[700] text-3xl">
            {t(`global.Admin_Users.auto_notifications`)}
          </h2>
          <p> {t(`global.Admin_Users.reserve_state`)} </p>
          <label className="block">
            {t(`global.Admin_Users.confirmed`)} <input type="checkbox" />
          </label>
          <label className="block">
            {t(`global.Admin_Users.send_email`)} <input type="checkbox" />
          </label>
        </section>
      </main>
    </>
  );
};
