import React from "react";

export default function HotelPassengerBasicData({ register, errors }) {
  const maxDate = new Date();
  const maxDateString = maxDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  return (
    <>
      <label htmlFor="name" className="mb-1 font-low text-sm text-[#242423]">
        Nombre
      </label>
      <div className="relative col-span-2">
        <input
          name="name"
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          placeholder="Como figura en el documento de viaje"
          id="name"
          {...register("data.first_name")}
        />
        {errors.data?.first_name && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
      <label htmlFor="surname" className="mb-1 font-low text-sm text-[#242423]">
        Apellido
      </label>
      <div className="col-span-2 relative">
        <input
          name="surname"
          className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
          placeholder="Como figura en el documento de viaje"
          id="surname"
          {...register("data.last_name")}
        />
        {errors.data?.last_name && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
      <label
        className="mb-1 font-low text-sm text-[#242423]"
        htmlFor="birthday"
      >
        Fecha de nacimiento
      </label>
      <div className="col-span-2 relative">
        <input
          name="birthday"
          type="date"
          max={maxDateString}
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          id="birthday"
          {...register("data.date_of_birth")}
        />
        {errors.data?.date_of_birth && (
          <p className="text-red-500">{errors.data.date_of_birth.message}</p>
        )}
      </div>
      <label className="mb-1 font-low text-sm text-[#242423]">Sexo</label>
      <span className="flex items-center gap-4 col-span-2">
        <fieldset>
          <div className="flex items-center gap-3">
            <input
              name="genre-0"
              type="radio"
              id="contactChoice1-0"
              value="F"
              {...register("data.gender")}
            />
            <label htmlFor="contactChoice1-0">Femenino</label>
            <input
              name="genre-0"
              type="radio"
              id="contactChoice2-0"
              value="M"
              {...register("data.gender")}
            />
            <label htmlFor="contactChoice2-0">Masculino</label>
          </div>
        </fieldset>
        {errors.data?.gender && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </span>
      <label htmlFor="email" className="mb-1 font-low text-sm text-[#242423]">
        Email
      </label>
      <div className="col-span-2 relative">
        <section className="relative buscador-centro-ubicacion-input-div">
          <input
            className="rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
            type="email"
            name="email"
            {...register("data.email_address")}
          />
          <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-[300px]">
            <div className="flex flex-col gap-1"></div>
          </section>
        </section>
        {errors.data?.email_address && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
    </>
  );
}
