import { useForm } from "react-hook-form";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import { versionTypes } from "./commons";
import { AiOutlineDelete } from "react-icons/ai";
import { useVersions } from "../../../services/useVersion";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ModalGeneric } from "../../UI/ModalGeneric";

export const ControlVersions = () => {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { register, handleSubmit, reset } = useForm();
  const { versions, postVersion, getVersions } = useVersions();

  const onSubmit = (version) => {
    toast.promise(
      postVersion({ version }).then(() => {
        reset();
        getVersions().then(() => {
          toggleVisibility();
        });
      }),
      {
        loading: "Cargando...",
        success: <b>Versión cargada con éxito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      },
    );
  };

  return (
    <div className="flex flex-col gap-4 px-2">
      <div>
        <Toaster />
      </div>
      <button
        onClick={toggleVisibility}
        className="ml-auto button bg-darkBlue text-white whitespace-nowrap px-2 hover:shadow-md transition-all"
      >
        + Agregar Versión
      </button>
      <div className="flex flex-col gap-2">
        <VersionHeader />
        {(versions || [])?.map(({ id, status, name }) => (
          <div key={id}>
            <div className="flex">
              <span className="flex items-center gap-4">
                <h2 className="font-[500] text-[28px]">{name || ""}</h2>
                <p className="text-xl">{status || ""}</p>
                <AiOutlineDelete
                  // onClick={() => deleteVersionInBack.mutate(id)}
                  style={{ fontSize: 24, cursor: "pointer" }}
                />
              </span>
            </div>
            {/* {statesFormatted?.map(({ label, value }, index) => (
                <ColorsStates
                  key={index}
                  index={index}
                  version={arr[index1]}
                  value={value}
                  label={label}
                  // updateState={updateState}
                />
              ))} */}
          </div>
        ))}
      </div>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <label className="font-medium w-[250px]">Nombre de versión</label>
              <input
                placeholder="v2.2"
                className="outline-none border-b border-[#CEDEF1]"
                name="name"
                {...register("name")}
              />
            </div>
            <div className="flex flex-col gap-3">
              <label className="font-medium w-[250px]">Estado</label>
              <input
                placeholder="Detenido, Hecho, Online..."
                className="outline-none border-b border-[#CEDEF1]"
                name="status"
                {...register("status")}
              />
            </div>
            {/* {(_.entries(newVersions) || [])?.map(([id, { label, state }]) => (
              <span key={id} className="flex items-center gap-4">
                <input
                  value={label}
                  onChange={(e) =>
                    updateVersion({
                      key: "label",
                      value: e.target.value,
                      id,
                    })
                  }
                  className="border-b outline-none border-slate-300"
                  placeholder="Infraestructura"
                />
                <select
                  onChange={(e) =>
                    updateVersion({
                      key: "value",
                      value: e.target.value,
                      id,
                    })
                  }
                  className="outline-none"
                >
                  {versionTypes.map(({ label, color }) => (
                    <option key={`${label}-${color}`}>{label} </option>
                  ))}
                </select>
                <RiDeleteBack2Line onClick={() => deleteVersion(id)} />
              </span>
            ))}
            <span className="border border-dotted border-[#D9D9D9] flex items-center justify-center py-2 px-3">
              <p
                onClick={() =>
                  setNewVersions((prev) => ({
                    ...prev,
                    [_.keys(newVersions)?.length + 1]: { label: "", value: "" },
                  }))
                }
                className="text-[#a0a0a0]"
              >
                + Agregar otro
              </p>
            </span> */}
          </div>
          <span className="flex items-center justify-center my-4">
            <button className="px-4 py-2 rounded bg-brightBlue text-white ml-auto">
              Cargar
            </button>
          </span>
        </form>
      </ModalGeneric>
    </div>
  );
};

// const ColorsStates = ({ label, value, updateState, version, index }) => {
//   const formatNewState = (label) => {
//     const newState = JSON.parse(version.states);
//     newState[index].value = label;

//     updateState({ ...version, states: JSON.stringify(newState) });
//   };

//   return (
//     <div className="flex justify-between mx-auto max-w-[522px] w-full my-1">
//       <p className="text-[#242423BF] font-[500] w-[150px]">{label}</p>
//       <div className="flex gap-3">
//         {(versionTypes || [])?.map(({ color, label }, index) => (
//           <div
//             onClick={() => formatNewState(label)}
//             key={index}
//             style={{
//               backgroundColor: color,
//             }}
//             className={clsx(
//               "w-[24.93px] h-[24.93px] mx-auto text-center rounded-full opacity-30 hover:opacity-60 cursor-pointer",
//               {
//                 "opacity-30": value !== label,
//                 "opacity-60 border": value === label,
//               }
//             )}
//           />
//         ))}
//       </div>
//       <div className="rounded-[10px]  flex items-center justify-center h-[31px] w-[116px]">
//         <p className="text-[#535353] font-[500] "></p>
//       </div>
//     </div>
//   );
// };

export const VersionHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-[500px] flex gap-2 px-14 justify-center">
      {versionTypes.map(({ label, icon }, index) => (
        <div className="flex items-center gap-3 px-4 py-2" key={index}>
          <img src={icon} alt={label} className="w-[16.22px] h-[16.22px]" />
          <p className="text-[14.2px] text-[#242423BF] font-[400]">
            {t(`global.Help_Page.${label}`)}
          </p>
        </div>
      ))}
    </div>
  );
};
