import i18n from "i18next";

export const updateLenguaje = (operator) => {
  if (operator?.TourOperatorAddresses[0].country_code) {
    const countyCode = operator.TourOperatorAddresses[0].country_code;

    const countryMap = {
      GB: "en",
      US: "en",
      AR: "es",
    };

    const newLenguaje = countryMap[countyCode] || "es";

    i18n.changeLanguage(newLenguaje);
  }
};
