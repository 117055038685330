import Head from "./head";
import RecommendationWrapper from "./landing/RecommendationWrapper";
import { useTourOperators } from "../services/useTourOperators";
import SEO from "../utils/SEO";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Buscador from "./searcher/buscador";
import Footer from "./home/footer/Footer";
import { useTourOperatorStore } from "../store/useTourOperatorStore";

const Home = () => {
  const { type } = useParams();
  const { tour_op_id } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { tourOperator, getTourOperatorByID } = useTourOperators();
  //const bgClass = type === "packages" ? "bg-package-bg" : "bg-beach-bg";
  const bgNat =
    tour_op_id === "nataliabequis" && type === "packages"
      ? "bg-package-bg"
      : "bg-beach-bg";
  const module =
    type === "flights" ? "Vuelos" : type === "packages" ? "Paquetes" : null;
  const [renderFlights, setRenderFlights] = useState(true);

  useEffect(() => {
    if (tourOP?.id) {
      getTourOperatorByID({ tourOperatorID: tourOP?.id });
    }
  }, [getTourOperatorByID, tourOP, tourOP?.id]);

  useEffect(() => {
    if (tourOperator === undefined) {
      setRenderFlights(true);
      return;
    }

    // check if the tour operator has any modules entry with the name Vuelos disabled

    tourOperator?.TourOperatorModules.slice(0, 4).forEach((module) => {
      if (
        (module.name === "Vuelos" && module.active === false) ||
        module !== "Vuelos"
      ) {
        setRenderFlights(false);
      }
    });
  }, [tourOperator]);

  return (
    <>
      <SEO />
      <Head />
      <section
        className={`relative w-full rounded-md max-w-[1338.46px] mx-auto h-[444.18px] bg-no-repeat bg-cover ${bgNat}`}
      >
        <div className="md:max-w-6xl md:py-32 md:pl-3 md:m-auto">
          <Buscador />
        </div>
      </section>
      <section className="w-full flex flex-col md:items-center md:mx-auto md:my-14 md:px-32 md:max-w-[1338.46px] mb:mt-28 md:mt-0">
        {(renderFlights ||
          tourOperator?.TourOperatorModules.slice(0, 4).some(
            (item) => item.name === module && item.active,
          )) && (
          <RecommendationWrapper isFlight={type === "flights"} type={type} />
        )}
      </section>
      <Footer />
    </>
  );
};

export default Home;
