import arrow from "../../../../iconos/chevron_down_gray.png";
import DestinationFilter from "../../packageFilters/DestinationFilter";
import OriginFilter from "../../packageFilters/OriginFilter";
import MonthFilter from "../../packageFilters/MonthFilter";
import CurrencyFilter from "../../packageFilters/CurrencyFilter";
import YearFilter from "../../packageFilters/YearFilter";
import { monthMap } from "../../../../utils/months";
import { useTranslation } from "react-i18next";

export default function PackageFilters({
  possibleCitiesAndMonth,
  filters,
  setFilters,
}) {
  const displayedDescode = new Set();
  const displayedOricode = new Set();
  const { t } = useTranslation();
  const capitalizeFirstLetter = (text) => {
    if (typeof text !== "string") return text; // Check if text is a string
    if (!text) return text; // Handle empty strings
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleChecks = (evt, filter, type) => {
    const { checked } = evt.target;

    setFilters((curr) => {
      let updatedArray;

      // Handle each filter type
      switch (type) {
        case "destination_code":
          updatedArray = checked
            ? [...curr.destination_code, filter] // Add the filter if checked
            : curr.destination_code.filter((value) => value !== filter); // Remove if unchecked
          break;

        case "origin_code":
          updatedArray = checked
            ? [...curr.origin_code, filter] // Add the filter if checked
            : curr.origin_code.filter((value) => value !== filter); // Remove if unchecked
          break;

        case "month":
          updatedArray = checked
            ? [...curr.month, filter] // Add the filter if checked
            : curr.month.filter((value) => value !== filter); // Remove if unchecked
          break;

        case "currency":
          updatedArray = checked
            ? [...curr.currency, filter] // Add the filter if checked
            : curr.currency.filter((value) => value !== filter); // Remove if unchecked
          break;

        case "year":
          updatedArray = checked
            ? Array.from(new Set([...curr.year, filter])) // Prevent duplicates
            : curr.year.filter((value) => value !== filter); // Remove if unchecked
          break;

        default:
          // Handle unknown types gracefully (you could throw an error here if needed)
          return curr;
      }

      return {
        ...curr,
        [type]: updatedArray, // Update the relevant filter type
      };
    });
  };

  const handleDisplay = (item) => {
    const body = document.getElementById(`${item}-body`);
    const arrow = document.getElementById(`${item}-arrow`);
    body.classList.toggle("scale-y-0");
    body.classList.toggle("h-0");
    arrow.classList.toggle("-rotate-180");
    arrow.classList.toggle("rotate-0");
  };

  const deleteFilters = () => {
    setFilters({
      month: [],
      destination_code: [],
      origin_code: [],
      currency: [],
      year: [],
    });
  };

  return (
    <div className="flex flex-col pr-4 w-[230px]">
      <div className=" inline-flex items-center">
        <h2 className="font-medium p-2 text-xl">
          {t(`global.Filter_Titles.filters`)}
        </h2>
        <button
          className="bg-transparent text-blue-500 text-md mt-1"
          onClick={deleteFilters}
        >
          {t(`global.Actions.delete_filters`)}
        </button>
      </div>
      <DestinationFilter
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleDisplay={handleDisplay}
        arrow={arrow}
        possibleCitiesAndMonth={possibleCitiesAndMonth}
        displayedDescode={displayedDescode}
        filters={filters}
        handleChecks={handleChecks}
      />
      <OriginFilter
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleDisplay={handleDisplay}
        arrow={arrow}
        possibleCitiesAndMonth={possibleCitiesAndMonth}
        displayedOricode={displayedOricode}
        filters={filters}
        handleChecks={handleChecks}
      />
      <MonthFilter
        handleDisplay={handleDisplay}
        arrow={arrow}
        monthMap={monthMap}
        filters={filters}
        handleChecks={handleChecks}
      />
      <YearFilter
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleDisplay={handleDisplay}
        arrow={arrow}
        possibleCitiesAndMonth={possibleCitiesAndMonth}
        displayedOricode={displayedOricode}
        filters={filters}
        handleChecks={handleChecks}
      />
      <CurrencyFilter
        capitalizeFirstLetter={capitalizeFirstLetter}
        handleDisplay={handleDisplay}
        arrow={arrow}
        possibleCitiesAndMonth={possibleCitiesAndMonth}
        displayedOricode={displayedOricode}
        filters={filters}
        handleChecks={handleChecks}
      />
    </div>
  );
}
