import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Pagination({
  items = [],
  itemsPerPage = 10,
  page,
  setPage,
  setItemsPerPage,
}) {
  const paginationLength = items.length;
  const numbOfPages = Math.ceil(paginationLength / itemsPerPage);
  const { t } = useTranslation();
  // Ensure page stays within valid bounds
  useEffect(() => {
    if (page >= numbOfPages) {
      setPage(Math.max(0, numbOfPages - 1)); // Reset to last valid page
    } else if (page < 0) {
      setPage(0); // Reset to first page
    }
  }, [page, numbOfPages, setPage]);

  // Calculate page buttons to display
  const pageButtons =
    numbOfPages <= 3
      ? Array.from({ length: numbOfPages }, (_, i) => i + 1) // Show all pages if <= 3
      : page === 0
        ? [1, 2, 3]
        : page === numbOfPages - 1
          ? [numbOfPages - 2, numbOfPages - 1, numbOfPages]
          : [page, page + 1, page + 2];

  if (paginationLength === 0) {
    return <p>{t(`global.Pagination.no_entries`)}</p>;
  }

  return (
    <section className="flex justify-between items-center mt-4 font-body">
      {/* Items Per Page Selector */}

      {/* Page Info */}
      <p className="text-darkBlue/70 text-sm font-low">
        {t(`global.Pagination.showing`)}
        {/*  {page * itemsPerPage + 1} a{" "} */}
        {/* {Math.min((page + 1) * itemsPerPage, paginationLength)}  */}
        <select
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
          className="py-1 px-2 border bg-light border-lightGray3 rounded"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
        </select>
        {t(`global.Pagination.of`)} {paginationLength}{" "}
        {t(`global.Pagination.entries`)}
      </p>

      {/* Pagination Controls */}
      <div className="flex gap-2">
        {/* Previous Button */}
        <button
          disabled={page === 0}
          onClick={() => setPage(Math.max(0, page - 1))}
          className="py-1 px-2 border bg-light border-lightGray3 rounded bg-white"
        >
          {t(`global.Pagination.previous`)}
        </button>

        {/* Page Buttons */}
        {pageButtons.map((btn) => (
          <button
            key={btn}
            className={`py-1 px-2 border bg-light border-lightGray3 rounded ${
              page + 1 === btn ? "bg-lightBlue font-medium" : "bg-white"
            }`}
            onClick={() => setPage(btn - 1)}
          >
            {btn}
          </button>
        ))}

        {/* Next Button */}
        <button
          disabled={page === numbOfPages - 1}
          onClick={() => setPage(Math.min(page + 1, numbOfPages - 1))}
          className="py-1 px-2 border bg-light border-lightGray3 rounded bg-white"
        >
          {t(`global.Pagination.next`)}
        </button>
      </div>
    </section>
  );
}
