import { useEffect, useState } from "react";
import FlexibleOption from "./FlexibleOption";
import SelectedQuote from "./SelectedQuote";
import useGetAirports from "../../services/useGetAirports";

export default function FlexibleQuotes({ flights }) {
  const [flexibleOption, setFlexibleOption] = useState();
  const [formattedCities, setFormattedCities] = useState({});
  const { airports, getAirports } = useGetAirports();

  useEffect(() => {
    getAirports();
  }, []);

  useEffect(() => {
    if (airports) {
      setFormattedCities(() => {
        let formattedCities = {};
        airports.forEach((airport) => {
          formattedCities[airport.iata] = {
            city: airport.City.name,
            airport: airport.name,
          };
        });
        return formattedCities;
      });
    }
  }, [airports]);

  const bestPrice =
    flights &&
    flights[0]
      .map(({ original }) => Number(original?.[0]?.price?.grandTotal))
      .sort((a, b) => (a > b ? 1 : -1))
      .slice(0, 3);

  return (
    <section className="flex items-start gap-4 2xl:gap-6 max-w-full">
      <section className="bg-white p-4 rounded-xl w-full">
        <header className="flex gap-8 mb-4">
          <div className="flex items-center gap-2">
            <div className="bg-brightBlue border h-3 w-3" />
            <p>Opción buscada</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="bg-brightGreen border h-3 w-3" />
            <p>Mejor precio</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="bg-orange-400 border h-3 w-3" />
            <p>Segundo mejor precio</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="bg-red-400 border h-3 w-3" />
            <p>Tercer mejor precio</p>
          </div>
        </header>

        <div className="grid grid-cols-7 gap-2">
          {flights &&
            flights[0].map(({ original, grouped }, index) => (
              <FlexibleOption
                key={index}
                original={original}
                grouped={grouped}
                bestPrice={bestPrice}
                formattedCities={formattedCities}
                setFlexibleOption={setFlexibleOption}
                index={index}
              />
            ))}
        </div>
      </section>
      <div
        className={`transition-all origin-right ${flexibleOption ? "w-1/3 scale-x-100" : "w-0 scale-x-0"}`}
      >
        {flexibleOption && (
          <SelectedQuote
            selectedOption={[flexibleOption]}
            original={[flexibleOption]}
            routeOption={"roundtrip"}
            setSelectedOption={setFlexibleOption}
          />
        )}
      </div>
    </section>
  );
}
