import axios from "axios";
import { useEffect, useState } from "react";

import { useTourOperatorStore } from "../store/useTourOperatorStore";
import {
  getSessionStorageValue,
  getLocalStorageValue,
} from "../hooks/useCookie";

export default function useCommissionsAndTaxes() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const [rulesFare, setRulesFares] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createRulesFares = async ({ ruleFare }) =>
    axios.post(
      BASE_URL + "v2/rules-fares",
      {
        ruleFare: { ...ruleFare, tour_op_id: tourOP.id },
      },
      headers,
    );

  const getRulesFares = async () => {
    try {
      const response = await axios.get(BASE_URL + "v2/rules-fares", headers);
      const filteredRulesFares = response.data.response;
      setRulesFares(filteredRulesFares);
      // Optionally, log to an external service or handle it here
    } catch (error) {
      // Handle the error gracefully
      throw new Error("Failed to fetch rules fares: " + error.message);
    }
  };

  const updateRulesFares = async (id, ruleFare) => {
    const { ...restOfRuleFare } = ruleFare;
    return await axios.patch(
      BASE_URL + `v2/rules-fares/${id}`,
      {
        ruleFare: { ...restOfRuleFare },
      },
      headers,
    );
  };

  useEffect(() => {
    getRulesFares();
  }, []);

  return {
    rulesFare,
    createRulesFares,
    getRulesFares,
    updateRulesFares,
  };
}
