import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { defaultDateFormat } from "../../utils/commons";
import FlightsTable from "./FlightsTable";
import Pasajeros from "./Pasajeros";
import Tarifa from "./Tarifa";
import SEO from "../../utils/SEO";
import PackageTable from "./PackageTable";
import { useTranslation } from "react-i18next";
import ToursDetailTable from "./bookingDetail/ToursDetailTable";
import FeeDetailTable from "./bookingDetail/FeeDetailTable";
import { useBookings } from "../../services/useBookings";
import useCommissionsAndTaxes from "../../services/useCommissionsAndTaxes";
import useGetAirports from "../../services/useGetAirports";

const handleDisplay = (item) => {
  const body = document.getElementById(`${item}-body`);
  const arrow = document.getElementById(`${item}-arrow`);
  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};

const getTravelerType = (birthDate) => {
  const today = new Date();
  const dob = new Date(birthDate);

  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  if (age > 17) return "ADULT";
  if (age > 1) return "CHILD";
  return "HELD_INFANT";
};

export default function BookingDetail({ isHistory = false }) {
  const { tour_op_id, booking_id, type } = useParams();
  const { getBooking, booking } = useBookings();
  const { getAirports, airports } = useGetAirports();
  const { commissions, getCommissions } = useCommissionsAndTaxes();
  const { t } = useTranslation();
  const [ciudadesFormatted, setCiudadesFormatted] = useState({});

  const {
    status,
    creation_date,
    Flights = [],
    Packages = [],
    Travelers = [],
    travelers_packages = [],
  } = booking || {};

  const parsedFlights =
    Flights.length > 0 ? JSON.parse(Flights[0]?.options || "{}") : {};

  let {
    lastTicketingDate,
    itineraries = [],
    travelerPricings = [],
    selectedAmenities = {},
  } = parsedFlights;

  const idas = itineraries?.[0]?.segments;
  const vueltas = itineraries?.[1]?.segments;
  const flightsDetails = travelerPricings[0]?.fareDetailsBySegment;
  useEffect(() => {
    if (booking_id) {
      getBooking({ bookingID: booking_id });
    }
    getAirports();
    getCommissions();
  }, []);

  useEffect(() => {
    if (airports) {
      setCiudadesFormatted(() => {
        let formattedCities = {};
        airports.forEach((airport) => {
          formattedCities[airport.iata] = airport;
        });
        return formattedCities;
      });
    }
  }, [airports]);

  travelerPricings =
    type === "flight"
      ? travelerPricings
      : travelers_packages.map((passenger) => ({
          travelerType: getTravelerType(passenger.date_of_birth),
        }));

  return (
    <div className="h-full flex flex-col gap-6 w-[55vw] mt-16 mx-auto text-darkBlue">
      <SEO title={"Detalle de Reserva"} />
      <div className="flex justify-end">
        <Link
          to={`/${tour_op_id}/${
            isHistory ? "history" : `panel/sales/bookings/${type}`
          }`}
          className="ml-auto button text-center bg-darkBlue font-medium text-white hover:bg-darkBlue/80 transition-colors"
        >
          {isHistory
            ? t(`global.Form_Titles.history_form_btn`)
            : t(`global.Form_Titles.booking_form_btn`)}
        </Link>
      </div>
      <section className="detailTable">
        <section className="datailTableRow bg-lightBlue">
          <div className="flex items-center gap-1">
            <p>{t(`global.Booking_Detail.reserve`)}:</p>
            <span className="font-medium text-xl"> {booking_id}</span>
          </div>
          <div className="flex items-center gap-1">
            <p>{t(`global.Booking_Detail.state`)}:</p>
            {status === "pending" && (
              <span className="font-medium text-lg">
                {t(`global.Admin_Bookings.pending`)}
              </span>
            )}
            {status === "confirmed" && (
              <span className="font-medium text-lg">
                {t(`global.Admin_Bookings.confirmed`)}
              </span>
            )}
            {status === "to-book" && (
              <span className="font-medium text-lg">
                {t(`global.Common_Words.reserve`)}
              </span>
            )}
            {status === "for-ticketing" && (
              <span className="font-medium text-lg">
                {t(`global.Admin_Bookings.for-ticketing`)}
              </span>
            )}
            {status === "to-ticket" && (
              <span className="font-medium text-lg">
                {t(`global.Admin_Bookings.to-ticket`)}
              </span>
            )}
          </div>
        </section>
        <section className="datailTableRow">
          <div className="flex items-center gap-1">
            <p> {t(`global.Booking_Detail.main_pax`)}:</p>
            <span className="font-medium text-lg">
              {`${
                Travelers?.[0]?.first_name ||
                travelers_packages?.[0]?.first_name
              } ${
                Travelers?.[0]?.last_name || travelers_packages?.[0]?.last_name
              }`}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <p>{t(`global.Booking_Detail.creation_date`)}:</p>
            <span className="font-medium text-lg">
              {" "}
              {creation_date &&
                format(new Date(creation_date), defaultDateFormat)}
            </span>
          </div>
        </section>
      </section>

      {type === "flight" ? (
        <>
          <FlightsTable
            title={"ida"}
            flights={[idas, flightsDetails]}
            ciudadesFormatted={ciudadesFormatted}
            handleDisplay={handleDisplay}
          />
          {vueltas && (
            <FlightsTable
              title={"vuelta"}
              flights={[vueltas, flightsDetails]}
              ciudadesFormatted={ciudadesFormatted}
              handleDisplay={handleDisplay}
            />
          )}
          <Tarifa
            tarifa={travelerPricings}
            emisionDate={lastTicketingDate}
            selectedAmenities={selectedAmenities}
            commissions={commissions}
            handleDisplay={handleDisplay}
          />
        </>
      ) : (
        <>
          <PackageTable pack={Packages?.[0]} handleDisplay={handleDisplay} />
          <ToursDetailTable ids={Packages?.[0]} handleDisplay={handleDisplay} />
          <FeeDetailTable ids={Packages?.[0]} handleDisplay={handleDisplay} />
        </>
      )}

      <Pasajeros
        pasajeros={travelers_packages}
        passengerType={travelerPricings}
        handleDisplay={handleDisplay}
      />
    </div>
  );
}
