import { useEffect } from "react";
import { useTravelers } from "../../services/useTravelers";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { traveleSchema } from "../../schemas/traveler";
import { zodResolver } from "@hookform/resolvers/zod";
import toast, { Toaster } from "react-hot-toast";

export default function UpdateTravelerModal({
  traveler,
  toggleVisibility,
  refreshValues,
}) {
  const { updateTraveler } = useTravelers();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: traveler,
    resolver: zodResolver(traveleSchema),
  });
  // function formatDateToInput(dateString) {
  //   if (!dateString) return "";
  //   return new Date(dateString).toISOString().split("T")[0];
  // }

  const onSubmit = async (data) => {
    toast.promise(updateTraveler(traveler.id, data), {
      success: <b>{t(`global.Traveler_Form.traveler_edit_toaster`)}</b>,
      loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
      error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
    });
    refreshValues();
    toggleVisibility();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setValue(name, newValue, { shouldValidate: true });
  };

  // const checkInput = (inputString, pattern) => {
  //   const regex = new RegExp(pattern);
  //   return regex.test(inputString);
  // };
  useEffect(() => {
    if (traveler?.category) {
      setValue("category", traveler.category); // Set the existing category
    } else {
      setValue("category", "ninguno"); // Default fallback category
    }

    if (traveler?.date_of_birth) {
      const formattedDate = format(
        new Date(traveler.date_of_birth),
        "yyyy-MM-dd",
      );
      setValue("date_of_birth", formattedDate);
    }
  }, [traveler, setValue]);

  return (
    <>
      <h1 className="text-darkBlue font-semibold text-xl text-center">
        {t(`global.Admin_Travelers.edit_traveler`)}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[80vw]">
        <Toaster />
        <section className="grid  gap-2 my-2 p-4">
          <div className="col">
            <div className="grid grid-cols-2 mb-4">
              <div className="w-full relative flex flex-col">
                <label>
                  {t(`global.Admin_Travelers.name`)}{" "}
                  <span className="text-red-400">*</span>
                </label>
                <input
                  className="addPackInput"
                  name="first_name"
                  {...register("first_name", {
                    required: "Nombre es requerido",
                  })}
                  onChange={handleInputChange}
                />
                {errors?.first_name && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <p>
                      {t(`global.Traveler_Form.${errors?.first_name.message}`)}
                    </p>
                  </div>
                )}
              </div>

              <div className="w-full relative flex flex-col">
                <label>
                  {t(`global.Admin_Travelers.last_name`)}{" "}
                  <span className="text-red-400">*</span>
                </label>
                <input
                  className="addPackInput"
                  name="last_name"
                  {...register("last_name", {
                    required: "Apellido es requerido",
                  })}
                  onChange={handleInputChange}
                />
                {errors?.last_name && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <p>
                      {t(`global.Traveler_Form.${errors?.last_name.message}`)}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label
                className="mb-1 font-low text-sm text-[#242423]"
                htmlFor="birthday"
              >
                {t(`global.Admin_Travelers.birth_date`)}
              </label>
              <div className="col-span-2 relative">
                <input
                  name="date_of_birth"
                  {...register("date_of_birth")}
                  type="date"
                  className="addPackInput"
                  id="date_of_birth"
                  onChange={handleInputChange}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
              {errors?.date_of_birth && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>
                    {t(`global.Traveler_Form.${errors?.date_of_birth.message}`)}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label className="mb-1 font-low text-sm text-[#242423]">
                {t(`global.Admin_Travelers.gender`)}
              </label>
              <span className="flex items-center gap-4 col-span-2">
                <fieldset>
                  <div className="flex items-center gap-3">
                    <input
                      {...register("gender")}
                      type="radio"
                      id="gender_F"
                      name="gender"
                      value="F"
                      checked={watch("gender") === "F"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="gender_F">
                      {t(`global.Admin_Travelers.female`)}
                    </label>

                    <input
                      {...register("gender")}
                      type="radio"
                      id="gender_M"
                      name="gender"
                      value="M"
                      checked={watch("gender") === "M"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="gender_M">
                      {t(`global.Admin_Travelers.male`)}
                    </label>
                  </div>
                </fieldset>
              </span>
            </div>

            <div className="w-full relative flex flex-col mb-8">
              <label>{t(`global.Admin_Travelers.category`)}</label>
              <select
                className="addPackInput"
                name="category"
                {...register("category")}
                onChange={handleInputChange}
                value={watch("category")}
              >
                <option value={"ninguno"}>
                  {t(`global.Admin_Travelers.none`)}
                </option>
                <option value={"directivos"}>
                  {t(`global.Admin_Travelers.management`)}
                </option>
                <option value={"cuerpo tecnico"}>
                  {t(`global.Admin_Travelers.technical_staff`)}
                </option>
                <option value={"jugadores"}>
                  {t(`global.Admin_Travelers.players`)}
                </option>
                <option value={"plantel"}>
                  {t(`global.Admin_Travelers.roster`)}
                </option>
                <option value={"reserva"}>
                  {t(`global.Admin_Travelers.reserve`)}
                </option>
                <option value={"juveniles"}>
                  {t(`global.Admin_Travelers.youth`)}
                </option>
                <option value={"inferiores"}>
                  {t(`global.Admin_Travelers.lower_divisions`)}
                </option>
                <option value={"otro 1"}>
                  {t(`global.Admin_Travelers.other1`)}
                </option>
                <option value={"otro 2"}>
                  {t(`global.Admin_Travelers.other2`)}
                </option>
                <option value={"otro 3"}>
                  {t(`global.Admin_Travelers.other3`)}
                </option>
              </select>
            </div>

            <div className="w-full relative flex flex-col mb-8">
              <label>{t(`global.Admin_Travelers.email`)}</label>
              <input
                className="addPackInput"
                name="email_address"
                {...register("email_address", {
                  required: "Email es requerido",
                })}
                onChange={handleInputChange}
              />
              {errors?.email_address && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>
                    {t(`global.Traveler_Form.${errors?.email_address.message}`)}
                  </p>
                </div>
              )}
            </div>

            {traveler?.TravelerDocument?.doc_type && (
              <div>
                <label className="mb-1 font-low text-sm text-[#242423]">
                  {t(`global.Admin_Travelers.traveler_document`)}
                </label>
                <span className="flex col-span-2 gap-4">
                  <input
                    defaultValue={
                      traveler.TravelerDocument.doc_type === "IDENTITY_CARD"
                        ? "Documento"
                        : "Pasaporte"
                    }
                    name="doc_type"
                    className="addPackInput"
                    disabled
                  />
                  <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full">
                    <div className="relative w-full">
                      <input
                        className="addPackInput"
                        name="number"
                        placeholder={`Número de ${
                          watch("doc_type") === "IDENTITY_CARD"
                            ? "documento"
                            : "pasaporte"
                        }`}
                        defaultValue={traveler.TravelerDocument.number}
                        disabled
                      />
                    </div>
                  </label>
                </span>
              </div>
            )}

            {traveler?.TravelerPhones?.device_type && (
              <div>
                <label className="mb-1 font-low text-sm text-[#242423]">
                  {t(`global.Admin_Travelers.cellphone`)}
                </label>
                <span className="flex col-span-2 gap-4">
                  <input
                    defaultValue={traveler.TravelerPhones.device_type}
                    name="doc_type"
                    className="addPackInput"
                    disabled
                  />
                  <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full">
                    <div className="relative w-full">
                      <input
                        className="addPackInput"
                        name="number"
                        defaultValue={traveler.TravelerPhones.number}
                        disabled
                      />
                    </div>
                  </label>
                </span>
              </div>
            )}
          </div>
        </section>

        <button className="font-semibold text-lg ml-auto mt-4 button w-1/4 bg-brightBlue text-white hover:shadow-lg transition-all">
          {t(`global.Admin_Travelers.save_traveler`)}
        </button>
      </form>
    </>
  );
}
