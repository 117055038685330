import { z } from "zod";

export const traveleSchema = z.object({
  first_name: z.string().min(1, { message: "first_name_warning" }),
  last_name: z.string().min(1, { message: "last_name_warning" }),
  gender: z
    .string()
    .min(1, { message: "gender_warning", invalid_type: "gender_warning" }),
  email_address: z.string().min(1, { message: "email_address_warning" }),
  date_of_birth: z.string().min(1, { message: "date_of_birth_warning" }),
  category: z.union([z.string(), z.number()]).nullable().default(null),
});
