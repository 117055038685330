import BookingTableHeader from "../bookingsTables/BookingTableHeader";
import FlightBookingTableRow from "../bookingsTables/BookingTableRow-Flight";
import { Link } from "react-router-dom";
import { headers } from "../bookingsTables/BookingHeaders";
import { useTranslation } from "react-i18next";
import PackageBookingTableRow from "../bookingsTables/BookingTableRow-Package";
export default function BookingTables({
  type,
  filteredBookings,
  page,
  itemsPerPage,
  bookingStatus,
  bookingStatusKeys,
  mutateEstadoReserva,
  openModal,
  mutateEstadoReservaPaquete,
  translateCurrency,
  path,
  selectedRows,
  setSelectedRows,
}) {
  const { t } = useTranslation();
  const handleRowSelect = (traveler) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((row) => row.id === traveler.id);
      return isSelected
        ? prevSelected.filter((row) => row.id !== traveler.id)
        : [...prevSelected, traveler];
    });
  };

  return (
    <table className="w-full border-collapse  rounded-lg overflow-hidden bg-lightBlue2 ">
      <BookingTableHeader
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        data={filteredBookings}
        headers={headers(type)}
      />

      {filteredBookings?.length > 0 ? (
        filteredBookings
          ?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
          ?.map((v, index) =>
            type === "flight" ? (
              <FlightBookingTableRow
                key={v.id}
                index={index}
                v={v}
                type={type}
                bookingStatus={bookingStatus}
                bookingStatusKeys={bookingStatusKeys}
                mutateEstadoReserva={mutateEstadoReserva}
                openModal={openModal}
              />
            ) : type === "package" ? (
              <PackageBookingTableRow
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleRowSelect={handleRowSelect}
                key={v.id}
                index={index}
                v={v}
                type={type}
                bookingStatus={bookingStatus}
                bookingStatusKeys={bookingStatusKeys}
                mutateEstadoReserva={mutateEstadoReservaPaquete}
                openModal={openModal}
                currency={translateCurrency(v?.Packages?.[0]?.currency_type_id)}
              />
            ) : type === "hotel" ? (
              <></>
            ) : null,
          )
      ) : (
        <tr>
          <td colSpan="7" className="my-3 ml-8 text-red-500 ">
            {t(`global.Admin_Bookings.no_booking_found`)} &quot;
            {type === "flight"
              ? t(`global.Header.Vuelos`)
              : type === "package"
                ? t(`global.Header.Paquetes`)
                : t(`global.Header.Hoteles`)}
            &quot;.
            <Link
              to={`${path}${
                type === "flight"
                  ? "package"
                  : type === "package"
                    ? "hotel"
                    : "flight"
              }`}
              className="underline pl-4 text-black"
            >
              {t(`global.Admin_Bookings.see_in`)} &quot;
              {type === "flight"
                ? t(`global.Header.Paquetes`)
                : type === "package"
                  ? t(`global.Header.Hoteles`)
                  : t(`global.Header.Vuelos`)}
              &quot;
            </Link>
          </td>
        </tr>
      )}
    </table>
  );
}
