import React from "react";
import arrow from "../../../../../iconos/down_arrow.png";
import lupa from "../../../../../iconos/lupa.png";
export default function PointOfInterestFilter({
  handleDisplay,
  filters,
  setFilters,
}) {
  return (
    <>
      <section
        onClick={() => handleDisplay("poi")}
        id="poi-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">Punto de Interes</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="poi-arrow"
          />
        </header>
      </section>
      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
        id="poi-body"
      >
        <div className="relative border border-[#8D99AE] flex  px-2 py-2 rounded-lg items-center">
          <div className="flex items-center justify-center rounded-md px-2 py-3 bg-lightBlue/80">
            <img className="w-4" src={lupa} alt="origen" />
          </div>

          <div
            className={`z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md `}
          >
            <input
              type="text"
              value={filters.poi}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  poi: e.target.value,
                })
              }
         
              className=" px-3 pt-1 m-0 w-full rounded cursor-pointer whitespace-pre-wrap transition-colors hover:bg-slate-100 focus:outline-none shadow-none "
            />
          </div>
        </div>
      </section>
    </>
  );
}
