import axios from "axios";
import { useEffect, useState } from "react";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";
import { useTourOperatorStore } from "../store/useTourOperatorStore";

export default function useCommissionsAndTaxes() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const [commissions, setCommissions] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createCommission = async ({ commission }) =>
    axios.post(
      BASE_URL + "v2/commissions",
      {
        commission: { ...commission, tour_op_id: tourOP.id },
      },
      headers,
    );

  const getCommissions = async () => {
    try {
      const response = await axios.get(BASE_URL + "v2/commissions", headers);
      setCommissions(
        response.data.response.filter((com) => com.tour_op_id === tourOP.id),
      );
    } catch (error) {
      // Handle the error properly (throw or log to an external service)
      throw new Error("Failed to fetch commissions: " + error.message);
    }
  };

  const createTax = async ({ tax }) =>
    axios.post(
      BASE_URL + "v2/taxes",
      {
        taxe: { ...tax },
      },
      headers,
    );

  useEffect(() => {
    getCommissions();
  }, []);

  return {
    commissions,
    createCommission,
    getCommissions,
    createTax,
  };
}
