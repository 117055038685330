import { z } from "zod";

export const supplierSchema = z.object({
  name: z.string().min(1, { message: "name_warning" }),
  legal_name: z.string().min(1, { message: "legal_name_warning" }),
  email: z.string().min(1, { message: "email_warning" }).email(),
  tax_code: z.string().min(1, { message: "tax_code_warning" }),
  tax_number: z
    .string()
    .min(1, { message: "tax_number_warning" })
    .refine((val) => !isNaN(Number(val)), {
      message: "tax_number_warning_2",
    })
    .transform((val) => Number(val)),
  address: z.string().min(1, { message: "address_warning" }),
  account_link: z.string().min(1, { message: "account_link_warning" }),
  phone_number: z
    .string()
    .regex(/^[+]?\d+$/, { message: "phone_number_warning_2" })
    .min(1, { message: "phone_number_warning" }),
  contact_name: z.string().min(1, { message: "contact_name_warning" }),
  cellphone_number: z
    .string()
    .regex(/^[+]?\d+$/, { message: "cellphone_number_warning_2" })
    .min(1, { message: "cellphone_number_warning" }),
});
