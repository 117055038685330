import search from "../../iconos/search.png";
import { VersionHeader } from "../staff/versions/ControlVersions";
import { useState } from "react";
import { Link } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";
import SEO from "../../utils/SEO";
import Pagination from "../UI/Pagination";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import CreateFeature from "./CreateFunctionality";
import Versions from "./components/Versions";
import { useVersions } from "../../services/useVersion";
import { Toaster, toast } from "react-hot-toast";
import FunctionalitiesTableRow from "./FunctionalitiesTableRow";
import EditFeature from "./EditFunctionality";
import { useTranslation } from "react-i18next";
import { useFunctionalities } from "../../services/useFunctionalities";
import { ModalGeneric } from "../UI/ModalGeneric";
import PageTitle from "../UI/PageTitle";

export const Faq = () => {
  const [wspText, setWspText] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedFunctionality, setSelectedFunctionality] = useState(null);
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const user = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData"),
  );

  const {
    functionalities,
    getFunctionalities,
    postFunctionality,
    deleteFunctionality,
    voteFunctionality,
    editFunctionality,
  } = useFunctionalities();

  const { versions } = useVersions();

  const openModal = (functionality) => {
    setSelectedFunctionality(functionality);
    toggleVisibility();
  };

  const createFunctionality = (functionality) => {
    toast.promise(
      postFunctionality({ functionality }).then(() => {
        getFunctionalities();
        toggleVisibility();
      }),
      {
        success: <b>{t(`global.Feature_Form.feature_create_toaster`)}</b>,
        loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
        error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
      },
    );
  };

  const handleDeleteFunctionality = () => {
    deleteFunctionality(selectedFunctionality).finally(() => {
      getFunctionalities();
      toggleVisibility();
    });
  };

  const actionVote = (id, votesquantity, option) => {
    const newVotesQuantity = option ? votesquantity + 1 : votesquantity - 1;

    voteFunctionality({
      functionality_id: id,
      votesquantity: newVotesQuantity,
    }).then(() => getFunctionalities());
  };

  const handleEditFunctionality = (editedFunctionality) => {
    const { id, ...restOfFunctionality } = editedFunctionality;

    toast.promise(
      editFunctionality({
        functionality_id: id,
        functionality: restOfFunctionality,
      }).then(() => {
        getFunctionalities();
        toggleVisibility();
      }),
      {
        success: <b>{t(`global.Feature_Form.feature_edit_toaster`)}</b>,
        loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
        error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
      },
    );
  };

  return (
    <>
      <SEO title={"Ayuda"} />
      <div>
        <Toaster />
      </div>
      <main className="mx-auto w-full mt-10 font-body">
        {/* Header Functionalities */}
        <header className="flex justify-between items-center mb-4">
          <div>
            <PageTitle
              title={"help_title"}
              subTitle={"help_subtitle"}
              route={"panel/packages/add-package"}
              btnText={"help_btn"}
              buttonVisivility={false}
            />
          </div>
          <div className="flex items-center w-[604px] justify-end">
            <button
              onClick={() => {
                toggleVisibility();
                setSelectedModal("post");
              }}
              className="button bg-darkBlue text-white whitespace-nowrap px-2 hover:shadow-md transition-all"
            >
              {t(`global.Help_Page.add_feature`)}
            </button>
          </div>
        </header>
        {/* Table */}
        <section className="p-4 border-2 border-gray2 bg-white rounded-lg">
          <div className="mb-4 border bg-lightBlue2 border-[#CDCDCD] outline-none w-[368px] h-12 rounded-xl relative">
            <img
              src={search}
              alt="Filtrar por nombre de gestion"
              className="w-4 h-4 absolute left-3 top-4"
            />
            <input
              onChange={(e) => setInputSearch(e.target.value)}
              value={inputSearch}
              placeholder={t(`global.Help_Page.search_placeholder`)}
              className="w-full pl-10 outline-none h-full rounded-xl bg-transparent"
            />
          </div>
          <header className="bg-lightBlue grid grid-flow-row grid-cols-12 gap-8 py-2 px-8 rounded-lg">
            <h4 className="font-medium text-brightBlue col-span-2">
              {t(`global.Help_Page.name`)}{" "}
            </h4>
            <h4 className="font-medium text-brightBlue mx-auto col-span-2">
              {t(`global.Help_Page.vote`)}
            </h4>
            <h4 className="font-medium text-brightBlue col-span-6">
              {t(`global.Help_Page.description`)}
            </h4>
            <h4 className="font-medium text-brightBlue mx-auto">
              {" "}
              {t(`global.Help_Page.state`)}{" "}
            </h4>
            <h4 className="font-medium text-brightBlue mx-auto whitespace-nowrap">
              {t(`global.Help_Page.video`)}
            </h4>
          </header>

          {functionalities ? (
            functionalities
              .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              .filter((element) =>
                inputSearch?.length
                  ? element.name
                      .toLowerCase()
                      .includes(inputSearch?.toLowerCase())
                  : true,
              )
              ?.map((functionality, index) => (
                <FunctionalitiesTableRow
                  key={functionality.id}
                  functionality={functionality}
                  index={index}
                  actionVote={actionVote}
                  openModal={openModal}
                  setSelectedModal={setSelectedModal}
                  isAdmin={user?.id === 1}
                />
              ))
          ) : (
            <div>
              <p className="p-2.5">
                {" "}
                {t(`global.Help_Page.no_features_found`)}
              </p>
            </div>
          )}
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {selectedModal === "post" && (
            <CreateFeature createFunctionality={createFunctionality} />
          )}
          {selectedModal === "delete" && (
            <div className="flex flex-col items-center gap-2">
              <h2>
                {t(`global.Help_Page.delete_ask`)}{" "}
                <b>{selectedFunctionality}</b>?
              </h2>
              <div className="flex justify-center gap-2">
                <button
                  className="py-1 px-2 rounded font-medium border border-gray2"
                  onClick={toggleVisibility}
                >
                  {t(`global.Actions.cancel`)}
                </button>
                <button
                  className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                  onClick={handleDeleteFunctionality}
                >
                  {t(`global.Actions.delete`)}
                </button>
              </div>
            </div>
          )}
          {selectedModal === "edit" && (
            <EditFeature
              editFunctionality={handleEditFunctionality}
              functionality={selectedFunctionality}
            />
          )}
        </ModalGeneric>

        <Pagination
          items={functionalities}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          page={page}
          setPage={setPage}
        />

        {/* State Product Title */}
        <section className="flex justify-between mt-14">
          <div>
            <h1 className="font-[700] text-darkBlue text-3xl">
              {t(`global.Help_Page.product_state`)}
            </h1>
            <span className="text-darkBlue">
              {t(`global.Help_Page.subtitle_product`)}
            </span>
          </div>
        </section>

        {/* Versions */}
        <section className="my-8 w-full">
          <div className="r-0 flex justify-end">
            <VersionHeader />
          </div>
          {versions && versions.length > 0 ? (
            versions?.map((version) => (
              <Versions key={version.id} version={version} />
            ))
          ) : (
            <span>{t(`global.Help_Page.no_version_found`)}</span>
          )}
        </section>

        {/* Send comments */}
        <section className="flex justify-between gap-4 mb-12">
          <div className="flex flex-col gap-4 w-3/4">
            <input
              onChange={(e) => setWspText(e.target.value)}
              className="border border-[#D9D9D9] h-full outline-none rounded-md px-4 bg-white"
              placeholder={t(`global.Help_Page.leave_comments`)}
            />
          </div>
          <Link
            className="w-fit whitespace-nowrap flex justify-between"
            to={`http://wa.me/3512051784?text=${wspText}`}
          >
            <p className="h-full bg-[#28B15C] px-4 text-white flex items-center gap-2 justify-center rounded-lg w-4/5 font-semibold">
              {t(`global.Help_Page.write_whatsApp`)}
            </p>
            <WhatsApp
              alt="enviar por wsahp"
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "#28B15C",
                borderRadius: "8px",
                color: "white",
                padding: "5px",
              }}
            />
          </Link>
        </section>
      </main>
    </>
  );
};
