import SEO from "../../utils/SEO";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { useEffect } from "react";
import FormAddAgency from "./FormAddAgency";
import AgenciesTable from "./AgenciesTable";
import PanelSelector from "./PanelSelector";
import { useTranslation } from "react-i18next";
import { useTourOperatorStore } from "../../store/useTourOperatorStore";
import useAgencies from "../../services/useAgencies";
import { ModalGeneric } from "../UI/ModalGeneric";
import PageTitle from "../UI/PageTitle";

export const PanelAgencies = () => {
  const tourOP = useTourOperatorStore((st) => st.tourOP.id);
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { agencies, deleteAgency, getAgenciesByTourOperator } = useAgencies();
  const { t } = useTranslation();
  useEffect(() => {
    getAgenciesByTourOperator();
  }, [tourOP]);

  const closeModalAddAgency = () => {
    toggleVisibility();
    getAgenciesByTourOperator();
  };

  return (
    <>
      <SEO title={"Administrador de Agencias"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <div className="mt-4">
          <PanelSelector type={"agencies"} />
        </div>
        <section className="my-8">
          <header className="flex justify-between items-center">
            <PageTitle
              title={"agency_title"}
              subTitle={"agency_subtitle"}
              route={""}
              btnText={"agency_btn"}
              buttonVisivility={false}
            />
            <button
              className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
              onClick={toggleVisibility}
            >
              {t(`global.Admin_Titles.agency_btn`)}
            </button>
          </header>

          <AgenciesTable
            useAgencies={{ agencies, deleteAgency, getAgenciesByTourOperator }}
          />
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          <FormAddAgency closeModal={closeModalAddAgency} />
        </ModalGeneric>
      </main>
    </>
  );
};
