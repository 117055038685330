import "../styles/head.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToggleVisibility } from "../hooks/useToggleVisibility";
import { Login } from "./auth/Login";
import { useState, useEffect, useCallback } from "react";
import Navbar from "./home/navbar/Navbar";
import { ALL_HEADER_OPTIONS } from "../utils/HeaderOptions";
import PageMenu from "./home/navMenu/PageMenu";
import { ModalGeneric } from "./UI/ModalGeneric";

const generateHeaderOptions = (permissions) =>
  ALL_HEADER_OPTIONS.filter(({ label }) => permissions.includes(label));

const Head = ({ noOptions }) => {
  const { tour_op_id, type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isVisible, toggleVisibility] = useToggleVisibility();

  const [opertatorPermissions, setOperatorPermissions] = useState(null);

  const headerOptions =
    opertatorPermissions && generateHeaderOptions(opertatorPermissions);

  const firstNavigableRoute = useCallback(() => {
    if (!opertatorPermissions || opertatorPermissions.length === 0)
      {return "flights";}

    const [firstModule] = opertatorPermissions;

    const foundOption = headerOptions.find(
      ({ label }) => label === firstModule,
    );

    return foundOption ? foundOption.path : "flights";
  }, [opertatorPermissions, headerOptions]); // Add dependencies

  // MANEJO DE "PATHS" SEGÚN PERMISOS DE OPERADORA
  useEffect(() => {
    if (!opertatorPermissions) return;

    const findCurrentPathModule = headerOptions.find(
      ({ path }) => path === type,
    );

    const pathToCheck = ALL_HEADER_OPTIONS.map(({ path }) =>
      path !== "search-activities" ? path : null,
    );

    const path = firstNavigableRoute();
    if (
      (!findCurrentPathModule && pathToCheck.includes(type)) ||
      // CHECKEAR QUE TENGA ALGÚN TYPE
      window.location.href.split("/").length === 4
    ) {
      navigate(`/${tour_op_id}/${path}`);
    }
  }, [
    opertatorPermissions,
    firstNavigableRoute,
    navigate,
    headerOptions,
    tour_op_id,
    type,
  ]);

  // MANEJO DE isVisible SEGÚN RUTA PARA MODAL DE LOGIN
  useEffect(() => {
    const { pathname } = location;

    if (isVisible && !pathname.includes("login")) {
      navigate(`/${tour_op_id}/login`);
    } else if (!isVisible && pathname.includes("login")) {
      toggleVisibility();
    }
  }, [isVisible, location, navigate, toggleVisibility, tour_op_id]);

  const closeModal = () => {
    toggleVisibility();
    navigate(`/${tour_op_id}/${firstNavigableRoute()}`);
  };

  return (
    <div className="bg-white mx-auto max-w-[1338.46px]">
      <Navbar firstNavigableRoute={firstNavigableRoute} />
      <PageMenu
        noOptions={noOptions}
        opertatorPermissions={opertatorPermissions}
        location={location}
        setOperatorPermissions={setOperatorPermissions}
        headerOptions={headerOptions}
      />
      <ModalGeneric show={isVisible} toggle={closeModal}>
        <Login closeModal={closeModal} />
      </ModalGeneric>
    </div>
  );
};

export default Head;
