import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import espanish from "./es/es.json";
import english from "./en/en.json";
import { saveLocale } from "../utils/LocaleFinder";

// Initialize `i18n` with default language
i18n.use(initReactI18next).init({
  resources: {
    es: { translation: espanish }, // Spanish translations
    en: { translation: english }, // English translations
  },
  lng: "es", // Default language
  interpolation: { escapeValue: false },
});

// Dynamically set language based on Zustand's locale
const updateLanguage = (locale) => i18n.changeLanguage(locale);

// Set initial language
updateLanguage(saveLocale.getState().locale);

// Subscribe to `saveLocale` state changes
saveLocale.subscribe((state) => {
  updateLanguage(state.locale);
});

export default i18n;
