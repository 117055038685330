import { useTranslation } from "react-i18next";

export default function MidFooter() {
  const { t } = useTranslation();
  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-row gap-4 w-full leading-0">
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          {t("global.Footer.Institutional")}
        </a>
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          {t("global.Footer.conditional")}
        </a>
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          {t("global.Footer.privacy_politcs")}
        </a>
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          {t("global.Footer.undo")}
        </a>
      </div>
    </div>
  );
}
