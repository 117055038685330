import arrow from "../../iconos/chevron_down_gray.png";
import { useTranslation } from "react-i18next";

const handleDisplay = (item) => {
  const body = document.getElementById(`${item}-body`);
  const arrow = document.getElementById(`${item}-arrow`);
  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};

export default function TravelersFilters({
  dataToUse,
  user,
  filtersState,
  tourOP,
}) {
  const { t } = useTranslation();
  const [agencies, users] = dataToUse;
  const [filters, setFilters] = filtersState;
  const travelersCategories = [
    { id: 1, title: "none" },
    { id: 2, title: "management" },
    { id: 3, title: "technical_staff" },
    { id: 4, title: "players" },
    { id: 5, title: "reserve" },
    { id: 6, title: "roster" },
    { id: 7, title: "youth" },
    { id: 8, title: "lower_divisions" },
    { id: 9, title: "other1" },
    { id: 10, title: "other2" },
    { id: 11, title: "other3" },
  ];

  const handleChecks = (evt, filter) => {
    setFilters((curr) => ({
      ...curr,
      [filter]: {
        ...curr[filter],
        [evt.target.id]: evt.target.checked,
      },
    }));
  };

  const isOperatorUser = user.agency_id === 1;
  return (
    <ul className={`grid grid-cols-${tourOP.id === 1 ? "4" : "3"} gap-8`}>
      {(isOperatorUser || tourOP.id === 1) && agencies?.length > 0 && (
        <li className="relative">
          <section
            onClick={() => handleDisplay("agencies")}
            id="agencies-header"
            className="cursor-pointer"
          >
            <header className="flex gap-2 items-center py-1 px-2 rounded-md bg-lightBlue">
              <h2 className="font-low">{t(`global.Config.agency`)}</h2>
              <img
                src={arrow}
                alt="arrow"
                className="w-4 h-2 rotate-0 transition-all"
                id="agencies-arrow"
              />
            </header>
          </section>
          <section
            className="absolute top-10 scale-y-0 h-0 rounded-md bg-white shadow py-1 px-2 mb-2 grid gap-2 overflow-y-scroll transition-all duration-200 origin-top"
            id="agencies-body"
            style={{ maxHeight: "calc(6 * 2.5rem)" }}
          >
            {Object.keys(filters.agencies).map((el) => {
              const { name } =
                agencies.find((item) => item.id === Number(el)) ?? null;

              return (
                <label className="flex gap-2" key={el}>
                  <input
                    type="checkbox"
                    id={el}
                    name="agencies"
                    checked={filters.agencies[el]}
                    onChange={(evt) => handleChecks(evt, "agencies")}
                  />

                  <p className="whitespace-nowrap">{name}</p>
                </label>
              );
            })}
          </section>
        </li>
      )}

      {users?.length > 0 && (
        <li className="relative">
          <section
            onClick={() => handleDisplay("users")}
            id="users-header"
            className="cursor-pointer"
          >
            <header className="flex gap-2 items-center py-1 px-2 rounded-md bg-lightBlue">
              <h2 className="font-low">{t(`global.Config.users`)}</h2>
              <img
                src={arrow}
                alt="arrow"
                className="w-4 h-2 rotate-0 transition-all"
                id="users-arrow"
              />
            </header>
          </section>
          <section
            className="absolute top-10 scale-y-0 h-0 rounded-md bg-white shadow py-1 px-2 mb-2 grid gap-2 overflow-y-scroll transition-all duration-200 origin-top"
            id="users-body"
            style={{ maxHeight: "calc(6 * 2.5rem)" }}
          >
            {Object.keys(filters.users).map((el) => {
              const { username } =
                users.find((item) => item.id === Number(el)) ?? null;

              return (
                <label className="flex gap-2" key={el}>
                  <input
                    type="checkbox"
                    id={el}
                    name="users"
                    checked={filters.users[el]}
                    onChange={(evt) => handleChecks(evt, "users")}
                  />

                  <p className="whitespace-nowrap">{username}</p>
                </label>
              );
            })}
          </section>
        </li>
      )}
      {users?.length > 0 && (
        <li className="relative">
          <section
            onClick={() => handleDisplay("category")}
            id="category-header"
            className="cursor-pointer"
          >
            <header className="flex gap-2 items-center py-1 px-2 rounded-md bg-lightBlue">
              <h2 className="font-low">{t(`global.Tour.category`)}</h2>
              <img
                src={arrow}
                alt="arrow"
                className="w-4 h-2 rotate-0 transition-all"
                id="category-arrow"
              />
            </header>
          </section>
          <section
            className="absolute top-10 scale-y-0 h-0 rounded-md bg-white shadow py-1 px-2 mb-2 grid gap-2 overflow-y-scroll transition-all duration-200 origin-top"
            id="category-body"
            style={{ maxHeight: "calc(6 * 2.5rem)" }} // Adjusting maxHeight to accommodate 8 elements
          >
            {travelersCategories.map((category) => {
              const { id, title } = category;

              return (
                <label className="flex gap-2" key={id}>
                  <input
                    type="checkbox"
                    id={id}
                    name="category"
                    checked={filters.category?.[id] || false}
                    onChange={(evt) => handleChecks(evt, "category")}
                  />
                  <p className="whitespace-nowrap">
                    {t(`global.Admin_Travelers.${title}`)}
                  </p>
                </label>
              );
            })}
          </section>
        </li>
      )}
    </ul>
  );
}
