import { create } from "zustand";

const useReloadStore = create((set) => ({
  reloadFees: false,
  reloadTours: false,
  reloadPackages: false,
  reloadQuotes: false,
  setReloadFees: () => set((state) => ({ reloadFees: !state.reloadFees })),
  setReloadTours: () => set((state) => ({ reloadTours: !state.reloadTours })),
  setReloadPackages: () =>
    set((state) => ({ reloadPackages: !state.reloadPackages })),
  setReloadQuotes: () =>
    set((state) => ({ reloadQuotes: !state.reloadQuotes })),
}));

export default useReloadStore;
