import { useToggleVisibility } from "../../../../../../hooks/useToggleVisibility";
import { MdFileCopy } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useReloadStore from "../../../../../../store/useRealoadStore";
import { useTourOperatorStore } from "../../../../../../store/useTourOperatorStore";
import { useUserStore } from "../../../../../../store/useUserStore";
import { useFees } from "../../../../../../services/useFees";
import { ModalGeneric } from "../../../../../UI/ModalGeneric";

export default function DuplicateFee({ fee, tourId, package_id }) {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const setReloadFees = useReloadStore((state) => state.setReloadFees);
  const { getFees, createFee } = useFees();
  const { user } = useUserStore();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { t } = useTranslation();
  const handleCopyPackage = () => {
    const fee2 = {
      fee: {
        base: fee.base,
        currency_type_id: fee.currency_type_id,
        fare: fee.fare,
        price: fee.price,
        tax: fee.tax,
        tours_id: tourId,
        id_tours: tourId,
        tour_op_id: tourOP.id,
        user_id: user.id,
        id_package: package_id,
      },
    };

    createFee(fee2).finally(() => {
      getFees();
      setReloadFees((prev) => !prev);
    });
  };

  return (
    <>
      <button
        className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
        onClick={toggleVisibility}
      >
        <MdFileCopy scale={23} />
        <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/copy:scale-100">
          {t(`global.Common_Words.duplicate`)}
        </span>
      </button>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <div className="flex flex-col items-center gap-2">
          <h2>
            {t(`global.Fee.duplicate_message`)} <b>{fee.name}</b>?
          </h2>
          <div className="flex justify-center gap-2">
            <button
              className="py-1 px-2 rounded font-medium border border-gray2"
              onClick={toggleVisibility}
            >
              {t(`global.Actions.cancel`)}
            </button>
            <button
              className="py-1 px-2 rounded font-medium border border-brightBlue bg-brightBlue text-white"
              onClick={handleCopyPackage}
            >
              {t(`global.Actions.duplicate`)}
            </button>
          </div>
        </div>
      </ModalGeneric>
    </>
  );
}
