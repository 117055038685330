import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";

export default function WarningDelete({ message }) {
  const warning = () => {
    toast.error(t(`global.Admin_Packages.${message}`));
  };
  const { t } = useTranslation();
  return (
    <>
      <button
        className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group"
        onClick={warning}
      >
        <MdDelete scale={23} />
        <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover:scale-100">
          {t(`global.Admin_Packages.${message}`)}
        </span>
      </button>
      <Toaster />
    </>
  );
}
