import Searcher from "./SearchQuotesHeader";
import SEO from "../../utils/SEO";
import SearchQuotesResults from "./SearchQuotesResults";
import { useEffect, useState } from "react";
import Anicarga from "../anicarga";
import { monthDictionary } from "../../utils/dictionaries/monthsDictionary";
import SelectedQuote from "./SelectedQuote";
import FlexibleQuotes from "./FlexibleQuotes";
import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";
import { translateToDec, translatedDuration } from "../vuelos";
import { useExchangeRateStore } from "../../store/useExchangeRateStore";
import { useGetFlights } from "../../services/useGetFlights";

const allAirlinesFormatted = Object.keys(AIRLINES_DICTIONARY).reduce(
  (acc, key) => ({
    ...acc,
    [key]: false,
  }),
  {},
);

const initialFilterValues = {
  directo: false,
  sin_USA: false,
  equipaje: false,
  business: false,
  scales: {
    0: false,
    1: false,
    2: false,
  },
  maxDuration: 0,
  airlines: allAirlinesFormatted,
};

const formatDate = (date) => {
  const day = date.slice(0, 2);
  const month = monthDictionary(date.slice(2));

  return `2024-${month}-${day}`;
};

const generateQuery = (data, index, routeOption, inputValue) => {
  const oricode = `oricode${index}`;
  const descode = `descode${index}`;
  const departureFlight = `departureDate${index}`;
  const returnFlight = `returnDate${index}`;

  const originLocationCode =
    data[oricode].length === 3
      ? data[oricode].toUpperCase()
      : data[oricode].slice(1, 4).toUpperCase();

  const destinationLocationCode =
    data[descode].length === 3
      ? data[descode].toUpperCase()
      : data[descode].slice(1, 4).toUpperCase();

  const departureDate = inputValue?.[index]?.[departureFlight]
    ? formatDate(inputValue?.[index]?.[departureFlight].toUpperCase())
    : data?.[departureFlight];

  const hasReturnDate =
    (routeOption === "roundtrip" || routeOption === "flexible") &&
    (inputValue?.[index]?.[returnFlight] || data?.[returnFlight]);

  const returnDate = hasReturnDate
    ? inputValue?.[index]?.[returnFlight]
      ? formatDate(inputValue?.[index]?.[returnFlight].toUpperCase())
      : data?.[returnFlight]
    : false;

  const adults = inputValue?.ADT ?? "1";
  const children = inputValue?.CHD;
  const infants = inputValue?.INF;
  const { travelClass } = data;

  return [
    `originLocationCode=${originLocationCode}&destinationLocationCode=${destinationLocationCode}&departureDate=${departureDate}${
      hasReturnDate ? `&returnDate=${returnDate}` : ""
    }&adults=${adults}${children ? `&children=${children}` : ""}${
      infants ? `&infants=${infants}` : ""
    }${travelClass ? `&travelClass=${travelClass}` : ""}`,
    {
      originLocationCode,
      destinationLocationCode,
      departureDate,
      returnDate,
      adults,
      children,
      infants,
      travelClass,
    },
  ];
};

export default function SearchQuotes() {
  // const { tour_op_id } = useParams();
  const { loading, getFlights, getFlexibleFlights } = useGetFlights();
  const exchangeRate = useExchangeRateStore((st) => st.exchangeRate);
  const [inputValue, setInputValue] = useState({
    ADT: 1,
    CHD: 0,
    INF: 0,
  });
  const [flightOptions, setFlightOptions] = useState();
  const [routeOption, setRouteOption] = useState("roundtrip");
  const [numberOfSection, setNumberOfSection] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const [filters, setFilters] = useState(initialFilterValues);
  const { ADT, CHD, INF } = inputValue;

  const setNewRouteOption = (option) => {
    setFlightOptions();
    setRouteOption(option);
    setSelectedOption({
      origin: new Array(option === "multidestination" ? 2 : 1).fill([]),
      destination: new Array(option === "multidestination" ? 2 : 1).fill([]),
    });
  };

  const onSubmit = async (data) => {
    const getSingleFlights = async (index) => {
      try {
        const [query, formattedQuery] = generateQuery(
          data,
          index,
          routeOption,
          inputValue,
        );

        const amadeusResponse =
          routeOption !== "flexible"
            ? // ? await getFlights(query)
              await getFlights({
                stage: "quotes",
                amadeusData: query,
                aramixData: formattedQuery,
              })
            : await getFlexibleFlights(query);

        return amadeusResponse;
      } catch (error) {
        throw new Error(`Error fetching flights for index ${index}:`, error);
      }
    };

    setSelectedOption({
      origin: new Array(routeOption === "multidestination" ? 2 : 1).fill([]),
      destination: new Array(routeOption === "multidestination" ? 2 : 1).fill(
        [],
      ),
    });

    // let amadeusResponse;
    if (routeOption === "multidestination") {
      await getSingleFlights(1).then((e) => {
        // amadeusResponse = [[e.flights, e.original]];
        getSingleFlights(2).then((e2) => {
          // amadeusResponse.concat([e2.flights, e2.original]);
          setFlightOptions([e, e2]);
        });
      });
    } else {
      const response = await getSingleFlights(1);
      // console.log(response)
      setFlightOptions([response]);
      // amadeusResponse = response;
      // return response;
    }
  };

  const includedBagsByFlight = {};
  const cabinByFlight = {};

  const getCabinByFlights = (original) => {
    original.forEach((pack) => {
      pack?.travelerPricings?.[0]?.fareDetailsBySegment?.forEach((fl) => {
        const { segmentId, includedCheckedBags, cabin } = fl;

        includedBagsByFlight[segmentId] = includedCheckedBags?.quantity;
        cabinByFlight[segmentId] = cabin;
      });
    });
  };

  let minFlightDuration = 5000;
  let maxFlightDuration = 0;
  const getMaxDurationFlight = () => {
    const originalOptions =
      flightOptions &&
      flightOptions?.map(({ flights }) => Object.entries(flights) || {});
    originalOptions?.forEach((option) => {
      option.forEach((flight) => {
        const origen = translatedDuration(flight?.[1]?.origin?.[0]?.duration);
        const destino = translatedDuration(
          flight?.[1]?.destination?.[0]?.duration,
        );

        if (origen > maxFlightDuration) maxFlightDuration = origen;
        if (destino > maxFlightDuration) maxFlightDuration = destino;
        if (origen < minFlightDuration) minFlightDuration = origen;
        if (destino < minFlightDuration) minFlightDuration = destino;
      });
    });
  };

  if (
    !loading &&
    flightOptions?.[0] && // Ensure flightOptions[0] is not null or undefined
    Object.prototype.hasOwnProperty.call(flightOptions[0], "flights") &&
    routeOption !== "flexible"
  ) {
    getMaxDurationFlight();
  }

  useEffect(() => {
    setFilters((curr) => ({
      ...curr,
      maxDuration: translateToDec(maxFlightDuration),
    }));
  }, [loading]);

  const filterOptions = (options, original) => {
    if (loading) return options;
    if (!options || !original) return options;

    getCabinByFlights(original);
    const {
      directo,
      sin_USA,
      equipaje,
      business,
      scales,
      airlines,
      maxDuration,
    } = filters;
    const checkedBags = [equipaje ? 1 : 0];
    const checkedAirlines = Object.keys(airlines).filter((el) => airlines[el]);
    const checkedScales = Object.keys(scales).filter((el) => scales[el]);

    const filterSegments = (data) =>
      data
        .filter((el) => {
          if (!directo) return el;
          return el.segments.length === 1;
        })
        .filter((el) => {
          if (!sin_USA) return el;
          return el;
        })
        .filter((el) => {
          if (!equipaje) return el;
          const arrBags = el.segments.filter((x) => {
            const thisFlightBags = includedBagsByFlight[x.id] === 0 ? 0 : 1;
            return checkedBags.includes(thisFlightBags);
          });
          return arrBags.length > 0;
        })
        .filter((el) => {
          if (!business) return el;
          return el.segments.every(
            (segment) => cabinByFlight[segment.id] !== "ECONOMY",
          );
        })
        .filter((el) => {
          if (checkedScales.length === 0) return el;
          return Number(checkedScales.at(-1)) >= el.segments.length - 1;
        })
        .filter((el) => {
          if (checkedAirlines.length === 0) return el;

          const arrCarrierCodes = el.segments.filter((x) =>
            checkedAirlines.includes(x.carrierCode),
          );
          return arrCarrierCodes.length > 0;
        })
        .filter((el, index) =>
          index !== 0 ? true : translatedDuration(el?.duration) <= maxDuration,
        );

    return (
      options &&
      options
        .map((opt) => {
          const { origin, destination } = opt[1];

          const filteredOrigen = filterSegments(origin);
          const filteredDestino =
            routeOption === "roundtrip" && filterSegments(destination);

          if (
            routeOption === "roundtrip" &&
            filteredOrigen.length > 0 &&
            filteredDestino.length > 0
          ) {
            return [
              opt[0],
              { origin: filteredOrigen, destination: filteredDestino },
            ];
          }
          if (routeOption !== "roundtrip" && filteredOrigen.length > 0) {
            return [opt[0], { origin: filteredOrigen }];
          }
          return null;
        })
        .filter((el) => el)
    );
  };

  const sections = routeOption !== "multidestination" ? [0] : [0, 1];
  const selectedBySection =
    flightOptions &&
    sections.map((index) => {
      const checkStages =
        routeOption === "roundtrip"
          ? selectedOption.origin[index].filter((id) =>
              selectedOption.destination[index].includes(id),
            )
          : selectedOption.origin[index];

      const selected = checkStages
        .map((id) =>
          flightOptions[index].original.filter((opt) => opt.id === id),
        )
        .flat();

      return selected;
    });

  const isAnyOptionSelected =
    flightOptions &&
    selectedBySection &&
    selectedBySection.some((item) => item.length > 0);

  return (
    <main className="h-full flex flex-col gap-8 font-body">
      <SEO title={"Cotizador"} />
      <header className="flex justify-between items-center mt-8">
        <div>
          <h1 className="text-darkBlue font-[700] text-3xl">
            Nueva Cotización
          </h1>
          <h2 className="text-darkBlue/70 text-lg">
            Controlá, Gestioná y Personalizá cada uno de tus paquetes.
          </h2>
        </div>
        {/* <Link
          to={`/${tour_op_id}/panel/quote/my-quotes`}
          className="w-44 rounded-md flex justify-center items-center text-white h-12 text-lg bg-brightBlue"
        >
          Mis Cotizaciones
        </Link> */}
        {/* <button className="w-44 px-8 rounded-md text-white h-12 text-lg bg-brightBlue flex items-center gap-2">
          <img src={calendar} alt="calendario" />
          <p>Calendario</p>
        </button> */}
      </header>
      <Searcher
        onSubmit={onSubmit}
        setNewRouteOption={setNewRouteOption}
        routeOption={routeOption}
        inputState={[inputValue, setInputValue]}
        filterState={[filters, setFilters]}
        minmaxDuration={[
          translateToDec(minFlightDuration),
          translateToDec(maxFlightDuration),
        ]}
      />
      {loading && (
        <div className="w-full flex mt-14 justify-center">
          <Anicarga />
        </div>
      )}

      {!loading && flightOptions && routeOption === "flexible" && (
        <FlexibleQuotes flights={flightOptions} />
      )}

      {!loading && flightOptions && routeOption !== "flexible" && (
        <section className="flex gap-4 2xl:gap-6 max-w-full">
          <div className="w-full">
            {routeOption === "multidestination" && (
              <section className="flex gap-3 mb-3">
                {sections.map((btn) => (
                  <button
                    key={btn}
                    type="button"
                    className={`rounded-3xl py-1 px-3 text-sm text-white bg-brightBlue transition-all hover:shadow ${
                      numberOfSection === btn &&
                      "pointer-events-none bg-darkBlue"
                    }`}
                    onClick={() => setNumberOfSection(btn)}
                  >
                    Tramo {btn + 1}
                  </button>
                ))}
              </section>
            )}
            {!flightOptions?.[0] ? (
              <h1>No se encontraron vuelos</h1>
            ) : (
              flightOptions.map(({ flights, original }, index) => (
                <SearchQuotesResults
                  key={index}
                  index={index}
                  flights={flights}
                  options={filterOptions(Object.entries(flights), original)}
                  exchangeRate={exchangeRate}
                  numberOfSection={numberOfSection}
                  routeOption={routeOption}
                  original={original}
                  wider={isAnyOptionSelected}
                  loading={loading}
                  state={[selectedOption, setSelectedOption]}
                />
              ))
            )}
          </div>
          {isAnyOptionSelected && (
            <SelectedQuote
              selectedOption={selectedBySection}
              original={flightOptions.map((og) => og.original)}
              routeOption={routeOption}
              exchangeRate={exchangeRate}
              setSelectedOption={setSelectedOption}
              passengers={{ ADT, CHD, INF }}
            />
          )}
        </section>
      )}
    </main>
  );
}
