import { useTranslation } from "react-i18next";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";

export default function FooterSocials({ TourOperatorSocialMedia }) {
  const { t } = useTranslation();
  const ig = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Instagram",
  )?.url;
  const fb = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Facebook",
  )?.url;
  const wpp = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Whatsapp",
  )?.url;
  return (
    <div>
      <h3 className="text-xl font-medium ">{t("global.Footer.socials")}</h3>

      {ig && (
        <li>
          <a target="_blank" href={ig} rel="noreferrer">
            <FiInstagram fontSize={24} />
          </a>
        </li>
      )}
      {wpp && (
        <a target="_blank" href={`https://wa.me/${wpp}`} rel="noreferrer">
          <FaWhatsapp fontSize={24} />
        </a>
      )}
      {fb && (
        <li className="relative right-1.5">
          <a target="_blank" href={fb} rel="noreferrer">
            <FaFacebook fontSize={24} />
          </a>
        </li>
      )}
    </div>
  );
}
