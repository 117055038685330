import SEO from "../../utils/SEO";
import { useParams } from "react-router-dom";
import BookingTable from "./bookingsTables/BookingTable";
import BookingSelector from "./bookingSelector/BookingSelector";
import PageTitle from "../UI/PageTitle";

export const BOOKINGS_STATUS = [
  { key: "allBookings", label: "Todas las Reservas" },
  { key: "pending", label: "Pendiente" },
  // { key: "to-book", label: "Reservar" },
  { key: "for-ticketing", label: "Ok p Emisión" },
  { key: "to-ticket", label: "Emitir" },
  { key: "confirmed", label: "Confirmado" },
];

export default function MyBookings() {
  const { type, tour_op_id } = useParams();

  return (
    <>
      <SEO title={"Mis Reservas"} />
      <main className="flex flex-col w-full mt-8 font-body">
        <header className="flex justify-between mb:flex-col md:flex-row items-center mb-4 ">
          <PageTitle title={"bookings_title"} subTitle={"bookings_subtitle"} />
          <BookingSelector tour_op_id={tour_op_id} type={type} />
        </header>
        <BookingTable
          bookingStatus={BOOKINGS_STATUS}
          path={`/${tour_op_id}/panel/sales/bookings/`}
        />
      </main>
    </>
  );
}
