import { z } from "zod";

export const tourSchema = z.object({
  dateIn: z.string().min(1, { message: "dateIn_warning" }),
  dateOut: z.string().min(1, { message: "dateOut_warning" }),
  hotels: z.string(),
  category: z.string(),
  meal_plan: z.string(),
  days: z
    .number({ invalid_type_error: "day_warning" })
    .refine((value) => value >= 1, { message: "day_warning" }),
  nights: z
    .number({ invalid_type_error: "night_warning" })
    .refine((value) => value >= 1, { message: "night_warning" }),
  stock: z
    .number({ invalid_type_error: "stock_warning" })
    .refine((value) => value >= 1, { message: "stock_warning" }),
  comments: z.string(),
});
