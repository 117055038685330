import { useTranslation } from "react-i18next";
import logo_walltrip from "../../../../iconos/logos/walltrip.png";
export default function LowFooter() {
  const { t } = useTranslation();
  return (
    <div className="w-4/5 bg-white rounded-xl  ">
      <div className="flex flex-row gap-4 w-full leading-0 items-center ">
        <p className="text-[.9rem] m-0 flex-grow ml-2">
          ©️2024 · {t("global.Footer.all_rights")}
        </p>
        <button className=" rounded-lg">
          <div className=" flex flex-row items-center gap-2">
            <p className="text-xl font-medium">
              {t("global.Footer.made_with")}
            </p>
            <a
              target="_blank"
              href={"http://www.thewalltrip.com"}
              rel="noreferrer"
            >
              <img
                className="text-[.9rem] m-0 flex-grow  max-w-[108px] max-h-10"
                alt="404 not found"
                src={logo_walltrip}
              />
            </a>
          </div>
        </button>
      </div>
    </div>
  );
}
