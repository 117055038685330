import useGetCurrencies from "../../../../../../../services/useGetCurrencies";

export default function SelectFeeCurrency({ feeEdit, fee, setFeeEdit }) {
  const { currencies } = useGetCurrencies();

  return (
    <select
      name="currency_type_id"
      id="currency_type_id"
      className="addPackInput w-full"
      value={feeEdit.currency_type_id || fee.currency_type_id || ""}
      onChange={(e) =>
        setFeeEdit({
          ...feeEdit,
          currency_type_id: e.target.value,
        })
      }
    >
      {currencies?.map(({ name, symbol, id }) => (
        <option key={id} value={id}>
          {name} ({symbol})
        </option>
      ))}
    </select>
  );
}
