import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import PackageDetailFeeSection from "../../packageDetail/detailSections/tourSection/feeSection/PackageDetailFeeSection";
import { useTranslation } from "react-i18next";
import { useFees } from "../../../../services/useFees";

export default function PackageLinkTourSection({
  tours,
  setSelectedTour,
  setSelectedFee,
}) {
  const [selectedFeeId, setSelectedFeeId] = useState("");
  const { getFeesForLink } = useFees();
  const [allFees, setAllFees] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (tours && tours.length > 0) {
      const fetchFeesForTours = async () => {
        const feesPromises = tours.map((tour) => getFeesForLink(tour.id));
        const feesResults = await Promise.all(feesPromises);
        setAllFees(feesResults.flat());
      };

      fetchFeesForTours();
    }
  }, [tours]);

  return (
    <div className="overflow-x-auto p-6 shadow-lg rounded-lg bg-white mt-4">
      {tours.map((tour, tourKey) => (
        <React.Fragment key={tourKey}>
          <h1 className="font-semibold">
            {t(`global.Tour.tour`)} &nbsp; {tourKey + 1}:
          </h1>
          <table
            className={`w-full bg-white text-center hover:bg-lightBlue2 rounded-lg border-none mb-6 shadow-lg`}
          >
            <thead>
              <tr className="bg-lightBlue">
                <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.tour`)}
                </th>
                <th className="border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.return`)}
                </th>
                <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.hotel`)}
                </th>
                <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.category`)}
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Tour Row */}
              <tr className="bg-white text-center border-2 border-lightBlue">
                <td className="p-2 border-2 border-lightBlue">
                  {tour.dateIn
                    ? format(new Date(tour.dateIn), "dd/MM/yy")
                    : "fecha salida"}
                </td>
                <td className="p-2 border-2 border-lightBlue">
                  {tour.dateIn
                    ? format(new Date(tour.dateOut), "dd/MM/yy")
                    : "fecha salida"}
                </td>
                <td className="p-2 border-2 border-lightBlue">
                  {tour.hotel ? tour.hotel : "-"}
                </td>
                <td className="p-2 border-2 border-lightBlue">
                  {tour.catergory ? tour.catergory : "-"}
                </td>
              </tr>

              {allFees.length > 0 && // Ensure `allFees` is loaded
                allFees
                  .filter((fee) => fee.id_tours === tour.id) // Match fee to the corresponding tour
                  .map((filteredFee, feeKey) => (
                    <PackageDetailFeeSection
                      tour={tour}
                      key={feeKey}
                      filteredFee={filteredFee}
                      selectedFeeId={selectedFeeId}
                      setSelectedFeeId={setSelectedFeeId}
                      setSelectedTour={setSelectedTour}
                      setSelectedFee={setSelectedFee}
                    />
                  ))}
            </tbody>
          </table>
        </React.Fragment>
      ))}
    </div>
  );
}
