import { NightsStay, WbSunny } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import { useTranslation } from "react-i18next";
import { usePackages } from "../../../../../services/usePackages";
import NewCalendar from "../../../../UI/NewCalendar";

const packagesCategoriesFromScratch = [
  { id: 3, title: "adventure" },
  { id: 6, title: "culture" },
  { id: 1, title: "sports" },
  { id: 2, title: "educational" },
  { id: 5, title: "quinceanera" },
  { id: 4, title: "religious" },
];

export default function TourSubForm({
  register,
  watch,
  errors,
  setValue,
  clearErrors,
}) {
  const [rangeDate, setRangeDate] = useState();
  const [opendateIn, toggleSalida] = useToggleVisibility();
  const { packagesCategories } = usePackages();
  const { t } = useTranslation();
  const dateInValue = watch("tourSchema.dateIn");
  const dateOutValue = watch("tourSchema.dateOut");

  useEffect(() => {
    // Si `destination_code` o `origin_code` tiene un valor, borra los mensajes de error
    if (dateInValue) {
      clearErrors("tourSchema.dateIn");
    }
    if (dateOutValue) {
      clearErrors("tourSchema.dateOut");
    }
  }, [dateInValue, dateOutValue, clearErrors]);

  return (
    <div className="w-full">
      <header className="flex justify-between  max-w-[1200px] mx-auto">
        <h1 className="text-darkBlue font-semibold text-3xl">
          {t(`global.Tour_Form.title`)}:
        </h1>
      </header>
      <div className="my-10 mt-3 mx-auto max-w-[1200px] bg-white rounded-lg p-8">
        <section className="flex flex-col gap-2">
          <h2 className="text-darkBlue font-medium mb-4">
            {t(`global.Tour_Form.dates`)}
          </h2>
          <div className="flex flex-row items-start gap-2">
            <label className="relative w-3/5">
              <input
                id="dateIn"
                autoComplete="off"
                name="tourSchema.dateIn"
                placeholder={t(`global.Tour_Form.departure`)}
                readOnly
                value={
                  watch("tourSchema.dateIn")
                    ? format(new Date(watch("tourSchema.dateIn")), "yyyy/MM/dd")
                    : ""
                }
                onClick={() => toggleSalida()}
                className="addPackInput w-full"
                {...register("tourSchema.dateIn")}
              />
              {errors?.dateIn && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>{t(`global.Tour_Form.${errors?.dateIn.message}`)}</p>
                </div>
              )}
              {opendateIn ? (
                <NewCalendar
                  handlers={[
                    rangeDate,
                    (date) => {
                      setRangeDate(date);
                      setValue(
                        "tourSchema.dateIn",
                        date?.from ? format(date?.from, "yyyy/MM/dd") : "",
                      );
                      setValue(
                        "tourSchema.dateOut",
                        date?.to ? format(date?.to, "yyyy/MM/dd") : "",
                      );
                      date?.from && date?.to && toggleSalida();
                    },
                  ]}
                  mode={"range"}
                  numberOfMonths={2}
                  close={toggleSalida}
                />
              ) : null}
            </label>
          </div>
          <div className="flex flex-col items-start gap-2">
            <label className="relative w-3/5">
              <input
                readOnly
                id="dateOut"
                autoComplete="off"
                name="dateOut"
                placeholder={t(`global.Tour_Form.return`)}
                value={
                  watch("tourSchema.dateOut")
                    ? format(
                        new Date(watch("tourSchema.dateOut")),
                        "yyyy/MM/dd",
                      )
                    : ""
                }
                onClick={() => toggleSalida()}
                className="addPackInput w-full"
                {...register("tourSchema.dateOut")}
              />
              {errors?.dateOut && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>{t(`global.Tour_Form.${errors?.dateOut.message}`)}</p>
                </div>
              )}
            </label>
          </div>
          <div>
            <h2 className="text-darkBlue font-medium mb-4">
              {t(`global.Tour_Form.category`)}
            </h2>
            <select
              className="addPackInput w-3/5"
              name="category"
              {...register("tourSchema.category")}
            >
              {packagesCategories &&
              packagesCategories?.data?.response?.length > 0
                ? packagesCategories?.data?.response?.map((element) => (
                    <option key={element.id} value={element.title}>
                      {t(`global.Tour_Form.${element.title}`)}
                    </option>
                  ))
                : packagesCategoriesFromScratch?.map((element) => (
                    <option key={element.id} value={element.title}>
                      {t(`global.Tour_Form.${element.title}`)}
                    </option>
                  ))}
            </select>
          </div>
          <div>
            <h2 className="text-darkBlue font-medium mb-4">
              {t(`global.Tour_Form.stay`)}
            </h2>
            <div className="flex flex-row gap-1">
              <div className="addPackInput border-[1px] rounded-md p-1 ">
                <WbSunny />
                <input
                  className="ml-2 outline-none"
                  type="number"
                  defaultValue={0}
                  name="days"
                  placeholder="Días"
                  {...register("tourSchema.days", { valueAsNumber: true })}
                />
                {errors?.days && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <p>{t(`global.Tour_Form.${errors?.days.message}`)}</p>
                  </div>
                )}
              </div>
              <div className="addPackInput border-[1px] rounded-md p-1 ">
                <NightsStay />
                <input
                  className="ml-2 outline-none"
                  type="number"
                  defaultValue={0}
                  name="nights"
                  placeholder="Noches"
                  {...register("tourSchema.nights", { valueAsNumber: true })}
                />
                {errors?.nights && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <p>{t(`global.Tour_Form.${errors?.nights.message}`)}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col col-span-2">
              <h2 className="text-darkBlue font-medium my-4">
                {t(`global.Tour_Form.hotel`)}
              </h2>
              <textarea
                id="hotels"
                {...register("tourSchema.hotels")}
                className="addPackInput w-full"
              />
            </div>
          </div>
        </section>
        <section className="relative w-auto h-full flex flex-col gap-4">
          <div className="flex flex-col">
            <h2 className="text-darkBlue font-medium my-4">
              {t(`global.Tour_Form.meal_plan`)}
            </h2>
            <textarea
              id="meal_plan"
              name="meal_plan"
              {...register("tourSchema.meal_plan")}
              className="addPackInput w-full"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <h2 className="text-darkBlue font-medium mb-4">
              {t(`global.Tour_Form.comments`)}
            </h2>
            <textarea
              id="comments"
              name="comments"
              {...register("tourSchema.comments")}
              className="addPackInput w-full"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-darkBlue font-medium mb-4">
              {" "}
              {t(`global.Tour_Form.stock`)}
            </label>
            <div className="addPackInput w-1/5 border-[1px] rounded-md">
              <input
                className="w-4/5 ml-2 outline-none"
                type="number"
                defaultValue={0}
                name="stock"
                {...register("tourSchema.stock", { valueAsNumber: true })}
              />
            </div>
            {errors?.stock && (
              <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                <p>{t(`global.Tour_Form.${errors?.stock.message}`)}</p>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
