import { useToggleVisibility } from "../../../../hooks/useToggleVisibility";
import { MdArrowBack, MdOutlineFilterAlt, MdSort } from "react-icons/md";
import { BuscadorPaquetes } from "./BuscadorPaquetes";
import PackageFilters from "./PackageFilters";
import { useTranslation } from "react-i18next";

export default function PackageFilterMobileMenu({
  filteredPackages,
  possibleCitiesAndMonth,
  setSearchTerm,
  searchTerm,
  setFilters,
  setPassengers,
  passengers,
  filters,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-8 flex-col">
      <div className="flex justify-between rounded-md w-screen bg-lightBlue p-4">
        <div className="flex-grow active:bg-gray2 flex items-center  ">
          <MdSort size={30} /> {t(`global.Actions.sort`)}
        </div>
        <div
          className="active:bg-gray2 flex items-center  "
          onClick={toggleVisibility}
        >
          <MdOutlineFilterAlt size={30} />
          {t(`global.Actions.filter`)}
        </div>
      </div>

      <div
        className={`transition-all duration-500 ease-in-out flex z-custom   ${
          isVisible
            ? "fixed top-0 left-0 w-screen h-screen flex-col  bg-white overflow-y-auto animate-slideInRight"
            : " hidden"
        }`}
      >
        <div className="flex justify-start p-4">
          <div className=" active:bg-gray2" onClick={toggleVisibility}>
            <MdArrowBack size={30} />
          </div>
        </div>

        <div className="flex justify-center flex-col items-center w-full  ">
          <BuscadorPaquetes
            filteredPackages={filteredPackages}
            possibleCitiesAndMonth={possibleCitiesAndMonth}
            searcher={[searchTerm, setSearchTerm]}
            setFilters={setFilters}
            setPassengers={setPassengers}
            passengers={passengers}
          />
          <PackageFilters
            possibleCitiesAndMonth={possibleCitiesAndMonth}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>
    </div>
  );
}
