import { SwapVert } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { MdSort } from "react-icons/md";

export default function QuoteSorting({ setSortCriteria, sortCriteria }) {
  const { t } = useTranslation();
  const handleSortChange = (key) => {
    setSortCriteria(() => ({
      key,
    }));
  };

  const handleDirectionToggle = () => {
    setSortCriteria((prev) => ({
      ...prev,
      direction: prev.direction === "asc" ? "desc" : "asc", // Toggle direction
    }));
  };

  return (
    <div className="inline-flex items-center gap-2">
      <div className="relative">
        {/* Hidden Select Element */}
        <select
          className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
          onChange={(e) => handleSortChange(e.target.value)}
          value={sortCriteria.key}
        >
          <option value="date">{t(`global.Sorting.upcoming`)}</option>
          <option value="name">{t(`global.Sorting.name`)}</option>
        </select>

        {/* Visible Sort Icon */}
        <span className="hover:text-blue-500 cursor-pointer">
          <MdSort size={23} />
        </span>
      </div>

      <button
        className="flex items-center"
        aria-label={`Sort ${
          sortCriteria.direction === "asc" ? "descending" : "ascending"
        }`}
        onClick={handleDirectionToggle}
      >
        <SwapVert
          className="hover:text-blue-500"
          color={sortCriteria.direction === "asc" ? `#000000` : `#00ffff`}
          size={23}
        />
      </button>
    </div>
  );
}
