import { useEffect, useState } from "react";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { format } from "date-fns";
import { Toaster } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import { initialWeekdays } from "./weekdays";
import { usePackages } from "../../services/usePackages";
import NewCalendar from "../UI/NewCalendar";

export const FormEditActividad = () => {
  const { operador, idActividad } = useParams();
  const { packages, getPackages } = usePackages();
  const [selectedPackage, setSelectedPackage] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [duration, setDuration] = useState("hours");
  const [weekdays, setWeekDays] = useState(
    selectedPackage?.weekdays || initialWeekdays,
  );

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    setSelectedPackage(
      packages.find((pack) => pack.id === Number(idActividad)),
    );
  }, [packages, idActividad]);

  useEffect(() => {
    if (selectedPackage) {
      setRangeDate({
        from: selectedPackage.fsalida,
        to: selectedPackage.fvuelta,
      });
    }
  }, [selectedPackage]);

  const [openFsalida, toggleSalida] = useToggleVisibility();

  // const onSubmitEdit = async (data) => {
  //   toast.promise(
  //     updatePackage({
  //       ...selectedPackage,
  //       fsalida: rangeDate.from,
  //       fvuelta: rangeDate.to,
  //       cupos_original: selectedPackage.cupos,
  //       adultos: Number(selectedPackage.adultos),
  //     }).then((e) => {}),
  //     {
  //       loading: "Cargando...",
  //       success: <b>Actividad editada con éxito</b>,
  //       error: <b>Hubo un error, intenta de nuevo.</b>,
  //     }
  //   );
  // };

  const handleEdit = (e) => {
    setSelectedPackage((curr) => ({
      ...curr,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDaySelection = (evt, label, selected) => {
    evt.preventDefault();
    setWeekDays((curr) => ({
      ...curr,
      [label]: {
        label,
        selected: !selected,
      },
    }));
  };

  return (
    <>
      <SEO title={"Editar Actividad"} />
      <div className="w-full">
        <header className="flex justify-between mb-8 mt-12 max-w-[1000px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">
            Editar Actividad
          </h1>
          <Link
            to={`/${operador}/panel/activities/my-activities`}
            className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Actividades
          </Link>
        </header>

        <form
          // onSubmit={handleSubmit(onSubmitEdit)}
          className="mx-auto max-w-[1000px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>
          <div className="grid grid-cols-12 gap-12">
            <section className="flex flex-col gap-4 col-span-5">
              <h2 className="text-darkBlue font-medium">INFORMACIÓN GENERAL</h2>
              <div className="grid grid-cols-2 gap-3">
                <input
                  className="addPackInput col-span-2"
                  name="name"
                  value={selectedPackage?.name}
                  onChange={handleEdit}
                />
                <input
                  className="addPackInput w-full"
                  value={selectedPackage?.oricode}
                  name="oricode"
                  onChange={handleEdit}
                />

                {/* <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="oricode"
                  placeholder="Origen"
                  handleAction={(d) => setValue("oricode", d.toUpperCase())}
                  />
                  <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="descode"
                  placeholder="Destino"
                  handleAction={(d) => setValue("descode", d.toUpperCase())}
                /> */}
                <select
                  className="addPackInput"
                  name="duration"
                  value={selectedPackage?.duration}
                  onChange={(evt) => setDuration(evt.target.value)}
                >
                  <option value="hours">En el día</option>
                  <option value="days">Más de un día</option>
                </select>
                {duration === "days" && (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-col items-start gap-2">
                        <input
                          autoComplete="off"
                          name="fsalida"
                          placeholder="Ida"
                          onClick={() => {
                            toggleSalida();
                            setRangeDate({
                              from: "",
                              to: "",
                            });
                          }}
                          value={
                            rangeDate?.from
                              ? format(new Date(rangeDate?.from), "dd/MM/yyyy")
                              : ""
                          }
                          className="addPackInput w-full"
                        />
                        {openFsalida ? (
                          <NewCalendar
                            handlers={[
                              rangeDate,
                              (date) => {
                                setRangeDate(date);
                              },
                            ]}
                            mode={"range"}
                            numberOfMonths={2}
                            close={toggleSalida}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-col items-start gap-2">
                        <input
                          autoComplete="off"
                          name="fvuelta"
                          placeholder="Vuelta"
                          onClick={() => toggleSalida()}
                          value={
                            rangeDate?.to
                              ? format(new Date(rangeDate?.to), "dd/MM/yyyy")
                              : ""
                          }
                          className="addPackInput w-full"
                        />
                      </div>
                    </div>
                  </>
                )}
                <select
                  className="addPackInput"
                  name="activityType"
                  value={selectedPackage?.activityType}
                >
                  <option value="trekking">Trekking</option>
                  <option value="safari">Safari</option>
                  <option value="surf">Surf</option>
                  <option value="buceo">Buceo</option>
                  <option value="escalada">Escalada</option>
                </select>
                <input
                  type="text"
                  className="addPackInput"
                  name="cupos"
                  value={selectedPackage?.cupos}
                  onChange={handleEdit}
                />

                {duration === "hours" && (
                  <div className="col-span-2 flex flex-col gap-2">
                    <h3 className="text-darkBlue font-medium mt-3">HORARIOS</h3>
                    <div className="flex justify-between gap-1">
                      {Object.keys(weekdays).map((day) => {
                        const { label, selected } = weekdays[day];

                        return (
                          <button
                            key={label}
                            className={`border rounded w-8 h-8 transition-colors ${
                              selected
                                ? "bg-black border-white text-white"
                                : "bg-transparent border-gray2"
                            }`}
                            onClick={(evt) =>
                              handleDaySelection(evt, label, selected)
                            }
                          >
                            {label}
                          </button>
                        );
                      })}
                    </div>

                    <div className="w-full flex gap-3 justify-between items-center">
                      <input
                        type="time"
                        name="schedule"
                        className="addPackInput w-full"
                      />
                      <input
                        type="time"
                        name="schedule"
                        className="addPackInput w-full"
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-col col-span-2">
                  <label
                    htmlFor="description"
                    className="text-darkBlue font-medium mt-3 my-2"
                  >
                    DESCRIPCIÓN
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={selectedPackage?.description}
                    onChange={handleEdit}
                    className="addPackInput"
                  />
                </div>
              </div>

              <div>
                <h3 className="text-darkBlue font-medium mt-3 mb-2">PRECIO</h3>
                <div className="grid grid-cols-2 gap-4">
                  {/* <input
                  type="number"
                  placeholder="Impuestos a cobrar"
                  className="addPackInput"
                  name="taxes"
                  {...register("taxes")}
                /> */}
                  <input
                    type="number"
                    placeholder="Precio por pasajero"
                    className="addPackInput"
                    name="price"
                    value={selectedPackage?.price}
                    onChange={handleEdit}
                  />
                </div>
              </div>
            </section>

            <section className="relative col-span-7 pb-16">
              <h2 className="text-darkBlue font-medium mb-4">
                FOTOS DE LA ACTIVIDAD
              </h2>
              <input
                type="text"
                placeholder="Link a imagen"
                className="addPackInput col-span-2 mb-4 w-64"
                name="photo"
                value={selectedPackage?.photo || ""}
                onChange={handleEdit}
              />
              {selectedPackage?.photo && (
                <img
                  src={selectedPackage?.photo}
                  alt="Foto paquete"
                  className="h-60"
                />
              )}

              {/* <section className="grid grid-cols-2 gap-y-3 gap-x-6">
              <div className="photo h-60" />
              <div className="grid grid-rows-2 gap-3">
              <div className="photo" />
              <div className="photo" />
              </div>
              <div className="photo h-16" />
              <div className="photo h-16" />
              <div className="photo h-16" />
              <div className="photo h-16" />
            </section> */}
              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Actualizar Actividad
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
