import { useState } from "react";
import UpdateAgencyModal from "./components/UpdateAgencyModal";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import Pagination from "../UI/Pagination";
import toast, { Toaster } from "react-hot-toast";
import AgenciesTableRow from "./AgenciesTableRow";
import ViewAgencyModal from "./components/ViewAgencyModal";
import AgenciesTableSearcher from "./AgenciesTableSearcher";
import { useTranslation } from "react-i18next";
import { ModalGeneric } from "../UI/ModalGeneric";

export default function AgenciesTable({ useAgencies }) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [selectedAgency, setSelectedAgency] = useState({});
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [typeModal, setTypeModal] = useState("");
  const { agencies, deleteAgency, getAgenciesByTourOperator } = useAgencies;
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();
  const openModal = (agency, modal) => {
    setSelectedAgency(agency);
    toggleVisibility();
    setTypeModal(modal);
  };

  const collapseText = (text, max) => {
    if (text.length > max) {
      return text.slice(0, max) + "...";
    }
    return text;
  };

  const closeModal = () => {
    toggleVisibility();
    getAgenciesByTourOperator();
  };

  const deleteAgencyWithToast = async (id) => {
    toast.promise(
      deleteAgency({ id }).then(() => {
        getAgenciesByTourOperator();
        toggleVisibility();
      }),
      {
        loading: "Eliminando...",
        success: <b>Agencia eliminada con éxito.</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      },
    );
  };

  const handleRowSelect = (agencie) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((row) => row.id === agencie.id);
      return isSelected
        ? prevSelected.filter((row) => row.id !== agencie.id)
        : [...prevSelected, agencie];
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all travelers for the current page

      setSelectedRows(agencies);
    } else {
      // Deselect all travelers
      setSelectedRows([]);
    }
  };

  return (
    <>
      <Toaster />
      <section className="bg-white w-full mt-4 rounded-xl p-4 border-2 border-lightBlue">
        <AgenciesTableSearcher pdfData={selectedRows} />
        <table className="min-w-full border-collapse">
          <thead className="bg-lightBlue">
            <tr>
              <th className="py-2.5 px-4">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    agencies.slice(page * 10, (page + 1) * 10).length > 0 &&
                    agencies
                      .slice(page * 10, (page + 1) * 10)
                      .every((agency) =>
                        selectedRows.some(
                          (selected) => selected.id === agency.id,
                        ),
                      )
                  }
                />
              </th>
              <th className="font-medium pl-4 col-span-2">
                {t(`global.Admin_Agency.Headers.col_1`)}
              </th>
              <th className="font-medium col-span-2">
                {t(`global.Admin_Agency.Headers.col_2`)}
              </th>
              <th className="font-medium">
                {t(`global.Admin_Agency.Headers.col_3`)}
              </th>
              <th className="font-medium">
                {t(`global.Admin_Agency.Headers.col_4`)}
              </th>
              <th className="font-medium">
                {t(`global.Admin_Agency.Headers.col_5`)}
              </th>
              <th className="font-medium">
                {t(`global.Admin_Agency.Headers.col_6`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {agencies
              ?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              ?.map((agency, key) => (
                <tr
                  key={agency.id}
                  className={`  ${
                    selectedRows.includes(agency)
                      ? "bg-blue-100"
                      : "bg-light-gray"
                  }`}
                >
                  <td className="py-2.5 px-4">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(agency)}
                      onChange={() => handleRowSelect(agency)}
                    />
                  </td>
                  <td className="pl-4 whitespace-nowrap capitalize col-span-2 text-sm xl:text-base">
                    {collapseText(agency?.name?.toLowerCase(), 30)}
                  </td>
                  <td className="col-span-2 text-sm xl:text-base">
                    {collapseText(agency.email, 30)}
                  </td>
                  <td className="text-sm xl:text-base">{agency.phone}</td>
                  <td className="text-sm xl:text-base">{agency.category}</td>
                  <td className="capitalize whitespace-nowrap text-sm xl:text-base">
                    {collapseText(
                      (agency?.contact_name?.toLowerCase() || "-") +
                        " " +
                        (agency?.contact_lastname?.toLowerCase() || "-"),
                      15,
                    )}
                  </td>
                  <td>
                    <AgenciesTableRow
                      key={key}
                      agency={agency}
                      openModal={openModal}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {typeModal === "edit" && (
            <UpdateAgencyModal
              agency={selectedAgency}
              closeModal={closeModal}
            />
          )}
          {typeModal === "delete" && (
            <div className="flex flex-col items-center gap-2">
              <h2>
                ¿Estás seguro de eliminar la agencia{" "}
                <b>{selectedAgency?.name}</b>?
              </h2>
              <div className="flex justify-center gap-2">
                <button
                  className="py-1 px-2 rounded font-medium border border-gray2"
                  onClick={toggleVisibility}
                >
                  Cancelar
                </button>
                <button
                  className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                  onClick={() => deleteAgencyWithToast(selectedAgency.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          )}
          {typeModal === "view" && <ViewAgencyModal agency={selectedAgency} />}
        </ModalGeneric>
      </section>

      <Pagination
        items={agencies}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
