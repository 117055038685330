import React from "react";

import HotelCard from "./listingItem/HotelCard";

export default function HotelListing({ hotels,pax }) {
  return (
    <div>
      <div className="font-body flex justify-between w-full gap-4 bg-lightBlue/30 rounded-lg p-4 mb-8">
        <h3>Busca entre los mejores hoteles a los precios mas bajos!</h3>
      </div>
      {hotels.length === 0 && <h1>No hay hoteles disponibles</h1>}
      {hotels.length !== 0 &&
        hotels.map((hotel) => {
          return (
            <div className="flex flex-col gap-4 mb-5">
              <HotelCard pax={pax} key={hotel.$.Code} hotel={hotel} />
            </div>
          );
        })}
    </div>
  );
}
