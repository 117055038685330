import React from "react";
import TableQuoteRow from "./quoteRow/TableQuoteRow";
import { useTranslation } from "react-i18next";

export default function TableQuoteBody({
  quotes,
  selectedRows,
  handleRowSelect,
  users,
}) {
  const { t } = useTranslation();
  return (
    <tbody className="text-sm xl:text-base items-center text-gray">
      {quotes.length > 0 ? (
        quotes.map((quote, index) => (
          <React.Fragment key={index}>
            <TableQuoteRow
              users={users}
              index={index}
              qto={quote}
              selectedRows={selectedRows}
              handleRowSelect={handleRowSelect}
            />
          </React.Fragment>
        ))
      ) : (
        <tr>
          <td colSpan="100%" className="text-center">
            {t(`global.Admin_Quotes.no_quotes`)}
          </td>
        </tr>
      )}
    </tbody>
  );
}
