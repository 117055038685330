import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGetCurrencies from "../../../../../services/useGetCurrencies";

export default function FeeSubForm({
  register,
  errors,
  watch,
  price,
  setPrice,
}) {
  const { currencies } = useGetCurrencies();
  const { t } = useTranslation();
  const fare = watch("feeSchema.fare", 0);
  const tax = watch("feeSchema.tax", 0);

  useEffect(() => {
    setPrice((fare || 0) + (tax || 0));
  }, [fare, tax]);

  return (
    <div className="w-full">
      <header className="flex justify-between  max-w-[1200px] mx-auto">
        <h1 className="text-darkBlue font-semibold text-3xl">
          {t(`global.Fee_Form.title`)}:
        </h1>
      </header>
      <div className="my-10 mt-3 mx-auto max-w-[1200px] bg-white rounded-lg p-8">
        <div>
          <h2 className="text-darkBlue font-medium mb-4">
            {t(`global.Fee_Form.room_type`)}
          </h2>
          <input
            type="text"
            placeholder={t(`global.Fee_Form.room_type_placeholder`)}
            className="addPackInput w-full"
            name="base"
            {...register("feeSchema.base")}
          />
          {errors?.base && (
            <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
              <p>{t(`global.Fee_Form.${errors?.base.message}`)}</p>
            </div>
          )}
        </div>

        <div>
          <h3 className="text-darkBlue font-medium">
            {t(`global.Fee_Form.price_per_person`)}
          </h3>
          <div className="grid grid-cols-3 gap-4">
            <label className="relative w-full">
              <p> {t(`global.Fee_Form.currency`)}</p>
              <select
                name="currency_type_id"
                id="currency_type_id"
                className="addPackInput w-full"
                {...register("feeSchema.currency_type_id", {
                  valueAsNumber: true,
                })}
              >
                <option value={0}>
                  {t(`global.Fee_Form.choose_currency`)}
                </option>
                {currencies?.map(({ name, symbol, id }) => (
                  <option key={id} value={id}>
                    {t(`global.Currency.${name}`)}
                    {} ({symbol})
                  </option>
                ))}
              </select>
              {errors?.currency_type_id && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>
                    {t(`global.Fee_Form.${errors?.currency_type_id.message}`)}
                  </p>
                </div>
              )}
            </label>

            <label className="relative w-full">
              <p>{t(`global.Fee_Form.fee`)}</p>
              <input
                type="number"
                defaultValue={0}
                className="addPackInput w-full"
                name="fare"
                {...register("feeSchema.fare", { valueAsNumber: true })}
              />
              {errors?.fare && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>{t(`global.Fee_Form.${errors?.fare.message}`)}</p>
                </div>
              )}
            </label>

            <label className="relative w-full">
              <p>{t(`global.Fee_Form.tax`)}</p>
              <input
                type="number"
                defaultValue={0}
                className="addPackInput w-full"
                name="tax"
                {...register("feeSchema.tax", { valueAsNumber: true })}
              />
              {errors?.tax && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>{t(`global.Fee_Form.${errors?.tax.message}`)}</p>
                </div>
              )}
            </label>

            <label className="relative w-full">
              <p>{t(`global.Fee_Form.price`)}</p>
              <input
                type="number"
                disabled
                min={0}
                className="addPackInput w-full"
                name="price"
                value={price}
                readOnly
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
