// import { useState } from "react";
import axios from "axios";
import { updateLenguaje } from "../Translations/updateLenguaje";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";

export const useTourOperators = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { tour_op_id } = useParams();
  const [tourOperator, setTourOperator] = useState();
  const [tourOperators, setTourOperators] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postTourOperator = async (tourOperator) =>
    axios.post(
      BASE_URL + "v2/tour-operators",
      {
        tourOperator,
      },
      headers,
    );

  const getTourOperators = async () => {
    try {
      const response = await axios.get(BASE_URL + "v2/tour-operators");
      setTourOperators(response.data.response);
      return response.data.response;
    } catch (err) {
      throw new Error();
    }
  };

  const getTourOperatorByID = useCallback(
    async ({ tourOperatorID }) => {
      try {
        const response = await axios.get(
          `${BASE_URL}v2/tour-operators/detail/${tourOperatorID}`,
        );
        setTourOperator(response.data.response);
        updateLenguaje(response.data.response);
      } catch (err) {
        throw new Error();
      }
    },
    [BASE_URL],
  );
  const getTourOperator = async () => {
    getTourOperators().then((e) => {
      const operator = tour_op_id
        ? e.find(
            (op) => op.name.split(" ").join("").toLowerCase() === tour_op_id,
          )?.id
        : "1";
      getTourOperatorByID({ tourOperatorID: operator });
    });
  };

  const updateTourOperator = async (tourOperator) => {
    const { id, ...restOfTourOperator } = tourOperator;

    return await axios.patch(
      BASE_URL + `v2/tour-operators/${id}`,
      {
        tourOperator: { ...restOfTourOperator },
      },
      headers,
    );
  };

  return {
    tourOperator,
    tourOperators,
    postTourOperator,
    getTourOperator,
    getTourOperatorByID,
    getTourOperators,
    updateTourOperator,
  };
};
