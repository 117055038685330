import { Link, useParams } from "react-router-dom";
import { useTourOperatorStore } from "../../../../../store/useTourOperatorStore";

export default function ConfirmQuoteStatus({ qto, t, toggleVisibility }) {
  const { tour_op_id } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  return (
    <div>
      <h1 className="font-semibold text-2xl mb-4">
        {t(`global.Admin_Quotes.confirm_quote_message_1`)}!
      </h1>
      <h1>{t(`global.Admin_Quotes.confirm_quote_message_2`)}</h1>
      <div className="flex flex-row justify-center gap-8 mt-8">
        <Link
          state={{ qto: qto, tourOP: tourOP }}
          to={`/${tour_op_id}/panel/sales/bookings/book`}
          className="bg-walltripBlue text-white rounded-md p-2 hover:bg-hoverBlue"
        >
          {t(`global.Actions.reserve`)}
        </Link>
        <button
          onClick={toggleVisibility}
          className="bg-red-600 text-white rounded-md p-2 hover:bg-red-700"
        >
          {t(`global.Actions.cancel`)}
        </button>
      </div>
    </div>
  );
}
