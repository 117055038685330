import TourRowAccionButtons from "./TourRowAccionButtons";
import FeeRow from "../feeRow/FeeRow";
import { useToggleVisibility } from "../../../../../../hooks/useToggleVisibility";
import { format } from "date-fns";
import TourModal from "../../../../Modals/TourModal";
import { ModalGeneric } from "../../../../../UI/ModalGeneric";

export default function TourRowTableBody({
  isFeeVisible,
  setIsFeeVisible,
  tour,
  index,
  package_id,
  AllowDelete,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();

  return (
    <>
      <tr
        className={` text-center bg-white hover:bg-lightGray border-2 border-lightBlue`}
      >
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue  p-2"
        >
          {index + 1}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {format(
            new Date(
              new Date(tour.dateIn.split("T")[0]).setDate(
                new Date(tour.dateIn.split("T")[0]).getDate() + 1,
              ),
            ),
            "dd/MM/yyyy",
          )}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.hotels}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.category}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.meal_plan}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.days}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.nights}
        </td>
        <td onClick={toggleVisibility} className=" p-2">
          <div className="inline-flex">
            {tour.stock}/{tour.original_stock}
            {tour.availability === "YES" ? (
              <span className="bg-red-500 rounded-full p-2 ml-4" />
            ) : (
              <>
                <span className="bg-green-500 rounded-full p-2 ml-4" />
              </>
            )}
          </div>
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.stock}
        </td>
        <td className=" p-2 flex justify-end">
          <TourRowAccionButtons
            setVisible={setIsFeeVisible}
            isVisible={isFeeVisible}
            data={tour}
            AllowDelete={AllowDelete}
          />
        </td>
      </tr>

      {isFeeVisible && (
        <tr className="rounded-lg">
          <td colSpan="11">
            <FeeRow tour={tour} package_id={package_id} />
          </td>
        </tr>
      )}

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <TourModal data={tour} />
      </ModalGeneric>
    </>
  );
}
