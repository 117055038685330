import { useTranslation } from "react-i18next";

export default function HistorySearcher({ setInputValue }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const passengerName = e.target.elements.passenger.value;
    const bookingId = e.target.elements.booking_id.value;

    setInputValue({ passengerName, bookingId });
  };
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit} className="flex gap-4">
      <input
        autoFocus
        name="passenger"
        placeholder={t(`global.History.name_placeholder`)}
        className="addPackInput"
      />
      <input
        name="booking_id"
        placeholder={t(`global.History.id_placeholder`)}
        className="addPackInput"
      />
      <button className="button w-fit px-4 text-lg bg-darkBlue text-white hover:shadow-lg transition-al">
        {t(`global.Actions.search`)}
      </button>
    </form>
  );
}
