import ModalViewQuote from "../quoteModal/ModalViewQuote";
import TableActionsQuotes from "../tableActions/TableActionsQuotes";
import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import { format } from "date-fns";
import SelectQuoteStatus from "./SelectQuoteStatus";
import ico_paquetes from "../../../../../iconos/icono-paquetes.png";
import ico_vuelos from "../../../../../iconos/icono-vuelos.png";
import ico_hoteles from "../../../../../iconos/hotel.png";
import UserQuoteCol from "./UserQuoteCol";
import { ModalGeneric } from "../../../../UI/ModalGeneric";
//import ico_actividades from "../../../../../iconos/actividades.png";

export default function TableQuoteRow({
  qto,
  selectedRows,
  handleRowSelect,
  users,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();

  return (
    <>
      <tr
        className={` text-center border-2 border-lightBlue hover:bg-lightBlue2  ${
          selectedRows.includes(qto) ? "bg-blue-100" : "bg-white"
        }`}
      >
        <td className="relative   group">
          {qto.whatsapp !== "" || qto.email !== "" ? (
            <input
              className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
              type="checkbox"
              checked={selectedRows.includes(qto)} // Check if qto is in selectedRows
              onChange={() => handleRowSelect(qto)} // Handle row selection
            />
          ) : (
            <a>
              <span className="absolute whitespace-nowrap bg-white border bottom-full py-0.5 px-1 rounded scale-0 group-hover:scale-100 transition-transform duration-300">
                No hay telefono o email disponible
              </span>
            </a>
          )}
        </td>
        <td>{qto.id}</td>
        <td
          className=" p-2 border-2   border-lightBlue "
          onClick={toggleVisibility}
        >
          <div className="flex justify-center">
            {
              qto.type === "flight" ? (
                <img className="w-5" src={ico_vuelos} alt="Flight Icon" />
              ) : qto.type === "package" ? (
                <img className="w-5" src={ico_paquetes} alt="Packages Icon" />
              ) : qto.type === "hotel" ? (
                <img className="w-5" src={ico_hoteles} alt="Packages Icon" />
              ) : null // fallback if none match
            }
          </div>
        </td>
        <td
          className=" p-2 border-2   border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.name}
        </td>
        {/* <td
          className="p-2 border-2 border-lightBlue"
          onClick={toggleVisibility}
        >
          {qto.passengers}
        </td> */}
        <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.destination}
        </td>
        <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.dates ? qto.dates.split("to")[0] : "N/A"}
        </td>
        {/* <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.passengers}
        </td> */}
        <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.creation_date
            ? format(new Date(qto.creation_date), "dd/MM/yyyy")
            : "N/A"}
        </td>
        <td className="p-2 border-2 border-lightBlue ">
          {qto.user_id ? (
            <>
              <UserQuoteCol users={users} id={qto.user_id} />
            </>
          ) : (
            "N/A"
          )}
        </td>
        <td className="p-2 border-2 border-lightBlue ">
          <SelectQuoteStatus qto={qto} status={qto.status} id={qto.id} />
        </td>

        <td className="p-2 flex justify-center ">
          <TableActionsQuotes qto={qto} />
        </td>
      </tr>

      <ModalGeneric toggle={toggleVisibility} show={isVisible}>
        <ModalViewQuote selectedQuote={qto} />
      </ModalGeneric>
    </>
  );
}
