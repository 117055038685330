import { MdCheck } from "react-icons/md";
import useReloadStore from "../../../../../../store/useRealoadStore";
import { useFees } from "../../../../../../services/useFees";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function EditFeeButton({
  edit,
  feeEdit,
  setEdit,
  tourId,
  setError,
}) {
  const { updateFee, getFees } = useFees();
  const setReloadFees = useReloadStore((state) => state.setReloadFees);
  const { t } = useTranslation();
  const handleEditFee = async () => {
    setError(false);
    if (!feeEdit.base || feeEdit.base.trim() === "") {
      setError(true); // Set error state if empty
      return; // Exit the function
    }

    // Proceed with the update if base is not empty

    await getFees(tourId); // Ensure the fees are fetched after the update
    setEdit(!edit);
    toast.promise(updateFee(feeEdit), {
      success: <b>{t(`global.Fee_Form.fee_edit_toaster`)}</b>,
      loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
      error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
    });
    setReloadFees((prev) => !prev);
  };

  return (
    <button
      className={`relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link ${
        edit ? "" : "text-green-500"
      }`}
      onClick={() => handleEditFee()}
    >
      <MdCheck scale={23} />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
        Editar
      </span>
    </button>
  );
}
