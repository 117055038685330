import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import { useBookings } from "../../services/useBookings";
import useGetCurrencies from "../../services/useGetCurrencies";
import usePayments from "../../services/usePayments";

export const FormEditPayment = () => {
  const { tour_op_id, payment_id } = useParams();
  const { handleSubmit } = useForm();
  const { getFlightBookings } = useBookings();

  const {
    payments,
    paymentMethods,
    getPayments,
    getPaymentMethods,
    updatePayment,
  } = usePayments();
  const { currencies } = useGetCurrencies();
  const [selectedPayment, setSelectedPayment] = useState();

  useEffect(() => {
    getPayments();
    getPaymentMethods();
    getFlightBookings();
  }, []);

  useEffect(() => {
    if (payments) {
      setSelectedPayment(() => {
        const thisPayment = payments.find(
          (payment) => payment.id === Number(payment_id),
        );
        const {
          PaymentMethod,
          CurrencyType,
          Booking,
          Traveler,
          ...restOfPayment
        } = thisPayment || {};

        return {
          ...restOfPayment,
          currency_type_id: CurrencyType?.id,
          payment_method_id: PaymentMethod?.id,
          booking_id: Booking?.id,
          traveler_id: Traveler?.id,
          tour_op_id: Booking?.tour_op_id,
        };
      });
    }
  }, [payments, payment_id]);

  const onSubmitEdit = async () => {
    toast.promise(updatePayment({ ...selectedPayment }), {
      loading: "Cargando...",
      success: <b>Pago editado con exito</b>,
      error: <b>Hubo un error, intenta de nuevo.</b>,
    });
  };

  const handleEdit = (e) => {
    setSelectedPayment((curr) => ({
      ...curr,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <SEO title={"Editar Pago"} />
      <main className="font-body w-full">
        <header className="flex justify-between mt-10 max-w-[1000px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">Editar Pago</h1>
          <Link
            to={`/${tour_op_id}/panel/invoicing/my-invoices/${selectedPayment?.booking_id}`}
            className="ml-auto button text-center border-2 whitespace-nowrap w-fit px-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a File {selectedPayment?.booking_id}
          </Link>
        </header>

        <form
          onSubmit={handleSubmit(onSubmitEdit)}
          className="my-10 mx-auto max-w-[1000px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <label className="w-full relative group/bookingid">
              <p>Id de reserva</p>
              <input
                type="text"
                className="addPackInput w-full"
                value={selectedPayment?.booking_id}
              />
            </label>

            <label className="w-full">
              <p>Método de Pago</p>
              <select
                name="payment_method_id"
                id="payment_method_id"
                className="addPackInput w-full"
                value={selectedPayment?.payment_method_id}
                onChange={handleEdit}
              >
                {paymentMethods.map(({ method, id }) => (
                  <option key={`${id}`} value={id}>
                    {method}
                  </option>
                ))}
              </select>
              {/* <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="payment_method_id" as="p" />
              </div> */}
            </label>

            <label className="w-full">
              <p>Monto</p>
              <input
                type="number"
                value={selectedPayment?.paid}
                className="addPackInput w-full"
                onChange={handleEdit}
                name="paid"
                // {...register("paid")}
              />
              {/* <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="paid" as="p" />
              </div> */}
            </label>

            <label className="w-full">
              <p>Moneda</p>
              <select
                name="currency_type_id"
                id="currency_type_id"
                className="addPackInput w-full"
                value={selectedPayment?.currency_type_id}
                onChange={handleEdit}
              >
                {currencies.map(({ name, symbol, id }) => (
                  <option key={`${id}`} value={id}>
                    {name} ({symbol})
                  </option>
                ))}
              </select>
              {/* <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="currency_type_id" as="p" />
              </div> */}
            </label>

            {/* <label className="w-full">
              <p>Recibo</p>
              <input
                type="text"
                value={selectedPayment?.invoiced}
                className="addPackInput w-full"
                onChange={handleEdit}
                name="invoiced"
                {...register("invoiced")}
              />
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="invoiced" as="p" />
              </div>
            </label>

            <label className="w-full">
              <p>A Pagar</p>
              <input
                type="number"
                value={selectedPayment?.to_pay}
                className="addPackInput w-full"
                onChange={handleEdit}
                name="to_pay"
                {...register("to_pay")}
              />
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="to_pay" as="p" />
              </div>
            </label> */}

            <button className="col-span-2 ml-auto button text-lg w-1/4 bg-darkBlue text-white hover:shadow-lg transition-all">
              Guardar Pago
            </button>
          </div>
        </form>
      </main>
    </>
  );
};
