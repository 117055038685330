import { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
export default function PackageBasicDetail({ packageData }) {
  const {
    title,
    destination_code,
    origin_code,
    description_general,
    include,
    not_included,
    itinerary,
    terms,
  } = packageData;

  // State variables to control visibility
  const [showDescription, setShowDescription] = useState(false);
  const [showInclude, setShowInclude] = useState(false);
  const [showNotIncluded, setShowNotIncluded] = useState(false);
  const [showItinerary, setShowItinerary] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { t } = useTranslation();
  const toggleVisibility = (setter) => () => {
    setter((prev) => !prev);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 shadow-lg rounded-lg bg-white">
      <h1 className="text-4xl font-semibold text-center mb-4">{title}</h1>

      <div className="border-b mb-4">
        <div className="grid grid-cols-2">
          <p className="border-r text-lg p-2 font-semibold">
            {" "}
            {t(`global.Search_Packages.origin`)}
          </p>
          <p className="text-lg p-2">{origin_code}</p>
        </div>

        <div className="grid grid-cols-2">
          <p className="border-r text-lg p-2 font-semibold">
            {t(`global.Search_Packages.destination`)}
          </p>
          <p className="text-lg p-2">{destination_code}</p>
        </div>
      </div>

      {/* Toggleable sections */}
      <div className="flex flex-col gap-4">
        {[
          {
            title: "description",
            content: description_general,
            isVisible: showDescription,
            toggle: toggleVisibility(setShowDescription),
          },
          {
            title: "include",
            content: include,
            isVisible: showInclude,
            toggle: toggleVisibility(setShowInclude),
          },
          {
            title: "not_include",
            content: not_included,
            isVisible: showNotIncluded,
            toggle: toggleVisibility(setShowNotIncluded),
          },
          {
            title: "itinerary",
            content: itinerary,
            isVisible: showItinerary,
            toggle: toggleVisibility(setShowItinerary),
          },
          {
            title: "terms",
            content: terms,
            isVisible: showTerms,
            toggle: toggleVisibility(setShowTerms),
          },
        ].map(
          ({ title, content, isVisible, toggle }, index) =>
            content && (
              <>
                <div
                  key={index}
                  className="flex justify-center flex-col border rounded-lg p-2"
                >
                  <button
                    onClick={toggle}
                    className="flex justify-between text-blue-600 font-semibold text-xl mb-2 hover:text-blue-800 focus:outline-none"
                  >
                    {isVisible ? (
                      <>{t(`global.Package_Detail.${title}`)}</>
                    ) : (
                      <>{t(`global.Package_Detail.${title}`)}</>
                    )}{" "}
                    {isVisible ? (
                      <FaAngleUp size={23} />
                    ) : (
                      <FaAngleDown size={23} />
                    )}
                  </button>
                  {isVisible && (
                    <div className="mt-2">
                      <p className="text-gray-700">{content}</p>
                    </div>
                  )}
                </div>
              </>
            ),
        )}
      </div>
    </div>
  );
}
