import React, { useState } from "react";

export default function HotelPictureModal() {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  const images = [
    "https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D",
    "https://hoteldel.com/wp-content/uploads/2021/01/hotel-del-coronado-views-suite-K1TOS1-K1TOJ1-1600x900-1.jpg",
    "https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ-IZgYXMclHE8Ga5sDMRiy8NJ0d3D_hDNQQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQe91lmkQ2L86B_0m3dPKjzK3lt13pD_BHxwg&s",
    "https://cdn.loewshotels.com/loewshotels.com-2466770763/cms/imagepool/66a97065eb7bf.jpg"
  ];


  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };


  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };


  const handlePrevious = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

      <div className="flex flex-col space-y-4">
        <div className="relative w-full h-64 bg-gray-300 rounded-lg overflow-hidden">
          <img
            src={images[currentImageIndex]}
            alt="Large Carousel "
            className="w-full h-full object-cover"
          />

          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-2xl"
            onClick={handlePrevious}
          >
            &#8592;
          </button>
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-2xl"
            onClick={handleNext}
          >
            &#8594;
          </button>
        </div>

        {/* Small Thumbnails */}
        <div className="grid grid-cols-3 gap-2">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Tiny ${index + 1}`}
              className="w-full h-24 object-cover rounded-md cursor-pointer"
              onClick={() => handleThumbnailClick(index)} // Update the carousel image when clicked
            />
          ))}
        </div>
      </div>

      {/* Data Section */}
      <div className="space-y-4">
        <p className="text-lg text-gray-700">
          Data paragraph 1: Lorem ipsum dolor sit amet, consectetur adipiscing
          elit.
        </p>
        <p className="text-lg text-gray-700">
          Data paragraph 2: Vivamus lacinia nunc vitae turpis tempor, ac
          hendrerit enim suscipit.
        </p>
        <p className="text-lg text-gray-700">
          Data paragraph 3: Sed dapibus dui vel urna malesuada, nec vestibulum
          mi vehicula.
        </p>
        <p className="text-lg text-gray-700">
          Data paragraph 4: Curabitur auctor risus quis libero suscipit, sed
          fermentum nunc luctus.
        </p>
        <p className="text-lg text-gray-700">
          Data paragraph 5: Nam gravida odio vel risus congue, in viverra augue
          pretium.
        </p>
        <p className="text-lg text-gray-700">
          Data paragraph 6: Fusce placerat dui eu magna sodales, id consequat
          enim mollis.
        </p>
      </div>
    </div>
  );
}