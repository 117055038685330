import { useTranslation } from "react-i18next";

export default function MisTravelersBusqueda({ searcher }) {
  const [{ textValue }, setSearcherValue] = searcher;
  const { t } = useTranslation();
  return (
    <section className="flex gap-4 pb-4 mb-4 justify-between border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-80 rounded"
        placeholder={t(`global.Admin_Travelers.search_placeholder`)}
        value={textValue}
        onChange={(e) =>
          setSearcherValue((curr) => ({ ...curr, textValue: e.target.value }))
        }
      />
      <div className="grid grid-cols-2 gap-2" />
    </section>
  );
}
