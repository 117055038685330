import React, { useState } from "react";
import { FaBed } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

export default function HotelRoomTab({ HotelRooms, hotel}) {
  const [selectedRoom] = useState(null); // Track selected room
  const { tour_op_id } = useParams();

  // const handleRoomSelection = (room) => {
  //   setSelectedRoom(room); // Update the selected room
  // };
  return (
    <>
      <div className="flex flex-row items-center">
        <table className="table-auto w-full">
          <thead className="rounded-lg">
            <tr className="bg-lightBlue">
              <th className="text-center px-4">Nombre</th>
              <th className="text-center px-4">Pasajeros</th>
              <th className="text-center px-4">Camas</th>
              <th className="text-center px-4">Categoria</th>
              <th className="text-center px-4">Precio</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {HotelRooms.map((hotelRoomGroup, index) =>
              hotelRoomGroup.HotelRoom.map((room, subIndex) => {
                const { Name, beds, price, RoomType,passenger } = room;
                return (
                  <tr
                    key={`${index}-${subIndex}`}D
                    className="text-center border-2 border-lightBlue bg-blue-100"
                  >
                    <td>{Name}</td>
                    <td>{passenger}</td>
                    <td className="flex inline-flex items-center gap-2">
                      {beds} <FaBed size={15} />
                    </td>
                    <td>{RoomType}</td>
                    <td>{price}</td>
                    <td>
                      <select className="addPackInput">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                      </select>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div>
          <Link
            to={`/${tour_op_id}/hotels-request`}
            className="button bg-brightBlue text-white text-center p-2 rounded-lg"
            state={{ hotelOffer: hotel }}
            // state={{ selectedRoom }} // Pass selected room as state
          >
            Reservar
          </Link>
        </div>
      </div>

      {selectedRoom && (
        <div className="mt-4">
          <Link
            to={`/${tour_op_id}/hotels-request`}
            className="button bg-brightBlue text-white text-center p-2 rounded-lg"
            state={{ hotelOffer: hotel }}
            // state={{ selectedRoom }} // Pass selected room as state
          >
            Reservar
          </Link>
        </div>
      )}
    </>
  );
}
