import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { newUserSchema } from "../../schemas/newUser";
import { useTourOperatorStore } from "../../store/useTourOperatorStore";
import useCreateAccount from "../../services/useCreateAccount";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";


export default function CreateAccount({
  state,
  justCreateAccount,
  closeModal,
}) {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const [showLogin, setShowLogin] = state;
  const { createNewAccount, responseErrors } = useCreateAccount();
  const [checkTerms, setCheckTerms] = useState(false);
  const [termsError, setTermsErrors] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(newUserSchema) });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValue("tour_op_id", tourOP.id);
  }, [tourOP]);
  const onSubmit = async (data) => {
    if (!checkTerms) {
      setTermsErrors("terms_error");
      return;
    }

    try {
      await toast.promise(
        createNewAccount({ ...data, terms: checkTerms }), // Pass the promise here
        {
          success: <b>{t(`global.User_Form.user_create_toaster`)}</b>,
          loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
          error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
        },
      );

      // If successful and not just creating an account
      if (!justCreateAccount) {
        setShowLogin(!showLogin);
      } else {
        closeModal();
      }
    } catch (validationError) {
      throw new Error(validationError);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formatedErrors = useMemo(
    () =>
      responseErrors &&
      responseErrors.reduce((acc, err) => {
        acc[err.path] = err.msg;
        return acc;
      }, {}),
    [responseErrors],
  );

  return (
    <section>
      <h2 className="text-center text-2xl font-medium">Crear Usuario</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 mt-2"
      >
        <Toaster />
        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline-none w-full"
            type="text"
            name="username"
            placeholder="Nombre de Usuario"
            {...register("username")}
          />
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {formatedErrors?.username ? (
              <p>{formatedErrors.username}</p>
            ) : (
              <ErrorMessage errors={errors} name="username" as="p" />
            )}
          </div>
        </div>
        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline- w-full"
            type="email"
            name="email"
            placeholder="Email"
            {...register("email")}
          />
          {errors?.email && (
            <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
              <p>{t(`global.Register.${errors.email.message}`)}</p>
            </div>
          )}
        </div>

        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline- w-full"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Contraseña"
            autoComplete="off"
            {...register("password")}
          />

          {errors?.password && (
            <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
              <p>{t(`global.Register.${errors.password.message}`)}</p>
            </div>
          )}
        </div>

        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline-none w-full"
            type={showPassword ? "text" : "password"}
            name="rePassword"
            placeholder="Confirmar Contraseña"
            autoComplete="off"
            {...register("rePassword")}
          />
          {watch("password") !== watch("rePassword") &&
            watch("rePassword").length >= 8 &&
            !errors.rePassword && (
              <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                <p>{t(`global.Register.passwordMismatch`)}</p>
              </div>
            )}
          {errors?.rePassword && (
            <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
              <p>{t(`global.Register.${errors.rePassword.message}`)}</p>
            </div>
          )}
        </div>

        <label className="flex items-center gap-2">
          <input type="checkbox" onChange={togglePasswordVisibility} />
          <p>Mostrar Contraseña</p>
        </label>

        <div className="relative flex items-center gap-2">
          <input
            type="checkbox"
            name="terms"
            checked={checkTerms}
            onChange={() => {
              if (!checkTerms) {
                setTermsErrors("");
              }
              setCheckTerms(!checkTerms);
            }}
          />
          <a
            href="https://thewalltrip.com/terminos"
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
          >
            Acepto los términos y condiciones.
          </a>
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {termsError && (
              <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                <p>{t(`global.Register.${termsError}`)}</p>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between gap-6">
          {!justCreateAccount && (
            <button
              onClick={() => setShowLogin(!showLogin)}
              className="text-sm underline"
            >
              Ya tengo cuenta
            </button>
          )}
          <button
            id="createAccount"
            type="submit"
            className={clsx(
              "bg-[#CEDEF1] ml-auto py-2 px-12 text-center font-medium rounded-md flex items-center gap-3",
            )}
          >
            Crear Cuenta
            {/* {loading ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  stroke="currentColor"
                  stroke-width="4"
                  cx="12"
                  cy="12"
                  r="10"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null} */}
          </button>
        </div>
      </form>
    </section>
  );
}
