import { format } from "date-fns";
import numberWithCommas from "../../utils/numberWithCommas";
import TramoModal from "../detalle/TramoModal";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../UI/ModalGeneric";

export default function FlexibleOption({
  original,
  grouped,
  bestPrice,
  formattedCities,
  setFlexibleOption,
  index,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const price = Object.keys(grouped?.[0]?.["1"]);
  const data = grouped?.[0]?.["1"]?.[price[0]];
  const { origin, destination } = data;
  const foundItem = original?.find((x) => x.price.total === price[0]);
  const { travelerPricings } = foundItem;
  const isBestPrice = Number(price) === bestPrice[0];
  const isSecondPrice = Number(price) === bestPrice[1];
  const isThirdPrice = Number(price) === bestPrice[2];

  const bgColor = isBestPrice
    ? "brightGreen"
    : isSecondPrice
      ? "orange-400"
      : isThirdPrice
        ? "red-400"
        : "lightGray3";

  return (
    <section
      className={`relative border rounded p-2 text-center cursor-pointer group/option ${
        index === 0 ? "bg-brightBlue" : `bg-${bgColor}`
      } `}
      onClick={() => setFlexibleOption(original)}
    >
      <h2>
        {format(new Date(origin[0].segments[0].departure.at), "dd/MM")} -{" "}
        {format(new Date(destination[0].segments[0].departure.at), "dd/MM")}
      </h2>
      <h3>$ {numberWithCommas(Number(price), 2)}</h3>
      <button
        onClick={(e) => {
          e.stopPropagation();
          toggleVisibility();
        }}
        className="bg-white px-2 text-sm rounded"
      >
        + info
      </button>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <div className="flex gap-4 rounded-lg">
          <TramoModal
            type={"IDA"}
            data={travelerPricings}
            duration={origin?.[0]?.duration}
            flights={original[0].itineraries}
            formattedCities={formattedCities}
            section="quotes"
            title={"Ida"}
          />
          <TramoModal
            type={"Vuelta"}
            data={travelerPricings}
            duration={destination?.[0]?.duration}
            flights={original[0].itineraries}
            formattedCities={formattedCities}
            section="quotes"
            title={"Vuelta"}
          />
        </div>
      </ModalGeneric>
    </section>
  );
}
