import { useState } from "react";
import Pagination from "../UI/Pagination";
import MisTravelersBusqueda from "./MisTravelersBusqueda";
import TravelerRow from "./table/TravelerRow";
import TravelersFilters from "./TravelersFilters";
import ExportButtonsTraveler from "./table/tableFilter/ExportButtonsTraveler";
import TravelerHeader from "./table/TravelerHeader";

export default function TravelersTable({
  travelers,
  searcher,
  setPage,
  page,
  refreshValues,
  agencies,
  filteredUsers,
  user,
  filters,
  setFilters,
  selectedOperator,
  setSelectedOperator,
  tourOP,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const handleRowSelect = (traveler) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((row) => row.id === traveler.id);
      return isSelected
        ? prevSelected.filter((row) => row.id !== traveler.id)
        : [...prevSelected, traveler];
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all travelers for the current page

      setSelectedRows(travelers);
    } else {
      // Deselect all travelers
      setSelectedRows([]);
    }
  };

  return (
    <>
      <section className="p-4 border-2 border-gray2 bg-white rounded-lg ">
        <div className="flex flex-row">
          <MisTravelersBusqueda searcher={searcher} pdfData={selectedRows} />

          <TravelersFilters
            dataToUse={[agencies, filteredUsers]}
            user={user}
            filtersState={[filters, setFilters]}
            tourOperatorState={[selectedOperator, setSelectedOperator]}
            tourOP={tourOP}
          />
          <ExportButtonsTraveler pdfData={selectedRows} />
        </div>

        <table className="min-w-full border-collapse">
          <thead className="bg-lightBlue">
            <TravelerHeader
              handleSelectAll={handleSelectAll}
              travelers={travelers}
              page={page}
              selectedRows={selectedRows}
            />
          </thead>
          <tbody>
            {travelers
              ?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              ?.map((traveler, index) => (
                <>
                  <TravelerRow
                    refreshValues={refreshValues}
                    traveler={traveler}
                    selectedRows={selectedRows}
                    handleRowSelect={handleRowSelect}
                    key={index}
                  />
                </>
              ))}
          </tbody>
        </table>
      </section>

      <Pagination
        items={travelers}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
