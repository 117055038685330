import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export default function FormTitle({ title, route, btnText }) {
  const { t } = useTranslation();
  const { tour_op_id } = useParams();
  return (
    <header className="flex justify-between my-16 max-w-[1200px] mx-auto">
      <h1 className="text-darkBlue font-semibold text-3xl">
        {t(`global.Form_Titles.${title}`)}
      </h1>
      <Link
        to={`/${tour_op_id}/${route}`}
        className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
      >
        {t(`global.Form_Titles.${btnText}`)}
      </Link>
    </header>
  );
}
