import { useTranslation } from "react-i18next";

export default function FooterContact({
  TourOperatorAddresses,
  TourOperatorContacts,
  TourOperatorPhones,
  email,
}) {
  const { t } = useTranslation();
  const { street_name, postal_code, city_name } =
    TourOperatorAddresses?.[0] || {};
  const { country_calling_code, number } = TourOperatorPhones?.[0] || {};
  return (
    <div>
      <div>
        <h3 className="text-xl font-medium mb-4">
          {t("global.Footer.contact")}
        </h3>
        <p>
          {TourOperatorAddresses?.length > 0
            ? `${street_name}, ${postal_code} ${city_name}`
            : "Justo José de Urquiza 2063, X5001FTH Córdoba"}
        </p>
        <p>
          {TourOperatorContacts?.length > 0
            ? `${
                country_calling_code.length <= 2 ? "+" : ""
              }${country_calling_code} ${number}`
            : "+54 12312 123123"}
        </p>
        <p>{email || "wearethewalltrip@gmail.com"}</p>
      </div>
    </div>
  );
}
