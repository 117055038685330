import { useState } from "react";
import oricode_chevron from "../../../../iconos/oricode_chevron.png";
import { AutoComplete } from "../../../UI/autoComplete/AutoComplete";
import { useTranslation } from "react-i18next";

export default function HotelCitySearch({
  setValue,
  register,

  errors,
}) {
  const [descodeInput, setDescodeInput] = useState("");
  const { t } = useTranslation();
  return (
    <div className="flex flex-col relative">
      <span className="flex gap-2 items-center justify-center">
        <img src={oricode_chevron} alt="oricode_chevron" className="w-4" />
        <p>Ciudad</p>
      </span>
      <AutoComplete
        justCities
        notIcon
        classname={"bg-[#EFEFEF] outline-none py-2 w-[306px] px-2"}
        register={register}
        name="cityName"
        placeholder="Ciudad de Destino"
        handleAction={(d) => {
          //setValue("cityCode", d?.iata);
          setValue("cityName", d);
        }}
        inputState={[descodeInput, setDescodeInput]}
      />
      {errors?.cityName && (
        <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
          <p>{t(`global.Hotel_Search.${errors?.cityName.message}`)}</p>
        </div>
      )}
    </div>
  );
}
