import { useState } from "react";
import axios from "axios";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";

export const useFees = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(false);

  const [fees, setFees] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createFee = async (pack) => {
    const response = await axios.post(BASE_URL + "v2/fee", pack, headers);
    return response;
  };

  const updateFee = async (fee) => {
    const { id } = fee;

    return await axios.patch(
      BASE_URL + `v2/fee/${id}`,
      {
        data: fee,
      },
      headers,
    );
  };

  const deleteFee = async (id) =>
    await axios.delete(BASE_URL + `v2/fee/${id}`, headers);

  const getFees = async (id) => {
    setLoading(true);
    axios
      .get(BASE_URL + `v2/fee/tour/${id}`, headers)
      .then((e) => {
        setFees(e.data.response);
      })
      .catch(() => setFees([]))
      .finally(() => setLoading(false));
  };

  const getFeesForLink = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(BASE_URL + `v2/fee/tour/${id}`, headers);
      return response.data.response;
    } catch {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const refreshFees = (id) => {
    getFees(id);
  };

  return {
    fees,
    loading,
    getFeesForLink,
    getFees,
    createFee,
    updateFee,
    deleteFee,
    refreshFees,
  };
};
