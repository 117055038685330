// VER DÓNDE VA EL LOGO DE LA AGENCIA
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
// const agency = agencies?.find(({ id }) => id === userData?.agency_id);
export default function MenuLink({
  link: mainLink,
  label,
  state,
  children,
  isHovered,
  location,
  icon,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`relative flex flex-col ${
          children && "gap-4"
        } transition-[width] py-2 hover:shadow-lg rounded-lg duration-500 ease-in-out  `}
      >
        <NavLink to={mainLink}>
          {() => (
            <div className="flex items-center">
              <span
                className={clsx("relative flex items-center", {
                  "opacity-60": state === "show",
                })}
              >
                {/* Icon positioned absolutely to maintain its position */}
                <img
                  src={icon}
                  alt={label}
                  className="w-6 absolute left-4"
                  style={{
                    left: "16px", // Icon stays fixed at this position
                  }}
                />

                {/* Text label with transition */}
                <p
                  className={clsx(
                    "font-medium ml-[60px] origin-left whitespace-nowrap  transition-transform duration-300 ease-in-out",
                    {
                      "opacity-0 scale-x-0": !isHovered, // Hidden when not hovered
                      "opacity-100 scale-x-100": isHovered, // Visible when hovered
                    },
                  )}
                  style={{
                    transitionDelay: "150ms",
                    willChange: "opacity, transform", // Hint for browser optimization
                  }}
                >
                  {t(`global.Panel.${label}`)}
                </p>
              </span>
            </div>
          )}
        </NavLink>

        {/* Sub-menu transition */}
        {location?.pathname?.includes(mainLink.split("/").at(0)) &&
        isHovered ? (
          <span
            className={clsx(
              "flex flex-col gap-4 pl-8 transition-transform origin-top transform scale-y-0 whitespace-nowrap", // Sub-menu starts with scale-y-0
              {
                "scale-x-100": isHovered, // Expands when hovered
                "duration-300 ease-in-out": true, // Smooth animation
              },
            )}
            style={{
              willChange: "transform",
            }}
          >
            <span className="flex flex-col gap-2">
              {children?.map(({ link, label }) => (
                <NavLink
                  key={label}
                  to={link}
                  className={({ isActive }) =>
                    clsx(
                      "text-sm text-center transition-all whitespace-nowrap",
                      {
                        "bg-lightGray3 rounded-full whitespace-nowrap max-w-[170px] text-center p-2 ":
                          isActive && isHovered,
                        "scale-x-0 ": !isActive && !isHovered,
                      },
                    )
                  }
                >
                  <p>{t(`global.Panel.${label}`)}</p>
                </NavLink>
              ))}
            </span>
          </span>
        ) : null}
      </div>
    </>
  );
}
