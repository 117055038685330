import { useEffect, useMemo, useState } from "react";
import HotelListing from "./HotelListing";
import { mocksHotel } from "../../../mocks/mocksHotelJunniper";
import HotelSkeleton from "./HotelSkeleton";
import HotelListingFilters from "./listingFilters/HotelListingFilters";
import { useGetHotels } from "../../../services/useGetHotels";
import { useLocationQuery } from "../../../hooks/useLocationQuery";

export default function HotelAdmin() {
  const { getJuniperHotels2, loading } = useGetHotels();
  const { parsedHash } = useLocationQuery();
  const adults = parseInt(parsedHash.adults || "0", 10);
  const children = parseInt(parsedHash.children || "0", 10);
  const infants = parseInt(parsedHash.infants || "0", 10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    stars: "1-5",
  });
  // Load pax with passengers
  const pax = [
    ...Array(adults).fill(30), // Add 30 for each adult
    ...Array(children).fill(12), // Add 12 for each child
    ...Array(infants).fill(1), // Add 1 for each infant
  ];
  const queryHotel = `&Pax=${JSON.stringify(pax)}&City="${
    parsedHash.cityName
  }"&StartDate="${parsedHash.departureDate}"&EndDate="${
    parsedHash.returnDate
  }"&CountryOfResidence="AR"&ShowHotelInfo=true`;

  useEffect(() => {
    getJuniperHotels2(queryHotel);
  }, [queryHotel, getJuniperHotels2]);

  const filteredHotels = useMemo(() => {
    const trimmedSearchTerm = searchTerm.trim();
    const upperCaseSearchTerm = trimmedSearchTerm.toUpperCase();

    return mocksHotel.filter((hotel) => {
      const hotelName = hotel.HotelInfo[0]?.Name;

      // Check if the hotel name matches the search term
      const matchesSearchTerm =
        trimmedSearchTerm === "" ||
        (hotelName && hotelName.toUpperCase().includes(upperCaseSearchTerm));

      // Accessing star rating from HotelCategory
      const starRating = parseInt(hotel.HotelInfo[0]?.HotelCategory[0]?._, 10); // Convert to integer

      // Check if filters.stars is set and parse the range
      let matchesStarRating = true;
      if (filters.stars) {
        const [minStars, maxStars] = filters.stars.split("-").map(Number); // Split and convert to numbers
        matchesStarRating = starRating >= minStars && starRating <= maxStars; // Check if within range
      }

      return matchesSearchTerm && matchesStarRating;
    });
  }, [searchTerm, filters.stars]);

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-start">
            <HotelSkeleton />
          </div>
        </>
      ) : (
        <>
          <div className="mx-auto flex gap-10 my-10 max-w-[1338.46px]">
            <div className="flex flex-col gap-4">
              <HotelListingFilters
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                hotels={filteredHotels}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
            <div className="w-full flex flex-col gap-8">
              <HotelListing pax={pax} hotels={filteredHotels} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
