export default function PortraitHandler({ portrait, setPortrait }) {
  function removePictureAtIndex() {
    setPortrait("");
  }

  return (
    <div>
      <section className="flex flex-wrap gap-6 w-full">
        {portrait && (
          <div className="flex flex-col items-center">
            <img
              src={portrait}
              alt={`Imagen`}
              className="max-w-[16rem] max-h-[500px]"
            />
            <button
              type="button"
              onClick={() => removePictureAtIndex()}
              className="rounded py-1 px-4 border border-red-500 mt-2"
            >
              Eliminar
            </button>
          </div>
        )}
      </section>
    </div>
  );
}
