import { useState, useEffect } from "react";
import { get, patch, destroy } from "./axios.service";
import { useTourOperatorStore } from "../store/useTourOperatorStore";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const tourOP = useTourOperatorStore((st) => st.tourOP);

  const getUsersByTourOperator = async () => {
    try {
      const response = await get("v2/users/" + tourOP?.id);

      setUsers(response.data.response);
    } catch (error) {
      throw new Error("Ocurrió un error (getUsersByTourOperator): ", error);
    }
  };
  const getUsersById = async (id) => {
    try {
      const response = await get("v2/users/detail/" + id);

      setUser(response.data.response);
    } catch (error) {
      throw new Error("Ocurrió un error (getUsersByTourOperator): ", error);
    }
  };

  const updateUser = async (id, data) => {
    try {
      const response = await patch(`v2/users/${id}`, { data: data });
      return response;
    } catch (error) {
      throw new Error("Ocurrió un error (UpdateUser): ", error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await destroy(`v2/users/${id}`);
      return response;
    } catch (error) {
      throw new Error("Ocurrió un error (deleteUser): ", error);
    }
  };

  useEffect(() => {
    getUsersByTourOperator();
  }, [tourOP]);

  return {
    users,
    user,
    getUsersById,
    setUsers,
    updateUser,
    deleteUser,
    getUsersByTourOperator,
  };
};
