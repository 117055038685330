import React from "react";
import { Slider } from "@mui/material"; // Import Slider from Material-UI
import arrow from "../../../../../iconos/down_arrow.png";

export default function MaxDistanceFilter({
  handleDisplay,
  filters,
  setFilters,
}) {
  const handleSliderChange = (event, newValue) => {
    const selectedDistance = String(newValue);
    setFilters({ ...filters, maxDistance: selectedDistance });
  };

  return (
    <>
      <section
        onClick={() => handleDisplay("distance")}
        id="distance-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">Distancia máxima</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="distance-arrow"
          />
        </header>
      </section>
      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
        id="distance-body"
      >
        <div className="mt-4">
          <Slider
            value={filters.maxDistance ? Number(filters.maxDistance) : 0} // Convert to number for Slider
            onChange={handleSliderChange}
            min={0}
            max={500}
            valueLabelDisplay="auto" // Display current value above the slider
            sx={{
              width: "90%",
              color: "blue", // Change the color of the selected track to blue
              "& .MuiSlider-thumb": {
                backgroundColor: "blue", // Change thumb color to blue
              },
              "& .MuiSlider-track": {
                backgroundColor: "blue", // Change track color to blue
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#d3d3d3", // Change rail color to light gray
              },
            }}
          />
        </div>
      </section>
    </>
  );
}
