import { z } from "zod";

export const feeSchema = z.object({
  base: z.string().min(1, { message: "base_warning" }),
  fare: z
    .number({ invalid_type_error: "fare_warning" })
    .refine((value) => value > 0, { message: "fare_warning" }),
  tax: z
    .number({ invalid_type_error: "tax_warning" })
    .refine((value) => value >= 0, { message: "tax_warning" }),
  currency_type_id: z
    .number({
      message: "currency_type_id_warning", // Message when value is missing
      invalid_type_error: "currency_type_id_warning", // Message for wrong type
    })
    .positive({ message: "currency_type_id_warning_valid" }),
});
