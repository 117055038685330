import { useTranslation } from "react-i18next";
import { MdShieldMoon, MdStar, MdSunny } from "react-icons/md";

export default function TourRowTableHead() {
  const { t } = useTranslation();
  return (
    <tr className=" bg-lightBlue  py-2 px-8 rounded-lg   ">
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_1`)}
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_2`)}
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_3`)}
      </th>
      <th className="font-medium text-brightBlue flex items-center justify-center h-full ">
        <MdStar scale={23} />
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_4`)}
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        <MdSunny scale={23} />
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        <MdShieldMoon scale={23} />
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_5`)}
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_6`)}
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        {t(`global.Admin_Packages.Tour_Headers.col_7`)}
      </th>
    </tr>
  );
}
