import _ from "lodash";
import numberWithCommas from "../../utils/numberWithCommas";
import { passengersTypes, taxesTypes } from "../../utils/commons";
import ticket from "../../iconos/fi-rr-ticket.png";
import { useTranslation } from "react-i18next";
import { useSelectFlightsStore } from "../../store/useSelectFlightsStore";

export const PurchaseDetail = ({
  type,
  selectedAmenities,
  commissions,
  currencies,
  numberOfPassengers,
  fee,
}) => {
  const { t } = useTranslation();
  const flight = useSelectFlightsStore((st) => st.flight);
  const { travelerPricings, price } = useSelectFlightsStore(
    (st) => st.original,
  );

  const breakdownPrices = [
    ...(price?.fees || []), // Provide an empty array as a fallback
    ...(Array.isArray(travelerPricings)
      ? travelerPricings.map((v) => ({
          amount: v?.price?.base || 0, // Default to 0 if undefined
          type: v?.travelerType || "Unknown", // Default to "Unknown" if undefined
          taxes: (v?.price?.total || 0) - (v?.price?.base || 0), // Handle undefined values safely
        }))
      : []), // Provide an empty array if travelerPricings is not an array
  ];

  const breakpricesFormatted = breakdownPrices
    ?.filter((x) => x?.amount > 0)
    ?.reduce((prev, acc) => {
      prev[acc?.type] = {
        amount: (prev[acc?.type]?.amount || 0) + parseFloat(acc?.amount || 0),
        taxes: (prev[acc?.type]?.taxes || 0) + parseFloat(acc?.taxes || 0),
      };
      return prev;
    }, {});

  const breakpricesQ = breakdownPrices?.reduce(
    (prev, acc) => ({
      ...prev,
      [acc.type]: _.reduce(acc, (p) => (p || 0) + 1, 1),
    }),
    {},
  );

  const pricePerPassenger = travelerPricings?.map((passenger) => {
    const { travelerId, travelerType, price } = passenger;
    return {
      travelerId,
      travelerType,
      price,
    };
  });

  const totalPercentageCommissions =
    commissions.length > 0 &&
    commissions.reduce((acc, { percentage }) => (acc += Number(percentage)), 0);

  let totalPrice = Number(
    type === "flights"
      ? flight?.totalPrice
      : Number(fee?.price) + Number(fee?.tax ?? "0"),
  );
  totalPrice += (totalPrice * Number(totalPercentageCommissions)) / 100;

  const passengerTypeCounter = {
    ADULT: 0,
    CHILD: 0,
    HELD_INFANT: 0,
  };

  const translateTravelerType = (traveler) => {
    if (traveler === "ADULT") return "Adulto";
    if (traveler === "CHILD") return "Niño";
    return "Bebé";
  };

  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <section className="relative flex flex-col gap-4">
      <div className="w-full px-8 py-3 flex gap-4 bg-lightBlue rounded-sm">
        <img src={ticket} alt="ticket" />
        <p className="font-medium text-xl">
          {t(`global.Package_Booking.pay_detail`)}
        </p>
      </div>

      {flight && (
        <div className="grid gap-2 px-4">
          {type === "flights" ? (
            pricePerPassenger?.map((passenger) => {
              const { travelerId, travelerType, price } = passenger;
              passengerTypeCounter[travelerType] += 1;

              return (
                <div key={travelerId}>
                  <span className="flex justify-between">
                    <p>
                      {translateTravelerType(travelerType)}{" "}
                      {passengerTypeCounter[travelerType]}
                    </p>
                    {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISAS */}
                    {/* <p>
                      ARS {price?.currency === "EUR" ? "~" : ""}{" "}
                      {numberWithCommas(totalToARS(Number(price.base)), 2)}
                    </p> */}

                    {/* ------------------------------------ */}
                    <p>EUR {numberWithCommas(Number(price.base), 2)}</p>
                    {/* ------------------------------------ */}
                  </span>
                  <span className="flex justify-between">
                    <p>Impuestos</p>

                    {/* ------------------------------------ */}
                    <p>
                      EUR{" "}
                      {numberWithCommas(Number(price.total - price.base), 2)}
                    </p>
                    {/* ------------------------------------ */}
                  </span>

                  {commissions.length > 0 && (
                    <span className="flex flex-col justify-between mt-2">
                      <h3 className="font-semibold">Comisiones</h3>
                      {commissions.map(({ id, name, percentage }) => (
                        <span key={id} className="flex justify-between">
                          <p>
                            {name} ({percentage}%)
                          </p>

                          {/* ---------------------------------------- */}
                          <p>
                            EUR{" "}
                            {numberWithCommas(
                              Number((price.total * Number(percentage)) / 100),
                              2,
                            )}
                          </p>
                          {/* ---------------------------------------- */}
                        </span>
                      ))}
                    </span>
                  )}
                </div>
              );
            })
          ) : (
            <section>
              {numberOfPassengers.map(({ travelerType, travelerId }, index) => (
                <div key={travelerId} className="flex justify-between gap-8">
                  <p className="whitespace-nowrap font-semibold">
                    <span className="w-[10ch]">{index + 1})</span>{" "}
                    {t(
                      `global.Passenger_Dictionary.${passengersTypes?.[travelerType]}`,
                    )}
                  </p>
                  <div className="grid grid-cols-2 w-full">
                    <p>{t(`global.Fee.fee`)}</p>
                    <p className="text-right">
                      {translateCurrency(fee.currency_type_id) ?? "USD"}{" "}
                      {numberWithCommas(Number(fee?.price), 2)}
                    </p>
                    <p>{t(`global.Fee.tax`)}</p>
                    <p className="text-right">
                      {translateCurrency(fee.currency_type_id) ?? "USD"}{" "}
                      {numberWithCommas(Number(fee?.tax ?? "0"), 2)}
                    </p>
                  </div>
                </div>
              ))}

              {commissions.length > 0 && (
                <span className="flex flex-col justify-between mt-2">
                  <h3 className="font-semibold">Comisiones</h3>
                  {commissions.map(({ id, name, percentage }) => (
                    <span key={id} className="flex justify-between">
                      <p>
                        {name} ({percentage}%)
                      </p>
                      <p>
                        {translateCurrency(fee.currency_type_id) ?? "USD"}{" "}
                        {numberWithCommas(
                          Number(
                            (fee?.price *
                              numberOfPassengers.length *
                              Number(percentage)) /
                              100,
                          ),
                          2,
                        )}
                      </p>
                    </span>
                  ))}
                </span>
              )}
            </section>
          )}
        </div>
      )}

      {selectedAmenities && (
        <div className="px-4">
          {Object.keys(selectedAmenities || {}).map((segmentId) =>
            selectedAmenities[segmentId].length === 0 ? null : (
              <div key={segmentId} className="flex gap-3">
                <h1 className="font-medium">Vuelo {segmentId}:</h1>
                {selectedAmenities[segmentId].map((amenity, index) => (
                  <p
                    key={`${segmentId}-${index}`}
                    className="capitalize text-sm"
                  >
                    {amenity.toLowerCase()}
                  </p>
                ))}
              </div>
            ),
          )}
        </div>
      )}

      <div className="h-[1px] mx-auto w-[95%] bg-black" />
      <div className="flex flex-col gap-3 px-4 my-1">
        <span className="flex items-center mb-2 justify-between">
          <p className="font-bold text-black">TOTAL</p>
          <p className="text-2xl font-bold text-[#242423]">
            {type === "packages"
              ? (translateCurrency(fee.currency_type_id) ?? "USD")
              : "EUR "}
            {type === "packages"
              ? numberWithCommas(totalPrice * numberOfPassengers.length, 2)
              : numberWithCommas(totalPrice * pricePerPassenger.length, 2)}
          </p>
        </span>

        {!fee ? (
          <div className="flex flex-col gap-3">
            {_.keys(breakpricesFormatted).map((l) => (
              <>
                <span className="flex justify-between">
                  <p>
                    {taxesTypes?.[l]} x{breakpricesQ?.[l]}
                  </p>
                  <p>${numberWithCommas(breakpricesFormatted?.[l]?.amount)}</p>
                </span>
                <span className="flex justify-between">
                  <p>Impuestos x{breakpricesQ?.[l]} </p>
                  <p>${numberWithCommas(breakpricesFormatted?.[l]?.taxes)}</p>
                </span>
              </>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
};
