import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import arrow from "../../../iconos/chevron_down_gray.png";
import FeeDetailRow from "./FeeDetailRow";
import { useFees } from "../../../services/useFees";
export default function FeeDetailTable({ ids, handleDisplay }) {
  const { getFees, fees } = useFees();
  const { t } = useTranslation();
  const [selectedFee, setSelectedFee] = useState(null);

  useEffect(() => {
    const fetchFees = async () => {
      if (ids) {
        await getFees(ids.packages_travelers.tour_id);
      }
    };
    fetchFees();
  }, [ids]);

  useEffect(() => {
    if (fees.length > 0 && ids?.packages_travelers.fee_id) {
      const foundFee = fees.find(
        (fee) => fee.id === ids?.packages_travelers.fee_id,
      );
      setSelectedFee(foundFee || null);
    }
  }, [fees, ids?.packages_travelers.tour_id]);

  return (
    <section className="pb-2">
      {selectedFee && (
        <>
          <header
            className="datailTableRow bg-lightBlue cursor-pointer flex justify-between items-center px-4 py-2"
            onClick={() => handleDisplay("fees")}
          >
            <h2 className="uppercase font-medium text-xl">
              {t(`global.Fee.fee`)}
            </h2>
            <img
              id={"fees-arrow"}
              src={arrow}
              alt="arrow"
              className="w-4 transform rotate-180 transition-transform duration-200"
            />
          </header>

          <div className="overflow-x-auto">
            <table
              id="fees-body"
              className="table-auto w-full border-collapse  overflow-y-hidden transition-transform duration-200 origin-top rounded-lg"
            >
              <thead className="bg-gray-100">
                <tr>
                  <th className="text-sm font-semibold col-span-2 ml-4 text-center align-middle">
                    {t(`global.Admin_Packages.Fee_Headers.col_2`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4 text-center align-middle">
                    {t(`global.Admin_Packages.Fee_Headers.col_3`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4 text-center align-middle">
                    {t(`global.Admin_Packages.Fee_Headers.col_4`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4 text-center align-middle">
                    {t(`global.Admin_Packages.Fee_Headers.col_6`)}
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white  rounded-lg">
                <FeeDetailRow fee={selectedFee} />
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
}
