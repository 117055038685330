import passenger from "../../../../iconos/passenger.png";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useLocation, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import setTwoSeacherSchema from "../../../../schemas/stepTwoSearcher";
import { FaCalendarAlt, FaSearch } from "react-icons/fa";
import { useState } from "react";
import { useLocationQuery } from "../../../../hooks/useLocationQuery";
import { useToggleVisibility } from "../../../../hooks/useToggleVisibility";
import { useTranslation } from "react-i18next";
import AutoCompleteDate from "../../../UI/autoComplete/AutoCompleteDate";

export const BuscadorPaquetes = ({
  possibleCitiesAndMonth,
  searcher,
  setFilters,
  setPassengers,
  passengers,
}) => {
  const [searchTerm, setSearchTerm] = searcher;
  const { parsedHash, changeQuery } = useLocationQuery();
  const location = useLocation();
  const { t } = useTranslation();
  const { tour_op_id } = useParams();
  const type = location.pathname.includes("search-flight")
    ? "Flights"
    : "Packages";

  const [, toggleSalida] = useToggleVisibility();
  const hasRetunDate = !!parsedHash?.returnDate;
  const navegar = (data) => {
    changeQuery({
      query: data,
      route: location.pathname.includes("search-flight")
        ? `${tour_op_id}/search-flight`
        : `${tour_op_id}/search-packages`,
      go: true,
    });
  };

  const schema = setTwoSeacherSchema(type, hasRetunDate);
  const [, setValue] = useState();

  const {
    register,
    handleSubmit,

    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      departureDate: parsedHash?.departureDate,
      returnDate: parsedHash?.returnDate,
      originLocationCode: parsedHash?.originLocationCode,
      destinationLocationCode: parsedHash?.destinationLocationCode,
      adults: parsedHash?.adults || "1",
      children: parsedHash?.children,
      infants: parsedHash?.infants,
    },
  });

  const onSubmit = (data) => {
    navegar({
      ...data,
      // ...{ opcionrutas: opcionrutas },
    });
  };

  //  Function to set month in the filters
  const handleChooseValue = (inputValue) => {
    let month = [];
    let year = [];

    if (inputValue) {
      // Extract the month part from the inputValue
      month = [Number(inputValue.split("-")[1])];
      year = [String(inputValue.split("-")[0])];
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      month,
      year,
    }));
  };

  // const disableVuelta = (date) =>
  //   isBefore(date, new Date(watch("departureDate") + "T00:00:00"));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg pr-4 pb-4 w-[230px] "
    >
      <span className="flex flex-col gap-1 pb-[26px] leading-0">
        <h2 className="text-[#242423] whitespace-nowrap text-xl font-[700] pl-2 m-0">
          {location?.pathname?.includes("search-flight") ? (
            "Buscá tu vuelo"
          ) : location?.pathname?.includes("search-packages") ? (
            <>{t(`global.package_listing.title`)}</>
          ) : (
            "Actividades turísticas"
          )}
        </h2>
        <h3 className="pl-2 m-0">
          {location?.pathname?.includes("search-flight")
            ? "¿A dónde querés ir?"
            : ""}{" "}
        </h3>
      </span>
      {/* Form */}
      <div className="flex flex-col gap-3">
        <div className="relative border border-[#8D99AE] flex  px-2 py-2 rounded-lg items-center">
          <div className="flex items-center justify-center rounded-md px-2 py-3  bg-lightBlue/80">
            <FaSearch color="#131516" size={13} />
          </div>

          <div
            className={`z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md `}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={t(
                `global.Search_Packages_Placeholder.origin_destination`,
              )}
              className=" px-3 pt-1 m-0 w-full rounded cursor-pointer whitespace-pre-wrap transition-colors hover:bg-slate-100 focus:outline-none shadow-none "
            />
          </div>
        </div>

        <div className="">
          <div className="relative border border-[#8D99AE] flex  px-2  rounded-lg items-center">
            <div className="flex items-center justify-center rounded-md px-2 py-3 bg-lightBlue/80">
              <FaCalendarAlt color="#131516" size={13} />
            </div>
            <div className="border border-none rounded-md w-full ">
              <AutoCompleteDate
                options={possibleCitiesAndMonth.dateIn}
                setValue={setValue}
                register={register}
                defaultValue={parsedHash?.departureDate}
                name="departureDate"
                placeholder="choose_date"
                handleChange={(optionValue) =>
                  handleChooseValue(optionValue, "departure_date")
                }
              />
            </div>
            <div className="absolute text-red-600 text-xs bottom-0.5 left-2 whitespace-nowrap">
              <ErrorMessage errors={errors} name="departureDate" as="p" />
            </div>
          </div>
          {hasRetunDate && <div className="w-[1px] h-2 bg-[#DAE0E6] py-8" />}
          {hasRetunDate && (
            <div className="py-10 text-center text-[#24242380] rounded-tr-xl rounded-br-xl">
              <div className="flex items-center h-5 gap-3">
                <input
                  name="returnDate"
                  placeholder="Vuelta"
                  onClick={() => toggleSalida()}
                  value={
                    !watch("returnDate")
                      ? parsedHash?.returnDate
                        ? format(
                            new Date(parsedHash?.returnDate + "T00:00:00"),
                            "dd/MM/yyyy",
                          )
                        : "Vuelta"
                      : format(
                          new Date(watch("returnDate") + "T00:00:00"),
                          "dd/MM/yyyy",
                        )
                  }
                  className="flex items-center justify-center w-full px-2 text-sm text-center outline-none bg-inherit h-7"
                  readOnly
                />
              </div>
              <div className="absolute text-red-600 text-xs bottom-0.5 right-2 whitespace-nowrap">
                <ErrorMessage errors={errors} name="returnDate" as="p" />
              </div>
            </div>
          )}
        </div>

        {/* passengers */}
        <div className="relative border border-[#8D99AE] flex flex-col gap-4 px-2 py-2 rounded-lg items-center">
          <div className="flex gap-4 items-center">
            <div className="flex items-center justify-center rounded-md p-3 bg-lightBlue/80">
              <img className="w-8" src={passenger} alt="pasajeros" />
            </div>
            <p className="text-sm">{t(`global.Passenger_Dictionary.adult`)}:</p>
            <input
              type="number"
              min={1}
              name="adults"
              {...register("adults", {
                onChange: (e) => {
                  setPassengers({
                    ...passengers,
                    adults: e.target.value, // Ensure it's a number
                  });
                },
              })}
              className="text-[#24242380] text-xs bg-inherit w-full outline-none"
              placeholder="Adultos"
            />
            <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
              <ErrorMessage errors={errors} name="adults" as="p" />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <div className="flex items-center justify-center rounded-md p-3 bg-lightBlue/80">
              <img className="w-8" src={passenger} alt="pasajeros" />
            </div>
            <p className="text-sm">
              {t(`global.Passenger_Dictionary.children`)}:
            </p>
            <input
              type="number"
              min={0}
              name="infants"
              {...register("children", {
                onChange: (e) => {
                  setPassengers({
                    ...passengers,
                    children: e.target.value, // Ensure it's a number
                  });
                },
              })}
              className="text-[#24242380] text-xs bg-inherit w-full outline-none"
              placeholder="0"
            />
            <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
              <ErrorMessage errors={errors} name="children" as="p" />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <div className="flex items-center justify-center rounded-md p-3 bg-lightBlue/80">
              <img className="w-8" src={passenger} alt="pasajeros" />
            </div>
            <p className="text-sm">
              {t(`global.Passenger_Dictionary.infants`)}:
            </p>
            <input
              type="number"
              min={0}
              name="infants"
              {...register("infants", {
                onChange: (e) => {
                  setPassengers({
                    ...passengers,
                    infants: e.target.value,
                  });
                },
              })}
              className="text-[#24242380] text-xs bg-inherit w-full outline-none"
              placeholder="0"
            />
            <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
              <ErrorMessage errors={errors} name="infants" as="p" />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
