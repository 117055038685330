import { useTranslation } from "react-i18next";

export default function BookingTableHeader({
  headers,
  setSelectedRows,
  selectedRows,
  data,
}) {
  const { t } = useTranslation();
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };
  return (
    <thead className="rounded-lg">
      <tr className="bg-lightBlue">
        {/* Default header with checkbox */}
        <th className="text-center px-4">
          <input
            className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
            type="checkbox"
            onChange={handleSelectAll}
            checked={selectedRows.length > 0}
          />
        </th>
        {headers.map((header, index) => (
          <th
            className="font-medium text-brightBlue text-center px-4"
            key={index}
            scope="col"
          >
            {t(`global.Admin_Bookings.Headers.${header}`)}
          </th>
        ))}
      </tr>
    </thead>
  );
}
