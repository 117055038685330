import PassengerSelector from "../../../UI/PassengerSelector";

export default function ContactInfo({
  qto,
  t,
  passengerData,
  setPassengerData,
}) {
  const addPassenger = (type) => {
    setPassengerData((prevState) => ({
      ...prevState,
      [type]: prevState[type] + 1, // Increment the count for the given passenger type
    }));
  };
  const removePassenger = (type) => {
    setPassengerData((prevState) => ({
      ...prevState,
      [type]: prevState[type] > 0 ? prevState[type] - 1 : 1,
    }));
  };

  return (
    <div className="flex flex-row mt-12  ">
      <div className="w-[530px]">
        <div className="flex flex-col ">
          <h1 className="font-semibold text-xl">
            {t(`global.Booking_Form.passenger_counter`)}
          </h1>
          {qto?.quote?.passengers && (
            <div className="bg-lightGray w-[500px] p-2 rounded-md">
              <div className="flex flex-row italic">
                {qto?.quote?.passengers}
              </div>
            </div>
          )}

          {!qto?.quote?.passengers && (
            <div className="bg-lightGray p-2 w-[500px] rounded-md">
              <div className="flex flex-row italic">
                {" "}
                {t(`global.Booking_Form.passenger_info_missing`)}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-[20vw]">
        <h1 className="font-semibold text-xl mt-7" />
        <PassengerSelector
          passengerData={passengerData || {}}
          removePassenger={removePassenger}
          addPassenger={addPassenger}
        />
      </div>
    </div>
  );
}
