import React, { useState } from "react";
import { Slider } from "@mui/material";
import { FaStar } from "react-icons/fa";
import arrow from "../../../../../iconos/down_arrow.png";
export default function StarFilter({ setFilters, handleDisplay }) {
  const [value, setValue] = useState([1, 5]); // Initial state for min and max stars

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilters((prevFilters) => ({
      ...prevFilters,
      stars: `${newValue[0]}-${newValue[1]}`, // Update filters with the selected range
    }));
  };

  return (
    <>
      <section
        onClick={() => handleDisplay("star")}
        id="star-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">Categoria</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="star-arrow"
          />
        </header>
      </section>

      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
        id="star-body"
      >
        <div className="flex flex-col mt-4">
          <Slider
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={1}
            max={5}
            sx={{
              marginBottom: 2,
              width: "90%", // Adjust width here (e.g., 80% of the parent container)
              "& .MuiSlider-track": {
                backgroundColor: "blue", // Change track color to blue
              },
              "& .MuiSlider-thumb": {
                color: "blue", // Change thumb color to blue
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#d3d3d3", // Change rail color to light gray
              },
            }}
            valueLabelFormat={(val) => `${val} Estrella${val > 1 ? "s" : ""}`} // Optional: format label
          />

          <div className="flex mt-2 justify-center">
            {[...Array(5)].map((_, index) => {
              const starValue = index + 1;
              return (
                <FaStar
                  key={starValue}
                  color={
                    starValue >= value[0] && starValue <= value[1]
                      ? "yellow"
                      : "gray"
                  }
                  size={20}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
