import { useEffect, useState } from "react";

export default function UserQuoteCol({ id, users }) {
  const [username, setUsername] = useState("");

  useEffect(() => {
    // Search for the user by id
    const userFound = users.find((user) => user.id === id);

    if (userFound) {
      setUsername(userFound.username); // Set the username if user is found
    }
  }, [id, users]); // Re-run the effect if 'id' or 'users' change

  return <div>{username}</div>;
}
