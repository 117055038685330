import { useParams } from "react-router-dom";
import ToggleButton from "../../../../UI/ToggleButton";
import SubMenuButton from "./tableButtons/SubMenuButton";
import CopyLinkButton from "../../../../UI/actionButtons/CopyLinkButton";

export default function TableActionsPackages({
  pkg,
  isTourVisible,
  setIsTourVisible,
}) {
  const { tour_op_id } = useParams();

  return (
    <div className="flex flex-row ">
      <CopyLinkButton
        route={"package-link"}
        tour_op_id={tour_op_id}
        data={pkg}
      />

      <ToggleButton
        toggleVisibility={setIsTourVisible}
        isVisible={isTourVisible}
      />

      <SubMenuButton tour_op_id={tour_op_id} pkg={pkg} />
    </div>
  );
}
