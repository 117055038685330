import { useTranslation } from "react-i18next";

export default function ModalViewPackage({ selectedPackage }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2 -mt-2">
      <div className="flex justify-between items-center">
        <h2 className="font-medium text-darkBlue text-2xl mb-2">
          {selectedPackage.title}
        </h2>
      </div>
      <div className="flex gap-8">
        <div className="flex flex-col h-full justify-between">
          <div className="grid grid-cols-2 gap-4">
            <input
              value={selectedPackage.origin_code}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
            <input
              value={selectedPackage.destination_code}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
          </div>
          <div className="mt-4">
            <label
              htmlFor="description"
              className="text-darkBlue text-lg font-medium"
            >
              {t(`global.Admin_Packages.Package_Modal.description`)}
            </label>
            <textarea
              name="description"
              id="description"
              value={selectedPackage.description_general?.replace(
                /<br>/g,
                "\n",
              )}
              className="w-full h-auto px-4 py-2 border border-gray2 rounded"
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
}
