import edit from "../../iconos/edit_icon.png";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import UpdateTravelerModal from "./UpdateTravelerModal";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { useTranslation } from "react-i18next";
import { ModalGeneric } from "../UI/ModalGeneric";

export default function TravelersTableButtons({ travelerData, refreshValues }) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { t } = useTranslation();
  return (
    <div className="flex justify-between gap-2 items-center">
      <button
        className="relative h-[24px] w-[24px] hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/edit"
        onClick={toggleVisibility}
      >
        <img src={edit} alt="edit" className="h-6" />
        <span className="absolute whitespace-nowrap bg-white border -top-8 -left-0 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
          {t(`global.Actions.edit`)}
        </span>
      </button>

      <a
        href={`mailto:${travelerData?.email_address}`}
        className="relative h-[24px] w-[24px] flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/mail"
      >
        <MailOutlinedIcon
          sx={{
            color: "#A0AFCD",
            height: "18px",
            width: "18px",
          }}
          alt="mail"
        />
        <span className="absolute whitespace-nowrap bg-white border -top-8 left-1/2 transform -translate-x-1/2 py-0.5 px-1 rounded scale-0 transition-transform group-hover/mail:scale-100">
          {t(`global.Admin_Travelers.send_email_traveler`)}
        </span>
      </a>
      <a
        href={`tel:${travelerData.number}`}
        className="relative h-[24px] w-[24px] flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/call"
      >
        <CallEndOutlinedIcon
          sx={{
            color: "#A0AFCD",
            height: "18px",
            width: "18px",
          }}
          alt="call"
        />
        <span className="absolute whitespace-nowrap bg-white border -top-8 left-1/2 transform -translate-x-1/2 py-0.5 px-1 rounded scale-0 transition-transform group-hover/call:scale-100">
          {t(`global.Admin_Travelers.call_traveler`)}
        </span>
      </a>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <UpdateTravelerModal
          traveler={travelerData}
          refreshValues={refreshValues}
          toggleVisibility={toggleVisibility}
        />
      </ModalGeneric>
    </div>
  );
}
