import { z } from "zod";

export const packageSchema = z.object({
  title: z.string().min(1, { message: "title_warning" }),
  origin_code: z.string().min(1, { message: "origin_warning" }),
  destination_code: z.string().nonempty("destination_warning"),
  description_general: z.string().optional(),
  include: z.string().optional(),
  not_included: z.string().optional(),
  itinerary: z.string().optional(),
  terms: z.string().optional(),
  flyer: z.string().optional(),
  portrait: z.string().optional(),
  pictures: z.array(z.string()).optional(),
  supplier_id: z.number().optional(),
  package_category_id: z.number().default(1),
  show: z.boolean(),
});
