import edit from "../../../../../iconos/edit_icon.png";
import { useTourOperatorStore } from "../../../../../store/useTourOperatorStore";

// import { Link, useParams } from "react-router-dom";

export default function EditButton() {
  //descomentar cuando lo utilizemos para la redireccion al formulario
  // const {tour_op_id} = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP.name);

  const handleWhatsAppMessage = () => {
    const userData = JSON.parse(sessionStorage.getItem("userData") || "{}");
    const username = userData.username || "Usuario";
    const phoneNumber = "34627970232";
    const message = encodeURIComponent(
      `Hola Walltrip: soy ${username}, del operador ${tourOP}. No puedo editar mis proveedores!`,
    );
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

    // Abrir el enlace en una nueva pestaña
    window.open(whatsappURL, "_blank");
  };

  return (
    <button
      className="relative h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/edit"
      onClick={handleWhatsAppMessage}
    >
      <img src={edit} alt="edit" className="mt-1 xl:mt-0" />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
        Editar
      </span>
    </button>
  );
}
