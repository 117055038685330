import { useEffect, useState } from "react";

function ImageComponent({ image, fallbackMessage }) {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    // Convertir los datos de la imagen a base64
    const arrayBuffer = new Uint8Array(image).buffer;
    const blob = new Blob([arrayBuffer], { type: "image/png" });
    const reader = new FileReader();

    reader.onloadend = function () {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(blob);
  }, [image]);

  return (
    <div>
      {imageSrc.replace("data:", "")?.length ? (
        <img className="w-20" src={imageSrc} alt="Imagen" />
      ) : (
        <p>{fallbackMessage || ""}</p>
      )}
    </div>
  );
}

export default ImageComponent;
