import { useTranslation } from "react-i18next";

export default function FooterLinks({ TourOperatorSocialMedia }) {
  const { t } = useTranslation();
  const wpp = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Whatsapp",
  )?.url;
  return (
    <div className="flex flex-col ">
      <div>
        <h3 className="text-xl font-medium ">
          {t("global.Footer.newsletter")}
        </h3>
        <input
          type="tel"
          placeholder="Nroº de telefono"
          className="mt-4 rounded-lg border border-footerGray  text-center placeholder:text-gray-500 block"
          pattern="[0-9]*"
          inputMode="numeric"
        />
        <div className="mt-4">
          <a
            className="mt-4 hover:bg-darkGray bg-footerGray rounded-lg p-2"
            target="_blank"
            href={wpp}
            rel="noreferrer"
          >
            {t("global.Subscribe")}
          </a>
        </div>
      </div>
    </div>
  );
}
