import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import { quoteSchema } from "../../../schemas/quote";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import { useQuotes } from "../../../services/useQuotes";
import { AutoComplete } from "../../UI/autoComplete/AutoComplete";
import { useTranslation } from "react-i18next";

export default function QuoteForm() {
  const { tour_op_id } = useParams();
  const { t } = useTranslation();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const navigate = useNavigate();
  const { createQuote } = useQuotes();
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const userData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData"),
  );

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(quoteSchema) });

  const onSubmit = async (data) => {
    toast.promise(
      createQuote({
        name: data.name,
        origin: oricodeInput,
        destination: descodeInput,
        email: data.email,
        whatsapp: data.whatsapp,
        dates: data.dates,
        ask: data.ask,
        type: data.type,
        passengers: data.passengers,
        tour_op_id: Number(tourOP.id),
        user_id: userData.id,
        agency_id: userData.agency_id,
      })
        .then(() => {
          navigate(`/${tour_op_id}/panel/sales/quotes/my-quotes/package`, {
            replace: true,
          });
        })
        .catch((e) => {
          throw new Error(e);
        }),
      {
        success: <b>{t(`global.Quotes_Form.quote_create_toaster`)}</b>,
        loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
        error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
      },
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="my-10 mt-16 mx-auto w-3/4 bg-white rounded-lg p-8"
    >
      <div>
        <Toaster />
      </div>
      <section className="grid  gap-2 my-2 p-4">
        <div className="col">
          <div className="grid grid-cols-2 mb-4 ">
            <div className="w-full relative flex flex-col ">
              <label>Nombre</label>
              <input
                className="addPackInput"
                name="name"
                {...register("name", {
                  required: "Nombre es requerido",
                })}
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="name" as="p" />
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 mb-4 gap-3">
            <div className="mb-4">
              <label>
                Origen
                <label />
              </label>

              <label className="col-span-2 relative ">
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="origin"
                  placeholder="Origen"
                  handleAction={(d) => setValue("origin", d)}
                  inputState={[oricodeInput, setOricodeInput]}
                />
                {errors?.origin && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <ErrorMessage errors={errors} name="origin" as="p" />
                  </div>
                )}
              </label>
            </div>
            <div className="mb-4">
              <label>Destino</label>

              <label className="col-span-2 relative">
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="destination"
                  placeholder="Destino"
                  handleAction={(d) => setValue("destination", d)}
                  inputState={[descodeInput, setDescodeInput]}
                />
                {errors?.destination && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <ErrorMessage errors={errors} name="destination" as="p" />
                  </div>
                )}
              </label>
            </div>
            <div className="mb-4 grid grid-cols mr-4">
              <label>Fechas</label>
              <input
                className="addPackInput"
                name="dates"
                {...register("dates", {})}
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="dates" as="p" />
              </span>
            </div>

            <div className="mb-4 grid grid-cols mr-4">
              <label>Pasajeros</label>
              <input
                className="addPackInput"
                name="passengers"
                {...register("passengers", {})}
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="passengers" as="p" />
              </span>
            </div>
          </div>
          <div className="mb-4 grid grid-cols mr-4">
            <label>Pedido</label>
            <textarea
              className="addPackInput w-full h-32"
              name="ask"
              {...register("ask", {})}
            />
          </div>
          <div className="grid grid-cols-2 mb-4">
            <div className="mb-4 grid grid-cols mr-4">
              <label>Email</label>
              <input
                className="addPackInput"
                name="email"
                {...register("email", {
                  required: "Nombre es requerido",
                })}
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="email" as="p" />
              </span>
            </div>
            <div className="mb-4 grid grid-cols">
              <label>Whatsapp</label>
              <input
                className="addPackInput"
                name="whatsapp"
                placeholder="+54 ..."
                {...register("whatsapp", {})}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9+]/g, "");
                }}
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="whatsapp" as="p" />
              </span>
            </div>
          </div>
          <div className="w-full relative flex flex-col mb-8">
            <label>
              Categoria
              <label>
                <span className="text-red-400">*</span>
              </label>
            </label>

            <select
              className="addPackInput"
              name="type"
              defaultValue={""} // Default value set to empty string (interpreted as null)
              {...register("type")}
            >
              <option value="">-- Elegir una Categoria --</option>{" "}
              {/* Null option */}
              <option value="package">Paquetes</option>
              <option value="flight">Vuelos</option>
              <option value="hotel">Hoteles</option>
              {/* <option value="cruise">Crusero</option> */}
            </select>
            {errors?.type && (
              <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                <p> {t(`global.Quote_Form.${errors?.type.message}`)}</p>
              </div>
            )}
          </div>
        </div>
      </section>

      <button className="font-semibold text-lg ml-auto mt-4 button w-1/4 bg-brightBlue text-white hover:shadow-lg transition-all">
        Guardar
      </button>
    </form>
  );
}
