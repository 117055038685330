import { useEffect, useState } from "react";
import TourRowTableHead from "./tourRow/TourRowTableHead";
import TourRowTableBody from "./tourRow/TourRowTableBody";
import { Link, useParams } from "react-router-dom";
import { useTours } from "../../../../../services/useTours";
import useReloadStore from "../../../../../store/useRealoadStore";
import { useTranslation } from "react-i18next";
import { useTourOperatorStore } from "../../../../../store/useTourOperatorStore";

export default function TableTourRow({ pkg, setTour }) {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { tours, getTours } = useTours();
  const { tour_op_id } = useParams();
  const { t } = useTranslation();
  const reloadTours = useReloadStore((state) => state.reloadTours);
  const AllowDelete = tours.length > 1 ? true : false;
  // Fetch tours when pkg.id or tourOP changes
  useEffect(() => {
    getTours(pkg.id);
  }, [pkg.id, tourOP, reloadTours]);

  // Create a visibility array for fee visibility control
  const [isFeeVisible, setIsFeeVisible] = useState([]);

  // Update the visibility array when tours change
  useEffect(() => {
    if (tours.length) {
      setIsFeeVisible(Array(tours.length).fill(false));
      setTour(tours);
    }
  }, [tours]);

  // Toggle fee visibility for a specific tour
  const toggleFeeVisibility = (index) => {
    setIsFeeVisible((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  return (
    <div className="rounded-lg p-4">
      {tours.length > 0 ? (
        <table className="w-full border-collapse border-2 rounded-lg overflow-hidden">
          <thead>
            <TourRowTableHead />
          </thead>
          <tbody>
            {tours.map((tour, index) => (
              <TourRowTableBody
                key={tour.id} // Add unique key here
                index={index}
                tour={tour}
                pkgId={pkg.id}
                setIsFeeVisible={() => toggleFeeVisibility(index)}
                isFeeVisible={isFeeVisible[index]}
                package_id={pkg.id}
                AllowDelete={AllowDelete}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <h1>{t(`global.Admin_Packages.no_departure`)}</h1>
        </>
      )}
      <Link
        className="button bg-darkBlue text-white items-center flex hover:shadow-md transition-all px-2 w-25 justify-center py-0 hover:bg-brightBlue mt-2"
        to={`/${tour_op_id}/panel/packages/add-tour`}
        state={{ package_id: pkg.id }}
      >
        + {t(`global.Admin_Packages.add_departure`)}
      </Link>
    </div>
  );
}
