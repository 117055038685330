import { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useUsers } from "../../../services/useUsers";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const UpdateUserModal = ({ roles, user, agencies, closeModal }) => {
  let [dataUser, setDataUser] = useState({});
  const { updateUser } = useUsers();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
    defaultValues: user,
  });

  const onSubmit = async () => {
    toast.promise(
      updateUser(user.id, dataUser).then(() => closeModal()),
      {
        success: <b>{t(`global.User_Form.user_edit_toaster`)}</b>,
        loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
        error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
      },
    );
  };

  const handleChange = (data) => {
    setDataUser({ ...dataUser, [data.target.name]: data.target.value });
  };

  return (
    <div className="p-4">
      <h1 className="text-darkBlue font-semibold text-2xl text-center mb-5">
        Editar Usuario
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleChange}
        className="mx-auto bg-white rounded-lg"
      >
        <Toaster />
        <section className="grid grid-cols-3 gap-2">
          <div className="w-full relative flex flex-col">
            <label>Nombre</label>
            <input className="addPackInput" name="name" {...register("name")} />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="name" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Apellido</label>
            <input
              className="addPackInput"
              name="last_name"
              {...register("last_name")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="last_name" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Nombre de usuario</label>
            <input
              className="addPackInput"
              name="username"
              {...register("username")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="username" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Email</label>
            <input
              className="addPackInput"
              name="email"
              {...register("email")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="email" as="p" />
            </span>
          </div>

          {/* <div className="w-full relative flex flex-col">
            <label>Contraseña</label>
            <input
              className="addPackInput"
              name="password"
              {...register("password")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="password" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Repetir contraseña</label>
            <input
              className="addPackInput"
              name="rePassword"
              {...register("rePassword")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="rePassword" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Avatar</label>
            <input
              className="addPackInput"
              name="avatar"
              {...register("avatar")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="avatar" as="p" />
            </span>
          </div> */}

          <div className="w-full relative flex flex-col">
            <label>Agencia</label>
            <select
              className="addPackInput"
              name="agency_id"
              id=""
              {...register("agency_id")}
            >
              <option value={1}>Sin Agencia</option>
              {agencies?.map((agency, key) => (
                <option value={agency.id} key={key}>
                  {agency.name}
                </option>
              ))}
            </select>
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="agency_id" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Rol</label>
            <select
              className="addPackInput"
              name="role_id"
              id=""
              {...register("role_id")}
            >
              {roles?.map((role, key) => (
                <option value={role.id} key={key}>
                  {role.name}
                </option>
              ))}
            </select>
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="role_id" as="p" />
            </span>
          </div>

          <button className="col-span-2 font-semibold text-lg ml-auto mt-4 button w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
            Guardar
          </button>
        </section>
      </form>
    </div>
  );
};

export default UpdateUserModal;
