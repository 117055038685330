import useSendEmail from "../services/useSendEmail";
import { generateEmailBodyForOp } from "../utils/generateEmailBodyForOp";

export const useConfirmBookingEmail = () => {
  const { sendEmail } = useSendEmail();

  const updateBookingAndSendEmail = async (reserva, value, tourOP, email) => {
    try {
      // Send email
      await sendEmail({
        type: "Gmail",
        tour_op_id: tourOP.id,
        options: {
          from: "wearewalltrip@gmail.com",
          bcc: email,
          subject: "Reserva Confirmada",
          html: generateEmailBodyForOp(reserva, tourOP.id, value),
        },
      });

      return { success: true }; // Return success if no errors occur
    } catch (error) {
      throw new Error(
        `Error updating booking or sending email: ${error.message}`,
      ); // Throw a new error with a detailed message
    }
  };

  return { updateBookingAndSendEmail };
};
