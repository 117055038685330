import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useTranslation } from "react-i18next";

const SuppliersFilter = ({ searcher, orderChange }) => {
  const [searchValue, setSearcherValue] = searcher;
  const { t } = useTranslation();
  const handleSortOrder = () => {
    orderChange((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  return (
    <section className="flex gap-4 pb-4 mb-4 items-center border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-96 rounded"
        placeholder={t(`global.Admin_Suppliers.search_placeholder`)}
        value={searchValue}
        onChange={(e) => setSearcherValue(e.target.value)}
      />

      <SwapVertIcon
        onClick={handleSortOrder}
        className="hover:cursor-pointer"
      />
    </section>
  );
};

export default SuppliersFilter;
