import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import SEO from "../../../utils/SEO";
import Head from "../../head";
import { useLocation } from "react-router-dom";
import HotelPassengerBasicData from "./hotelRequestForm/HotelPassengerBasicData";
import HotelPassengerLegalData from "./hotelRequestForm/HotelPassengerLegalData";
import HotelData from "./hotelRequestForm/HotelData";
import { bookHotelSchema } from "../../../schemas/bookHotelSchema";
import { useBookings } from "../../../services/useBookings";

export default function HotelRequest() {
  const location = useLocation();
  const { hotelOffer } = location.state || {};
  const { HotelInfo, HotelOptions } = hotelOffer;
  const { Prices, HotelRooms } = HotelOptions[0].HotelOption[0];
  const { $, TotalFixAmounts } = Prices?.[0]?.Price?.[0] || {};
  const firstRoom = HotelRooms?.[0]?.HotelRoom?.[0];
  const { RoomOccupancy } = firstRoom || {};
  const { Adults, Children } = RoomOccupancy?.[0]?.$ || {};

  const { postHotelBooking } = useBookings(); // Call the postHotelBooking function from the useBookings hook

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(bookHotelSchema),
  });

  const onSubmit = (data) => {
    let body = {
      booking: {
        creation_date: new Date().toISOString(),
        status: "pending",
        comments: "No comments",
        type: "Hotels",
        tour_op_id: 1,
        currency_type_id: 1,
        agency_id: 1,
        user_id: 1,
      },
      hotel: {
        data: JSON.stringify(hotelOffer),
      },
      travelers: [
        {
          data: {
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            date_of_birth: data.data.date_of_birth,
            gender: data.data.gender,
            email_address: data.data.email_address,
          },
          documentation: {
            doc_type: data.documentation.doc_type,
            birth_place: data.documentation.birth_place,
            issuance_location: data.documentation.issuance_location,
            issuance_date: data.documentation.issuance_date,
            number: data.documentation.number,
            expiry_date: data.documentation.expiry_date,
            issuance_country: data.documentation.issuance_country,
            validity_country: data.documentation.issuance_country,
            nationality: data.documentation.nationality,
            holder: true,
            traveler_id: 1,
          },
          contact_phones: [
            {
              device_type: data.contact_phones[0].device_type,
              country_calling_code: data.contact_phones[0].country_calling_code,
              number: data.contact_phones[0].number,
              traveler_id: 1,
            },
          ],
        },
      ],
      user_id: 1,
    };

    toast.promise(postHotelBooking(body), {
      loading: "Cargando...",
      success: <b>Enviado con exito!</b>,
      error: <b>Hubo un error, intenta de nuevo.</b>,
    });
  };

  return (
    <main className="font-body">
      <SEO title={"Hoteles"} />
      <Head />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[1100px] mx-auto my-8 flex justify-between gap-3"
      >
        <div>
          <Toaster />
        </div>
        <div
          className="flex flex-row  w-full justify-between items-center gap-x-4 gap-y-8 origin-top transition-all duration-300 p-4 border border-gray2 rounded-lg overflow-hidden"
          id="Pasajero 1 (Adulto)-body"
        >
          <div className="w-2/3 flex flex-col">
            <HotelPassengerBasicData register={register} errors={errors} />
            <HotelPassengerLegalData register={register} errors={errors} />
          </div>

          <div className="w-1/3">
            <HotelData
              HotelInfo={HotelInfo}
              Adults={Adults}
              Children={Children}
              $={$}
              TotalFixAmounts={TotalFixAmounts}
            />
            <button
              onSubmit={handleSubmit(onSubmit)}
              className="relative col-span-2 row-span-2 text-darkBlue text-center py-4 border-2 border-darkBlue text-2xl font-semibold w-full rounded-md mb-2 cursor-pointer hover:bg-darkBlue hover:text-white active:opacity-75 transition-all justify-self-end self-end"
            >
              RESERVAR
            </button>
          </div>
        </div>
      </form>
    </main>
  );
}
