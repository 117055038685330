import { format } from "date-fns";
export default function TourDetailRow({ tour }) {
  return (
    <tr className=" rounded-xl border-gray2 border-2">
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {tour?.dateIn ? format(new Date(tour.dateIn), "dd/MM/yyyy") : ""}
      </td>
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {tour?.dateOut ? format(new Date(tour.dateOut), "dd/MM/yyyy") : ""}
      </td>
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {tour?.hotels}
      </td>
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {tour?.category}
      </td>
      <td className="font-body text-center align-middle">{tour?.meal_plan}</td>
    </tr>
  );
}
