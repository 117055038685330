import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useSendEmail from "../../services/useSendEmail";
import AgencySelector from "./AgencySelector";
import { generateEmailBody } from "../packages/generateEmailBody";
import useAgencies from "../../services/useAgencies";
import { generateEmailBodyForOp } from "../../utils/generateEmailBodyForOp";
export default function SendEmail({ selectedPackage, tourOP, closeModal }) {
  const { tour_op_id } = useParams();
  const { agencies, getAgenciesByTourOperator } = useAgencies();
  const { sendEmail } = useSendEmail();
  const [subject, setSubject] = useState("");
  const [checkAllAgencies, setCheckAllAgencies] = useState(true);
  const [checkedAgencies, setCheckedAgencies] = useState({});
  const [selectedState] = useState([]);

  useEffect(() => {
    getAgenciesByTourOperator();
  }, [tourOP]);

  useEffect(() => {
    if (agencies?.length > 0) {
      if (checkAllAgencies) {
        const allAgencyIds = agencies.reduce((acc, agency) => {
          acc[agency.id] = true; // Set each agency as checked by default
          return acc;
        }, {});
        setCheckedAgencies(allAgencyIds);
      } else {
        setCheckedAgencies({});
      }
    }
  }, [agencies, checkAllAgencies]);

  const handleCheckboxChange = (checkObject, id) => {
    checkObject((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const sendAutomaticEmail = (e) => {
    e.preventDefault();

    const agenciesEmail = agencies
      ?.filter((item) => checkedAgencies[item.id])
      ?.map(({ email }) => email);

    toast.promise(
      sendEmail({
        type: tourOP.id === 1 ? "Gmail" : "",
        tour_op_id: tourOP.id,
        options: {
          from: tourOP?.email,
          bcc: agenciesEmail,
          subject,
          html: generateEmailBody(selectedPackage, tourOP, tour_op_id),
        },
      })
        .then((e) => {
          const numberOfEmailsSent =
            e?.data?.results?.[0]?.info?.accepted?.length;

          if (!numberOfEmailsSent) {
            throw new Error("No se pudieron enviar mails.");
          }

          sendEmail({
            type: "Gmail",
            tour_op_id: 1,
            options: {
              from: "wearewalltrip@gmail.com",
              bcc: [tourOP.id === 1 ? tourOP?.email : "mkt@newplaces.com.ar"],
              subject: "Envío #1 OK",
              html: generateEmailBodyForOp(
                selectedState,
                numberOfEmailsSent,
                subject,
                selectedPackage,
                agenciesEmail,
                tour_op_id,
              ),
            },
          });
          setTimeout(() => closeModal(), 5500);
        })
        .catch((e) => {
          throw new Error(e);
        }),
      {
        loading: "Enviando...",
        success: <b>Mail enviado con éxito.</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      },
    );
  };
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col justify-center  gap-2"
    >
      <div>
        <Toaster
          toastOptions={{
            success: {
              duration: 5000,
            },
          }}
        />
      </div>
      <h1 className="text-center text-xl font-semibold mb-4">
        Mandar Email a Agencias
      </h1>
      <form className="flex flex-row gap-6" onSubmit={sendAutomaticEmail}>
        <div className="justify-start">
          <span className="">Tu Flyer :</span>
          {selectedPackage?.flyer && (
            <img
              src={selectedPackage?.flyer}
              alt="flyer"
              className="max-h-[500px] max-w-[16rem] min-w-[8rem]"
            />
          )}
        </div>
        <div className="flex-col">
          <label className="justify-center flex items-center gap-1">
            <input
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              type="checkbox"
              checked={checkAllAgencies}
              onChange={() => setCheckAllAgencies(!checkAllAgencies)}
            />
            Enviar a todas las agencias
          </label>
          <label htmlFor="subject" className="w-4/5">
            <input
              placeholder="Asunto para el mail"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="addPackInput w-full"
            />
          </label>
          {!checkAllAgencies && agencies?.length > 0 && (
            <div className=" mt-4 ">
              <AgencySelector
                agencies={agencies}
                checkedAgencies={checkedAgencies}
                handleCheckboxChange={handleCheckboxChange}
                setCheckedAgencies={setCheckedAgencies}
              />
            </div>
          )}

          <button
            type="submit"
            className={`mt-4 button text-white font-semibold transition-colors bg-brightBlue hover:bg-darkBlue`}
          >
            Enviar Email
          </button>
        </div>
      </form>
    </div>
  );
}
