import { Link } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function EditButton({ tour_op_id, data, route }) {
  const { t } = useTranslation();
  return (
    <Link
      to={`/${tour_op_id}/${route}/${data.id}`}
      className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
      state={{ data }}
    >
      <MdModeEditOutline scale={23} />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
        {t(`global.Actions.edit`)}
      </span>
    </Link>
  );
}
