import { useTranslation } from "react-i18next";
import missing from "./../../iconos/missing.jpg";
export default function PackageRow({ pack }) {
  const { origin_code, destination_code, portrait, description } = pack ?? {};
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full flex items-start border-r-8 border-lightGray h-full">
        <div className="flex-none w-1/5 flex flex-col items-start border-r-2 border-lightGray">
          <img
            className="w-full h-1/2 object-cover rounded-sm"
            src={portrait || missing} // Try to load the portrait first, then missing
            alt="" // No alt text
            onError={(e) => {
              if (e.target.src !== missing) {
                // Prevents infinite loop
                e.target.src = missing; // Fallback to the missing image
              }
            }}
          />
        </div>
        <div className="h-full flex justify-center items-center p-2">
          <p>{description}</p>
        </div>
        <div className="flex-1 flex-col">
          <table className="table-auto w-full border-collapse rounded-lg">
            <thead>
              <tr>
                <th className="text-sm font-semibold text-center align-middle">
                  {t(`global.Search_Packages.destination`)}
                </th>
                <th className="text-sm font-semibold text-center align-middle">
                  {t(`global.Search_Packages.origin`)}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center align-middle">{origin_code}</td>
                <td className="text-center align-middle">{destination_code}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
