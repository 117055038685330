import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { feeSchema as schema } from "../../../../schemas/fees";
import useGetCurrencies from "../../../../services/useGetCurrencies";
import SEO from "../../../../utils/SEO";
import { useUserStore } from "../../../../store/useUserStore";
import { useTourOperatorStore } from "../../../../store/useTourOperatorStore";
import { useFees } from "../../../../services/useFees";
import FormTitle from "../../../UI/FormTitle";
import { useTranslation } from "react-i18next";

export const FormAddFee = () => {
  const { tour_op_id } = useParams();
  const { t } = useTranslation();
  const { user } = useUserStore();
  const tourOP = useTourOperatorStore((st) => st.tourOP.id);
  const location = useLocation();
  const { currencies } = useGetCurrencies();
  const { createFee } = useFees();
  const { tour_id, package_id } = location.state || {};
  const [price, setPrice] = useState(0);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const fare = watch("fare", 0); // valor por defecto 0
  const tax = watch("tax", 0); // valor por defecto 0

  useEffect(() => {
    setPrice((fare || 0) + (tax || 0));
  }, [fare, tax]);

  const onSubmit = async (data) => {
    try {
      await toast.promise(
        createFee({
          fee: {
            base: data.base,
            fare: data.fare,
            tax: data.tax,
            price: price,
            currency_type_id: data.currency_type_id,
            id_tours: tour_id,
            tours_id: tour_id,
            tour_op_id: tourOP,
            id_package: package_id,
            user_id: user.id,
          },
        }),
        {
          success: <b>{t(`global.Fee_Form.fee_create_toaster`)}</b>,
          loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
          error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
        },
      );
      navigate(`/${tour_op_id}/panel/packages/my-packages`, {
        replace: true,
      });
    } catch (e) {
      throw new Error();
    }
  };

  return (
    <>
      <SEO title={"Agregar Tarifa"} />
      <div className="w-full">
        <FormTitle
          route={"panel/packages/my-packages"}
          title={"fee_form_title"}
          btnText={"package_form_btn"}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8 gap-4"
        >
          <div>
            <Toaster />
          </div>
          <div className="flex flex-row justify-evenly gap-10">
            <div>
              <h2 className="text-darkBlue font-medium mb-4">
                TIPO DE HABITACÍON
              </h2>
              <input
                className="addPackInput w-full"
                placeholder="Agrega una Habitación.."
                name="base"
                {...register("base")}
              />
              {errors?.base && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>{t(`global.Fee_Form.${errors?.base.message}`)}</p>
                </div>
              )}
            </div>
            <div>
              <h3 className="text-darkBlue font-medium">PRECIO POR PERSONA</h3>
              <div className="grid grid-cols-3 gap-4">
                <label className="relative w-full">
                  <p>Moneda</p>
                  <select
                    name="currency_type_id"
                    id="currency_type_id"
                    className="addPackInput w-full"
                    {...register("currency_type_id", { valueAsNumber: true })}
                  >
                    <option value="">- Elegir Moneda -</option>
                    {currencies?.map(({ name, symbol, id }) => (
                      <option key={id} value={id}>
                        {name} ({symbol})
                      </option>
                    ))}
                  </select>
                  {errors?.currency_type_id && (
                    <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                      <p>
                        {t(
                          `global.Fee_Form.${errors?.currency_type_id.message}`,
                        )}
                      </p>
                    </div>
                  )}
                </label>
                <label htmlFor="fare" className="relative w-full">
                  <p>Tarifa</p>
                  <input
                    type="number"
                    min={1}
                    placeholder="Por pasajero"
                    className="addPackInput w-full"
                    name="fare"
                    {...register("fare", { valueAsNumber: true })}
                  />
                  {errors?.fare && (
                    <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                      <p>{t(`global.Fee_Form.${errors?.fare.message}`)}</p>
                    </div>
                  )}
                </label>
                <label htmlFor="tax" className="relative w-full">
                  <p>Impuesto</p>
                  <input
                    type="number"
                    min={0}
                    placeholder="Por pasajero"
                    className="addPackInput w-full"
                    name="tax"
                    {...register("tax", { valueAsNumber: true })}
                  />
                  {errors?.tax && (
                    <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                      <p>{t(`global.Fee_Form.${errors?.tax.message}`)}</p>
                    </div>
                  )}
                </label>
                <label htmlFor="price" className="relative w-full">
                  <p>Precio Total</p>
                  <input
                    type="number"
                    disabled
                    min={0}
                    className="addPackInput w-full"
                    name="price"
                    value={price}
                  />
                </label>
              </div>
            </div>
          </div>
          <button
            className="button ml-auto mt-5 text-lg w-1/2 bg-darkBlue text-white transition-all"
            type="submit"
          >
            Guardar Tarifa
          </button>
        </form>
      </div>
    </>
  );
};
