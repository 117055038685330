import SEO from "../../../utils/SEO";
import FormTitle from "../../UI/FormTitle";
import QuoteForm from "./QuoteForm";

export default function AddQuoteForm() {
  return (
    <div>
      <SEO />
      <FormTitle
        title={"quote_form_title"}
        btnText={"quote_form_btn"}
        route={"panel/sales/quotes/my-quotes/package"}
      />
      <QuoteForm />
    </div>
  );
}
