import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocationQuery } from "../../../hooks/useLocationQuery";
import { useForm } from "react-hook-form";
import { packageData } from "../../../mocks/mocksCompletePackages";
import PackageSearchModule from "./searchModules/PackageSearchModule";
import { useTranslation } from "react-i18next";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import { usePackages } from "../../../services/usePackages";
export default function PackageSearchEngine() {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { tour_op_id, type } = useParams();
  const { changeQuery } = useLocationQuery();
  const { packages, getPackagesStep2 } = usePackages();
  const { t } = useTranslation();
  const [, setFilteredPackages] = useState();
  const [passengerCounter, setPassengerCounter] = useState({
    ADT: 1,
    CHD: 0,
    INF: 0,
  });
  const { ADT, CHD, INF } = passengerCounter;
  const sumPassengers = ADT + CHD + INF;

  const handlePassenger = (passenger, register, min, op) => {
    const passengerValue = passengerCounter[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
          ? passengerValue - 1
          : passengerValue;

    setValue(register, newValue.toString());

    setPassengerCounter((curr) => ({
      ...curr,
      [passenger]: newValue,
    }));
  };

  useEffect(() => {
    if (tourOP) {
      getPackagesStep2();
    }
  }, [tourOP]);

  // REDIRECCIÓN AL PASO 2 UNA VEZ COMPLETADA EL FORM DE BÚSQUEDA

  const { handleSubmit, register, setValue } = useForm({});

  // FILTRO DE PAQUETES SEGÚN INPUTS DE BÚSQUEDA
  useEffect(() => {
    if (type !== "packages" || !packages) return;

    setFilteredPackages(packageData);
  }, [
    type,
    packages,
    //watch("month"),
    //watch("year"),
  ]);
  const onSubmit = (data) => {
    changeQuery({
      query: data,
      route: `${tour_op_id}/search-packages`,
      go: true,
    });
  };
  // MANEJO DE LA DATA DE PAQUETES PARA LUEGO LLEVAR A <PackageSearchModule /> LA INFO JUSTA Y NECESARIA
  const reducedPackages = packages?.reduce(
    (acc, { origin_code, destination_code, tours }) => {
      tours.forEach(({ dateIn }) => {
        // Check if origin_code, destination_code, and dateIn are valid (not null or undefined)
        if (origin_code && destination_code && dateIn) {
          acc.origin_code.push(origin_code.toUpperCase());
          acc.destination_code.push(destination_code.toUpperCase());
          acc.departure_date.push(dateIn.split("T")[0]);
        }
      });
      return acc;
    },
    { origin_code: [], destination_code: [], departure_date: [] },
  );

  return (
    <form
      className="flex mb:flex-col md:flex-row justify-between items-center gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col w-full gap-4">
        <p className="text-xl font-medium text-lightBlue mb-4">
          {t("global.Header.Paquetes")}
        </p>
        <PackageSearchModule
          setValue={setValue}
          packages={packages}
          register={register}
          filteredPackages={reducedPackages}
          handlePassenger={handlePassenger}
          sumPassengers={sumPassengers}
          passangerState={[passengerCounter, setPassengerCounter]}
        />
      </div>

      <button
        type="submit"
        className="bg-[#CEDEF1] rounded-xl p-6 px-12 text-black shadow-sm shadow-lightBlue2 md:mt-10 hover:bg-hoverBlue"
      >
        <span className="text-black font-medium">
          {t("global.Actions.search")}
        </span>
      </button>
    </form>
  );
}
