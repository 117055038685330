import { useToggleVisibility } from "../../../hooks/useToggleVisibility";

import { MdDelete } from "react-icons/md";
import useReloadStore from "../../../store/useRealoadStore";
import { useTranslation } from "react-i18next";
import { ModalGeneric } from "../ModalGeneric";

export default function DeleteButton({ data, delete: deleteFunction, type }) {
  const setReloadFees = useReloadStore((state) => state.setReloadFees);
  const setReloadTours = useReloadStore((state) => state.setReloadTours);
  const setReloadPackages = useReloadStore((state) => state.setReloadPackages);
  const { t } = useTranslation();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const handleDelete = async () => {
    try {
      await deleteFunction(data.id);
    } catch (error) {
      throw new Error();
    } finally {
      if (type === "fee") setReloadFees();
      else if (type === "tour") setReloadTours();
      else if (type === "package") setReloadPackages();
      toggleVisibility();
    }
  };
  return (
    <>
      <button
        className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
        onClick={toggleVisibility}
      >
        <MdDelete scale={23} />
        <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/copy:scale-100">
          {t(`global.Actions.delete`)}
        </span>
      </button>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <div className="flex flex-col items-center gap-2">
          <h2>
            {t(`global.Common_Words.confirm_cancel`)} <b>{data.name}</b>?
          </h2>
          <div className="flex justify-center gap-2">
            <button
              className="py-1 px-2 rounded font-medium border border-gray2"
              onClick={toggleVisibility}
            >
              {t(`global.Actions.cancel`)}
            </button>
            <button
              className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
              onClick={handleDelete}
            >
              {t(`global.Actions.delete`)}
            </button>
          </div>
        </div>
      </ModalGeneric>
    </>
  );
}
