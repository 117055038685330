import { useEffect, useState } from "react";
import { useStoreSelectPackages } from "../../../store/useStoreSelectPackages";
import { Link, useParams } from "react-router-dom";
import PackageBasicDetail from "../packageDetail/detailSections/PackageBasicDetail";
import PackageImageSection from "../packageDetail/detailSections/PackageImageSection";
import PackagePassengerSection from "../packageDetail/detailSections/PackagePassengerSection";
import { useTours } from "../../../services/useTours";
import PackageLinkTourSection from "./subDetail/PackageLinkTourSection";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import { usePackages } from "../../../services/usePackages";

export default function PackageLink() {
  const numberOfPassengers = useStoreSelectPackages(
    (st) => st.numberOfPassengers,
  );
  const { tour_op_id, package_id } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { individualPackage, getPackage } = usePackages();
  const { tours, getTours } = useTours();
  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedFee, setSelectedFee] = useState(undefined);
  const setNumberOfPassengers = useStoreSelectPackages(
    (st) => st.setNumberOfPassengers,
  );
  useEffect(() => {
    getPackage(package_id);
    getTours(package_id);
  }, [tourOP]);

  const { title, portrait, pictures } = individualPackage;

  const handlePassenger = (passenger, min, op) => {
    const passengerValue = numberOfPassengers[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
          ? passengerValue - 1
          : passengerValue;

    setNumberOfPassengers({
      ...numberOfPassengers,
      [passenger]: newValue,
    });
  };

  return (
    <main className="max-w-[1338.46px] grid grid-cols-2 gap-16 mb-2">
      <div className="col-span-1">
        <PackageBasicDetail packageData={individualPackage} />
        <PackageLinkTourSection
          tours={tours}
          setSelectedTour={setSelectedTour}
          setSelectedFee={setSelectedFee}
        />
      </div>
      <div>
        <div className="flex justify-center">
          <PackageImageSection
            title={title}
            portrait={portrait}
            pictures={pictures}
          />
        </div>

        <section className="mt-4">
          <PackagePassengerSection
            numberOfPassengers={numberOfPassengers}
            handlePassenger={handlePassenger}
          />
          <div className="mt-8">
            {selectedFee ? (
              <Link
                className={`mt-8 bg-darkBlue button p-6 text-white text-xl font-semibold ${
                  selectedFee ? "" : "opacity-50 cursor-not-allowed"
                }`}
                to={`/${tour_op_id}/detail?type=packages&fee=${selectedFee?.id}`}
                state={{
                  packageData: individualPackage,
                  fee: selectedFee,
                  tour: selectedTour,
                }}
              >
                Reservar
              </Link>
            ) : (
              <></>
            )}
          </div>
        </section>
      </div>
    </main>
  );
}
