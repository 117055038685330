import { useMemo, useRef, useState, useEffect } from "react";
import clsx from "clsx";
import useGetAirports from "../../../services/useGetAirports";
import useOnClickOutside from "../../../hooks/useClickOutside";

const PRINCIPAL_AIRPORTS = [
  1110, 83, 1644, 3365, 4686, 706, 5667, 1900, 2002, 4799, 3287, 3444, 2523,
  4454, 3074, 3063, 914, 1762, 1674,
];

function replaceAccents(str) {
  const accentMap = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
  };

  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\s]/g, "")
    .replace(/[áéíóúÁÉÍÓÚ]/g, (match) => accentMap[match] || match);
}

export const AutoCompleteQuote = ({
  classname,
  autoFocus,
  quoteInputId,
  nextQuoteInputId,
  name,
  placeholder,
  handleAction,
  register,
  inputState,
  handleInputChange,
}) => {
  const { getAirports, airports } = useGetAirports();
  const [selected, setSelected] = useState("s");
  const [inputValue, setInputValue] = inputState;
  const ref = useRef(null);

  useOnClickOutside(ref, () => setSelected("s"));

  const inputValueWithoutAccents = replaceAccents(inputValue.toLowerCase());

  const formattedAirports = airports.map((x) => ({
    id: x.id,
    airport: x.name,
    iata: x.iata,
    city: x.City.name,
    country: x.City.Country.name,
  }));

  useEffect(() => {
    getAirports();
  }, []);

  useEffect(() => {
    if (inputValue.length === 3) {
      const isCoincidence = formattedAirports.find((airport) =>
        airport.iata.toLowerCase().includes(inputValue.toLowerCase()),
      );
      if (isCoincidence) {
        setSelected("");
        setInputValue(
          `[${isCoincidence?.iata}] ${isCoincidence?.city}, ${isCoincidence?.country}`,
        );
        handleAction(isCoincidence?.iata?.toUpperCase());
        handleInputChange(quoteInputId, nextQuoteInputId);
      }
    }
  }, [inputValue]);

  const listOfOptions = useMemo(
    () =>
      formattedAirports
        .sort((a, b) => {
          const lowerInputValue = inputValueWithoutAccents.toLowerCase();
          const lowerA = a?.airport && a?.airport?.toLowerCase();
          const lowerB = b?.airport && b?.airport?.toLowerCase();

          if (!lowerA) {
            if (!lowerB) return 0;
            else return 1;
          }
          if (!lowerB) return 0;

          if (
            lowerA.startsWith(lowerInputValue) &&
            !lowerB.startsWith(lowerInputValue)
          ) {
            return -1;
          } else if (
            !lowerA.startsWith(lowerInputValue) &&
            lowerB.startsWith(lowerInputValue)
          ) {
            return 1;
          } else {
            return 0;
          }
        })
        // PRINCIPAL AIRPORTS
        .sort((a, b) => {
          if (PRINCIPAL_AIRPORTS.includes(a.id)) return -1;
          if (PRINCIPAL_AIRPORTS.includes(b.id)) return +1;
          return 0;
        })
        // FIRST CBA AN BS AS
        .sort((a, b) => {
          if (a.iata === "BAI") return 1;
          if (
            (a.city === "Ciudad de Córdoba" || a.city === "Buenos Aires") &&
            a.country === "Argentina"
          ) {
            return -1;
          }
          if (
            (b.city === "Ciudad de Córdoba" || b.city === "Buenos Aires") &&
            b.country === "Argentina"
          ) {
            return 1;
          }
          return 0;
        })
        // CHANGE UNITED STATE -> USA
        .map((opt) => {
          if (opt.country === "United States") {
            return {
              ...opt,
              country: "USA",
            };
          }
          if (opt.iata === "BAI") {
            return {
              ...opt,
              country: "Costa Rica",
            };
          }
          return opt;
        }),
    [airports],
  );

  const filteredOptions =
    listOfOptions &&
    listOfOptions.filter(
      (item) =>
        item?.iata
          ?.toLowerCase()
          ?.includes(inputValueWithoutAccents.toLowerCase()) ||
        item?.airport
          ?.toLowerCase()
          ?.includes(inputValueWithoutAccents.toLowerCase()) ||
        item?.city
          ?.toLowerCase()
          ?.includes(inputValueWithoutAccents.toLowerCase()),
    );

  return (
    <div className="relative w-full">
      <input
        className={clsx(
          { [classname]: classname?.length },
          {
            "py-4 pl-4 text-sm buscador-centro-ubicacion-input focus:outline-none":
              !classname?.length,
          },
        )}
        type="text"
        id={quoteInputId}
        {...register(name)}
        value={inputValue}
        autoFocus={autoFocus}
        name={name}
        autoComplete="off"
        placeholder={placeholder}
        onChange={(e) => {
          setInputValue(e.target.value);
          setSelected("");
        }}
        // onBlur={(e) => handleAction(e.target.value)}
      />

      <div className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md px-3 shadow-xl w-[450px]">
        <div ref={ref} className="flex flex-col gap-1">
          {!selected.length ? (
            <>
              {filteredOptions.slice(0, 3).map((v, index) => (
                <div key={v?.city + index}>
                  {index === 0 && (
                    <p className="font-semibold text-brightBlue">Aeropuerto</p>
                  )}
                  <p
                    key={index}
                    onClick={() => {
                      setSelected(v?.iata);
                      setInputValue(`[${v?.iata}] ${v?.city}, ${v?.country}`);
                      handleAction(v?.iata);
                      handleInputChange(quoteInputId, nextQuoteInputId);
                    }}
                    className="px-1 pt-1 m-0 w-fit cursor-pointer whitespace-pre-wrap hover:bg-slate-100"
                  >
                    {`[${v?.iata}] ${v?.airport}`}
                    {" - "}
                    {v?.city}, {v?.country?.toUpperCase().substr(0, 3)}
                  </p>
                </div>
              ))}
              {filteredOptions.slice(0, 3).map((v, index) => (
                <div key={v?.city - index}>
                  {index === 0 && (
                    <p className="font-semibold text-brightBlue">Ciudad</p>
                  )}
                  <p
                    key={index}
                    onClick={() => {
                      setSelected(v?.iata);
                      setInputValue(v?.city + " - " + v?.iata);
                      handleAction(v?.iata);
                    }}
                    className="px-1 pt-1 m-0 min-w-[200px] cursor-pointer whitespace-pre-wrap hover:bg-slate-100"
                  >
                    {v?.city}, {v?.country?.toUpperCase().substr(0, 3)}
                  </p>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
