import { useCallback, useState } from "react";
import axios from "axios";
import { useTourOperatorStore } from "../store/useTourOperatorStore";
import {
  getSessionStorageValue,
  getLocalStorageValue,
} from "../hooks/useCookie";

export const useTravelers = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const [travelers, setTravelers] = useState([]);
  const [traveler, setTraveler] = useState([]);
  const [travelerPhone, setTravelerPhone] = useState([]);
  const [travelerDocument, setTravelerDocument] = useState([]);
  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createTraveler = async (traveler) => {
    const response = await axios.post(
      BASE_URL + "v2/travelers",
      traveler,
      headers,
    );
    return response;
  };

  const getTravelers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}v2/travelers/tour_op/${tourOP.id}/`,
        headers,
      );
      setTravelers(response.data.response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setTravelers([]);
      } else {
        throw new Error();
      }
    } finally {
      setLoading(false);
    }
  }, [tourOP.id, BASE_URL, headers]);

  const getTraveler = async (id) =>
    axios
      .get(`${BASE_URL}v2/travelers/${id}`, headers)
      .then((e) => setTraveler(e.data.response))
      .catch(() => {
        throw new Error();
      });

  const getTravelerPhone = async (id) =>
    axios
      .get(`${BASE_URL}v2/travelers/phones/${id}`, headers)
      .then((e) => setTravelerPhone(e.data.response))
      .catch(() => {
        throw new Error();
      });

  const getTravelerDocument = async (id) =>
    axios
      .get(`${BASE_URL}v2/travelers/documents/${id}`, headers)
      .then((e) => setTravelerDocument(e.data.response))
      .catch(() => {
        throw new Error();
      });

  const updateTraveler = async (id, data) => {
    try {
      return await axios.patch(
        `v2/travelers/${id}`,
        {
          traveler: { ...data },
        },
        headers,
      );
    } catch (error) {
      throw new Error("Ocurrió un error (UpdateUser): ", error);
    }
  };

  return {
    travelers,
    getTravelers,
    loading,
    getTraveler,
    traveler,
    travelerPhone,
    getTravelerPhone,
    getTravelerDocument,
    travelerDocument,
    updateTraveler,
    createTraveler,
  };
};
