import { useTranslation } from "react-i18next";

export const PackagePassengerForm = ({ index, idBody, register, errors }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`grid grid-cols-3 items-center gap-x-4 gap-y-8 origin-top transition-all duration-300 h-0 scale-y-0 p-4`}
      id={idBody}
    >
      {/* Name Field */}
      <label
        htmlFor={`name-${index}`}
        className="mb-1 font-low text-sm text-[#242423]"
      >
        {t(`global.Package_Booking.complete_name`)}
      </label>
      <div className="relative col-span-2">
        <input
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          placeholder={t(`global.booking_placeHolder.traveler_document`)}
          name={`passengers.${index}.name`} // Dynamic name field
          id={`passengers-name-${index}`}
          {...register(`passengers.${index}.name`)} // Dynamically register
        />
        {errors?.passengers?.[index]?.name && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>
              {t(
                `global.Passenger_Form.${errors.passengers[index].name.message}`,
              )}
            </p>
          </div>
        )}
      </div>

      {/* Surname Field */}
      <label
        htmlFor={`surname-${index}`}
        className="mb-1 font-low text-sm text-[#242423]"
      >
        {t(`global.Common_Words.last_name`)}
      </label>
      <div className="col-span-2 relative">
        <input
          className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
          placeholder={t(`global.booking_placeHolder.traveler_document`)}
          name={`passengers.${index}.surname`} // Dynamic name field
          id={`passengers-surname-${index}`}
          {...register(`passengers.${index}.surname`)} // Dynamically register
        />
        {errors?.passengers?.[index]?.surname && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>
              {t(
                `global.Passenger_Form.${errors.passengers[index].surname.message}`,
              )}
            </p>
          </div>
        )}
      </div>

      {/* Birthday Field */}
      <label
        htmlFor={`birthday-${index}`}
        className="mb-1 font-low text-sm text-[#242423]"
      >
        {t(`global.Common_Words.birth_date`)}
      </label>
      <div className="col-span-2 relative">
        <input
          type="date"
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          name={`passengers.${index}.birthday`} // Dynamic name field
          id={`passengers-birthday-${index}`}
          {...register(`passengers.${index}.birthday`)} // Dynamically register
        />
        {errors?.passengers?.[index]?.birthday && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>
              {t(
                `global.Passenger_Form.${errors.passengers[index].birthday.message}`,
              )}
            </p>
          </div>
        )}
      </div>

      {/* Document Type Field */}
      <label
        htmlFor={`docType-${index}`}
        className="mb-1 font-low text-sm text-[#242423]"
      >
        {t(`global.Package_Booking.nr_document_type`)}
      </label>
      <span className="flex col-span-2 gap-4">
        <select
          className="px-2 py-1 text-xs h-8 bg-lightGray3 rounded-md"
          name={`passengers.${index}.docType`} // Dynamic name field
          id={`passengers-docType-${index}`}
          {...register(`passengers.${index}.docType`)} // Dynamically register
        >
          <option className="text-xs" value="IDENTITY_CARD">
            DNI
          </option>
          <option className="text-xs" value="PASSPORT">
            {t(`global.Common_Words.Passport`)}
          </option>
        </select>

        {/* Document Number Field */}
        <label
          htmlFor={`docNumber-${index}`}
          className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full"
        >
          <div className="relative w-full">
            <input
              className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
              placeholder={t(`global.booking_placeHolder.document_number`)}
              name={`passengers.${index}.docNumber`} // Dynamic name field
              id={`passengers-docNumber-${index}`}
              {...register(`passengers.${index}.docNumber`)} // Dynamically register
            />
          </div>
        </label>
      </span>
      {errors?.passengers?.[index]?.docNumber && (
        <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
          <p>
            {t(
              `global.Passenger_Form.${errors.passengers[index].docNumber.message}`,
            )}
          </p>
        </div>
      )}
    </div>
  );
};
