import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ruleSchema } from "../../schemas/ruleSchema";
import useGetCurrencies from "../../services/useGetCurrencies";
import { Toaster } from "react-hot-toast";

export default function FormAddRulesFares({ onSubmit }) {
  const { currencies } = useGetCurrencies();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ruleSchema),
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Toaster />
      <section className="flex flex-col gap-8">
        <header className="relative w-60 mx-auto flex items-center bg-lightBlue  justify-center border-0 rounded-3xl h-10">
          <button
            type="button"
            className="px-4 rounded-3xl w-60 z-10"
            onClick={() => {
              reset();
            }}
          >
            {t(`global.Admin_Rules.form_title`)}
          </button>
        </header>

        <div className="grid grid-cols-2 gap-12">
          <div className="col-span-1">
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.name_field`)}
              </label>
              <input
                placeholder={t(`global.Admin_Rules.name_field_placeholder`)}
                className="outline-none border-b border-[#CEDEF1]"
                name="name"
                {...register("name")}
              />
              {errors?.name && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p> {t(`global.Form_Rules.${errors?.name.message}`)}</p>
                </div>
              )}
            </div>
            <div className="mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.amount_field`)}
              </label>
              <div className="flex items-center  gap-2 relative">
                <div>
                  <select
                    className="addPackInput w-30"
                    name="rate_type"
                    {...register("rate_type")}
                  >
                    <option value={"porcentaje"}>
                      {t(`global.Admin_Rules.percentage`)}{" "}
                    </option>
                    <option value={"fijo "}>
                      {t(`global.Admin_Rules.fixed`)}{" "}
                    </option>
                  </select>
                </div>
                <div>
                  <input
                    type="number"
                    placeholder={t(
                      `global.Admin_Rules.amount_field_placeholder`,
                    )}
                    className="outline-none border-b border-[#CEDEF1]"
                    name="number"
                    {...register("number")}
                  />
                  {errors?.number && (
                    <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                      <p> {t(`global.Form_Rules.${errors?.number.message}`)}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.currency_field`)}
              </label>

              <select
                name="currency_type_id"
                {...register("currency_type_id", { valueAsNumber: true })}
                id="currency_type_id"
                className="addPackInput w-full"
              >
                {currencies?.map(({ name, symbol, id }) => (
                  <option key={`${id}`} value={id}>
                    {t(`global.Currency.${name}`)} ({symbol})
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.type_field`)}
              </label>
              <select
                className="addPackInput w-full"
                name="percentage"
                {...register("operation_fiscal")}
              >
                <option value={"impuesto"}>
                  {t(`global.Admin_Rules.tax`)}
                </option>
                <option value={"comisión"}>
                  {t(`global.Admin_Rules.commission`)}{" "}
                </option>
                <option value={"descuento"}>
                  {t(`global.Admin_Rules.discount`)}{" "}
                </option>
              </select>
              {errors?.operation_fiscal && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p>
                    {" "}
                    {t(`global.Form_Rules.${errors?.operation_fiscal.message}`)}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.operation_field`)}
              </label>
              <select
                className="addPackInput w-full"
                name="operation"
                {...register("operation")}
              >
                <option value={"suma"}>
                  {t(`global.Admin_Rules.addition`)}
                </option>
                <option value={"resta"}>
                  {t(`global.Admin_Rules.subtraction`)}{" "}
                </option>
              </select>

              {errors?.operation && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p> {t(`global.Form_Rules.${errors?.operation.message}`)}</p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.on_the`)}
              </label>
              <select
                className="addPackInput w-full"
                name="variable"
                {...register("variable")}
              >
                <option value={"neto"}> {t(`global.Admin_Rules.net`)} </option>
                <option value={"bruto"}>
                  {t(`global.Admin_Rules.gross`)}{" "}
                </option>
              </select>

              {errors?.variable && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p> {t(`global.Form_Rules.${errors?.variable.message}`)}</p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.product_field`)}
              </label>
              <select
                className="addPackInput w-full"
                name="product"
                {...register("product")}
              >
                <option value={"vuelos"}>
                  {t(`global.Admin_Rules.flights`)}{" "}
                </option>
                <option value={"hoteles"}>
                  {t(`global.Admin_Rules.hotels`)}{" "}
                </option>
                <option value={"paquetes"}>
                  {t(`global.Admin_Rules.packages`)}{" "}
                </option>
                <option value={"asistencia"}>
                  {t(`global.Admin_Rules.assistance`)}{" "}
                </option>
                <option value={"autos"}>{t(`global.Admin_Rules.cars`)} </option>
                <option value={"crucero"}>
                  {t(`global.Admin_Rules.cruise`)}{" "}
                </option>
              </select>
              {errors?.product && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <p> {t(`global.Form_Rules.${errors?.product.message}`)}</p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-2">
              <div className="flex flex-col gap-2 ">
                <label className="font-medium w-[300px]">
                  {t(`global.Admin_Rules.active`)}
                </label>
                <input
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  {...register("active", { valueAsBoolean: true })}
                  id="checkbox-active"
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label className="font-medium w-[300px]">
                  {t(`global.Admin_Rules.unic_rule`)}
                </label>
                <input
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  {...register("running", { valueAsBoolean: true })}
                  id="checkbox-running"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex items-center justify-end mt-4">
        <button
          type="submit"
          className="px-6 py-4 rounded bg-brightBlue text-white"
        >
          {t(`global.Actions.load`)}
        </button>
      </section>
    </form>
  );
}
