import { useTranslation } from "react-i18next";

export default function DestinationFilter({
  handleDisplay,
  arrow,
  possibleCitiesAndMonth,
  displayedDescode,
  filters,
  handleChecks,
  capitalizeFirstLetter,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <section
        onClick={() => handleDisplay("cities")}
        id="cities-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">
            {t(`global.Filter_Titles.city_destination`)}
          </h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="cities-arrow"
          />
        </header>
      </section>
      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
        id="cities-body"
      >
        {Object.keys(possibleCitiesAndMonth.destination_code).map((el) => {
          const descode = possibleCitiesAndMonth.destination_code[el];

          // Skip if the descode is null or undefined
          if (!descode || displayedDescode.has(descode)) {
            return null;
          }

          // Add this descode to the set of displayed codes
          displayedDescode.add(descode);
          const formattedDescode = capitalizeFirstLetter(descode);

          // Check if the current descode is in the filters.descode array
          const isChecked = filters.destination_code.includes(descode);

          return (
            <div className="flex items-center" key={el}>
              <input
                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                type="checkbox"
                id={el}
                name="cities"
                checked={isChecked}
                onChange={(evt) =>
                  handleChecks(evt, descode, "destination_code")
                }
              />
              <label className="flex gap-2 items-center" htmlFor={el}>
                <p className="max-w-[15ch] ml-2">{formattedDescode}</p>
              </label>
            </div>
          );
        })}
      </section>
    </div>
  );
}
