import KeyIcon from "@mui/icons-material/Key";
import { useTourOperatorStore } from "../../../../../store/useTourOperatorStore";

export default function CredentialsButton() {
  const tourOP = useTourOperatorStore((st) => st.tourOP.name);

  const handleWhatsAppMessage = () => {
    const userData = JSON.parse(sessionStorage.getItem("userData") || "{}");
    const username = userData.username || "Usuario";
    const phoneNumber = "34627970232";
    const message = encodeURIComponent(
      `Hola Walltrip: soy ${username}, del operador ${tourOP}. Quisiera agregar o editar credenciales de mis proveedores!`,
    );
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

    // Abrir el enlace en una nueva pestaña
    window.open(whatsappURL, "_blank");
  };

  return (
    <button
      className="relative w-7 h-7 flex items-center justify-center cursor-pointer hover:bg-[#EEE] rounded-full transition-colors group/delete"
      onClick={handleWhatsAppMessage}
    >
      <KeyIcon
        sx={{
          color: "#A0AFCD",
          height: "20px",
          width: "20px",
        }}
        alt="Credentials"
      />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/delete:scale-100">
        Credenciales
      </span>
    </button>
  );
}
