import { create } from "zustand";
import { useTourOperatorStore } from "../store/useTourOperatorStore";

// Store for locale
export const saveLocale = create((set) => ({
  locale: "es", // Default locale

  setLocale: (newLocale) => set({ locale: newLocale }), // Action to set locale
  setLocaleBasedOnCountry: () => {
    // Access `tourOP` state from `saveTourOperator`
    const tourOP = useTourOperatorStore.getState().tourOP;
    const countryCode = tourOP?.TourOperatorAddresses?.country_code;

    // Dynamically set the locale based on `countryCode`
    if (countryCode === "GB" || countryCode === "US" || countryCode === "UK") {
      set({ locale: "en" });
    } else {
      set({ locale: "es" }); // Default to Spanish for other cases
    }
  },
}));
