import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { quoteSchema } from "../../../schemas/quote";
import toast, { Toaster } from "react-hot-toast";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import { useQuotes } from "../../../services/useQuotes";
import { AutoComplete } from "../../UI/autoComplete/AutoComplete";
import { useTranslation } from "react-i18next";

export default function EditQuoteForm() {
  const location = useLocation();
  const { t } = useTranslation();
  const { data } = location.state || {};
  const { tour_op_id } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { updateQuote } = useQuotes();
  const navigate = useNavigate();
  const [selectedQuote, setSelectedQuote] = useState("");
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const userData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData"),
  );
  // useEffect(() => {
  //   getQuote(quote_id);
  // }, []);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(quoteSchema) });

  useEffect(() => {
    setSelectedQuote(data);
    setOricodeInput(data.origin);
    setDescodeInput(data.destination);
    setValue("email", data.email);
    setValue("type", data.type);
  }, [data, setValue]);

  const onSubmit = async () => {
    toast.promise(
      updateQuote({
        id: selectedQuote.id,
        name: selectedQuote.name,
        origin: oricodeInput,
        destination: descodeInput,
        email: selectedQuote.email || "agregarun@email.com",
        whatsapp: selectedQuote.whatsapp,
        dates: selectedQuote.dates,
        ask: selectedQuote.ask,
        type: selectedQuote.type,
        passengers: selectedQuote.passengers,
        tour_op_id: Number(tourOP.id),
        agency_id: userData.agency_id,
      })
        .then(() => {
          navigate(`/${tour_op_id}/panel/sales/quotes/my-quotes/package`, {
            replace: true,
          });
        })
        .catch((e) => {
          throw new Error(e);
        }),
      {
        success: <b>{t(`global.Quotes_Form.quote_edit_toaster`)}</b>,
        loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
        error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
      },
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="my-10 mt-16 mx-auto w-3/4 bg-white rounded-lg p-8"
    >
      <div>
        <Toaster />
      </div>
      <section className="grid  gap-2 my-2 p-4">
        <div className="col">
          <div className="grid grid-cols-2 mb-4 ">
            <div className="w-full relative flex flex-col ">
              <label>Nombre</label>
              <input
                className="addPackInput"
                name="name"
                defaultValue={selectedQuote.name}
                {...register("name", {
                  required: "Nombre es requerido",
                })}
                onChange={(e) =>
                  setSelectedQuote({
                    ...selectedQuote,
                    name: e.target.value,
                  })
                }
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="name" as="p" />
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 mb-4 gap-4">
            <div className="mb-4">
              <label>
                Origen
                <label />
              </label>

              <label className="col-span-2 relative ">
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="origin"
                  defaultValue={selectedQuote?.origin}
                  placeholder="Origen"
                  handleAction={(d) => setValue("origin", d)}
                  inputState={[oricodeInput, setOricodeInput]}
                />
                {errors?.origin && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <ErrorMessage errors={errors} name="origin" as="p" />
                  </div>
                )}
              </label>
            </div>
            <div className="mb-4">
              <label>Destino</label>
              <label className="col-span-2 relative">
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="destination"
                  defaultValue={selectedQuote?.destination}
                  placeholder="Destino"
                  handleAction={(d) => setValue("destination", d)}
                  inputState={[descodeInput, setDescodeInput]}
                />
                {errors?.destination && (
                  <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                    <ErrorMessage errors={errors} name="destination" as="p" />
                  </div>
                )}
              </label>
            </div>
          </div>
          <div className="mb-4 grid grid-cols mr-4">
            <label>Fechas</label>
            <input
              className="addPackInput"
              name="dates"
              {...register("dates", {})}
              defaultValue={selectedQuote?.dates}
              onChange={(e) =>
                setSelectedQuote({
                  ...selectedQuote,
                  dates: e.target.value,
                })
              }
            />
            <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="dates" as="p" />
            </span>
          </div>

          <div className="mb-4 grid grid-cols mr-4">
            <label>Pasajeros</label>
            <input
              className="addPackInput"
              name="passengers"
              {...register("passengers", {})}
              defaultValue={selectedQuote?.passengers}
              onChange={(e) =>
                setSelectedQuote({
                  ...selectedQuote,
                  passengers: e.target.value,
                })
              }
            />
            <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="passengers" as="p" />
            </span>
          </div>
          <div className="mb-4 grid grid-cols mr-4">
            <label>Pedido</label>
            <textarea
              className="addPackInput h-32"
              name="ask"
              {...register("ask", {})}
              defaultValue={selectedQuote?.ask}
              onChange={(e) =>
                setSelectedQuote({
                  ...selectedQuote,
                  ask: e.target.value,
                })
              }
            />
          </div>
          <div className="grid grid-cols-2 mb-4">
            <div className="mb-4 grid grid-cols mr-4">
              <label>Email</label>
              <input
                className="addPackInput"
                defaultValue={selectedQuote?.email}
                name="email"
                {...register("email", {
                  required: "Nombre es requerido",
                })}
                onChange={(e) =>
                  setSelectedQuote({
                    ...selectedQuote,
                    email: e.target.value,
                  })
                }
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="email" as="p" />
              </span>
            </div>
            <div className="mb-4 grid grid-cols">
              <label>Whatsapp</label>
              <input
                className="addPackInput"
                name="whatsapp"
                defaultValue={selectedQuote?.whatsapp}
                placeholder="+54 ..."
                {...register("whatsapp", {})}
                onChange={(e) =>
                  setSelectedQuote({
                    ...selectedQuote,
                    whatsapp: (e.target.value = e.target.value.replace(
                      /[^0-9+]/g,
                      "",
                    )),
                  })
                }
              />
              <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="whatsapp" as="p" />
              </span>
            </div>
          </div>

          <div className="w-full relative flex flex-col mb-8">
            <label>
              Categoria
              <label>
                <span className="text-red-400">*</span>
              </label>
            </label>

            <select
              className="addPackInput"
              name="type"
              value={selectedQuote.type}
              {...register("type")}
              onChange={(e) =>
                setSelectedQuote({
                  ...selectedQuote,
                  type: e.target.value,
                })
              }
            >
              <option value="">-- Elegir una Categoria --</option>
              {/* Null option */}
              <option value="package">Paquetes</option>
              <option value="flight">Vuelos</option>
              <option value="hotel">Hoteles</option>
              {/* <option value="cruise">Crusero</option> */}
            </select>
            <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="type" as="p" />
            </span>
          </div>
        </div>
      </section>

      <button className="font-semibold text-lg ml-auto mt-4 button w-1/4 bg-brightBlue text-white hover:shadow-lg transition-all">
        Editar
      </button>
    </form>
  );
}
