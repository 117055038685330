export const generateEmailBodyForOp = (
  packageSelected,
  tour_op_id,
  newBookingId,
) => {
  const currentHREF = window.location.href.split("/").slice(0, -4).join("/");
  const packageLink = `${currentHREF}/${encodeURIComponent(
    tour_op_id,
  )}/package-link/${encodeURIComponent(packageSelected?.id)}`;

  return `<h3>Nueva reserva confirmada!</h3>
    <p>Paquete:${packageSelected.title} </p>
    <h3>Nº de Reserva: ${newBookingId}</h3>
    
      <a href="${packageLink}">Ver detalles del paquete</a>
        <h4>Si ese link no funciona</h4>
        <p>Link: ${packageLink}</p>
    
    `;
};
