import { useEffect } from "react";
import TableFeeRow from "./TableFeeRow";
import TableFeeBody from "./TableFeeBody";
import { Link, useParams } from "react-router-dom";
import useReloadStore from "../../../../../../store/useRealoadStore";
import { useTranslation } from "react-i18next";
import { useFees } from "../../../../../../services/useFees";

export default function FeeRow({ tour, package_id }) {
  const { fees, getFees, loading } = useFees();
  const { t } = useTranslation();
  const { tour_op_id } = useParams();
  const reloadFees = useReloadStore((state) => state.reloadFees);
  const AllowDelete = fees.length > 1 ? true : false;
  const tableHeader = [
    "col_1",
    "col_2",
    "col_3",
    "col_4",
    "col_5",
    "col_6",
    "col_7",
  ];

  // Fetch fees when the tour id changes
  useEffect(() => {
    getFees(tour.id);
  }, [tour.id, reloadFees]); // Added tour.id as a dependency

  return (
    <div className="rounded-lg p-4 bg-lightGray3">
      {loading ? (
        <>
          <h1>{t(`global.Admin_Packages.no_fee`)}</h1>
          <Link
            className="button bg-darkBlue text-white items-center flex hover:shadow-md transition-all px-2 w-20 justify-center py-0 hover:bg-brightBlue mt-2"
            to={`/${tour_op_id}/packages/add-fee`}
          >
            + {t(`global.Admin_Packages.add_fee`)}
          </Link>
        </>
      ) : (
        <table className="w-full border-collapse rounded-lg overflow-hidden bg-lightGray3">
          <thead>
            <TableFeeRow cols={tableHeader} />
          </thead>
          <tbody className="border-2 rounded-lg border-lightBlue">
            {fees.map((fee, index) => (
              <TableFeeBody
                key={fee.id} // Added key for each mapped item
                index={index}
                fee={fee}
                tourId={tour.id}
                AllowDelete={AllowDelete}
                package_id={package_id}
              />
            ))}
          </tbody>
          <Link
            className="button bg-darkBlue text-white items-center flex hover:shadow-md transition-all px-2 w-20 justify-center py-0 hover:bg-brightBlue mt-2"
            to={`/${tour_op_id}/panel/packages/add-fee`}
            state={{ tour_id: tour.id, package_id }}
          >
            + {t(`global.Admin_Packages.add_fee`)}
          </Link>
        </table>
      )}
    </div>
  );
}
