import { useTranslation } from "react-i18next";
import {
  FaIndustry,
  FaList,
  FaPhone,
  FaPhoneAlt,
  FaUser,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

export default function PanelSelector({ type }) {
  const { tour_op_id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="relative flex items-center border-2 border-lightBlue rounded-lg p-2">
          <button
            className={`px-8 rounded-1xl w-42 z-10 text-lg flex flex-row items-center gap-2${
              type === "users"
                ? " h-12 w-42 bg-lightBlue transition-all rounded-lg "
                : ""
            }`}
            onClick={() => navigate(`/${tour_op_id}/panel/profile/users`)}
          >
            <div>
              <FaUser color="#A0A2A4" size={15} />
            </div>
            <h1>{t(`global.Config.users`)}</h1>
          </button>
          <button
            className={`px-8 rounded-1xl w-42 z-10 text-lg justify-center flex flex-row items-center gap-2${
              type === "agencies"
                ? " h-12 w-42 bg-lightBlue transition-all rounded-lg "
                : ""
            }`}
            onClick={() => navigate(`/${tour_op_id}/panel/profile/agencies`)}
          >
            <div>
              <FaIndustry color="#A0A2A4" size={15} />
            </div>
            <h1>{t(`global.Config.agency`)}</h1>
          </button>
          <button
            className={`px-8 rounded-1xl w-42 z-10 text-lg justify-center flex flex-row items-center gap-2${
              type === "rules"
                ? " h-12 w-42 bg-lightBlue transition-all rounded-lg "
                : ""
            }`}
            onClick={() => navigate(`/${tour_op_id}/panel/profile/fees`)}
          >
            <div>
              <FaList color="#A0A2A4" size={15} />
            </div>
            <h1>{t(`global.Config.rules`)}</h1>
          </button>

          <button
            className={`px-8 rounded-1xl w-42 z-10 text-lg justify-center flex flex-row items-center gap-2${
              type === "operator"
                ? " h-12 w-42 bg-lightBlue transition-all rounded-lg "
                : ""
            } `}
            onClick={() =>
              navigate(`/${tour_op_id}/panel/profile/my-tour-operator`)
            }
          >
            <div>
              <FaPhone color="#A0A2A4" size={15} />
            </div>
            <h1>{t(`global.Config.operator`)}</h1>
          </button>

          {tour_op_id === "walltrip" ? (
            <button
              className={`px-8 rounded-lg w-42 z-10 text-lg justify-center flex flex-row items-center gap-2${
                type === "operators"
                  ? " h-12 w-42 bg-lightBlue transition-all rounded-lg "
                  : ""
              } `}
              onClick={() => navigate(`/${tour_op_id}/panel/profile/admin`)}
            >
              <div>
                <FaPhoneAlt color="#A0A2A4" size={15} />
              </div>
              <h1>{t(`global.Config.operators`)}</h1>
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
