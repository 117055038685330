import { FiInstagram } from "react-icons/fi";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";

export default function NavBarSocials() {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { TourOperatorSocialMedia, TourOperatorContacts, TourOperatorPhones } =
    tourOP;
  const { country_calling_code, number } = TourOperatorPhones?.[0] || {};
  const ig = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Instagram",
  )?.url;
  const fb = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Facebook",
  )?.url;
  const wpp = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Whatsapp",
  )?.url;
  return (
    <div>
      <ul className="flex gap-4">
        {wpp && (
          <a href={`tel:${number}`}>
            <p>
              {TourOperatorContacts?.length > 0
                ? `${
                    country_calling_code.length <= 2 ? "+" : ""
                  }${country_calling_code} ${number}`
                : "+54 12312 123123"}
            </p>
          </a>
        )}
        {ig && (
          <li>
            <a target="_blank" href={ig} rel="noreferrer">
              <FiInstagram fontSize={24} />
            </a>
          </li>
        )}
        {fb && (
          <li className="relative ">
            <a target="_blank" href={fb} rel="noreferrer">
              <FaFacebook fontSize={24} />
            </a>
          </li>
        )}
        {wpp && (
          <li>
            <a target="_blank" href={wpp} rel="noreferrer">
              <FaWhatsapp fontSize={24} />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}
