const Role = {
  1: [
    "view:quoter", //cotizador
    "view:myquotes", //mis cotizaciones
    "view:bookings", // reservas
    "view:packages", // paquetes
    "view:supplier", //Proveedores
    "view:invoicing", //Facturacion
    "view:passengers", //Pasajeros
    "view:configuration", //Configuracion
  ],
  2: [
    "view:myquotes", //mis cotizaciones
    "view:bookings", // reservas
    "view:packages", // paquetes
    "view:supplier", //Proveedores
    "view:invoicing", //Facturacion
    "view:passengers", //Pasajeros
    "view:configuration", //Configuracion
  ],
  3: [
    "view:bookings", // reservas
  ],
  4: [
    "view:myquotes", //mis cotizaciones
    "view:bookings", // reservas
    "view:packages", // paquetes
    "view:passengers", //Pasajeros
  ],
  5: [
    //estos serian todas las views del role finance por ejemplo
    "view:bookings", // reservas
    "view:supplier", //Proveedores
    "view:invoicing", //Facturacion
    "view:passengers", //Pasajeros
  ],
};

export const hasPermission = (permission) => {
  const userData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData"),
  );
  const userRol = userData?.role_id;
  return Role[userRol]?.includes(permission);
};
