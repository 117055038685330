import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import { format } from "date-fns";

export default function BookingPackagePdf({ pdfData }) {
  // Global styles
  const globalStyles = StyleSheet.create({
    body: {
      fontSize: 10,
    },
  });

  // PDF-specific styles
  const styles = StyleSheet.create({
    divider: {
      width: "100%",
      borderBottomWidth: 2,
      borderBottomColor: "black",
      marginBottom: 10,
      marginTop: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingBottom: 60,
      paddingTop: 30,
      alignItems: "center",
    },
    fcType: {
      width: 40,
      height: 40,
      borderWidth: 2,
      borderColor: "black",
      padding: 3,
      textAlign: "center",
      fontSize: 30,
    },
    section1: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 2,
    },
    seller: {
      flexDirection: "column",
    },
    fcInfo: {
      flexDirection: "row",
    },
    fcInfoCol: {
      flexDirection: "column",
      marginHorizontal: 5,
    },
    client: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 2,
    },
    clientColumns: {
      flexDirection: "column",
    },
    table: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 2,
    },
  });

  return (
    <Document>
      <Page size="A4" style={globalStyles.body}>
        <View style={styles.table}>
          <View>
            <Text>ID</Text>
            {pdfData?.map((item, index) => (
              <Text key={index} style={styles.nameText}>
                {item.id}
              </Text>
            ))}
          </View>
          <View>
            <Text>Estado</Text>
            {pdfData?.map((item, index) => (
              <Text key={index} style={styles.nameText}>
                {item.status === "pending"
                  ? "Pendiente"
                  : item.status === "confirmed"
                    ? "Confirmado"
                    : item.status}
              </Text>
            ))}
          </View>
          <View>
            <Text>Primer Pasajero</Text>
            {pdfData?.map((item, index) => (
              <Text key={index} style={styles.nameText}>
                {item.travelers_packages[0]?.last_name}
                {""}
                {item.travelers_packages[0]?.first_name}
              </Text>
            ))}
          </View>
          <View>
            <Text>Destino</Text>
            {pdfData?.map((item, index) => (
              <Text key={index} style={styles.nameText}>
                {item.Packages[0]?.destination_code}
              </Text>
            ))}
          </View>
          <View>
            <Text>Origen</Text>
            {pdfData?.map((item, index) => (
              <Text key={index} style={styles.nameText}>
                {item.Packages[0]?.origin_code}
              </Text>
            ))}
          </View>
          <View>
            <Text>Fecha de Creación</Text>
            {pdfData?.map((item, index) => (
              <Text key={index} style={styles.nameText}>
                {` ${format(new Date(item.creation_date), "dd/MM/yyyy")}`}
              </Text>
            ))}
          </View>
          {/* <View>
                    <Text>Amadeus</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {`${upperCase(item.last_name)}/${upperCase(item.first_name)} ${format(new Date(item.date_of_birth), "ddMMMyy")} P/`}
                        </Text>
                    ))}
                </View> */}
        </View>
      </Page>
    </Document>
  );
}
