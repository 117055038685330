import { useTranslation } from "react-i18next";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MdSort } from "react-icons/md";

export default function PackageSorting({ setSortCriteria, sortCriteria }) {
  const { t } = useTranslation();
  const handleSortChange = (key) => {
    setSortCriteria(() => ({
      key,
    }));
  };

  const handleDirectionToggle = () => {
    setSortCriteria((prev) => ({
      ...prev,
      direction: prev.direction === "asc" ? "desc" : "asc", // Toggle direction
    }));
  };

  return (
    <div className="inline-flex items-center gap-2">
      <h1>{t(`global.Sorting.order_by`)}</h1>
      <select
        className="absolute opacity-0 border-0 rounded-lg text-black p-2 focus:outline-none appearance-none bg-transparent hover:bg-transparent w-[120px]"
        onChange={(e) => handleSortChange(e.target.value)}
        value={sortCriteria.key}
      >
        <option value="price">{t(`global.Common_Words.price`)}</option>
        <option value="date">{t(`global.Sorting.upcoming`)}</option>
        <option value="stock">{t(`global.Sorting.most_sold`)}</option>
        <option value="title">{t(`global.Sorting.title`)}</option>
      </select>
      <span className="hover:text-blue-500 cursor-pointer">
        <MdSort size={23} />
      </span>
      <button
        className="flex items-center"
        aria-label={`Sort by price ${
          sortCriteria.direction === "asc" ? "descending" : "ascending"
        }`}
        onClick={handleDirectionToggle}
      >
        {sortCriteria.direction === "asc" ? (
          <FaArrowUp size={15} />
        ) : (
          <FaArrowDown size={15} />
        )}
      </button>
    </div>
  );
}
