import { useRef, useEffect } from "react";
export const VideoPlayer = ({ src, h }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.src = src;
      videoElement.load();
    }
  }, [src]);

  return (
    <div>
      <video
        ref={videoRef}
        className="rounded-[63px] border-2 border-[#D9D9D9]"
        style={{ height: h }}
        controls
        loop
      >
        <source src={src} type="video/mp4" />
        Tu navegador no soporta la reproducción de videos.
      </video>
    </div>
  );
};
