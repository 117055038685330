import React from "react";

export default function HotelPassengerLegalData({ register, errors }) {
  const maxDate = new Date();
  const maxDateString = maxDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  return (
    <>
      <label className="mb-1 font-low text-sm text-[#242423]">
        Tipo y número de documento{" "}
      </label>
      <span className="flex flex-col col-span-2">
        <div className="flex gap-4">
          <select
            name="docType"
            className="px-2 py-1 text-xs h-8 bg-lightGray3 rounded-md"
            {...register("documentation.doc_type")}
          >
            <option className="text-xs" value="IDENTITY_CARD">
              DNI
            </option>
            <option className="text-xs" value="PASSPORT">
              Pasaporte
            </option>
          </select>
          <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full ">
            <div className="relative w-full">
              <input
                name="docNumber"
                className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
                placeholder="Número de documento"
                {...register("documentation.number")}
              />
            </div>
          </label>
        </div>
        {errors.documentation?.number && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </span>

      <label
        htmlFor="placeofbirth"
        className="mb-1 font-low text-sm text-[#242423]"
      >
        Lugar de Nacimiento
      </label>
      <div className="col-span-2 relative">
        <section className="relative buscador-centro-ubicacion-input-div">
          <input
            className="rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
            type="text"
            name="placeofbirth"
            {...register("documentation.birth_place")}
          />
          <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-[300px]">
            <div className="flex flex-col gap-1"></div>
          </section>
        </section>
        {errors.documentation?.birth_place && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>

      <label
        htmlFor="issuanceLocation"
        className="mb-1 font-low text-sm text-[#242423]"
      >
        Lugar de emision del documento de viaje
      </label>
      <div className="col-span-2 relative">
        <section className="relative buscador-centro-ubicacion-input-div">
          <input
            className="rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
            type="text"
            name="issuanceLocation"
            {...register("documentation.issuance_location")}
          />
          <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-[300px]">
            <div className="flex flex-col gap-1"></div>
          </section>
        </section>
        {errors.documentation?.issuance_location && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>

      <label
        className="mb-1 font-low text-sm text-[#242423]"
        htmlFor="issuanceDate"
      >
        Fecha de emision del documento de viaje
      </label>
      <div className="col-span-2 relative">
        <input
          name="issuanceDate"
          type="date"
          max={maxDateString}
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          id="birthday"
          {...register("documentation.issuance_date")}
        />
        {errors.documentation?.issuance_date && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
      <label
        className="mb-1 font-low text-sm text-[#242423]"
        htmlFor="expiryDate"
      >
        Fecha de venicimiento del documento de viaje
      </label>
      <div className="col-span-2 relative">
        <input
          name="expiryDate"
          max={maxDateString}
          type="date"
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          id="expiryDate"
          {...register("documentation.expiry_date")}
        />
        {errors.documentation?.expiry_date && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
      <label
        htmlFor="issuanceCounrty"
        className="mb-1 font-low text-sm text-[#242423]"
      >
        Pais de Emision del documento de viaje
      </label>
      <div className="col-span-2 relative">
        <input
          name="issuanceCounrty"
          className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
          id="issuanceCounrty"
          {...register("documentation.issuance_country")}
        />
        {errors.documentation?.issuance_country && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
      <label
        htmlFor="nationality"
        className="mb-1 font-low text-sm text-[#242423]"
      >
        Nacionalidad
      </label>
      <div className="col-span-2 relative">
        <input
          name="nationality"
          className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
          id="nationality"
          {...register("documentation.nationality")}
        />
        {errors.documentation?.nationality && (
          <p className="text-red-500">Este campo es necesario</p>
        )}
      </div>
      <label className="mb-1 font-low text-sm text-[#242423]">
        Tipo y número de telefono{" "}
      </label>
      <span className="flex flex-col col-span-2 gap-4">
        <div className="flex gap-4">
          <select
            name="docType"
            className="px-2 py-1 text-xs h-8 bg-lightGray3 rounded-md"
            {...register("contact_phones[0].device_type")}
          >
            <option className="text-xs" value="CEL">
              CEL
            </option>
            <option className="text-xs" value="TEL">
              TEL
            </option>
          </select>
          <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full ">
            <div className="relative w-full flex gap-2">
              <input
                name="docNumber"
                className="w-4/12 rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
                placeholder="+XX"
                {...register("contact_phones[0].country_calling_code")}
              />
              <input
                name="docNumber"
                className="w-8/12 rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
                placeholder="XXX XXX XXXX"
                {...register("contact_phones[0].number")}
              />
            </div>
          </label>
        </div>

        {errors.contact_phones?.[0]?.number &&
          errors.contact_phones?.[0]?.country_calling_code && (
            <p className="text-red-500">Este campo es necesario</p>
          )}
      </span>
    </>
  );
}
