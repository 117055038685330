import userIcon from "../../../../iconos/fi-rr-user.png";
import { handleDisplay } from "../../../../utils/HandleDisplay";
import PassengerHeader from "../../../packages/booking/bookingSegments/PassengerHeader";
import { PackagePassengerForm } from "./PackagePassengerForm";
export default function PassengerSection({
  numberOfPassengers,
  t,
  errors,
  register,
  watch,
}) {
  const formattedNumberOfPassengers = () => {
    const { ADT, CHD, INF } = numberOfPassengers;
    const totalPassangers = ADT + CHD + INF;

    return new Array(totalPassangers).fill(0).map((_, index) => {
      const travelerType =
        index < ADT
          ? "adult"
          : index < ADT + CHD
            ? "children_single"
            : "infant";

      return {
        travelerType,
        travelerId: index + 1,
      };
    });
  };

  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {(formattedNumberOfPassengers() || []).map((v, index) => {
          const title = `${t(`global.Search_Packages.passenger`)} ${index + 1} 
                  (${
                    t(`global.Passenger_Dictionary.${v?.travelerType}`) || (
                      <>{t(`global.Passenger_Dictionary.adult`)}</>
                    )
                  })`;

          return (
            <div key={v?.travelerId || index} className="">
              <PassengerHeader
                first={null}
                key={v?.travelerId || index}
                row={6}
                title={title}
                icon={userIcon}
                handleDisplay={handleDisplay}
              />
              <PackagePassengerForm
                id={v?.travelerId}
                index={index}
                idBody={`${title}-body`}
                isFlight={"flights"}
                errors={errors}
                register={register}
                watch={watch}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
