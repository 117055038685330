import { useTranslation } from "react-i18next";
import EditButton from "./tableSupplier/tableRow/tableButtons/EditButton";

const ModalViewSupplier = ({ selectedSupplier }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4 mt-2">
      <div className="flex flex-row gap-1">
        <h2 className="font-medium text-darkBlue text-2xl">
          {selectedSupplier.name}
        </h2>
        <EditButton />
      </div>
      <div className="grid grid-cols-2 gap-9">
        <div className="flex flex-col gap-3">
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.legal_name`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.legal_name}
            </p>
          </div>
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.fiscal_acount`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.tax_code}
            </p>
          </div>
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.address`)}
            </label>
            {selectedSupplier.adress === undefined ? (
              <p className="w-full px-4 py-2 border border-gray2 rounded">
                {t(`global.Admin_Suppliers.no_info`)}
              </p>
            ) : (
              <p className="w-full px-4 py-2 border border-gray2 rounded">
                {selectedSupplier.adress}
              </p>
            )}
          </div>
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.phone`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.phone_number}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.email`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.email}
            </p>
          </div>
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.account_number`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.tax_number}
            </p>
          </div>
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.link`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.account_link}
            </p>
          </div>
          <div>
            <label className="font-medium">
              {t(`global.Admin_Suppliers.cellphone`)}
            </label>
            <p className="w-full px-4 py-2 border border-gray2 rounded">
              {selectedSupplier.cellphone_number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalViewSupplier;
