import Skeleton from "./Skeleton";

export default function SkeletonsFlightSearch() {
  return (
    <div className=" p-20">
      <div className="flex flex-row -mt-20 gap-10">
        <div className="">
          <Skeleton className="h-10 w-60 rounded ml-4 " />
          <Skeleton className="h-28 w-40 rounded ml-4 mt-4" />
          <Skeleton className="h-10 w-60 rounded ml-4 mt-4 " />
          <Skeleton className="h-28 w-40 rounded ml-4 mt-4" />
          <Skeleton className="h-10 w-60 rounded ml-4 mt-4 " />
          <Skeleton className="h-28 w-40 rounded ml-4 mt-4" />
        </div>

        <Skeleton className="h-80 w-3/4 rounded  " />
        <Skeleton className="absolute h-20 w-2/5 rounded  translate-x-[55%] translate-y-[55%]" />
        <Skeleton className="absolute h-20 w-2/5 rounded  translate-x-[55%] translate-y-[200%]" />
        <Skeleton className="h-80 w-1/4 rounded  " />
      </div>
    </div>
  );
}
