import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { useTranslation } from "react-i18next";

export default function EmailButton({ suppEmail }) {
  const { t } = useTranslation();
  return (
    <a
      className="relative mx-0.5 h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/send"
      href={suppEmail ? `mailto:${suppEmail}` : undefined} // Conditionally set href
    >
      <MailOutlinedIcon
        sx={{
          color: "#A0AFCD",
          height: "20px",
          width: "20px",
        }}
        alt="Mail"
      />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-20 py-0.5 px-1 rounded scale-0 transition-transform group-hover/send:scale-100">
        {suppEmail
          ? t(`global.Common_Words.email`)
          : t(`global.Common_Words.no_email`)}
      </span>
    </a>
  );
}
