import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import HotelPictureModal from "./HotelPictureModal";
import ReactDOM from "react-dom";
import { ModalGeneric } from "../../../../UI/ModalGeneric";
export default function HotelCardImage({ image }) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  return (
    <>
      <div
        onClick={toggleVisibility}
        className="mb:w-full lg:w-[20%] mb:height:1/4 lg:h-full flex-shrink-0 "
      >
        <img
          src={image}
          alt="Hotel"
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      {isVisible &&
        ReactDOM.createPortal(
          <ModalGeneric show={isVisible} toggle={toggleVisibility}>
            <HotelPictureModal />
          </ModalGeneric>,
          document.body, // Render the modal at the end of the body (outside the parent container)
        )}
    </>
  );
}
