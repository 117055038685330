import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export default function PageTitle({
  title,
  subTitle,
  route,
  btnText,
  buttonVisivility,
}) {
  const { tour_op_id } = useParams();
  const { t } = useTranslation();
  return (
    <header className="flex justify-between items-center mb-4 mb:block lg:flex">
      <span>
        <h1 className="font-[700] text-darkBlue text-3xl">
          {t(`global.Admin_Titles.${title}`)}
        </h1>
        <p className="text-darkBlue mb:mb-4 mb:flex-wrap">
          {t(`global.Admin_Titles.${subTitle}`)}
        </p>
      </span>
      {buttonVisivility && (
        <Link
          className="text-center button bg-darkBlue text-white w-44 hover:shadow-md transition-all"
          to={`/${tour_op_id}/${route}`}
        >
          {t(`global.Admin_Titles.${btnText}`)}
        </Link>
      )}
    </header>
  );
}
