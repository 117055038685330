import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from "react-icons/fa";

export default function RolePermissionTable() {
  const { t } = useTranslation();
  return (
    <div className="mt-4 rounded-xl p-4 border-2 bg-white border-lightBlue  ">
      <table className="bg-white w-full border-lightBlue">
        <thead className="bg-lightBlue/30 rounded py-2">
          <tr>
            <th className="font-medium ">
              {t(`global.Admin_Users.Headers_Permissions.col_0`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_1`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_2`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_3`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_4`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_5`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_6`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_7`)}
            </th>
            <th className="font-medium text-center">
              {t(`global.Admin_Users.Headers_Permissions.col_8`)}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* <tr className="py-2">
          <td className="pl-16">{t("global.Admin_Users.superAdmin")}</td>
            {[...Array(8)].map((_, index) => (
              <td key={index} className="text-center">
                <div className="flex justify-center">
                  <FaCheck color="green" size={23} />
                </div>
              </td>
            ))}
          </tr> */}
          <tr className="py-2 border-2 border-lightBlue">
            <td className="text-center  border-2 border-lightBlue py-2">
              {t("global.Admin_Users.admin")}
            </td>
            {[false, true, true, true, true, true, true, true].map(
              (value, index) => (
                <td
                  key={index}
                  className="text-center border-2 border-lightBlue"
                >
                  <div className="flex justify-center">
                    {value ? (
                      <FaCheck color="green" size={23} />
                    ) : (
                      <FaTimes color="red" size={23} />
                    )}
                  </div>
                </td>
              ),
            )}
          </tr>
          <tr className="py-2 border-2 border-lightBlue">
            <td className="text-center  border-2 border-lightBlue py-2">
              {t("global.Admin_Users.seller")}
            </td>
            {[false, true, true, true, false, false, true, false].map(
              (value, index) => (
                <td
                  key={index}
                  className="text-center border-2 border-lightBlue"
                >
                  <div className="flex justify-center">
                    {value ? (
                      <FaCheck color="green" size={23} />
                    ) : (
                      <FaTimes color="red" size={23} />
                    )}
                  </div>
                </td>
              ),
            )}
          </tr>
          <tr className="py-2 border-2 border-lightBlue">
            <td className="text-center  border-2 border-lightBlue py-2">
              {t("global.Admin_Users.finance")}
            </td>
            {[false, false, true, false, true, true, true, false].map(
              (value, index) => (
                <td
                  key={index}
                  className="text-center border-2 border-lightBlue"
                >
                  <div className="flex justify-center">
                    {value ? (
                      <FaCheck color="green" size={23} />
                    ) : (
                      <FaTimes color="red" size={23} />
                    )}
                  </div>
                </td>
              ),
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
