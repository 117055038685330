import { handleDisplay } from "../../utils/HandleDisplay";
import arrow from "./../../iconos/chevron_down_gray.png";
export default function AgencySelector({
  agencies,
  checkedAgencies,
  handleCheckboxChange,
  setCheckedAgencies,
}) {
  return (
    <>
      <section
        onClick={() => handleDisplay("agency")}
        id="agency-header"
        className="cursor-pointer justify-center"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">Agencia</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 rotate-0 transition-all"
            id="agency-arrow"
          />
        </header>
      </section>
      <section
        className="bg-lightBlue/20 rounded-md py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top scale-y-0 h-0"
        id="agency-body"
      >
        {agencies.map((item) => (
          <label
            key={item.id}
            htmlFor={`checkbox-${item.id}`}
            className="flex items-center gap-1 capitalize"
          >
            <input
              type="checkbox"
              id={`checkbox-${item.id}`}
              name={item.name}
              checked={checkedAgencies[item.id] || false}
              onChange={() => handleCheckboxChange(setCheckedAgencies, item.id)}
            />
            {item.name.toLowerCase()}
          </label>
        ))}
      </section>
    </>
  );
}
