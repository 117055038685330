import { useTranslation } from "react-i18next";
import { PASSENGER_DICTIONARY } from "../../utils/dictionaries/passengerDictionary";

export default function PassengerSelector({
  addPassenger,
  removePassenger,
  passengerData,
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 p-2 rounded-lg border">
      {Object.entries(passengerData).map(([key, value]) => {
        const { label, description, min } = PASSENGER_DICTIONARY[key]; // Pass passenger type as key
        return (
          <div className="flex justify-between items-center" key={key}>
            <div>
              <h2 className="font-medium">
                {t(`global.Passenger_Dictionary.${label}`)}
              </h2>
              <p className="text-xs text-gray">
                {t(`global.Passenger_Dictionary.${description}`)}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <button
                type="button"
                onClick={() => removePassenger(key, min)}
                className="bg-lightGray3 border w-7 h-7 rounded-full text-sm font-[700]"
              >
                -
              </button>
              <p className="text-xl">{value}</p>
              <button
                type="button"
                onClick={() => addPassenger(key)}
                className="bg-lightGray3 border w-7 h-7 rounded-full text-sm font-[700]"
              >
                +
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
