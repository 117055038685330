import { z } from "zod";

export const flightSearchSchema = (routeOption) =>
  z.object({
    originLocationCode: z
      .string({ required_error: "origin_location_code_required" })
      .min(3, { message: "origin_location_code_min" }),
    destinationLocationCode: z
      .string({ required_error: "destination_location_code_required" })
      .min(3, { message: "destination_location_code_min" }),
    departureDate: z
      .string({ required_error: "departure_date_required" })
      .min(1),
    returnDate:
      routeOption === "idayvuelta"
        ? z.string({ required_error: "departure_date_min" }).min(1)
        : z.string().optional(),
    adults: z.string().default("1"),
    children: z.string().optional().default("0"),
    infants: z.string().optional().default("0"),
    travelClass: z.string().optional(),
  });

export const packageSearchSchema = z.object({
  originLocationCode: z
    .string({ required_error: "Campo requerido" })
    .optional(),
  destinationLocationCode: z
    .string({ required_error: "Campo requerido" })
    .min(3, { message: "Mínimo 3 caracteres" }),
  departureDate: z.string({ required_error: "Campo requerido" }).min(1),
  returnDate: z.string({ required_error: "Campo requerido" }).optional(),
  adults: z.string().default("1"),
  children: z.string().optional().default("0"),
  infants: z.string().optional().default("0"),
  travelClass: z.string().optional(),
});

export const hotelSearchSchema = z.object({
  // cityCode: z
  //   .string({ required_error: "Campo requerido" })
  //   .min(3, { message: "Mínimo 3 caracteres" })
  //   .max(3, { message: "Máximo 3 caracteres" }),
  cityName: z
    .string({ required_error: "origin_location_required" })
    .min(3, { message: "origin_location_min" }),
  departureDate: z
    .string({ required_error: "departure_date_required" })
    .min(1, { message: "date_min" }),
  returnDate: z
    .string({ required_error: "departure_date_required" })
    .min(1, { message: "date_min" }),
  adults: z.string().default("1"),
  children: z.string().optional().default("0"),
  infants: z.string().optional().default("0"),
  rooms: z.string().default("1"),
});
