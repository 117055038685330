export const checkErrors = (errors, firstPassenger, type, t) => {
  const errorLength = Object.keys(errors)?.filter(
    (key) => !errors[key]?.correct,
  ).length;

  if (
    errorLength > 0 ||
    Object.keys(errors).length <
      (type === "flights" ? (firstPassenger ? 8 : 7) : 4)
  ) {
    const btn = document.getElementById("reserve_btn");
    btn.classList.add("bg-red-400");
    btn.classList.add("hover:bg-red-400");
    btn.textContent = t("global.Warnings.complete_all_fields");

    setTimeout(() => {
      btn.classList.remove("bg-red-400");
      btn.classList.remove("hover:bg-red-400");
      btn.textContent = t("global.Common_Words.reserve");
    }, 2000);

    return false;
  }
  return true;
};
