import { useRef } from "react";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import useOnClickOutside from "../../hooks/useClickOutside";
import { es } from "date-fns/locale";

export default function NewCalendar({
  handlers,
  mode = "single",
  numberOfMonths = 1,
  close,
}) {
  const [date, setDate] = handlers;
  const ref = useRef(null);
  useOnClickOutside(ref, close, ref);

  return (
    <div ref={ref}>
      <DayPicker
        locale={es}
        mode={mode}
        numberOfMonths={numberOfMonths}
        captionLayout="dropdown-buttons"
        fromDate={new Date()}
        toYear={2026}
        selected={date}
        onSelect={setDate}
        className="bg-lightGray2 border-2 border-gray rounded-md absolute capitalize p-2 z-custom"
        modifiersStyles={{
          selected: {
            backgroundColor: "rgb(206, 222, 241)",
            color: "black",
            fontWeight: "bolder",
          },
          range_middle: {
            backgroundColor: "rgb(206, 222, 241)",
            fontWeight: "normal",
          },
          today: { color: "rgb(85, 137, 255)" },
        }}
      />
    </div>
  );
}
