import React from "react";

export default function HotelData({
  HotelInfo,
  Adults,
  Children,
  TotalFixAmounts,
  $,
}) {
  return (
    <>
      <div className="flex flex-col gap-5">
        <div className="border border-gray2 rounded-lg overflow-hidden min-w-max p-4 flex flex-col">
          <h1 className=" font-bold text-3xl">Display de reserva</h1>
          <h2 className=" text-2xl">{HotelInfo[0].Name?.[0]}</h2>
          <div className="grid grid-cols-2 ">
            {
              // TODO add support for multiple people types
            }
            <div className="grid-rows-4">
              <h2>Personas</h2>
              <h2>Tipo de habitacion</h2>
              <h3>Fechas de entrada</h3>
              <h3>Fechas de salida</h3>
            </div>
            <div className="grid-rows-4 text-right">
              <p>{Number(Adults) + Number(Children)}</p>
              {/* <p>{RoomCategory[0]._ || "-"}</p> */}
              {/* <p>{format(new Date(checkInDay), "dd/MM/yyyy")}</p>
        <p>{format(new Date(checkOutDay), "dd/MM/yyyy")}</p> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="border border-gray2 rounded-lg overflow-hidden min-w-max p-4 flex flex-col">
            <h1 className="font-bold text-3xl">Costos</h1>
            <div className="grid grid-cols-2 items-center">
              <div className="grid-rows-2">
                <h2 className="text-2xl">Precio total</h2>
              </div>
              <div className="grid-rows-2 text-right text-xl">
                <span>{TotalFixAmounts?.[0].$.Gross}</span>{" "}
                <span>{$.Currency}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
