import { useState, useEffect } from "react";
import { get } from "./axios.service";

export const useRole = () => {
  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    try {
      const response = await get("v2/roles");
      setRoles(response.data.response); // Set roles if the request is successful
    } catch (error) {
      throw new Error(`Get Roles Error: ${error.message}`); // Throw a descriptive error
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return {
    roles,
    getRoles,
  };
};
