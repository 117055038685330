import React from 'react'
import arrow from "../../../../../iconos/down_arrow.png";
export default function FeeFilter({ handleDisplay }) {
    return (
      <>
        <section
          onClick={() => handleDisplay("fee")}
          id="fee-header"
          className="cursor-pointer"
        >
          <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
            <h2 className="font-low">Tipo de tarifa</h2>
            <img
              src={arrow}
              alt="arrow"
              className="w-4 h-2 -rotate-180 transition-all"
              id="fee-arrow"
            />
          </header>
        </section>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
          id="fee-body"
        >
          <div className="flex  flex-col ">
            <div className="flex flex-row items-center gap-2">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              />
              <span>Preferentemente reembolsable</span>
            </div>
            
          </div>
        </section>
      </>
    );
  }
  