import { z } from "zod";

export const newUserSchema = z.object({
  email: z.string().nonempty("email_required").email("email_invalid"),
  password: z
    .string()
    .nonempty("password_required")
    .min(8, "password_min_length")
    .max(16, "password_max_length")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,16}$/,
      "password_regex",
    ),
  rePassword: z
    .string()
    .nonempty("repassword_required")
    .min(8, "repassword_min_length")
    .max(16, "repassword_max_length")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,16}$/,
      "repassword_regex",
    ),
  username: z.string().optional(),
  tour_op_id: z.number().default(1),
});
