import React, { useState } from "react";
import HotelRoomTab from "./cardTabs/HotelRoomTab";
import HotelAdressTab from "./cardTabs/HotelAdressTab";

export default function HotelCardTabs({ HotelInfo, HotelOption,handleReserve,hotel }) {
  const [tab, setTab] = useState("rooms");

  const { Description, Address, Latitude, Longitude,pax } = HotelInfo;
  return (
    <section>
      <header className="grid grid-cols-3 mb-4">
        <button
          className={`border-b border-brightBlue rounded-t font-bold py-1 px-4 cursor-pointer hover:bg-lightBlue transition-colors ${
            tab === "rooms" && "bg-darkBlue text-white border-b-4"
          }`}
          onClick={() => setTab("rooms")}
        >
          Habitación
        </button>
        <button
          className={`border-b border-brightBlue rounded-t font-bold py-1 px-4 cursor-pointer hover:bg-lightBlue transition-colors ${
            tab === "description" && "bg-darkBlue text-white border-b-4"
          }`}
          onClick={() => setTab("description")}
        >
          Descripción
        </button>
        <button
          className={`border-b border-brightBlue rounded-t font-bold py-1 px-4 cursor-pointer hover:bg-lightBlue transition-colors ${
            tab === "address" && "bg-darkBlue text-white border-b-4"
          }`}
          onClick={() => setTab("address")}
        >
          Dirección
        </button>
      </header>
      {tab === "rooms" && <HotelRoomTab pax={pax} hotel={hotel} handleReserve={handleReserve} HotelRooms={HotelOption.HotelRooms} />}
      {tab === "description" && <p>{Description[0]}</p>}
      {tab === "address" && (
        <HotelAdressTab
          Address={Address}
          Latitude={Latitude}
          Longitude={Longitude}
        />
      )}
    </section>
  );
}
