import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function ModalViewQuote({ selectedQuote }) {
  const { t } = useTranslation();

  const datesFormatted = selectedQuote.dates
    ? selectedQuote.dates
    : t("global.Admin_Quotes.no_date_available");

  const statusMap = {
    "to-start": {
      text: t("global.Admin_Quotes.to-start"),
      bgColor: "bg-yellow-100",
    },
    "quote-asked": {
      text: t("global.Admin_Quotes.quote-asked"),
      bgColor: "bg-blue-100",
    },
    "info-missing": {
      text: t("global.Admin_Quotes.info-missing"),
      bgColor: "bg-red-100",
    },
    "no-answer": {
      text: t("global.Admin_Quotes.no-answer"),
      bgColor: "bg-gray-100",
    },
    "quote-sent": {
      text: t("global.Admin_Quotes.quote-sent"),
      bgColor: "bg-green-100",
    },
    "ask-info": {
      text: t("global.Admin_Quotes.ask-info"),
      bgColor: "bg-orange-100",
    },
    pending: {
      text: t("global.Admin_Quotes.pending"),
      bgColor: "bg-purple-100",
    },
  };

  const statusInfo = statusMap[selectedQuote.status] || {
    text: t("global.unknown"),
    bgColor: "bg-gray-200",
  };

  return (
    <div className="">
      <h2 className="text-xl font-semibold">
        {t("global.Admin_Quotes.detail_title")}
      </h2>

      <div className="mt-4">
        <h3 className="font-bold">{t("global.Admin_Quotes.passengers")}</h3>
        <p>{selectedQuote.passengers}</p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">{t("global.Admin_Quotes.travel_details")}</h3>
        <p>
          <span className="font-medium">
            {t("global.Admin_Quotes.origin")}:
          </span>{" "}
          {selectedQuote.origin || t("global.Admin_Quotes.not_specified")}
        </p>
        <p>
          <span className="font-medium">
            {t("global.Admin_Quotes.destination")}:
          </span>{" "}
          {selectedQuote.destination || t("global.Admin_Quotes.not_specified")}
        </p>
        <p>
          <span className="font-medium">{t("global.Admin_Quotes.dates")}:</span>{" "}
          {datesFormatted}
        </p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">{t("global.Admin_Quotes.request")}</h3>
        <p>{selectedQuote.ask || t("global.Admin_Quotes.not_specified")}</p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">{t("global.Admin_Quotes.contact_info")}</h3>
        <p>
          <span className="font-medium">{t("global.Admin_Quotes.name")}:</span>{" "}
          {selectedQuote.name || t("global.Admin_Quotes.not_available")}
        </p>
        <p>
          <span className="font-medium">{t("global.Admin_Quotes.email")}:</span>{" "}
          {selectedQuote.email || t("global.Admin_Quotes.not_available")}
        </p>
        <p>
          <span className="font-medium">
            {t("global.Admin_Quotes.whatsapp")}:
          </span>{" "}
          {selectedQuote.whatsapp || t("global.Admin_Quotes.not_available")}
        </p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">
          {t("global.Admin_Quotes.additional_info")}
        </h3>
        <p>
          <span className="font-medium">
            {t("global.Admin_Quotes.quote_status")}:
          </span>{" "}
          <span
            className={`p-2 rounded text-sm font-semibold text-black ${statusInfo.bgColor}`}
          >
            {statusInfo.text} ({selectedQuote.status})
          </span>
        </p>
      </div>

      <div className="mt- flex flex-inline items-center gap-2">
        <span className="font-medium">
          {t("global.Admin_Quotes.creation_date")}:
        </span>
        <p>
          {selectedQuote.creation_date
            ? format(
                new Date(selectedQuote.creation_date),
                "yyyy-MM-dd  HH:mm:ss",
              )
            : t("global.Admin_Quotes.not_specified")}
        </p>
      </div>
    </div>
  );
}
