import axios from "axios";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStore } from "../store/useUserStore";
import { capitalizeString } from "../utils/capitalizeString";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";
import { useTourOperatorStore } from "../store/useTourOperatorStore";

const BASE_URL = process.env.REACT_APP_API_URL;

const generateTravelersArray = (passenger, type, opContact) =>
  passenger.map((pax, index) => {
    let data = {
      first_name: capitalizeString(pax.name),
      last_name: capitalizeString(pax.surname),
      date_of_birth: pax.birthday + "T17:00:00.000Z",
      gender: "M",
    };
    let documentation = {
      doc_type: pax.docType,
      number: pax.docNumber.toString(),
      holder: index === 0 ? "Y" : "N",
      issuance_date: new Date(),
      expiry_date: new Date(),
    };
    const contact_phones = [
      {
        device_type: "MOBILE",
        country_calling_code: "54",
        number: opContact, // Por ahora meto acá el número 'crudo' de la operadora
      },
    ];

    if (type === "flight") {
      data = {
        ...data,
        gender: pax?.genre,
        email_address: pax?.mail ?? "-",
      };
      documentation = {
        ...documentation,
        expiry_date: pax.docExpirationDate + "T17:00:00.000Z",
        birth_place: "-",
        issuance_location: "-",
        issuance_country: pax.docNationality,
        validity_country: "-",
        nationality: pax.nationality,
      };
    }

    return { data, documentation, contact_phones };
  });

export const useBookings = () => {
  const navigate = useNavigate();
  const { tour_op_id, type } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const [loading, setLoading] = useState(false);
  const [errorPNR, setErrorPNR] = useState(false);
  const [booking, setBooking] = useState([]);
  const [bookings, setBookings] = useState([]);

  let { user } = useUserStore();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const getFlightBookings = async () => {
    setBookings([]); // Clear previous bookings

    try {
      const response = await axios.get(
        BASE_URL + `v2/bookings/flights/all/${tourOP.id}`,
      );
      setBookings(
        response.data.response.filter(
          (booking) => booking.type === "flight" && booking.Flights.length > 0,
        ),
      );
    } catch (error) {
      throw new Error("Failed to fetch flight bookings: " + error.message);
    }
  };

  const getFlightBookingsByTourOperator = async (tourOperator) => {
    setBookings([]); // Clear previous bookings

    try {
      const response = await axios.get(
        BASE_URL + `v2/bookings/flights/all/${tourOperator}`,
      );
      setBookings(
        response.data.response.filter(
          (booking) => booking.type === "flight" && booking.Flights.length > 0,
        ),
      );
    } catch (error) {
      // Instead of using console.log, throw the error or handle it as appropriate
      throw new Error(
        "Failed to fetch flight bookings for the tour operator: " +
          error.message,
      );
    }
  };

  const getPackageBookings = async () => {
    setBookings([]);
    return axios
      .get(BASE_URL + `v2/bookings/packages/all/${tourOP.id}`, headers)
      .then((e) => {
        setBookings(e.data.response);
      });
  };

  const createBooking = async ({
    passenger,
    type,
    selectedItem,
    fee,
    tour,
  }) => {
    if (!user?.id) throw new Error("There is not logged user");

    const { country_calling_code, number } =
      tourOP?.TourOperatorPhones?.[0] || {};
    if (!country_calling_code || !number) {
      throw new Error("Missing tour operator contact information.");
    }

    const opContact = `${country_calling_code} ${number}`;
    const travelers = generateTravelersArray(passenger, type, opContact);

    const itemToSend =
      type === "flight"
        ? {
            flight: {
              options: JSON.stringify(selectedItem),
              provider_id: tourOP.id,
            },
          }
        : {
            package: {
              package_id: selectedItem,
            },
          };

    try {
      const e = await axios.post(BASE_URL + `v2/bookings/${type}s`, {
        booking: {
          creation_date: new Date().toISOString(),
          status: "pending",
          comments: "No comments",
          type,
          tour_op_id: tourOP.id,
          currency_type_id: fee.currency_type_id,
        },
        package: { package_id: selectedItem },
        fee: { fee_id: fee.id },
        tour: { tour_id: tour.id },
        travelers,
        user_id: user.id,
        ...itemToSend,
      });
      return e;
    } catch (err) {
      throw new Error("Failed to create booking: " + err.message); // Handle the error
    } finally {
      setTimeout(() => {
        navigate(
          user?.role_id === 3
            ? `/${tour_op_id}/${type}s`
            : `/${tour_op_id}/panel/sales/bookings/${type}`,
        );
      }, 2000);
    }
  };

  const updateBooking = async ({ booking, update }) =>
    await axios.patch(BASE_URL + `v2/bookings/${booking.id}`, update, headers);

  const deleteBooking = async ({ type, id }) => {
    await axios.delete(BASE_URL + `v2/bookings/${type}s/${id}`, headers);
  };

  const getBooking = async ({ bookingID }) => {
    setLoading(true);
    axios
      .get(BASE_URL + `v2/bookings/${type || "flight"}s/${bookingID}`)
      .then((r) => {
        // console.log(r);
        setBooking(r.data.response);
      })
      .catch(() => {
        // console.log(err);
        setLoading(false);
      });
  };

  const postAmadeusBooking = async ({ booking }) => {
    const { Flights, Travelers } = booking;

    const travelers = Travelers.map((tr, index) => ({
      id: index + 1,
      dateOfBirth: tr.date_of_birth.split("T")[0],
      name: {
        firstName: tr.first_name,
        lastName: tr.last_name,
      },
      gender: tr.gender === "M" ? "MALE" : "FEMALE",
      contact: {
        emailAddress: tr.email_address,
        phones: [
          {
            deviceType: tr.TravelerPhones?.[0]?.device_type,
            countryCallingCode: tr.TravelerPhones?.[0]?.country_calling_code,
            number: tr.TravelerPhones?.[0]?.number,
          },
        ],
      },
      documents: [
        {
          documentType: tr.TravelerDocument.doc_type,
          number: tr.TravelerDocument.number,
          expiryDate: tr.TravelerDocument.expiry_date.split("T")[0],
          // -----------------------------
          issuanceLocation: "Paris",
          issuanceDate: "2018-02-15",
          // -----------------------------
          nationality: tr.TravelerDocument.nationality,
          issuanceCountry: tr.TravelerDocument.issuance_country,
          holder: index === 0,
        },
      ],
    }));

    const {
      id,
      name,
      TourOperatorPhones,
      TourOperatorRemarks,
      TourOperatorTicketingAgreements,
      TourOperatorAddresses,
      TourOperatorContacts,
    } = tourOP;

    const flightOrder = {
      type: "Flight",
      flightOffers: [JSON.parse(Flights[0].options)],
      travelers,
      remarks: {
        general: [
          {
            subType: TourOperatorRemarks[0].sub_type,
            text: TourOperatorRemarks[0].text || "",
          },
        ],
      },
      ticketingAgreement: {
        option: TourOperatorTicketingAgreements[0].option,
        delay: TourOperatorTicketingAgreements[0].delay,
      },
      contacts: [
        {
          addresseeName: {
            firstName: TourOperatorContacts[0].addressee_first_name,
            lastName: TourOperatorContacts[0].addressee_last_name,
          },
          companyName: name,
          //  --------------------
          purpose: "STANDARD",
          //  --------------------
          phones: [
            {
              deviceType: TourOperatorPhones[0].device_type,
              countryCallingCode: TourOperatorPhones[0].country_calling_code,
              number: TourOperatorPhones[0].number,
            },
          ],
          emailAddress: TourOperatorContacts[0].email_address,
          address: {
            lines: [TourOperatorAddresses[0].street_name],
            postalCode: TourOperatorAddresses[0].postal_code,
            cityName: TourOperatorAddresses[0].city_name,
            countryCode: TourOperatorAddresses[0].country_code,
          },
        },
      ],
      tour_op_id: id,
    };

    return await axios
      .post(
        BASE_URL + "v2/bookings/flights/flights-orders",
        { flightOrder },
        headers,
      )
      .catch((e) => {
        const { error } = e.response.data;
        setErrorPNR(error.description);
        return error;
      });
  };

  const postHotelBooking = async (body) =>
    await axios.post(BASE_URL + "v2/bookings/hotels", body).catch((e) => {
      const { error } = e.response.data;
      setErrorPNR(error.description);
      return error;
    });

  return {
    loading,
    errorPNR,
    booking,
    bookings,
    getFlightBookings,
    getFlightBookingsByTourOperator,
    getPackageBookings,
    getBooking,
    createBooking,
    updateBooking,
    deleteBooking,
    postAmadeusBooking,
    postHotelBooking,
  };
};
