import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { featureSchema } from "../../schemas/featureSchema";
import { Toaster } from "react-hot-toast";

export default function CreateFeature({ createFunctionality }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(featureSchema),
  });

  const onSubmit = (data) => {
    createFunctionality({
      ...data,
      votesquantity: 1,
      dateofcreation: new Date(),
      version_id: 1,
    });
  };

  return (
    <form
      className="grid grid-cols-2 gap-6"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Toaster />
      <label htmlFor="name" className="relative">
        <p>{t(`global.Help_Page.name`)}</p>
        <input name="name" {...register("name")} className="addPackInput" />
        {errors?.name && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>{t(`global.Help_Page.${errors?.name.message}`)}</p>
          </div>
        )}
      </label>

      <label htmlFor="link" className="relative">
        <p>{t(`global.Help_Page.link`)}</p>
        <input name="link" {...register("link")} className="addPackInput" />
        {errors?.link && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>{t(`global.Help_Page.${errors?.link.message}`)}</p>
          </div>
        )}
      </label>

      <label htmlFor="description" className="relative col-span-2">
        <p>{t(`global.Help_Page.description`)}</p>
        <input
          name="description"
          {...register("description")}
          className="addPackInput w-full"
        />
        {errors?.link && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>{t(`global.Help_Page.${errors?.link.message}`)}</p>
          </div>
        )}
      </label>

      <label htmlFor="status" className="relative">
        <p>{t(`global.Help_Page.state`)}</p>
        <input name="status" {...register("status")} className="addPackInput" />
        {errors?.status && (
          <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
            <p>{t(`global.Help_Page.${errors?.status.message}`)}</p>
          </div>
        )}
      </label>

      <label htmlFor="vote" className="relative">
        <p>{t(`global.Help_Page.vote`)}</p>
        <select
          name="vote"
          {...register("vote")}
          className="addPackInput w-full"
        >
          <option value="Y" selected>
            {t(`global.Common_Words.yes`)}
          </option>
          <option value="N"> {t(`global.Common_Words.no`)}</option>
        </select>
      </label>

      <button className="col-span-2 px-4 py-2 rounded bg-brightBlue text-white ml-auto">
        {t(`global.Help_Page.add_feature`)}
      </button>
    </form>
  );
}
