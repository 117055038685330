import { useEffect, useState } from "react";
import { useTours } from "../../../services/useTours";
import { useTranslation } from "react-i18next";
import arrow from "../../../iconos/chevron_down_gray.png";
import TourDetailRow from "./TourDetailRow";
export default function ToursDetailTable({ ids, handleDisplay }) {
  const { getTours, tours } = useTours();
  const { t } = useTranslation();
  const [selectedTour, setSelectedTour] = useState([]);

  useEffect(() => {
    const fetchTours = async () => {
      if (ids) {
        await getTours(ids.id);
      }
    };
    fetchTours();
  }, [ids]);

  useEffect(() => {
    if (tours.length > 0 && ids?.packages_travelers.tour_id) {
      const foundTour = tours.find(
        (tour) => tour.id === ids?.packages_travelers.tour_id,
      );
      setSelectedTour(foundTour || null);
    }
  }, [tours, ids?.packages_travelers.tour_id]);

  return (
    <section className="pb-2">
      {selectedTour && (
        <>
          <header
            className="datailTableRow bg-lightBlue cursor-pointer flex justify-between items-center px-4 py-2"
            onClick={() => handleDisplay("tours")}
          >
            <h2 className="uppercase font-medium text-xl">
              {t(`global.Tour.tour`)}
            </h2>
            <img
              id={"tours-arrow"}
              src={arrow}
              alt="arrow"
              className="w-4 transform rotate-180 transition-transform duration-200"
            />
          </header>

          <div className="overflow-x-auto">
            <table
              id="tours-body"
              className="table-auto w-full border-collapse  overflow-y-hidden transition-transform duration-200 origin-top rounded-lg"
            >
              <thead className="bg-gray-100">
                <tr>
                  <th className="text-sm font-semibold col-span-2 ml-4">
                    {t(`global.Tour.tour`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4">
                    {t(`global.Tour.return`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4">
                    {t(`global.Tour.hotel`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4">
                    {t(`global.Tour.category`)}
                  </th>
                  <th className="text-sm font-semibold col-span-2 ml-4">
                    {t(`global.Admin_Packages.Tour_Modal.meal_plan`)}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <TourDetailRow tour={selectedTour} />
              </tbody>
            </table>
          </div>
        </>
      )}
    </section>
  );
}
