import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function TourModal({ data }) {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg p-6 max-w-md w-full">
      <h2 className="text-xl font-semibold mb-4">
        {t(`global.Admin_Packages.Tour_Modal.departure_details`)}
      </h2>
      <table className="w-full text-left">
        <tbody>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.id`)}:
            </td>
            <td>{data.id}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.return_date`)}:
            </td>
            <td>{format(new Date(data.dateIn), "dd/MM/yyyy")}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.Departure_Date`)}:
            </td>
            <td>{format(new Date(data.dateOut), "dd/MM/yyyy")}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.hotels`)}:
            </td>
            <td>{data.hotels}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.category`)}:
            </td>
            <td>{data.category}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.meal_plan`)}:
            </td>
            <td>{data.meal_plan}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.days`)}:
            </td>
            <td>{data.days}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.nights`)}:
            </td>
            <td>{data.nights}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.stock`)}:
            </td>
            <td>{data.stock}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.original_stock`)}:
            </td>
            <td>{data.original_stock}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.availability`)}:
            </td>
            <td>{data.availability}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.comments`)}:
            </td>
            <td>{data.comments}</td>
          </tr>
          <tr>
            <td className="font-bold">
              {t(`global.Admin_Packages.Tour_Modal.package_id`)}:
            </td>
            <td>{data.id_package}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
