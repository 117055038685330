import { CallEndOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function CallButton({ number }) {
  const { t } = useTranslation();
  return (
    <a
      href={number ? `tel:${number}` : undefined}
      className="relative h-[24px] w-[24px] flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/call"
    >
      <CallEndOutlined
        sx={{
          color: "#A0AFCD",
          height: "18px",
          width: "18px",
        }}
        alt="call"
      />
      <span className="absolute whitespace-nowrap bg-white border -top-8 -left-10 transform -translate-x-1/2 py-0.5 px-1 rounded scale-0 transition-transform group-hover/call:scale-100">
        {number
          ? t(`global.Common_Words.phone`)
          : t(`global.Common_Words.no_phone`)}
      </span>
    </a>
  );
}
