import { useEffect } from "react";
import SEO from "../../utils/SEO";
import Head from "../head";
import PackageListing from "./PackageListing/PackageListing";
import { useParams } from "react-router-dom";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import Anicarga from "../anicarga";
import { useTourOperatorStore } from "../../store/useTourOperatorStore";
import useGetCurrencies from "../../services/useGetCurrencies";
import { usePackages } from "../../services/usePackages";

export default function Packages() {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { tour_op_id } = useParams();
  const { parsedHash } = useLocationQuery();
  const { currencies } = useGetCurrencies();
  const { loading, getPackagesStep2, packages } = usePackages();
  useEffect(() => {
    getPackagesStep2();
  }, [tourOP]);

  return (
    <div>
      <SEO title={"Paquetes"} />
      <Head />
      {loading ? (
        <div className=" flex justify-center items-center">
          <Anicarga />
        </div>
      ) : (
        <div className="mt-4">
          <PackageListing
            packages={packages}
            parsedHash={parsedHash}
            currencies={currencies}
            tour_op_id={tour_op_id}
          />
        </div>
      )}
    </div>
  );
}
