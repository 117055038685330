import { useTranslation } from "react-i18next";
import { useQuotes } from "../../../../../services/useQuotes";
import { toast } from "react-hot-toast";
import useReloadStore from "../../../../../store/useRealoadStore";
import { ModalGeneric } from "../../../../UI/ModalGeneric";
import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import ConfirmQuoteStatus from "./ConfirmQuoteStatus";
import { statusValues } from "../../../../../schemas/status";

export default function SelectQuoteStatus({ status, id, qto }) {
  const { t } = useTranslation();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { updateQuote } = useQuotes();
  const setReloadQuotes = useReloadStore((state) => state.setReloadQuotes);

  const handleStatusQuote = async (event) => {
    if (event.target.value === "book") {
      toggleVisibility();
    } else {
      try {
        await toast.promise(
          updateQuote({
            id: id,
            status: event.target.value,
          }),
          {
            loading: "Procesando...",
            success: "Estado Guardado",
            error: "Hubo un error, intentalo de nuevo!",
          },
        );
        setReloadQuotes((prev) => !prev);
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  return (
    <>
      <select
        value={status}
        className="border-2 border-gray2 rounded-lg p-1"
        onChange={handleStatusQuote}
      >
        {statusValues.map(({ value, color }) => (
          <option
            key={value}
            value={value}
            className="font-semibold"
            style={{ color }}
          >
            {t(`global.Admin_Quotes.${value}`)}
          </option>
        ))}
      </select>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <ConfirmQuoteStatus
          qto={qto}
          updateQuote={updateQuote}
          setReloadQuotes={setReloadQuotes}
          t={t}
          toggleVisibility={toggleVisibility}
        />
      </ModalGeneric>
    </>
  );
}
