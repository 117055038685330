import FeePriceSymbol from "../../packages/packageDetail/detailSections/tourSection/feeSection/FeePriceSymbol";

export default function FeeDetailRow({ fee }) {
  return (
    <tr className=" rounded-xl border-gray2 border-2">
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {fee?.base}
      </td>
      <td className="font-body text-center align-middle border-r-2  border-gray2 ">
        <p className="flex justify-center">
          <FeePriceSymbol
            currency_type_id={fee.currency_type_id}
            data={fee.fare}
          />
        </p>
      </td>
      <td className="font-body text-center align-middle border-r-2  border-gray2">
        <p className="flex justify-center">
          <FeePriceSymbol
            currency_type_id={fee.currency_type_id}
            data={fee.tax}
          />
        </p>
      </td>
      <td className="font-body text-center align-middle  ">
        <p className="flex justify-center">
          <FeePriceSymbol
            currency_type_id={fee.currency_type_id}
            data={fee.price}
          />
        </p>
      </td>
    </tr>
  );
}
