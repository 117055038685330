import SEO from "../../../utils/SEO";
import PageTitle from "../../UI/PageTitle";
import TableQuotes from "./tableQuote/TableQuotes";
import { Toaster } from "react-hot-toast";

export default function AdminQuotes() {
  return (
    <div className="mt-8">
      <SEO title={"Mis Cotizaciones"} />
      <Toaster />
      <PageTitle
        title={"my_quotes_title"}
        subTitle={"my_quotes_subtitle"}
        route={"panel/sales/quotes/my-quotes/add-quote"}
        btnText={"my_quotes_btn"}
        buttonVisivility={true}
      />
      <TableQuotes />
    </div>
  );
}
