import { useState, useEffect } from "react";
import useGetCurrencies from "../../../../../../services/useGetCurrencies";
import ActivateEditingButton from "./ActivateEditingButton";
import EditFeeButton from "./EditFeeButton";
import FeeInput from "./feeFormInputs/FeeInput";
import SelectFeeCurrency from "./feeFormInputs/SelectFeeCurrency";
import WarningDelete from "../../../../../UI/WarningDelete";
import DuplicateFee from "./DuplicateFee";
import { useFees } from "../../../../../../services/useFees";
import DeleteButton from "../../../../../UI/actionButtons/DeleteButton";

export default function TableFeeBody({
  index,
  fee,
  tourId,
  AllowDelete,
  package_id,
}) {
  const [edit, setEdit] = useState(true); // Initialize with editing disabled
  const { currencies } = useGetCurrencies();
  const { deleteFee, getFees } = useFees();
  const [error, setError] = useState("");
  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  const [feeEdit, setFeeEdit] = useState({
    id: fee.id,
    base: fee.base,
    fare: fee.fare,
    tax: fee.tax,
    price: fee.price,
    currency_type_id: fee.currency_type_id,
  });

  // Recalculate price in real time when tax or fare change
  useEffect(() => {
    setFeeEdit((prevFeeEdit) => ({
      ...prevFeeEdit,
      price:
        parseFloat(prevFeeEdit.fare || 0) + parseFloat(prevFeeEdit.tax || 0),
    }));
  }, [feeEdit.tax, feeEdit.fare]);

  return (
    <>
      <tr
        className={`text-center bg-white border-2 border-lightBlue hover:bg-lightBlue2`}
      >
        <td className="border-2 border-lightBlue p-2">{index + 1}</td>
        <td className="border-2 border-lightBlue p-2">
          <FeeInput
            edit={edit}
            fee={fee}
            feeEdit={feeEdit}
            setFeeEdit={setFeeEdit}
            type="base"
          />
          {error && <h1 className="text-red-500">Base es requerida</h1>}
        </td>
        <td className="border-2 border-lightBlue p-2">
          <FeeInput
            edit={edit}
            fee={fee}
            feeEdit={feeEdit}
            setFeeEdit={setFeeEdit}
            type="fare"
          />
        </td>
        <td className="border-2 border-lightBlue p-2">
          <FeeInput
            edit={edit}
            fee={fee}
            feeEdit={feeEdit}
            setFeeEdit={setFeeEdit}
            type="tax"
          />
        </td>
        <td className="border-2 border-lightBlue p-2">
          {feeEdit.price} {/* Display the updated price in real-time */}
        </td>
        <td className="p-2">
          {edit ? (
            translateCurrency(Number(fee.currency_type_id))
          ) : (
            <SelectFeeCurrency feeEdit={feeEdit} setFeeEdit={setFeeEdit} />
          )}
        </td>
        <td className="p-2 flex justify-end">
          {edit ? (
            <></>
          ) : (
            <EditFeeButton
              edit={edit}
              setFeeEdit={setFeeEdit}
              feeEdit={feeEdit}
              fee={fee}
              setEdit={setEdit}
              tourId={tourId}
              setError={setError}
            />
          )}
          <ActivateEditingButton edit={edit} setEdit={setEdit} />
          <DuplicateFee
            fee={fee}
            tourId={tourId}
            package_id={package_id}
            setFeeEdit={setFeeEdit}
          />
          {AllowDelete ? (
            <DeleteButton
              data={fee}
              delete={deleteFee}
              get={getFees}
              type="fee"
            />
          ) : (
            <WarningDelete message={"delete_fee_not_allowed"} />
          )}
        </td>
      </tr>
    </>
  );
}
