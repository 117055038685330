import { PASSENGER_DICTIONARY } from "../../../../utils/dictionaries/passengerDictionary";
import { useTranslation } from "react-i18next";

export default function PackagePassengerSection({
  numberOfPassengers,
  handlePassenger,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2 p-2 rounded-lg border">
      {Object.entries(numberOfPassengers).map((passenger) => {
        const { key, label, description, min } =
          PASSENGER_DICTIONARY[passenger[0]];
        return (
          <div className="flex justify-between items-center" key={key}>
            <div>
              <h2 className="font-medium">
                {t(`global.Passenger_Dictionary.${label}`)}
              </h2>
              <p className="text-xs text-gray">
                {" "}
                {t(`global.Passenger_Dictionary.${description}`)}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <button
                type="button"
                onClick={() => handlePassenger(key, min, "-")}
                className="bg-lightGray3 border w-7 h-7 rounded-full text-sm font-[700]"
              >
                -
              </button>
              <p className="text-xl">{passenger[1]}</p>
              <button
                type="button"
                onClick={() => handlePassenger(key, min, "+")}
                className="bg-lightGray3 border w-7 h-7 rounded-full text-sm font-[700]"
              >
                +
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
