import EditButton from "../../../../UI/actionButtons/EditButton";
import EmailButton from "../../../../suppliers/tableSupplier/tableRow/tableButtons/EmailButton";
import { useParams } from "react-router-dom";
import CallButton from "../../../../UI/actionButtons/CallButton";
import WhatsappButton from "../../../../UI/actionButtons/WhatsappButton";

export default function TableActionsQuotes({ qto }) {
  const { tour_op_id } = useParams();
  return (
    <div className="flex flex-row items-center gap-2">
      <EditButton
        tour_op_id={tour_op_id}
        data={qto}
        route={`panel/sales/quotes/my-quotes/update-quote`}
      />
      <EmailButton suppEmail={qto.email} />
      <WhatsappButton number={qto.whatsapp} size={18} />
      <CallButton number={qto.whatsapp} />
    </div>
  );
}
