import { useTranslation } from "react-i18next";
import { useBookings } from "../../../../services/useBookings";
export default function DeleteBookingModal({
  selectedBooking,
  selectedModal,
  toggleVisibility,
  getBookingsByType,
  setSelectedBooking,
  type,
}) {
  const { t } = useTranslation();
  const { deleteBooking } = useBookings();
  return (
    <>
      {selectedBooking && selectedModal === "delete" && (
        <div className="flex flex-col items-center gap-2">
          <h2>
            {t(`global.Admin_Bookings.delete_booking`)}{" "}
            <b>{selectedBooking.id}</b>?
          </h2>
          <div className="flex justify-center gap-2">
            <button
              className="py-1 px-2 rounded font-medium border border-gray2"
              onClick={toggleVisibility}
            >
              {t(`global.Actions.cancel`)}
            </button>
            <button
              className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
              onClick={() => {
                deleteBooking({ type, id: selectedBooking.id })
                  .then(() => {
                    getBookingsByType();
                  })
                  .finally(() => {
                    toggleVisibility();
                    setSelectedBooking();
                  });
              }}
            >
              {t(`global.Actions.delete`)}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
