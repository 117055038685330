import { format } from "date-fns";
import numberWithCommas from "../../utils/numberWithCommas";
import { defaultDateFormat } from "../../utils/commons";

export default function PasajeroRow({ pasajero, passengerType }) {
  // Safe access to TravelerDocument
  const travelerDocument = pasajero?.TravelerDocument;

  if (!travelerDocument?.doc_type || !travelerDocument?.number) return;

  const documentNumber =
    travelerDocument.doc_type === "IDENTITY_CARD"
      ? numberWithCommas(Number(travelerDocument.number), 0)
      : travelerDocument.number;

  return (
    <tr className=" rounded-xl border-gray2 border-2">
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {pasajero?.first_name}
      </td>
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {pasajero?.last_name}
      </td>
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {documentNumber}
      </td>
      <td className="font-body text-center align-middle   border-r-2  border-gray2">
        {pasajero?.date_of_birth
          ? format(new Date(pasajero.date_of_birth), defaultDateFormat)
          : ""}
      </td>
      <td className="font-body text-center align-middle   ">{passengerType}</td>
    </tr>
  );
}
