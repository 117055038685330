import { useTranslation } from "react-i18next";

export default function TableSuppliersHeader({ cols }) {
  const { t } = useTranslation();
  return (
    <>
      <thead className="rounded-lg">
        <tr className="bg-lightBlue  ">
          {cols.map((header, index) => (
            <th
              className="font-medium text-brightBlue text-center px-4"
              key={index}
            >
              {t(`global.Admin_Suppliers.Headers.${header}`)}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
}
