import axios from "axios";
import { decodeToken } from "react-jwt";
import {
  getLocalStorageValue,
  updateLocalStorage,
  deleteLocalStorageItem,
  getSessionStorageValue,
  updateSessionStorage,
  deleteSessionStorageItem,
} from "../../hooks/useCookie";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStore } from "../../store/useUserStore";
import { useTourOperatorStore } from "../../store/useTourOperatorStore";

export const useAuth = (keepRoute) => {
  const { tour_op_id } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    token: getLocalStorageValue("token") || getSessionStorageValue("token"),
    userData:
      getLocalStorageValue("token") || getSessionStorageValue("token")
        ? decodeToken(
            getLocalStorageValue("token") || getSessionStorageValue("token"),
          )
        : {},
  });

  const clearUser = useUserStore((st) => st.clearUser);
  const storeUser = useUserStore((st) => st.storeUser);

  const login = async ({ email, password, keepSession }) => {
    setLoading(true);
    const BASE_URL = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(BASE_URL + "v2/users/login", {
        user: email,
        password,
      });

      const token = "Bearer " + response?.data?.token;
      let userDecoded = decodeToken(response?.data?.token);

      // ERROR SI EL USUARIO NO CORRESPONDE CON LA URL DE LA OPERADORA
      if (
        tourOP &&
        userDecoded?.id !== 1 &&
        userDecoded?.tour_op_id !== tourOP?.id
      ) {
        throw new Error();
      }

      setUser({
        user: {
          userData: userDecoded,
          token: token,
        },
      });
      // console.log(userDecoded)
      storeUser(userDecoded);

      if (keepSession) {
        updateLocalStorage("token", token);
        updateLocalStorage("userData", userDecoded);
        updateLocalStorage("tokenTimestamp", new Date().getTime());
      } else {
        updateSessionStorage("token", token);
        updateSessionStorage("userData", userDecoded);
      }

      token &&
        !keepRoute &&
        navigate(
          userDecoded.role_id === 3
            ? `/${tour_op_id ?? "walltrip"}/packages`
            : `/${tour_op_id ?? "walltrip"}/panel/sales/bookings/flight`,
        );

      setLoading(false);
      return { shouldCloseModal: keepRoute || userDecoded.role_id === 3 };
    } catch (error) {
      setLoading(false);
      setErrors(
        error?.response?.data?.errors ?? [
          {
            path: "tour_op",
            msg: "El usuario no corresponde con la URL de la operadora.",
          },
        ],
      );
      throw new Error(error);
    }
  };

  const logOut = () => {
    deleteLocalStorageItem("token");
    deleteLocalStorageItem("userData");
    deleteSessionStorageItem("token");
    deleteSessionStorageItem("userData");
    setUser({
      user: {
        userData: {},
        token: null,
      },
    });

    clearUser();
  };

  return {
    user,
    login,
    logOut,
    errors,
    loading,
  };
};
