import React from 'react'
import lupa from "../../../../../iconos/lupa.png";
export default function SearchFilter({setSearchTerm,searchTerm}) {
  return (
    <div className="relative border border-[#8D99AE] flex  px-2 py-2 rounded-lg items-center">
    <div className="flex items-center justify-center rounded-md px-2 py-3 bg-lightBlue/80">
      <img className="w-4" src={lupa} alt="origen" />
    </div>

    <div
      className={`z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md `}
    >
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Nombre"
        className=" px-3 pt-1 m-0 w-full rounded cursor-pointer whitespace-pre-wrap transition-colors hover:bg-slate-100 focus:outline-none shadow-none "
      />
    </div>
  </div>
  )
}
