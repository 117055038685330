import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SEO from "../../utils/SEO";
import { format } from "date-fns";
import { MisActividadesBusqueda } from "./MisActividadesBusqueda";
import TableHeaderActividades from "./TableHeaderActividades";
import TableRowActividades from "./TableRowActividades";
import { usePackages } from "../../services/usePackages";
import { ModalGeneric } from "../UI/ModalGeneric";
import Pagination from "../UI/Pagination";

const useToggleVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  return [isVisible, toggleVisibility];
};

export default function MisActividades() {
  const { packages, loading, getPackages, createPackage, deletePackage } =
    usePackages();
  const { operador } = useParams();

  const [searcherValue, setSearcherValue] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedModal, setSelectedModal] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isVisible, toggleVisibility] = useToggleVisibility();

  useEffect(() => {
    !isVisible && getPackages();
  }, [isVisible]);

  const openModal = (pack) => {
    setSelectedPackage(pack);
    toggleVisibility();
  };

  const lowerSearch = searcherValue.toLowerCase();
  const filteredPaquetes = packages
    .filter((pack) => {
      const { name, descode, oricode } = pack;
      return (
        name.toLowerCase().includes(lowerSearch) ||
        descode.toLowerCase().includes(lowerSearch) ||
        oricode.toLowerCase().includes(lowerSearch)
      );
    })
    .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

  return (
    <>
      <SEO title={"Mis Actividades"} />
      <main className="flex flex-col w-full mt-8 font-body">
        <header className="flex justify-between items-center mb-4">
          <span>
            <h1 className="font-[700] text-darkBlue text-3xl">
              Mis Actividades
            </h1>
            <p className="text-darkBlue">
              Controlá, Gestioná y Personalizá cada uno de tus Actividades.
            </p>
          </span>
          <Link
            className="text-center button bg-darkBlue text-white w-44 hover:shadow-md transition-all"
            to={`/${operador}/panel/activities/add-activity`}
          >
            Agregar Actividad
          </Link>
        </header>

        <section className="p-4 border-2 border-gray2 bg-white rounded-lg">
          <MisActividadesBusqueda
            searcher={[searcherValue, setSearcherValue]}
          />

          <section>
            <TableHeaderActividades />

            {!loading && filteredPaquetes.length === 0 ? (
              <span className="text-red-600 mt-4 text-lg">
                No se encontraron actividades
              </span>
            ) : (
              filteredPaquetes.map((pack, index) => (
                <TableRowActividades
                  key={pack.id}
                  index={index}
                  pack={pack}
                  openModal={openModal}
                  setSelectedModal={setSelectedModal}
                />
              ))
            )}
          </section>

          <ModalGeneric show={isVisible} toggle={toggleVisibility}>
            {/* modal "ver paquete" */}
            {selectedPackage && selectedModal === "view" && (
              <div className="flex flex-col gap-2 -mt-4">
                <h2 className="font-medium text-darkBlue text-2xl mb-2">
                  {selectedPackage.name}
                </h2>
                <div className="flex gap-8">
                  <div className="flex flex-col h-full justify-between">
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        value={selectedPackage.oricode}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                      <input
                        value={selectedPackage.descode}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                      <input
                        value={format(
                          new Date(selectedPackage.fsalida),
                          "dd/MM/yy",
                        )}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                      <input
                        value={format(
                          new Date(selectedPackage.fvuelta),
                          "dd/MM/yy",
                        )}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                      <input
                        value={`$ ${selectedPackage.price}`}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                      <input
                        value={`${selectedPackage.cupos} Cupos Totales`}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="description"
                        className="text-darkBlue text-lg font-medium"
                      >
                        Descripción
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        value={selectedPackage.description}
                        className="w-full px-4 py-2 border border-gray2 rounded"
                        readOnly
                      />
                    </div>
                  </div>
                  {selectedPackage.photo && (
                    <img
                      src={selectedPackage.photo}
                      alt={`${selectedPackage.name} foto`}
                      className="max-h-72 max-w-[50%] rounded"
                    />
                  )}
                </div>
              </div>
            )}

            {/* modal "copiar paquete" */}
            {selectedPackage && selectedModal === "copy" && (
              <div className="flex flex-col items-center gap-2">
                <h2>
                  ¿Estás seguro de copiar <b>{selectedPackage.name}</b>?
                </h2>
                <div className="flex justify-center gap-2">
                  <button
                    className="py-1 px-2 rounded font-medium border border-gray2"
                    onClick={toggleVisibility}
                  >
                    Cancelar
                  </button>
                  <button
                    className="py-1 px-2 rounded font-medium border border-brightBlue bg-brightBlue text-white"
                    onClick={() => {
                      const { ...restOfPack } = selectedPackage;

                      createPackage({
                        paquete: {
                          ...restOfPack,
                          name: restOfPack.name + " (1)",
                        },
                      });
                      setTimeout(() => {
                        toggleVisibility();
                      }, 1000);
                    }}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            )}

            {/* modal "eliminar paquete" */}
            {selectedPackage && selectedModal === "delete" && (
              <div className="flex flex-col items-center gap-2">
                <h2>
                  ¿Estás seguro de eliminar <b>{selectedPackage.name}</b>?
                </h2>
                <div className="flex justify-center gap-2">
                  <button
                    className="py-1 px-2 rounded font-medium border border-gray2"
                    onClick={toggleVisibility}
                  >
                    Cancelar
                  </button>
                  <button
                    className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                    onClick={() => {
                      deletePackage(selectedPackage.id);
                      setTimeout(() => {
                        toggleVisibility();
                      }, 1000);
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            )}
          </ModalGeneric>
        </section>

        <Pagination
          items={packages}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          page={page}
          setPage={setPage}
        />
      </main>
    </>
  );
}
