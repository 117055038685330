import React from "react";
import useHotelOfferStore from "../../HotelStore";
import { StarTwoTone } from "@mui/icons-material";
import HotelCardImage from "./hotelCardItems/HotelCardImage";
import { useToggleVisibility } from "../../../../hooks/useToggleVisibility";
import HotelCardBody from "./hotelCardItems/HotelCardBody";
import HotelCardTabs from "./hotelCardItems/HotelCardTabs";
import { useLocationQuery } from "../../../../hooks/useLocationQuery";
import { useParams } from "react-router-dom";

export default function HotelCard({ hotel, pax }) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { parsedHash, changeQuery } = useLocationQuery();
  const { tour_op_id } = useParams();
  const { HotelInfo, HotelOptions } = hotel;
  const { Name, HotelCategory, TimeInformation, Address, Latitude, Longitude } =
    HotelInfo[0];
  const { Prices, HotelRooms } = HotelOptions[0].HotelOption[0];
  const { CheckIn, CheckOut } = TimeInformation[0].CheckTime[0].$;
  const { $, TotalFixAmounts } = Prices?.[0].Price[0];

  const setHotelOffer = useHotelOfferStore((state) => state.setHotelOffer);
  // Function to redirect to the hotel request page and set the entry to the store
  const handleReserve = () => {
    const offer = {
      ...hotel,
      checkInDay: parsedHash?.departureDate,
      checkOutDay: parsedHash?.returnDate,
    };
    setHotelOffer(offer);

    // Log the hotel offer being set (for debugging)
    console.log("Hotel offer being set:", offer);

    // Redirect to the hotel request page
    changeQuery({
      route: `${tour_op_id}/hotels-request`,
      go: true,
    });
  };

  const stars = new Array(Number(HotelCategory?.[0]?._?.at(0))).fill(
    <StarTwoTone className="text-yellow-500" />
  );

  return (
    <div className="font-body flex flex-col justify-between w-full gap-4 bg-lightBlue/80 rounded-lg p-4 lg:hover:scale-105">
      {/* This is the top part of the item */}
      <div className="flex gap-4 grow">
        <HotelCardImage image={hotel.image} />
        <HotelCardBody
          Name={Name}
          stars={stars}
          TotalFixAmounts={TotalFixAmounts}
          HotelRooms={HotelRooms}
          CheckIn={CheckIn}
          CheckOut={CheckOut}
          toggle={toggleVisibility}
          visible={isVisible}
          $={$}
          Address={Address}
          Longitude={Longitude}
          Latitude={Latitude}
        />
      </div>
      <div className={`${isVisible ? "h-fit" : " h-0"} overflow-hidden`}>
        <HotelCardTabs
          pax={pax}
          hotel={hotel}
          HotelInfo={HotelInfo[0]}
          HotelOption={HotelOptions[0].HotelOption[0]}
          handleReserve={handleReserve}
        />
      </div>
      <div className={`${isVisible ? "h-fit" : " h-0"} overflow-hidden`}></div>
    </div>
  );
}
