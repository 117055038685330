import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";

export default function WhatsappButton({ size, number }) {
  const { t } = useTranslation();
  return (
    <a
      className="relative mx-0.5 hover:bg-[#EEE] rounded-full transition-colors group/send"
      target="_blank"
      href={number ? `https://wa.me/${number}` : undefined}
      rel="noreferrer"
    >
      <FaWhatsapp color="#A0AFCD" fontSize={size} />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-20 z-[9999] py-0.5 px-1 rounded scale-0 transition-transform group-hover/send:scale-100">
        {number
          ? t(`global.Common_Words.whatsapp`)
          : t(`global.Common_Words.no_whatsapp`)}
      </span>
    </a>
  );
}
