import { z } from "zod";
export const ruleSchema = z.object({
  name: z.string().min(1, { message: "name_required" }),
  operation_fiscal: z.string().min(1, { message: "operation_fiscal_required" }),
  rate_type: z.string().min(1, { message: "rate_type_required" }),
  number: z.string().min(1, { message: "number_required" }),
  operation: z.string().min(1, { message: "operation_required" }),
  variable: z.string().min(1, { message: "variable_required" }),
  product: z.string().min(1, { message: "product_required" }),
  active: z.boolean(),
  running: z.boolean(),
  currency_type_id: z.number().min(1, { message: "currency_type_id_required" }),
});
