import { useTranslation } from "react-i18next";

export default function TravelerHeader({
  handleSelectAll,
  travelers,
  page,
  selectedRows,
}) {
  const { t } = useTranslation();
  return (
    <tr>
      <th className="py-2 px-4 text-left font-medium text-brightBlue">
        <input
          className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            travelers.slice(page * 10, (page + 1) * 10).length > 0 &&
            travelers
              .slice(page * 10, (page + 1) * 10)
              .every((traveler) =>
                selectedRows.some((selected) => selected.id === traveler.id),
              )
          }
        />
      </th>

      <th className="py-2 px-4 text-left font-medium text-brightBlue ">
        {t(`global.Admin_Travelers.header.col_1`)}
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        {t(`global.Admin_Travelers.header.col_2`)}
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        {t(`global.Admin_Travelers.header.col_3`)}
      </th>
      {/* <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
                Próx Viaje
              </th> */}
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        {t(`global.Admin_Travelers.header.col_4`)}
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        {t(`global.Admin_Travelers.header.col_5`)}
      </th>

      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        {t(`global.Admin_Travelers.header.col_6`)}
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        {t(`global.Admin_Travelers.header.col_7`)}
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue">
        {t(`global.Admin_Travelers.header.col_8`)}
      </th>
    </tr>
  );
}
