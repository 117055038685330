import { format } from "date-fns";
import FeePriceSymbol from "../../../packages/packageDetail/detailSections/tourSection/feeSection/FeePriceSymbol";

export default function SelectorSection({
  errors,
  packages,
  register,
  fees,
  tours,
  t,
  onChangePackage,
  onChangeTour,
  watch,
}) {
  return (
    <div className="flex flex-col">
      <div>
        {" "}
        <div className="mt-4">
          <h1 className="font-semibold text-xl">
            {t(`global.Booking_Form.type`)}
          </h1>
          <select
            className="addPackInput w-[310px]"
            name="type"
            defaultValue={"package"} // Default value set to empty string (interpreted as null)
            {...register("type")}
          >
            {/* Null option */}
            <option value="package">Paquetes</option>
            {/* <option value="flight">Vuelos</option>
            <option value="hotel">Hoteles</option> */}
            {/* <option value="cruise">Crusero</option> */}
          </select>
          {errors?.type && (
            <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
              <p> {t(`global.Quote_Form.${errors?.type.message}`)}</p>
            </div>
          )}
        </div>
        {packages && (
          <div className="mt-4">
            <h1 className="font-semibold text-xl">
              {t(`global.Booking_Form.package`)}
            </h1>
            <select
              className="addPackInput w-[310px]"
              value={watch("package")}
              {...register("package", {
                onChange: (e) => onChangePackage(e.target.value),
              })}
            >
              {packages?.map((packageItem, index) => (
                <option
                  key={index}
                  value={JSON.stringify(packageItem)}
                  className="font-semibold"
                >
                  {packageItem.id} - {packageItem.title}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="flex-col">
        {tours.length > 0 && (
          <div className="mt-4">
            <h1 className="font-semibold text-xl">
              {t(`global.Booking_Form.tour`)}
            </h1>
            <select
              className="addPackInput w-[310px]"
              value={watch("tour")}
              {...register("tour", {
                onChange: (e) => onChangeTour(e.target.value),
              })}
            >
              {tours?.map((tour, index) => (
                <option
                  key={index}
                  value={JSON.stringify(tour)}
                  className="font-semibold"
                >
                  {tour?.dateIn
                    ? `${format(new Date(tour.dateIn), "dd/MM/yyyy")} - ${format(new Date(tour.dateOut), "dd/MM/yyyy")}`
                    : "Sin Tours"}
                </option>
              ))}
            </select>
          </div>
        )}

        {fees.length > 0 && (
          <div className="mt-4">
            <h1 className="font-semibold text-xl">
              {t(`global.Booking_Form.fee`)}
            </h1>
            <select
              className="addPackInput w-[300px]"
              value={watch("fee")}
              {...register("fee")}
            >
              {fees?.map((fee, index) => (
                <option
                  key={index}
                  value={JSON.stringify(fee)}
                  className="font-semibold"
                >
                  {` ${fee?.base} - `}
                  <FeePriceSymbol
                    data={fee.price}
                    currency_type_id={fee.currency_type_id}
                  />
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}
