export const taxesTypes = {
  ADULT: "Adulto",
  HELD_INFANT: "Bebé",
  CHILD: "Menor",
  SUPPLIER: "Proveedor",
  TICKETING: "Ticketeria",
};

export const passengerTypes = {
  ADULT: "Adulto",
  CHILD: "Menor",
  HELD_INFANT: "Bebé",
};
export const passengersTypes = {
  ADULT: "adult",
  CHILD: "children",
  HELD_INFANT: "infant",
};
export const defaultDateFormat = "dd/MM/yyyy";
