import React from "react";
import SearchFilter from "./hotelFilters/SearchFilter";
import StarFilter from "./hotelFilters/StarFilter";
import PromotionsFilter from "./hotelFilters/PromotionsFilter";
import FeeFilter from "./hotelFilters/FeeFilter";
import PointOfInterestFilter from "./hotelFilters/PointOfInterestFilter";
import MaxDistanceFilter from "./hotelFilters/MaxDistanceFilter";
import AmenityFilter from "./hotelFilters/AmenityFilter";
import ServiceFilter from "./hotelFilters/ServiceFilter";

export default function HotelListingFilters({
  hotels,
  setSearchTerm,
  searchTerm,
  setFilters,
  filters,
  
}) {
  const handleDisplay = (item) => {
    const body = document.getElementById(`${item}-body`);
    const arrow = document.getElementById(`${item}-arrow`);
    body.classList.toggle("scale-y-0");
    body.classList.toggle("h-0");
    arrow.classList.toggle("-rotate-180");
    arrow.classList.toggle("rotate-0");
  };

  return (
    <div className="flex-col">
      <div>
        <SearchFilter setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
      </div>
      <div>
        <div className="flex inline-flex items-center">
          <h2 className="font-medium p-2 text-xl">Filtros</h2>
          <button
            className="bg-transparent text-blue-500 text-xs mt-1"
            onClick={(e) =>setFilters({
                stars: ""

            })}
          >
            Borrar Filtros
          </button>
        </div>
        <PromotionsFilter handleDisplay={handleDisplay} />
        <FeeFilter handleDisplay={handleDisplay} />
        <StarFilter
          handleDisplay={handleDisplay}
          filters={filters}
          setFilters={setFilters}
        />
        <PointOfInterestFilter
          handleDisplay={handleDisplay}
          filters={filters}
          setFilters={setFilters}
        />
        <MaxDistanceFilter
          handleDisplay={handleDisplay}
          filters={filters}
          setFilters={setFilters}
        />
        <AmenityFilter handleDisplay={handleDisplay} />
        <ServiceFilter handleDisplay={handleDisplay} />
      </div>
    </div>
  );
}
