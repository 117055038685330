import { useEffect, useMemo, useState } from "react";
import Pagination from "../../UI/Pagination";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import BookingTableSearcher from "./BookingTableSearcher";
import { useParams } from "react-router-dom";
import useSendEmail from "../../../services/useSendEmail";
import BookingTables from "../bookingTable/BookingTables";
import { useStoreSearchInput } from "../../../store/useStoreSearchInput";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import { useBookings } from "../../../services/useBookings";
import useGetCurrencies from "../../../services/useGetCurrencies";
import { ModalGeneric } from "../../UI/ModalGeneric";
import ModalBooking from "./bookingsModal/ModalBooking";

const generateEmailBodyForOp = (reserva, value) => {
  // Mapping of booking statuses to labels
  const bookingStatusLabels = {
    allBookings: "Todas las Reservas",
    pending: "Pendiente",

    "for-ticketing": "Ok p Emisión",
    "to-ticket": "Emitir",
    confirmed: "Confirmado",
  };

  // Get the label for the current value
  const statusLabel = bookingStatusLabels[value] || "Estado desconocido"; // Fallback if value is not in the mapping

  // const currentHREF = window.location.href.split("/").slice(0, -4).join("/");

  return `
    <h3>Actualización en estado de reserva!</h3>
    <h2>Estado de reserva: ${statusLabel}</h2>
    <h3>Nº de Reserva: ${reserva.id}</h3>
  `;
};
export default function BookingTable({ bookingStatus, path }) {
  const { type } = useParams();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { currencies } = useGetCurrencies();
  // const { sendEmail } = useSendEmail();
  const { bookings, updateBooking, getFlightBookings, getPackageBookings } =
    useBookings();

  const searchInput = useStoreSearchInput((st) => st.searchInput);
  const setSearchInput = useStoreSearchInput((st) => st.setSearchInput);
  const [filters, setFilters] = useState({
    radioDate: "creation",
    inputDate: "",
    inputStatus: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedBooking, setSelectedBooking] = useState();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { sendEmail } = useSendEmail();

  const getBookingsByType = () => {
    if (type === "flight") {
      return getFlightBookings();
    } else if (type === "package") {
      return getPackageBookings();
    } else if (type === "hotel") {
      return;
    }
  };

  useEffect(() => {
    setShowError(false);
  }, [isVisible]);

  useEffect(() => {
    getBookingsByType();
  }, [type, tourOP]);

  const bookingStatusKeys = bookingStatus.map(({ key }) => key);
  // FILTRAR TODOS LOS BOOKINGS CON STATUS DE "QUOTE" O "RESERVA"
  const allBookings = useMemo(
    () => bookings?.filter(({ status }) => bookingStatusKeys.includes(status)),
    [bookings, type],
  );

  const filteredBookings =
    allBookings &&
    allBookings
      .filter((booking) => {
        const { id, Travelers, travelers_packages, Packages } = booking;
        const loweredValue = searchInput?.toLowerCase();
        const travelersToUse =
          type === "flight" ? Travelers : travelers_packages;

        return (
          id?.toString()?.includes(loweredValue) ||
          travelersToUse?.[0]?.first_name
            ?.toLowerCase()
            ?.includes(loweredValue) ||
          travelersToUse?.[0]?.last_name
            ?.toLowerCase()
            ?.includes(loweredValue) ||
          Packages?.[0]?.origin_code?.toLowerCase()?.includes(loweredValue) ||
          Packages?.[0]?.destination_code?.toLowerCase()?.includes(loweredValue)
        );
      })
      .filter((booking) => {
        const { creation_date, Flights } = booking;

        // If it's a "flight" booking, check for Flights
        if (booking.type === "flight") {
          if (!Array.isArray(Flights) || Flights.length === 0) {
            return true; // Skip this booking if Flights is missing or invalid
          }

          const { lastTicketingDate, itineraries } =
            JSON.parse(Flights[0]?.options || "{}") ?? {};
          const { at } = itineraries?.[0]?.segments?.[0]?.departure ?? {};

          // Handle the date filters for flights
          if (!filters.inputDate || !filters.radioDate) {
            return true;
          }
          if (filters.radioDate === "creation") {
            return creation_date.split("T")[0] === filters.inputDate;
          }
          if (filters.radioDate === "departure") {
            return at?.split("T")[0] === filters.inputDate;
          }
          return lastTicketingDate === filters.inputDate;
        }

        // If it's a "package" booking, handle the Packages filter
        if (booking.type === "package") {
          // Handle date filters for packages here (e.g., check for 'creation_date' or any other date fields)
          if (!filters.inputDate) return true;
          return creation_date.split("T")[0] === filters.inputDate; // Example for package filtering
        }

        return true;
      })
      .filter((booking) => {
        if (
          filters.inputStatus === "allBookings" ||
          filters.inputStatus === ""
        ) {
          return true;
        }

        return booking.status === filters.inputStatus;
      });

  const openModal = (booking, modal) => {
    setSelectedBooking(booking);
    toggleVisibility();
    setSelectedModal(modal);
  };

  const mutateEstadoReserva = ({ reserva, e }) => {
    if (e.target.value === "confirmed" && type === "flight") {
      toggleVisibility();
      setSelectedBooking(reserva);
      setSelectedModal("confirm_PNR");
    } else {
      updateBookingState(reserva, e.target.value).finally(() =>
        getBookingsByType(),
      );
    }
  };

  const mutateEstadoReservaPaquete = ({ reserva, e }) => {
    if (e.target.value === "confirmed" && type === "package") {
      toggleVisibility();
      setSelectedBooking(reserva);
      setSelectedModal("confirm_PACKAGE");
    } else {
      updateBookingState(reserva, e.target.value).finally(() =>
        getBookingsByType(),
      );
    }
  };

  const updateBookingState = (reserva, value) => {
    updateBooking({
      booking: reserva,
      update: {
        booking: {
          status: value,
          comment: `This booking is ${value}`,
        },
      },
    })
      .then(() =>
        // Send the email after the booking is successfully updated
        sendEmail({
          type: "Gmail",
          tour_op_id: 1,
          options: {
            from: "wearewalltrip@gmail.com",
            bcc: tourOP.email,
            subject: "Envío Nueva Reserva",
            html: generateEmailBodyForOp(reserva, tourOP.id, value), // Ensure correct arguments
          },
        }),
      )
      .catch(() => {
        throw new Error("Error updating the booking and sending the email.");
      });
  };

  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <>
      <section className="p-4 border-2 border-gray2 bg-white rounded-lg mb:-ml-16 lg:ml-0">
        <BookingTableSearcher
          selectedRows={selectedRows}
          filters={filters}
          setFilters={setFilters}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          bookingStatus={bookingStatus}
          page={page}
          type={type}
        />

        <BookingTables
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          type={type}
          filteredBookings={filteredBookings}
          page={page}
          itemsPerPage={itemsPerPage}
          bookingStatus={bookingStatus}
          bookingStatusKeys={bookingStatusKeys}
          openModal={openModal}
          mutateEstadoReservaPaquete={mutateEstadoReservaPaquete}
          translateCurrency={translateCurrency}
          path={path}
          mutateEstadoReserva={mutateEstadoReserva}
        />
      </section>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <ModalBooking
          selectedBooking={selectedBooking}
          selectedModal={selectedModal}
          toggleVisibility={toggleVisibility}
          getBookingsByType={getBookingsByType}
          setSelectedBooking={setSelectedBooking}
          showError={showError}
          setShowError={setShowError}
          setLoading={setLoading}
          updateBookingState={updateBookingState}
          loading={loading}
          type={type}
        />
      </ModalGeneric>

      <Pagination
        items={filteredBookings}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
