import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { featureSchema } from "../../schemas/featureSchema";
import { Toaster } from "react-hot-toast";

export default function EditFeature({ editFunctionality, functionality }) {
  const { id, name, description, status, link, vote } = functionality;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(featureSchema),
    defaultValues: {
      name,
      description,
      status,
      link,
      vote,
    },
  });

  const onSubmit = async (data) => {
    editFunctionality({ ...data, id });
  };

  return (
    <form
      className="grid grid-cols-2 gap-6"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Toaster />
      <label htmlFor="name" className="relative">
        <p>Nombre</p>
        <input name="name" {...register("name")} className="addPackInput" />
        <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
          <ErrorMessage errors={errors} name="name" as="p" />
        </div>
      </label>

      <label htmlFor="link" className="relative">
        <p>Link</p>
        <input name="link" {...register("link")} className="addPackInput" />
        <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
          <ErrorMessage errors={errors} name="link" as="p" />
        </div>
      </label>

      <label htmlFor="description" className="relative col-span-2">
        <p>Descripción</p>
        <input
          name="description"
          {...register("description")}
          className="addPackInput w-full"
        />
        <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
          <ErrorMessage errors={errors} name="description" as="p" />
        </div>
      </label>

      <label htmlFor="status" className="relative">
        <p>Estado</p>
        <input name="status" {...register("status")} className="addPackInput" />
        <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
          <ErrorMessage errors={errors} name="status" as="p" />
        </div>
      </label>

      <label htmlFor="vote" className="relative">
        <p>Voto</p>
        <select
          name="vote"
          {...register("vote")}
          className="addPackInput w-full"
        >
          <option value="Y" selected>
            Sí
          </option>
          <option value="N">No</option>
        </select>
      </label>

      <button className="col-span-2 px-4 py-2 rounded bg-brightBlue text-white ml-auto">
        Editar funcionalidad
      </button>
    </form>
  );
}
