import { useEffect } from "react";
import NavMenu from "./NavMenu";
import { useTourOperators } from "../../../services/useTourOperators";
import { get } from "lodash";
import { useParams } from "react-router-dom";
import ico_asistencia from "../../../iconos/info_outlined.png";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";

export default function PageMenu({
  noOptions,
  opertatorPermissions,
  location,
  setOperatorPermissions,
  headerOptions,
}) {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { tour_op_id } = useParams();
  const { tourOperator } = useTourOperators((st) => st.getTourOperatorByID);
  useEffect(() => {
    if (tourOP?.id) {
      get({ tourOperatorID: tourOP?.id });
    }

    // MANEJO DE "MODULES" SEGÚN PERMISOS DE OPERADORA
    if (tourOP.TourOperatorModules) {
      const acceptedModules = tourOP.TourOperatorModules.slice(0, 4)
        .filter(({ active }) => active)
        .map(({ name }) => name);

      setOperatorPermissions(acceptedModules);
    }
  }, [tourOP?.id, setOperatorPermissions, tourOP]);

  return (
    <>
      {!noOptions && opertatorPermissions ? (
        <div className="relative flex items-end w-full mb:gap-0 md:gap-4 py-2 justify-center">
          {location?.pathname.includes("search-flight") ||
          location?.search.includes("flights") ||
          location?.pathname.includes("search-packages") ||
          location?.search.includes("package") ||
          location?.pathname.includes("package-detail") ||
          location?.pathname.includes("search-hotels") ||
          location?.pathname.includes("hotels-request") ||
          location?.search.includes("hotels") ||
          location?.pathname.includes("search-activities") ||
          location?.search.includes("activity") ? (
            <div className="w-full absolute bottom-[8px] left-0 bg-[#DAE0E6] rounded-full h-6" />
          ) : null}
          {/* header icons */}
          {headerOptions
            .filter(({ label }) =>
              tourOperator?.TourOperatorModules.slice(0, 4).some(
                (item) => item.name === label && item.active,
              ),
            )
            .map(({ label, image, alt, path }, index) => (
              <NavMenu
                key={index}
                image={image}
                label={label}
                alt={alt}
                link={`/${tour_op_id || "walltrip"}/${path}`}
              />
            ))}
          {!tourOperator &&
            headerOptions.map(({ label, image, alt, path }, index) => (
              <NavMenu
                key={index}
                image={image}
                label={label}
                alt={alt}
                link={`/${tour_op_id || "walltrip"}/${path}`}
              />
            ))}
          {tour_op_id === "nataliabequis" ? (
            <>
              <NavMenu
                image={ico_asistencia}
                label={"Asistencia"}
                alt={"alt"}
                link={`https://twglobalprotection.com/promo/argentina/tva/index.html`}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="h-24 my-0.5" />
      )}
    </>
  );
}
