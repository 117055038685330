import TablePackages from "./tablePackages/TablePackages";
import SEO from "../../../utils/SEO";
import PageTitle from "../../UI/PageTitle";
import { Toaster } from "react-hot-toast";

export default function AdminPackages() {
  return (
    <>
      <div className="mt-8">
        <SEO title={"Mis Paquetes"} />
        <Toaster />
        <PageTitle
          title={"packages_title"}
          subTitle={"packages_subtitle"}
          route={"panel/packages/add-package"}
          btnText={"packages_btb"}
          buttonVisivility={true}
        />
        <TablePackages />
      </div>
    </>
  );
}
