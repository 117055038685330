import { z } from "zod";
import { passengerPackageSchema } from "./passengerPackageSchema";
export const packageBookSchema = z.object({
  status: z.string().optional(),
  package: z.string().min(1, { message: "package_warning" }),
  tour: z
    .string()
    .min(1, { message: "tour_warning", invalid_type: "tour_warning" }),
  fee: z
    .string()
    .min(1, { message: "fee_warning", invalid_type: "fee_warning" }),
  passengers: z
    .array(passengerPackageSchema)
    .min(1, { message: "passenger_required" }),
});
