import axios from "axios";
import { useState } from "react";
import {
  getSessionStorageValue,
  getLocalStorageValue,
} from "../hooks/useCookie";

export default function usePayments() {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(false);
  // const [payment, setPayment] = useState([]);
  const [payments, setPayments] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postPayment = async ({ accounting }) => {
    try {
      await axios.post(BASE_URL + "v2/accounting", { accounting }, headers);
    } catch (err) {
      throw new Error("Payment posting failed: " + err.message); // Provide a clear error message
    }
  };

  const getPaymentMethods = async () => {
    try {
      const res = await axios.get(BASE_URL + "v2/payments-methods");
      if (res?.data) {
        setPaymentMethods(res?.data?.response);
      }
      return res?.data || {};
    } catch (error) {
      // Replace console.log with an appropriate error handling or logging mechanism
      throw new Error("Failed to fetch payment methods: " + error.message);
    }
  };

  // const getPayment = async (package_id) => {
  //   setLoading(true);
  //   axios
  //     .get(BASE_URL + "v2/payments/detail/" + package_id)
  //     .then((e) => {
  //       console.log(e);
  //       setPayment(e.data.response);
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setLoading(false));
  // };

  const getPayments = async () => {
    setLoading(true);
    await axios
      .get(BASE_URL + "v2/accounting", headers)
      .then((e) => {
        setPayments(e.data.response);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => setLoading(false));
  };

  const updatePayment = async (payment) => {
    const { id, ...restOfPayment } = payment;

    try {
      const response = await axios.patch(
        BASE_URL + `v2/accounting/${id}`,
        {
          payment: { ...restOfPayment },
        },
        headers,
      );
      // Optionally handle the response here, e.g., update state or log to an external service
      return response.data; // Returning the response data if needed
    } catch (error) {
      // Handle the error gracefully
      throw new Error("Payment update failed: " + error.message);
    }
  };

  const deletePayment = async (id) =>
    await axios.delete(BASE_URL + `v2/accounting/${id}`, headers);

  return {
    // payment,
    payments,
    paymentMethods: paymentMethods || [],
    loading,
    postPayment,
    // getPayment,
    getPaymentMethods,
    getPayments,
    updatePayment,
    deletePayment,
  };
}
