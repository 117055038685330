import { BlobProvider } from "@react-pdf/renderer";
import download from "../../../../../iconos/download.png";
import QuotePDFile from "./QuotePDFile";
import TableDateFiltering from "./TableDateFiltering";
import QuoteSorting from "./QuoteSorting";
import { useTranslation } from "react-i18next";
export default function TableQuoteFiltering({
  searcher,
  sortCriteria,
  setSortCriteria,
  pdfData,
  setFilters,
  filters,
}) {
  const [searcherValue, setSearcherValue] = searcher;
  const { t } = useTranslation();
  return (
    <section className="flex gap-4 pb-4 mb-4 items-center border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-96 rounded"
        placeholder={t(`global.Admin_Quotes.search_placeholder`)}
        value={searcherValue.textValue}
        onChange={(e) =>
          setSearcherValue({
            ...searcherValue,
            textValue: e.target.value,
          })
        }
      />
      <QuoteSorting
        setSortCriteria={setSortCriteria}
        sortCriteria={sortCriteria}
      />
      <TableDateFiltering setFilters={setFilters} filters={filters} />

      {pdfData && pdfData.length > 0 && (
        <>
          <BlobProvider document={<QuotePDFile pdfData={pdfData} />}>
            {({ url, loading }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={url}
                  download="Cotizaciones.pdf"
                  className=" border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
                >
                  Exportar PDF
                  <img src={download} alt="download" className="h-8 py-2.5" />
                </a>
              )
            }
          </BlobProvider>
        </>
      )}
    </section>
  );
}
