export const PASSENGER_DICTIONARY = {
  ADT: {
    key: "ADT",
    label: "adult",
    register: "adults",
    description: "ulder_than_12",
    min: 1,
  },
  CHD: {
    key: "CHD",
    label: "children",
    register: "children",
    description: "from_2_11",
    min: 0,
  },
  INF: {
    key: "INF",
    label: "infants",
    register: "infants",
    description: "from_0_1",
    min: 0,
  },
};
