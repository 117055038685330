import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTourOperators } from "../services/useTourOperators";
import {
  deleteLocalStorageItem,
  deleteSessionStorageItem,
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";
import walltrip_logo from "./../iconos/mini_walltrip_logo.png";
import useGetCurrencies from "../services/useGetCurrencies";
import { useUserStore } from "../store/useUserStore";
import { useTourOperatorStore } from "../store/useTourOperatorStore";
import { useExchangeRateStore } from "../store/useExchangeRateStore";

const isTokenExpired = () => {
  if (getSessionStorageValue("userData")) return false;

  const tokenTimestamp = getLocalStorageValue("tokenTimestamp");

  if (!tokenTimestamp) {
    return true;
  }

  const currentTimestamp = new Date().getTime();
  const threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;

  return currentTimestamp - tokenTimestamp > threeDaysInMilliseconds;
};

export default function SEO({ title }) {
  const { tour_op_id } = useParams();
  const { tourOperator, getTourOperator } = useTourOperators();
  const { exchangeRate: responseFromJazz, getExchangeRate } =
    useGetCurrencies();
  const setTourOP = useTourOperatorStore((st) => st.setTourOP);
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const exchangeRate = useExchangeRateStore((st) => st.exchangeRate);
  const setExchangeRate = useExchangeRateStore((st) => st.setExchangeRate);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, storeUser } = useUserStore();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const savedUser =
    getLocalStorageValue("userData") || getSessionStorageValue("userData");

  const forceLogOut = () => {
    deleteSessionStorageItem("token");
    deleteSessionStorageItem("userData");
    deleteLocalStorageItem("token");
    deleteLocalStorageItem("userData");
    deleteLocalStorageItem("tokenTimestamp");

    if (location.pathname.includes("panel")) navigate(`/${tour_op_id}/login`);
  };

  useEffect(() => {
    if (tourOP?.name?.split(" ")?.join("")?.toLowerCase() !== tour_op_id) {
      getTourOperator();
    }

    if (isTokenExpired()) {
      return forceLogOut();
    }
  }, [tour_op_id]);

  useEffect(() => {
    tourOperator && setTourOP(tourOperator);

    // cierre de sesión si no coincide usuario con tour_op_id
    if (
      tourOperator &&
      user?.id !== 1 &&
      user?.tour_op_id !== tourOperator?.id
    ) {
      return forceLogOut();
    }
  }, [tourOperator]);

  useEffect(() => {
    const { priceBSP: euro } = responseFromJazz;
    const dolar = "1,00";

    if (dolar && euro) {
      setExchangeRate({
        dolar: Number(dolar.split(",").join(".")).toFixed(2),
        euro: Number(euro.split(",").join(".")).toFixed(2),
      });
    }
  }, [responseFromJazz]);

  useEffect(() => {
    // recargar usuario en zustand
    if (token && !user?.id) {
      storeUser(savedUser);
    }

    if (exchangeRate.dolar === 1 && exchangeRate.euro === 1) {
      getExchangeRate();
    }
  }, []);

  return (
    <Helmet>
      <title>
        {`${tour_op_id && tourOP ? tourOP?.name : "Walltrip"}${
          title ? ` - ${title}` : ""
        }`}
      </title>
      <link rel="icon" href={tourOP?.logo || walltrip_logo} />
    </Helmet>
  );
}
