import PasajeroRow from "./PasajeroRow";
import TableHeader from "./BookingDetailHeader";
import { useTranslation } from "react-i18next";

export const adaptPassenger = (passenger) => {
  if (passenger === "ADULT") return "ADT";
  if (passenger === "CHILD") return "CHD";
  if (passenger === "HELD_INFANT") return "INF";
};

export default function Pasajeros({ pasajeros, passengerType, handleDisplay }) {
  const { t } = useTranslation();
  return (
    <section className="pb-32">
      {pasajeros && (
        <>
          <TableHeader title={"passengers"} handleDisplay={handleDisplay} />
          <table
            id="passengers-body"
            className="table-auto w-full border-collapse  overflow-y-hidden transition-transform duration-200 origin-top rounded-lg"
          >
            <thead className="bg-gray-100">
              <tr>
                <th className="text-sm font-semibold col-span-2 ml-4">
                  {t(`global.Booking_Detail.names`)}
                </th>
                <th className="text-sm font-semibold col-span-2 ml-4">
                  {t(`global.Booking_Detail.last_names`)}
                </th>
                <th className="text-sm font-semibold col-span-2 ml-4">
                  {t(`global.Booking_Detail.passport_dni`)}
                </th>
                <th className="text-sm font-semibold col-span-2 ml-4">
                  {t(`global.Booking_Detail.birth_date`)}
                </th>
                <th className="text-sm font-semibold col-span-2 ml-4">
                  {t(`global.Booking_Detail.type`)}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white  rounded-lg">
              {pasajeros?.map((pasajero, index) => (
                <PasajeroRow
                  key={pasajero.id}
                  pasajero={pasajero}
                  passengerType={adaptPassenger(
                    passengerType?.[index]?.travelerType,
                  )}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
    </section>
  );
}
