import { useState, useMemo } from "react";
import "../styles/opcionvuelo.css";
import { WrapperFlights } from "./flights/WrapperFlights";
import { SubTotalFlights } from "./flights/SubTotalFlights";
import { useLocationQuery } from "../hooks/useLocationQuery";
import { useToggleVisibility } from "../hooks/useToggleVisibility";
import ModalAddOptions from "./flights/ModalAddOptions";
import _ from "lodash";
import { useSelectFlightsStore } from "../store/useSelectFlightsStore";
import { ModalGeneric } from "./UI/ModalGeneric";

const Opcionvuelo = ({
  airlinesFormatted,
  ciudadesFormatted,
  commissions,
  destino,
  exchangeRate,
  origen,
  original,
  totalPrice,
}) => {
  const [thisOption, setThisOption] = useState();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { parsedHash } = useLocationQuery();

  const { flight } = useSelectFlightsStore((state) => {
    if (!state) {
      throw new Error("State is undefined");
    }
    return {
      flight: state.flight,
    };
  });
  const foundItem = original?.find((x) => x.price.total === totalPrice);
  const { travelerPricings, price } = foundItem || {}; // Default to an empty object if `foundItem` is undefined

  const breakdownPrices = [
    ...price.fees,
    ...travelerPricings.map((v) => ({
      amount: v?.price?.base,
      type: v?.travelerType,
      taxes: v?.price?.total - v?.price?.base,
    })),
  ];

  const obtenerOpcionVuelo = () => {
    const comparation = (op) => {
      if (parsedHash?.returnDate) {
        return (
          _.isEqual(op.itineraries[0], flight["IDA"]) &&
          _.isEqual(op.itineraries[1], flight["Vuelta"])
        );
      } else {
        return _.isEqual(op.itineraries[0], flight["IDA"]);
      }
    };
    return original
      .map((op) => (comparation(op) ? op : {}))
      .filter((x) => x.id && x.price.grandTotal === totalPrice)?.[0];
  };

  const opcionVuelo = useMemo(() => obtenerOpcionVuelo(), [flight]);
  const idOffer = opcionVuelo?.id;

  return (
    <div className="font-body flex justify-between w-full gap-4 bg-lightBlue/30 rounded-lg p-4">
      <div className="flex flex-col gap-4 w-full">
        <WrapperFlights
          airlinesFormatted={airlinesFormatted}
          ciudadesFormatted={ciudadesFormatted}
          origen={origen}
          original={original}
          totalPrice={totalPrice}
          type={"IDA"}
        />
        {destino && (
          <WrapperFlights
            airlinesFormatted={airlinesFormatted}
            ciudadesFormatted={ciudadesFormatted}
            destino={destino}
            original={original}
            totalPrice={totalPrice}
            type={"Vuelta"}
          />
        )}
      </div>
      {/* {type === "IDA" ? ( */}
      <SubTotalFlights
        breakdownPrices={breakdownPrices}
        commissions={commissions}
        currency={price.currency}
        exchangeRate={exchangeRate}
        hasReturnFlight={!!destino}
        idOffer={idOffer}
        original={original}
        price={totalPrice}
        showModal={(selectedOption) => {
          setThisOption(selectedOption);
          toggleVisibility();
        }}
      />
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <ModalAddOptions thisOption={thisOption} />
      </ModalGeneric>
      {/* ) : null} */}
    </div>
  );
};

export default Opcionvuelo;
