import TableSuppliers from "./tableSupplier/TableSuppliers";
import SEO from "../../utils/SEO";
import PageTitle from "../UI/PageTitle";
import { Toaster } from "react-hot-toast";

export default function AdminPackages() {
  return (
    <>
      <div className="mt-8">
        <SEO title={"Mis Proveedores"} />
        <Toaster />
        <PageTitle
          title={"my_suppliers_title"}
          subTitle={"my_suppliers_subtitle"}
          route={"panel/suppliers/add-supplier"}
          btnText={"my_suppliers_btn"}
          buttonVisivility="True"
        />
        <TableSuppliers />
      </div>
    </>
  );
}
