import { z } from "zod";
export const invoiceSchema = z.object({
  paid: z
    .string({ required_error: "Campo Requerido" })
    .regex(/^[+]?\d+([.]\d+)?$/),
  currency_type_id: z
    .string({ required_error: "Campo Requerido" })
    .regex(/^[+]?\d+([.]\d+)?$/)
    .default("1"),
  payment_method_id: z
    .string({ required_error: "Campo Requerido" })
    .regex(/^[+]?\d+([.]\d+)?$/)
    .default("1"),
});
