import { z } from "zod";
export const orderSchema = z.object({
  quote: z.object({
    askItineraries: z.object({
      priority: z.string(),
      departure: z.string(),
      arrival: z.string(),
      dates: z.string(),
      numberOfStops: z.string(),
      duration: z.number(),
      checkedBags: z.string(),
      budget: z.string(),
      comments: z.string(),
      nextstep: z.string(),
    }),
    travelers: z.array(
      z.object({
        data: z.object({
          first_name: z.string().nonempty("Nombre es requerido"),
          email_address: z
            .string()
            .nonempty("Email requerido")
            .email("Email inválido"),
        }),
        contact_phones: z.array(
          z.object({
            number: z
              .string()
              .min(10, "Número de WhatsApp inválido")
              .nonempty("Numero de WhatsApp requerido"),
          }),
        ),
      }),
    ),
  }),
});
