import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import HotelPictureModal from "./HotelPictureModal";
import ReactDOM from "react-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { ModalGeneric } from "../../../../UI/ModalGeneric";
export default function HotelCardBody({
  Name,
  stars,
  TotalFixAmounts,
  HotelRooms,
  CheckIn,
  CheckOut,
  toggle,
  visible,
  $,
  Latitude,
  Longitude,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  return (
    <>
      <div className="flex flex-col gap-2 w-full grow justify-between">
        <header className="flex items-center">
          <button
            onClick={toggleVisibility}
            className=" text-xl mr-2 hover:text-blue-500"
          >
            {Name}
          </button>
          <div>
            {stars.map((star, index) => (
              <span key={index}>{star}</span>
            ))}
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com.ar/maps/dir//${Latitude?.[0]},${Longitude?.[0]}/@${Latitude?.[0]},${Longitude?.[0]},17z/data=!4m2!4m1!3e0?entry=ttu`}
            className="hover:text-red-300"
          >
            <FaMapMarkerAlt color="red" size={15} />
          </a>
          <p className="ml-auto">
            Desde <span>{TotalFixAmounts?.[0].$.Gross}</span>{" "}
            <span>{$.Currency}</span>
          </p>
        </header>

        <h4>{HotelRooms?.[0].HotelRoom?.[0]?.RoomCategory?.[0]?._ || ""}</h4>
        <p>Check-In desde {CheckIn}hs</p>
        <p>Check-Out hasta {CheckOut}hs</p>
        <button onClick={toggle} className="button outline w-full">
          {`${visible ? "-" : "+"} info`}
        </button>
      </div>
      {isVisible &&
        ReactDOM.createPortal(
          <ModalGeneric show={isVisible} toggle={toggleVisibility}>
            <HotelPictureModal />
          </ModalGeneric>,
          document.body, // Render the modal at the end of the body (outside the parent container)
        )}
    </>
  );
}
