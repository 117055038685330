import axios from "axios";
import { useEffect, useState } from "react";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";

export const useVersions = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [versions, setVersions] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postVersion = async ({ version }) =>
    await axios.post(BASE_URL + "v2/versions", { version }, headers);

  const getVersions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}v2/versions`, headers); // Make the API call
      if (response?.data) {
        setVersions(response.data.response); // Set the versions if data exists
        return response.data || {}; // Return the data or an empty object
      }
    } catch (error) {
      throw new Error(`Error fetching versions: ${error.message}`); // Throw an error with a clear message
    }
  };

  useEffect(() => {
    getVersions();
  }, []);

  return { versions, postVersion, getVersions };
};
