export default function FormEditAddPicture({ pictures, setPictures }) {
  // Función para eliminar la imagen en el índice dado
  function removePictureAtIndex(indexToRemove) {
    setPictures((prevPictures) =>
      prevPictures.filter((_, index) => index !== indexToRemove),
    );
  }

  return (
    <div>
      <section className="flex flex-wrap gap-6 w-full">
        {pictures.map((url, index) => (
          <div key={index} className="flex flex-col items-center">
            <img
              src={url}
              alt={`Imagen ${index}`}
              className="max-w-[16rem] max-h-[500px]"
            />
            <button
              type="button"
              onClick={() => removePictureAtIndex(index)}
              className="rounded py-1 px-4 border border-red-500 mt-2"
            >
              Eliminar
            </button>
          </div>
        ))}
      </section>
    </div>
  );
}
