import { useParams } from "react-router-dom";
import ToggleButton from "../../../../../UI/ToggleButton";
import SubMenuTourRow from "../tableButtons/SubMenuTourRow";

export default function TourRowAccionButtons({
  data,
  isVisible,
  setVisible,
  AllowDelete,
}) {
  const tour_op_id = useParams();

  return (
    <div className="flex flex-row ">
      <ToggleButton toggleVisibility={setVisible} isVisible={isVisible} />

      <SubMenuTourRow
        AllowDelete={AllowDelete}
        tour_op_id={tour_op_id}
        pkg={data}
      />
    </div>
  );
}
