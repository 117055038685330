import FormTitle from "../../UI/FormTitle";
import { Toaster } from "react-hot-toast";
import BookForm from "./BookForm";
import { useLocation } from "react-router-dom";

export default function BookFormPage() {
  const location = useLocation();
  const { qto } = location.state || {};
  return (
    <div>
      <div className="mt-8">
        <FormTitle
          title={"book_title"}
          btnText={"quote_form_btn"}
          route={"panel/sales/quotes/my-quotes/package"}
        />
      </div>
      <Toaster />
      <BookForm qto={qto} />
    </div>
  );
}
