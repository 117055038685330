const headers = (type) => [
  "col_1",
  "col_2",

  type === "flight" ? "col_3_a" : "col_3_b",
  type === "flight" ? "col_4_a" : "col_4_b",
  "col_5",
  "col_6",
  "col_7",
  "col_8",
];
export { headers };
