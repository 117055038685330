import edit from "../../iconos/edit_icon.png";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import useRulesFares from "../../services/useRulesFares";
import { useState } from "react";
import UpdateFeesModal from "./components/UpdateFeesModal";
import SEO from "../../utils/SEO";
import FormAddRulesFares from "./FormAddRulesFares";
import PanelSelector from "./PanelSelector";
import { useTranslation } from "react-i18next";
import { ModalGeneric } from "../UI/ModalGeneric";
import PageTitle from "../UI/PageTitle";
import toast, { Toaster } from "react-hot-toast";

export default function PanelRulesFare() {
  const { role_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData"),
  );
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [typeModal, setTypeModal] = useState("");
  const [editIndex, setEdit] = useState("");
  const { rulesFare, getRulesFares, createRulesFares } = useRulesFares();
  const { t } = useTranslation();
  const postRulesFares = (data) => {
    toast
      .promise(
        createRulesFares({
          ruleFare: {
            ...data,
            role_id,
          },
        }),
        {
          success: <b>{t(`global.Rules_Form.rules_create_toaster`)}</b>,
          loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
          error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
        },
      )
      .then(() => {
        getRulesFares();
        toggleVisibility();
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const openModal = (modal, index) => {
    toggleVisibility();
    setTypeModal(modal);
    setEdit(index);
  };

  const closeModal = () => {
    toggleVisibility();
    getRulesFares();
  };

  return (
    <>
      <SEO title={"Impuestos y Comisiones"} />
      <Toaster />
      <main className="relative font-body max-w-7xl mx-auto">
        <div className="mt-4">
          <PanelSelector type={"rules"} />
        </div>

        <header className="my-8 flex justify-between items-center">
          <PageTitle
            title={"rules_title"}
            subTitle={"rules_title_subtitle"}
            route={""}
            btnText={"rules_title_btn"}
            buttonVisivility={false}
          />
          <button
            className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
            onClick={() => openModal("new")}
          >
            {t(`global.Common_Words.add`)}
          </button>
        </header>

        {rulesFare?.length > 0 && (
          <section className="bg-white p-8 rounded-3xl text-lg flex flex-col gap-2 mt-16">
            <table className="w-full border-collapse rounded-lg overflow-hidden ">
              <thead>
                <tr className="bg-lightBlue/30 rounded py-2 text-dark">
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.name`)}
                  </th>
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.type`)}
                  </th>
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.amount`)}
                  </th>
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.about`)}
                  </th>
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.active`)}
                  </th>
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.unic_rule`)}
                  </th>
                  <th className="p-4 text-left">
                    {t(`global.Admin_Rules.action`)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {rulesFare.map(
                  (
                    {
                      id,
                      name,
                      operation,
                      active,
                      operation_fiscal,
                      variable,
                      rate_type,
                      number,
                      running,
                    },
                    index,
                  ) => (
                    <tr key={id} className="bg-white hover:bg-lightBlue/20">
                      <td className="p-4 whitespace-nowrap">{name}</td>
                      <td className="p-4 whitespace-nowrap">
                        {operation_fiscal}
                      </td>
                      <td>
                        <div className="inline-flex">
                          {operation === "suma" ? (
                            <>
                              <p className="mr-2">+</p>
                            </>
                          ) : (
                            <div>
                              <p className="mr-2">-</p>
                            </div>
                          )}
                          {rate_type === "porcentaje" ? (
                            <>
                              <p>%</p>
                            </>
                          ) : (
                            <>
                              <p>$</p>
                            </>
                          )}
                          <p>{number}</p>
                        </div>
                      </td>
                      <td className="p-4 whitespace-nowrap">{variable}</td>
                      <td className="p-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={active}
                          disabled
                          className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        />
                      </td>
                      <td className="p-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={running}
                          disabled
                          className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        />
                      </td>

                      <td className="p-4 whitespace-nowrap">
                        <button onClick={() => openModal("edit", index)}>
                          <img src={edit} alt="edit" className="h-8" />
                        </button>
                        {/* <button onClick={() => openModal("delete")}>
            <img src={trash} alt="delete" className="h-8" />
          </button> */}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </section>
        )}

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {typeModal === "new" && (
            <FormAddRulesFares onSubmit={postRulesFares} />
          )}
          {typeModal === "edit" && (
            <UpdateFeesModal
              datas={rulesFare[editIndex]}
              closeModal={closeModal}
            />
          )}
        </ModalGeneric>
      </main>
    </>
  );
}
