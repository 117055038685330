import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../auth/useAuth";
import { useUserStore } from "../../../store/useUserStore";
import logo_walltrip from "../../../iconos/logos/walltrip.png";
import NavBarSocials from "./NavBarSocials";
import { useTranslation } from "react-i18next";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";

export default function Navbar({ firstNavigableRoute }) {
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { t } = useTranslation();
  const { tour_op_id } = useParams();
  const { logOut } = useAuth();
  const { user } = useUserStore();

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  return (
    <>
      <div className="flex justify-between items-center w-full py-4 mb:block md:inline-flex z-custom ">
        <div className="mb:mb-4  ">
          <Link
            className="cursor-pointer"
            to={`/${tour_op_id || "walltrip"}/${firstNavigableRoute()}`}
          >
            <img
              className="max-w-[128px] max-h-16 text-black  "
              src={tourOP?.logo !== "-" ? tourOP?.logo : logo_walltrip}
              alt={tourOP?.name}
            />
          </Link>
        </div>
        <div className="flex md:gap-3 mb:gap-1 mb:justify-center ">
          <NavBarSocials />
          <Link
            to={`/${tour_op_id || "walltrip"}/history`}
            className="hover:bg-hoverBlue border max-h-8 flex items-center md:px-6 mb:px-2 py-1 border-[#8D99AE] rounded-md font-medium text-sm cursor-pointer"
          >
            {t("global.Navbar.my_trips")}
          </Link>
          {!token ? (
            <Link
              to={`/${tour_op_id}/login`}
              className="hover:bg-hoverBlue max-h-8 flex items-center px-6 py-1 bg-[#CEDEF1] rounded-md font-medium text-sm cursor-pointer"
            >
              {t("global.Navbar.agency_access")}
            </Link>
          ) : (
            <button
              onClick={logOut}
              className="hover:bg-hoverBlue max-h-8 flex items-center px-6 py-1 bg-[#CEDEF1] rounded-md font-medium text-sm cursor-pointer"
            >
              {t("global.Navbar.logout")}
            </button>
          )}

          {token && user?.role_id !== 3 ? (
            <Link
              to={`/${tour_op_id || "walltrip"}/panel/sales/bookings/flight`}
              className="hover:bg-hoverBlue border max-h-8 flex items-center px-6 py-1 border-[#8D99AE] rounded-md font-medium text-sm cursor-pointer"
            >
              {t("global.Navbar.panel")}
            </Link>
          ) : null}
        </div>
      </div>
    </>
  );
}
