import React from "react";
import arrow from "../../../../../iconos/down_arrow.png";
export default function AmenityFilter({ handleDisplay }) {
  return (
    <>
      <section
        onClick={() => handleDisplay("amenity")}
        id="amenity-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">Régimen</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="amenity-arrow"
          />
        </header>
      </section>
      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
        id="amenity-body"
      >
        <div className="flex  flex-col gap-4 ">
          <div className="flex flex-row items-center gap-2 inline">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
            />
            <span>Solo Alojamiento</span>
          </div>
          <div className="flex flex-row items-center gap-2 ">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
            />
            <span className="text-inline">Alojamiento y Desayuno</span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
            />
            <span>Solo Cena</span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
            />
            <span>Media Pensión</span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
            />
            <span>Todo Incluido</span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
            />
            <span>No Informado</span>
          </div>
        </div>
      </section>
    </>
  );
}
