import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import useRulesFares from "../../../services/useRulesFares";
import { useTranslation } from "react-i18next";
import useGetCurrencies from "../../../services/useGetCurrencies";
import toast, { Toaster } from "react-hot-toast";

export default function UpdateFeesModal({ datas, closeModal }) {
  let [dataRulesFare, setDataRulesFare] = useState({});
  const { t } = useTranslation();
  const { currencies } = useGetCurrencies();
  const { updateRulesFares } = useRulesFares();
  const specificCurrencyId = datas.currency_type_id;
  const monto = datas.rate_type;
  const specificCurrency = currencies.find(
    (currency) => currency.id === specificCurrencyId,
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
    defaultValues: datas,
  });

  const onSubmit = async () => {
    toast.promise(
      updateRulesFares(datas.id, dataRulesFare).then(() => closeModal()),
      {
        success: <b>{t(`global.Rules_Form.rules_edit_toaster`)}</b>,
        loading: <b>{t(`global.Common_Words.toaster_loading`)}</b>,
        error: <b>{t(`global.Common_Words.toaster_error`)}</b>,
      },
    );
  };

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;

    // Determine the value based on input type
    const newValue = type === "checkbox" ? checked : value;

    setDataRulesFare((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
      <Toaster />
      <section className="flex flex-col gap-8">
        <header className="relative w-60 mx-auto flex items-center bg-lightBlue  justify-center border-0 rounded-3xl h-10">
          <button
            type="button"
            className="px-4 rounded-3xl w-60 z-10"
            onClick={() => {
              reset();
            }}
          >
            {t(`global.Admin_Rules.form_title`)}
          </button>
        </header>

        <div className="grid grid-cols-2 gap-12">
          <div className="col-span-1">
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.name_field`)}
              </label>
              <input
                placeholder={t(`global.Admin_Rules.name_field_placeholder`)}
                className="outline-none border-b border-[#CEDEF1]"
                name="name"
                {...register("name")}
              />
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="name" as="p" />
              </div>
            </div>

            <div className="mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.amount_field`)}
              </label>
              <div className="flex items-center  gap-2 relative">
                <div>
                  <select
                    className="addPackInput w-30"
                    name="rate_type"
                    {...register("rate_type")}
                  >
                    <option hidden disabled value={monto}>
                      {monto}
                    </option>
                    <option value={"porcentaje"}>
                      {t(`global.Admin_Rules.percentage`)}{" "}
                    </option>
                    <option value={"fijo "}>
                      {" "}
                      {t(`global.Admin_Rules.fixed`)}{" "}
                    </option>
                  </select>
                </div>
                <div className="">
                  <input
                    type="number"
                    placeholder={t(
                      `global.Admin_Rules.amount_field_placeholder`,
                    )}
                    className="outline-none border-b border-[#CEDEF1]"
                    name="number"
                    {...register("number")}
                  />
                  <div className="flex flex-col gap-2 relative">
                    <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                      <ErrorMessage errors={errors} name="name" as="p" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.currency_field`)}
              </label>

              <select
                name="currency_type_id"
                {...register("currency_type_id", { valueAsNumber: true })}
                id="currency_type_id"
                className="addPackInput w-full"
              >
                <option hidden disabled value={specificCurrencyId}>
                  {specificCurrency?.name} ({specificCurrency?.symbol}){" "}
                </option>
                {currencies?.map(({ name, symbol, id }) => (
                  <option key={`${id}`} value={id}>
                    {t(`global.Currency.${name}`)} ({symbol})
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.type_field`)}
              </label>
              <select
                className="addPackInput w-full"
                name="percentage"
                {...register("operation_fiscal")}
              >
                <option value={"impuesto"}>
                  {" "}
                  {t(`global.Admin_Rules.tax`)}
                </option>
                <option value={"comisión"}>
                  {t(`global.Admin_Rules.commission`)}{" "}
                </option>
                <option value={"descuento"}>
                  {t(`global.Admin_Rules.discount`)}{" "}
                </option>
              </select>
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="operation_fiscal" as="p" />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.operation_field`)}
              </label>
              <select
                className="addPackInput w-full"
                name="operation"
                {...register("operation")}
              >
                <option value={"suma"}>
                  {t(`global.Admin_Rules.addition`)}
                </option>
                <option value={"resta"}>
                  {t(`global.Admin_Rules.subtraction`)}{" "}
                </option>
              </select>

              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="operation" as="p" />
              </div>
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {t(`global.Admin_Rules.on_the`)}
              </label>
              <select
                className="addPackInput w-full"
                name="variable"
                {...register("variable")}
              >
                <option value={"neto"}>{t(`global.Admin_Rules.net`)} </option>
                <option value={"bruto"}>
                  {" "}
                  {t(`global.Admin_Rules.gross`)}{" "}
                </option>
              </select>

              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="variable" as="p" />
              </div>
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">
                {" "}
                {t(`global.Admin_Rules.product_field`)}
              </label>
              <select
                className="addPackInput w-full"
                name="product"
                {...register("product")}
              >
                <option value={"vuelos"}>
                  {" "}
                  {t(`global.Admin_Rules.flights`)}{" "}
                </option>
                <option value={"hoteles"}>
                  {t(`global.Admin_Rules.hotels`)}{" "}
                </option>
                <option value={"paquetes"}>
                  {t(`global.Admin_Rules.packages`)}{" "}
                </option>
                <option value={"asistencia"}>
                  {t(`global.Admin_Rules.assistance`)}{" "}
                </option>
                <option value={"autos"}>{t(`global.Admin_Rules.cars`)} </option>
                <option value={"crucero"}>
                  {" "}
                  {t(`global.Admin_Rules.cruise`)}{" "}
                </option>
              </select>
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="product" as="p" />
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="flex flex-col gap-2 ">
                <label className="font-medium w-[300px]">
                  {" "}
                  {t(`global.Admin_Rules.active`)}
                </label>
                <input
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  {...register("active", { valueAsBoolean: true })}
                  id="checkbox-active"
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label className="font-medium w-[300px]">
                  {" "}
                  {t(`global.Admin_Rules.unic_rule`)}
                </label>
                <input
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  {...register("running", { valueAsBoolean: true })}
                  id="checkbox-running"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex items-center justify-end mt-4">
        <button
          type="submit"
          className="px-6 py-4 rounded bg-brightBlue text-white"
        >
          {t(`global.Actions.load`)}
        </button>
      </section>
    </form>
  );
}
