import { useTranslation } from "react-i18next";
import FeePriceSymbol from "./FeePriceSymbol";

export default function PackageDetailFeeSection({
  feeKey,
  filteredFee,
  selectedFeeId,
  setSelectedFeeId,
  setSelectedFee,
  setSelectedTour,
  tour,
}) {
  const { t } = useTranslation();
  const selectFee = (id) => {
    setSelectedFeeId(id); // Set the selected fee ID
    setSelectedFee(filteredFee); // Set the selected fee to the filteredFee object
    setSelectedTour(tour); // Set the selected tour
  };
  return (
    <tr
      key={feeKey}
      className={` text-center border-2 border-lightBlue rounded-lg `}
    >
      <td colSpan="4" className="p-2 border-2 border-lightBlue ">
        <table
          className={`w-full bg-white text-center border-2 border-lightBlue hover:bg-lightBlue2`}
        >
          <thead>
            <tr className="bg-lightBlue rounded-lg ">
              <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                {t(`global.Fee.base`)}
              </th>
              <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                {t(`global.Fee.fee`)}
              </th>
              <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                {t(`global.Fee.tax`)}
              </th>
              <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                {t(`global.Common_Words.price`)}
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={`${
                selectedFeeId === filteredFee.id ? "bg-indigo-100" : ""
              } rounded-lg`}
            >
              <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                {filteredFee.base}
              </td>
              <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                <FeePriceSymbol
                  currency_type_id={filteredFee.currency_type_id}
                  data={filteredFee.fare}
                />
              </td>
              <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                <FeePriceSymbol
                  currency_type_id={filteredFee.currency_type_id}
                  data={filteredFee.tax}
                />
              </td>
              <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                <FeePriceSymbol
                  currency_type_id={filteredFee.currency_type_id}
                  data={filteredFee.price}
                />
              </td>

              <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                <input
                  type="radio"
                  name="feeSelection"
                  value={filteredFee.id}
                  checked={selectedFeeId === filteredFee.id}
                  onChange={() => selectFee(filteredFee.id)} // Pass the ID instead
                  className="form-radio text-indigo-600"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
