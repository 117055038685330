import axios from "axios";
import { useEffect, useState } from "react";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";

export const useFunctionalities = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [functionalities, setFunctionalities] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postFunctionality = async ({ functionality }) =>
    await axios.post(
      BASE_URL + "v2/functionalities",
      { functionality },
      headers,
    );

  const getFunctionalities = async () => {
    try {
      const res = await axios.get(BASE_URL + "v2/functionalities", headers);
      if (res?.data) {
        setFunctionalities(res?.data?.response);
      }
      return res?.data || {};
    } catch (error) {
      // Throw the error to be handled by a higher-level error handler
      throw new Error(`Error fetching functionalities: ${error.message}`);
    }
  };
  const deleteFunctionality = async (id) =>
    await axios.delete(BASE_URL + `v2/functionalities/${id}`, headers);

  const voteFunctionality = async ({ functionality_id, votesquantity }) =>
    await axios.patch(
      BASE_URL + `v2/functionalities/votes/${functionality_id}`,
      {
        votesquantity,
      },
      headers,
    );

  const editFunctionality = async ({ functionality_id, functionality }) =>
    await axios.patch(
      BASE_URL + `v2/functionalities/${functionality_id}`,
      { functionality },
      headers,
    );

  useEffect(() => {
    getFunctionalities();
  }, []);

  return {
    functionalities,
    postFunctionality,
    getFunctionalities,
    deleteFunctionality,
    voteFunctionality,
    editFunctionality,
  };
};
