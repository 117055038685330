import axios from "axios";
import { useEffect, useState } from "react";

export default function useGetCurrencies() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [currencies, setCurrencies] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);

  const getCurrencies = async () => {
    try {
      const res = await axios.get(BASE_URL + "v2/currency-types");
      if (res?.data) {
        setCurrencies(res?.data?.response);
      }
      return res?.data || {};
    } catch (error) {
      throw new Error(`Error fetching currencies: ${error.message}`);
    }
  };
  const getExchangeRate = async () => {
    try {
      const res = await axios.get(BASE_URL + "v2/scraping/jazz");
      if (res?.data) {
        setExchangeRate(res?.data?.response);
      }
      return res?.data || {};
    } catch (error) {
      throw new Error(`Error fetching exchange rate: ${error.message}`);
    }
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return { currencies: currencies || [], exchangeRate, getExchangeRate };
}
