export default function QuoteBasicData({ qto, t }) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <h1 className="font-semibold text-xl">
          {t(`global.Booking_Form.trip_title`)}
        </h1>
        <div className="bg-lightGray p-2 rounded-md w-[500px]">
          <div className="flex flex-row gap-2">
            <p className="font-medium italic">
              {t(`global.Booking_Form.origin`)}:
            </p>
            <p className="italic">{qto.origin}</p>
          </div>
          <div className="flex flex-row gap-2">
            <p className="font-medium italic">
              {t(`global.Booking_Form.destination`)}:
            </p>
            <p className="italic">{qto.destination}</p>
          </div>
          <div className="flex flex-row gap-2">
            <p className="font-medium italic">
              {t(`global.Booking_Form.dates`)}:
            </p>
            <p className="italic">{qto.dates}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
