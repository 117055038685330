import { useEffect, useState } from "react";
import clsx from "clsx";
import { useConfirmBookingEmail } from "../../../../hooks/useConfirmBookingEmail";
import { useUserStore } from "../../../../store/useUserStore";
import useAgencies from "../../../../services/useAgencies";
import { useTourOperatorStore } from "../../../../store/useTourOperatorStore";
import { useBookings } from "../../../../services/useBookings";

export default function ConfirmPackageModal({
  selectedModal,
  selectedBooking,
  showError,
  toggleVisibility,
  setShowError,
  setLoading,
  updateBookingState,
  setSelectedBooking,
  getBookingsByType,
  loading,
}) {
  const { errorPNR } = useBookings();
  const { getAgenciesByTourOperator, agencies } = useAgencies();

  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const { updateBookingAndSendEmail } = useConfirmBookingEmail();
  let { user } = useUserStore();
  const [agencieEmail, setAgencieEmail] = useState();

  useEffect(() => {
    // If agencies are already loaded, find the matching agency
    if (agencies.length > 0) {
      const matchedAgency = agencies.find(
        (agency) => agency.id === user.agency_id,
      );
      if (matchedAgency) {
        setAgencieEmail(matchedAgency.email);
      }
    } else {
      // Fetch agencies if not already available
      getAgenciesByTourOperator(tourOP.id);
    }
  }, [tourOP, user.agency_id, agencies]);

  const handleConfirm = async () => {
    try {
      setShowError(false);
      setLoading(true);

      const isError = false; // Replace with actual error condition logic
      if (isError) {
        setShowError(true);
        return;
      }

      // TourOp email
      await updateBookingState(selectedBooking, "confirmed");

      // useEmail
      await updateBookingAndSendEmail(
        selectedBooking,
        "confirmed",
        tourOP,
        user.email,
      );

      await updateBookingAndSendEmail(
        selectedBooking,
        "confirmed",
        tourOP,
        agencieEmail,
      );
    } catch (error) {
      throw new Error(
        "Error updating the booking and sending the email.",
        error,
      );
    } finally {
      setLoading(false);
      toggleVisibility();
      setSelectedBooking(null);
      getBookingsByType();
    }
  };

  return (
    <>
      {selectedBooking && selectedModal === "confirm_PACKAGE" && (
        <div className="flex flex-col items-center gap-2">
          {!showError ? (
            <>
              <h2>
                ¿Estás seguro de confirmar la reserva{" "}
                <b>{selectedBooking.id}</b>?
              </h2>
              <div className="flex justify-center gap-2">
                <button
                  className="py-1 px-2 rounded font-medium border border-gray2"
                  onClick={toggleVisibility}
                >
                  Cancelar
                </button>
                <button
                  onClick={handleConfirm}
                  className={clsx(
                    {
                      "opacity-75 text-white cursor-not-allowed pointer-events-none":
                        loading,
                    },
                    "py-1 px-2 rounded font-medium border border-brightBlue bg-brightBlue text-white",
                  )}
                  disabled={loading}
                >
                  Confirmar Reserva
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        stroke="currentColor"
                        strokeWidth="4"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  ) : null}
                </button>
              </div>
            </>
          ) : (
            <h1>
              {errorPNR?.[0]?.title}: {errorPNR?.[0]?.detail}
            </h1>
          )}
        </div>
      )}
    </>
  );
}
