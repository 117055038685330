import axios from "axios";
import { useState } from "react";

export default function useGetAirports() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [airports, setAirports] = useState([]);

  const getAirports = async () => {
    try {
      const res = await axios.get(BASE_URL + "v2/airports");
      if (res?.data) {
        setAirports(res?.data?.response);
      }
      return res?.data || {};
    } catch (error) {
      throw new Error(`Error fetching airports: ${error.message}`);
    }
  };

  return { getAirports, airports: airports || [] };
}
