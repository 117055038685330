import SEO from "../../../utils/SEO";
import FormTitle from "../../UI/FormTitle";
import EditQuoteForm from "./EditQuoteForm";

export const EditQuote = () => (
  <div>
    <SEO />
    <FormTitle
      title={"quote_editform_title"}
      btnText={"quote_form_btn"}
      route={"panel/sales/quotes/my-quotes/package"}
    />
    <EditQuoteForm />
  </div>
);
