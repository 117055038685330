import CalculateSharpIcon from "../../iconos/calculate.svg";
import dashboard_customize from "../../iconos/dashboard_customize.svg";
import library_add_check from "../../iconos/library_add_check.svg";
import luggage from "../../iconos/luggage.svg";
import group from "../../iconos/group.svg";
import account_balance from "../../iconos/account_balance.svg";
import proveedores from "../../iconos/transport.png";

export const sidebarOptions = [
  {
    link: "sales/quotes/create-quote",
    label: "quote_generator",
    icon: CalculateSharpIcon,
    permission: "view:quoter",
  },
  {
    link: "sales/quotes/my-quotes/package",
    label: "my_quotes",
    icon: dashboard_customize,
    permission: "view:myquotes",
  },
  {
    link: "sales/bookings/package",
    label: "bookings",
    icon: library_add_check,
    permission: "view:bookings",
  },
  {
    link: "packages/my-packages",
    label: "packages",
    icon: luggage,
    permission: "view:packages",
    children: [
      {
        link: "packages/my-packages",
        label: "my_packages",
        permission: "view:mypackages",
      },
      {
        link: "packages/add-package",
        label: "add_package",
        permission: "view:addpackage",
      },
    ],
  },
  {
    link: "suppliers/my-suppliers",
    label: "my_suppliers",
    icon: proveedores,
    permission: "view:supplier",
    children: [
      {
        link: "suppliers/my-suppliers",
        label: "add_supplier",
        permission: "view:addsupplier",
      },
      {
        link: "suppliers/add-supplier",
        label: "Agregar Proveedores",
        permission: "view:addsupplier",
      },
    ],
  },
  {
    link: "invoicing/my-invoices",
    label: "invoicing",
    icon: account_balance,
    permission: "view:invoicing",
    children: [
      {
        link: "invoicing/my-invoices",
        label: "my-invoices",
        permission: "view:myinvoices",
      },
      {
        link: "invoicing/add-payment",
        label: "add-payment",
        permission: "view:addpayment",
      },
    ],
  },
  {
    link: "travelers",
    label: "passengers",
    icon: group,
    permission: "view:passengers",
  },
];
