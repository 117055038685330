import toast from "react-hot-toast";
import _ from "lodash";
import useSendEmail from "../services/useSendEmail";
import { generateEmailBodyForOp } from "./generateEmailBodyForOp";

export const bookPackage = async (
  packageSelected,
  errors,
  createBooking,
  passengerData,
  fee,
  tour,
  tourOP,
  user,
  t,
) => {
  const { sendEmail } = useSendEmail();

  // Debugging log

  if (errors.length > 0) {
    toast.error(
      t("global.error.fix_errors") ||
        "Please fix the errors before proceeding.",
    );
    return;
  }

  try {
    const response = await createBooking({
      selectedItem: packageSelected?.id,
      passenger: _.values(passengerData),
      type: "package",
      currency_type_id: fee.currency_type_id,
      fee: fee,
      tour: tour,
    });

    const bookingId = response?.data?.detail?.booking?.id;
    await sendEmail({
      type: "Gmail",
      tour_op_id: tourOP?.id || 1,
      options: {
        from: "wearewalltrip@gmail.com",
        bcc: tourOP.email,
        //bcc: "leuca.ignacio@gmail.com",
        subject: t("email.new_booking_subject"),
        html: generateEmailBodyForOp(packageSelected, tourOP?.id, bookingId),
      },
    });

    toast.success(t("global.success.booking_success") || "Booking successful!");
  } catch (error) {
    toast.error(
      user?.id
        ? t("global.error.generic") || "An unexpected error occurred."
        : t("global.Login.login_required") || "Please log in to continue.",
    );
  }
};
