import React, { useState } from "react";
import PackageDetailFeeSection from "./feeSection/PackageDetailFeeSection";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function PackageDetailTourSection({
  packageData,
  setSelectedTour,
  setSelectedFee,
}) {
  const { t } = useTranslation();
  const [selectedFeeId, setSelectedFeeId] = useState("");
  return (
    <div className="overflow-x-auto p-2  mt-2">
      {packageData.tours.map((tour, tourKey) => (
        <React.Fragment key={tourKey}>
          <h1 className="font-semibold">
            {t(`global.Tour.tour`)} &nbsp; {tourKey + 1}:
          </h1>
          <table
            className={`w-full bg-white text-center hover:bg-lightBlue2 rounded-lg border-none mb-6 shadow-lg`}
          >
            <thead>
              <tr className="bg-lightBlue">
                <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.tour`)}
                </th>
                <th className="border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.return`)}
                </th>
                <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.hotel`)}
                </th>
                <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
                  {t(`global.Tour.category`)}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white text-center border-2 border-lightBlue">
                <td className="border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {tour.dateIn
                    ? format(new Date(tour.dateIn), "dd/MM/yy")
                    : "fecha salida"}
                </td>
                <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {tour.dateIn
                    ? format(new Date(tour.dateOut), "dd/MM/yy")
                    : "fecha salida"}
                </td>
                <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {tour.hotel ? tour.hotel : "-"}
                </td>
                <td className="p-2 border-2 border-lightBlue w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {tour.catergory ? tour.catergory : "-"}
                </td>
              </tr>

              {/* Fees Row (filtered by corresponding tour id) */}
              {tour.fees
                .filter((fee) => fee.id_tours === tour.id) // Match fee to the corresponding tour
                .map((filteredFee, feeKey) => (
                  <PackageDetailFeeSection
                    tour={tour}
                    key={feeKey}
                    filteredFee={filteredFee}
                    selectedFeeId={selectedFeeId}
                    setSelectedFeeId={setSelectedFeeId}
                    setSelectedTour={setSelectedTour}
                    setSelectedFee={setSelectedFee}
                  />
                ))}
            </tbody>
          </table>
        </React.Fragment>
      ))}
    </div>
  );
}
