import { z } from "zod";
export const bookHotelSchema = z.object({
  data: z.object({
    first_name: z.string().nonempty(),
    last_name: z.string().nonempty(),
    date_of_birth: z.string().nonempty(),
    gender: z.string().nonempty(),
    email_address: z.string().nonempty(),
  }),
  documentation: z.object({
    doc_type: z.string().nonempty(),
    birth_place: z.string().nonempty(),
    issuance_location: z.string().nonempty(),
    issuance_date: z.string().nonempty(),
    number: z.string().nonempty(),
    expiry_date: z.string().nonempty(),
    issuance_country: z.string().nonempty(),
    nationality: z.string().nonempty(),
  }),
  contact_phones: z.array(
    z.object({
      device_type: z.string().nonempty(),
      country_calling_code: z.string().nonempty(),
      number: z.string().nonempty(),
    }),
  ),
});
