import axios from "axios";
import {
  getLocalStorageValue,
  getSessionStorageValue,
} from "../hooks/useCookie";
import { useState } from "react";
import { useTourOperatorStore } from "../store/useTourOperatorStore";

export default function useAgencies() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const [agencies, setAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState();
  const [agenciesStates, setAgenciesStates] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postAgency = async ({ agency }) =>
    await axios.post(
      BASE_URL + "v2/agencies",
      { agencies: { ...agency, tour_op_id: tourOP.id } },
      headers,
    );

  const getAllAgencies = async () => {
    try {
      const response = await axios.get(BASE_URL + "v2/agencies", headers);
      setAllAgencies(response.data.response);
    } catch (error) {
      throw new Error("Failed to fetch agencies");
    }
  };

  const getAgenciesByTourOperator = async (states = []) => {
    const query = states?.length > 0 ? `?state=${JSON.stringify(states)}` : "";

    try {
      const response = await axios.get(
        BASE_URL + `v2/agencies/${tourOP.id}${query}`,
        headers,
      );
      setAgencies(response.data.response);
    } catch (err) {
      throw new Error("Failed to fetch agencies by tour operator");
    }
  };

  const updateAgency = async ({ id, agency }) =>
    await axios.patch(BASE_URL + `v2/agencies/${id}`, { agency }, headers);

  const deleteAgency = async ({ id }) =>
    await axios.delete(BASE_URL + `v2/agencies/${id}`, headers);

  const getStatesByAgencies = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `v2/agencies/${tourOP.id}`,
        headers,
      );
      const uniqueStates = [
        ...new Set(
          response.data.response
            .map(({ state }) => state?.toUpperCase())
            .filter((state) => !!state),
        ),
      ];
      setAgenciesStates(uniqueStates);
    } catch (err) {
      // Replace console.log with a custom error handling or alert
      // Example: You could use an alert (for client-side apps) or log it to an external service
      throw new Error("Failed to fetch states by agencies: " + err.message);
    }
  };

  return {
    agencies,
    allAgencies,
    agenciesStates,
    postAgency,
    getAgenciesByTourOperator,
    getAllAgencies,
    getStatesByAgencies,
    updateAgency,
    deleteAgency,
  };
}
