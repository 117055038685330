import { useState } from "react";
import PassengerSelectorModule from "./PassengerSelectorModule";
import icono_calendar from "../../../../iconos/icono_calendar.png";
import oricode_chevron from "../../../../iconos/oricode_chevron.png";
import descode_point from "../../../../iconos/descode_point.png";
import { useTranslation } from "react-i18next";
import AutoCompleteDate from "../../../UI/autoComplete/AutoCompleteDate";
import AutoCompletePackages from "../../../UI/autoComplete/AutoCompletePackages";

export default function PackageSearchModule({
  filteredPackages,
  register,
  handlePassenger,
  passangerState,
  sumPassengers,
  packages,
  setValue,
}) {
  const [searchData, setSearchData] = useState({
    origin_code: null,
    destination_code: null,
    dateIn: null,
  });
  const { t } = useTranslation();
  const handleChooseValue2 = (inputValue, value) => {
    // Initialize the filtered object

    const filteredValues = {
      origin_code: [],
      destination_code: [],
      dateIn: [],
    };

    // Handle null or invalid inputValue
    if (
      !inputValue ||
      (typeof inputValue === "string" && inputValue.trim() === "")
    ) {
      // Dynamically set only the relevant field to null based on the value
      setSearchData((prevData) => ({
        origin_code: value === "origin_code" ? null : prevData.origin_code,
        destination_code:
          value === "destination_code" ? null : prevData.destination_code,
        dateIn: value === "dateIn" ? null : prevData.dateIn,
      }));

      return; // Exit the function early if inputValue is null or empty
    }
    // Check if packages is defined and is an array
    if (!Array.isArray(packages) || packages.length === 0) {
      return;
    }

    // When selecting a date
    if (value === "dateIn") {
      const [inputYear, inputMonth] = inputValue.split("-"); // Split input value to year and month
      packages.forEach((pkg) => {
        pkg.tours.forEach((tour) => {
          const [tourYear, tourMonth] = tour.dateIn.split("-"); // Split tour date to year and month
          if (tourYear === inputYear && tourMonth === inputMonth) {
            // Collect unique origin and destination codes for the selected date
            if (!filteredValues.origin_code.includes(pkg.origin_code)) {
              filteredValues.origin_code.push(pkg.origin_code);
            }
            if (
              !filteredValues.destination_code.includes(pkg.destination_code)
            ) {
              filteredValues.destination_code.push(pkg.destination_code);
            }
          }
        });
      });
    } else {
      // When selecting an origin or destination
      packages.forEach((pkg) => {
        // Ensure that both inputValue and package codes are compared in uppercase
        const upperInputValue = inputValue.toUpperCase();
        const upperOriginCode = pkg.origin_code.toUpperCase();
        const upperDestinationCode = pkg.destination_code.toUpperCase();

        if (
          (value === "origin_code" && upperOriginCode === upperInputValue) ||
          (value === "destination_code" &&
            upperDestinationCode === upperInputValue)
        ) {
          pkg.tours.forEach((tour) => {
            const [tourYear, tourMonth] = tour.dateIn.split("-"); // Split tour date to year and month
            const formattedDate = `${tourYear}-${tourMonth}`; // Format tour date in "YYYY-MM" format

            // Add all valid tour dates in the same format as the date search
            if (tour.dateIn && !filteredValues.dateIn.includes(formattedDate)) {
              filteredValues.dateIn.push(formattedDate); // Push the formatted date
            }
          });

          // Collect unique origin and destination codes
          if (!filteredValues.origin_code.includes(upperOriginCode)) {
            filteredValues.origin_code.push(upperOriginCode);
          }
          if (!filteredValues.destination_code.includes(upperDestinationCode)) {
            filteredValues.destination_code.push(upperDestinationCode);
          }
        }
      });
    }
    // Ensure unique values
    filteredValues.origin_code = [...new Set(filteredValues.origin_code)];
    filteredValues.destination_code = [
      ...new Set(filteredValues.destination_code),
    ];

    // Update searchData with the gathered values
    setSearchData(() => ({
      origin_code:
        filteredValues.origin_code.length > 0
          ? filteredValues.origin_code
          : null,
      destination_code:
        filteredValues.destination_code.length > 0
          ? filteredValues.destination_code
          : null,
      dateIn: filteredValues.dateIn.length > 0 ? filteredValues.dateIn : null,
    }));
  };

  return (
    <div className="flex mb:flex-col md:flex-row items-center gap-8 w-full">
      <div className=" bg-white rounded-2xl  flex flex-col  p-2 h-20 pt-2  md:w-3/4 mb:mb-6 md:mb-0">
        <span className="flex gap-2 items-center justify-center">
          <img src={descode_point} alt="descode_point" className="w-4" />
          <p>{t("global.Search_Packages.destination")}</p>
        </span>
        <AutoCompletePackages
          options={
            searchData?.destination_code || filteredPackages.destination_code
          }
          setValue={setValue}
          classname={"bg-[#EFEFEF] outline-none px-2 py-2"}
          register={register}
          name="destination_code"
          valueName="destination_code"
          placeholder="choose_destination"
          handleChange={(optionValue, value) =>
            handleChooseValue2(optionValue, value)
          }
        />
      </div>
      <div className=" bg-white rounded-2xl flex flex-col  p-2 h-20 pt-2  md:w-3/4 mb:mb-6 md:mb-0">
        <span className="flex gap-2 items-center justify-center">
          <img src={icono_calendar} alt="icono_calendar" className="w-4" />
          <p>{t("global.Search_Packages.departure")}</p>
        </span>

        <AutoCompleteDate
          setValue={setValue}
          options={searchData?.dateIn || filteredPackages.departure_date}
          classname={"bg-[#EFEFEF] outline-none px-2 py-2"}
          register={register}
          name="dateIn"
          placeholder="choose_date"
          handleChange={(optionValue) =>
            handleChooseValue2(optionValue, "dateIn")
          }
        />
      </div>

      <div className=" bg-white rounded-2xl  flex flex-col  p-2 h-20 pt-2  md:w-3/4 mb:mb-6 md:mb-0">
        <span className="flex gap-2 items-center justify-center">
          <img src={oricode_chevron} alt="oricode_chevron" className="w-4" />
          <p>{t("global.Search_Packages.origin")}</p>
        </span>
        <AutoCompletePackages
          setValue={setValue}
          options={searchData?.origin_code || filteredPackages.origin_code}
          classname={"bg-[#EFEFEF] outline-none px-2 py-2"}
          register={register}
          name="origin_code"
          valueName="origin_code"
          placeholder="choose_origin"
          handleChange={(optionValue, value) =>
            handleChooseValue2(optionValue, value)
          }
        />
      </div>
      <div>
        <PassengerSelectorModule
          register={register}
          handlePassenger={handlePassenger}
          passangerState={passangerState}
          sumPassengers={sumPassengers}
        />
      </div>
    </div>
  );
}
