import { useTranslation } from "react-i18next";

export default function TableFeeRow({ cols }) {
  const { t } = useTranslation();
  return (
    <tr className=" bg-lightBlue py-2 px-8 rounded-lg ">
      {cols.map((header, index) => (
        <th
          className="font-medium text-brightBlue text-center px-4"
          key={index}
        >
          {t(`global.Admin_Packages.Fee_Headers.${header}`)}
        </th>
      ))}
    </tr>
  );
}
