import { z } from "zod";

export const requestSchema = z.object({
  quote: z.object({
    type: z.string().nonempty("Selecciona una opción"), //lo agregue para el tipo de cotizacion
    askItineraries: z.object({
      priority: z.string().nonempty("Selecciona una opción"),
      departure: z.string().nonempty("Campo requerido"),
      arrival: z.string().nonempty("Campo requerido"),
      dates: z.string().nonempty("Campo requerido"),
      numberOfStops: z.string(),
      duration: z.number(),
      checkedBags: z.string(),
      budget: z.string().nonempty("Campo requerido"),
      comments: z.string(),
      nextstep: z.string().nonempty("Campo requerido"),
    }),
    travelers: z.array(
      z.object({
        data: z.object({
          first_name: z.string().nonempty("Nombre es requerido"),
          email_address: z
            .string()
            .nonempty("Email requerido")
            .email("Email inválido"),
        }),
        contact_phones: z.array(
          z.object({
            number: z
              .string()
              .nonempty("Numero de WhatsApp requerido")
              .min(10, "Número de WhatsApp inválido"),
          }),
        ),
      }),
    ),
  }),
});
