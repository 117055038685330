import userIcon from "../../../iconos/fi-rr-user.png";
import down_arrow from "../../../iconos/down_arrow.png";
import _ from "lodash";
import { Toaster, toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { FormUserData } from "../../detalle/FormUserData";
import { PurchaseDetail } from "../../detalle/PurchaseDetail";
import { Tramo } from "../../detalle/Tramo";
import { useLocationQuery } from "../../../hooks/useLocationQuery";
import { passengerTypes } from "../../../utils/commons";
import { useStoreSelectPackages } from "../../../store/useStoreSelectPackages";
import useGetAirports from "../../../services/useGetAirports";
import Head from "../../head";
import SEO from "../../../utils/SEO";
import useGetCurrencies from "../../../services/useGetCurrencies";
import { useUserStore } from "../../../store/useUserStore";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import { Login } from "@mui/icons-material";
import useSendEmail from "../../../services/useSendEmail";
import { useSelectFlightsStore } from "../../../store/useSelectFlightsStore";
import { useTourOperatorStore } from "../../../store/useTourOperatorStore";
import { useBookings } from "../../../services/useBookings";
import useCommissionsAndTaxes from "../../../services/useCommissionsAndTaxes";
import { useGetAirlines } from "../../../services/useGetAirlines";
import { ModalGeneric } from "../../UI/ModalGeneric";

const translateCabin = (cabin) => {
  if (cabin === "ECONOMY") return "ECO";
  return "EJE";
};

const checkErrors = (errors, firstPassenger, type) => {
  const errorLength = Object.keys(errors)?.filter(
    (key) => (!errors[key]?.correct).length,
  );

  if (
    errorLength > 0 ||
    Object.keys(errors).length <
      (type === "flights" ? (firstPassenger ? 8 : 7) : 4)
  ) {
    const btn = document.getElementById("reserve_btn");
    btn.classList.add("bg-red-400");
    btn.classList.add("hover:bg-red-400");
    btn.textContent = "Completar todos los campos";

    setTimeout(() => {
      btn.classList.remove("bg-red-400");
      btn.classList.remove("hover:bg-red-400");
      btn.textContent = "Reserva";
    }, 2000);

    return false;
  }
  return true;
};

const generateEmailBodyForOp = (packageSelected, tour_op_id, newBookingId) => {
  const currentHREF = window.location.href.split("/").slice(0, -4).join("/");
  const packageLink = `${currentHREF}/${encodeURIComponent(
    tour_op_id,
  )}/package-detail/${encodeURIComponent(packageSelected?.id)}`;

  return `<h3>Nueva reserva confirmada!</h3>
<p>Paquete:${packageSelected.title} </p>
<h3>Nº de Reserva: ${newBookingId}</h3>

  <a href="${packageLink}">Ver detalles del paquete</a>
    <h4>Si ese link no funciona</h4>
    <p>Link: ${packageLink}</p>

`;
};

const handleDisplay = (title) => {
  const body = document.getElementById(`${title}-body`);
  const arrow = document.getElementById(`${title}-arrow`);

  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  body.classList.toggle("p-4");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};
export default function FlightsBookings() {
  const { user } = useUserStore();
  const { parsedHash } = useLocationQuery();
  const tourOP = useTourOperatorStore((st) => st.tourOP);
  const type = parsedHash?.type;
  const flight = useSelectFlightsStore((st) => st.flight);
  const original = useSelectFlightsStore((st) => st.original);
  const packageSelected = useStoreSelectPackages((st) => st.packageSelected);
  const numberOfPassengers = useStoreSelectPackages(
    (st) => st.numberOfPassengers,
  );
  const { travelerPricings, selectedAmenities } = original;
  const { currencies } = useGetCurrencies();
  const { createBooking, newBookingId } = useBookings();
  const { commissions, getCommissions } = useCommissionsAndTaxes();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { sendEmail } = useSendEmail();
  const [passengerData, setPassengerData] = useState({});
  const [errors, setErrors] = useState({});
  const hasReturnDate =
    type === "flights" &&
    Object.prototype.hasOwnProperty.call(flight, "Vuelta");

  useEffect(() => {
    getAirports();
    getAirlines();
    getCommissions();
  }, []);

  const book = async () => {
    // VALIDAR TODOS LOS CAMPOS DE TODOS LOS PASAJEROS
    const separatedErrors = Object.values(
      errors?.["1"] ? errors : { 1: { name: { correct: false, value: "" } } },
    );

    if (
      separatedErrors.some(
        (errors, index) => !checkErrors(errors, index === 0, type),
      )
    ) {
      return;
    }
    const currency_type_id =
      type === "flights"
        ? currencies.find(({ symbol }) =>
            original?.price?.currency.includes(symbol),
          )?.id
        : packageSelected.currency_type_id;

    toast.promise(
      createBooking({
        selectedItem: type === "flights" ? original : packageSelected?.id,
        passenger: _.values(passengerData),
        type: type === "flights" ? "flight" : "package",
        currency_type_id,
      })
        .then(() => {
          sendEmail({
            type: "Gmail",
            tour_op_id: 1,
            options: {
              from: "wearewalltrip@gmail.com",
              bcc: tourOP.email,
              subject: "Envío Nueva Reserva",
              html: generateEmailBodyForOp(
                packageSelected,
                tourOP.id,
                newBookingId,
              ),
            },

            // options: {
            //   ...setMailOptions(
            //     tourOP?.email,
            //     user?.email,
            //     type,
            //     e?.data?.detail
            //   ),
            // },
            // });
          });
        })
        .catch(() => {
          !user?.id && toggleVisibility(true);
          throw new Error();
        }),
      {
        loading: "Cargando...",
        success: <b>Reserva con éxito.</b>,
        error: (
          <b>
            {!user?.id ? "Inicia sesión." : "Hubo un error, intenta de nuevo."}
          </b>
        ),
      },
    );
  };

  const flArrival = flight?.IDA?.segments?.at(-1)?.arrival;
  const departureDate = flight?.IDA?.segments?.[0]?.departure?.at;
  const arrivalDate = flight?.Vuelta?.segments?.at(-1)?.arrival.at;

  const { getAirports, airports } = useGetAirports();
  const { getAirlines, airlines } = useGetAirlines();
  const [ciudadesFormatted, setCiudadesFormatted] = useState({});
  const [airlinesFormatted, setAirlinesFormatted] = useState({});

  useEffect(() => {
    if (airports) {
      setCiudadesFormatted(() => {
        let formattedCities = {};
        airports.forEach((airport) => {
          formattedCities[airport.iata] = {
            city: airport.City.name,
            airport: airport.name,
          };
        });
        return formattedCities;
      });
    }
  }, [airports]);

  useEffect(() => {
    if (airlines.length > 0) {
      setAirlinesFormatted(() => {
        let formattedAirlines = {};
        airlines.forEach((airline) => {
          formattedAirlines[airline.code] = airline;
        });
        return formattedAirlines;
      });
    }
  }, [airlines]);

  const formattedNumberOfPassengers = () => {
    const { ADT, CHD, INF } = numberOfPassengers;
    const totalPassangers = ADT + CHD + INF;

    return new Array(totalPassangers).fill(0).map((_, index) => {
      const travelerType =
        index < ADT ? "ADULT" : index < ADT + CHD ? "CHILD" : "HELD_INFANT";

      return {
        travelerType,
        travelerId: index + 1,
      };
    });
  };

  return (
    <section className="h-full">
      <SEO title={"Detalle"} />
      <div>
        <Toaster />
      </div>
      <Head />
      <main className="max-w-[1100px] mx-auto my-8">
        <div className="grid justify-center grid-cols-12 gap-1 grid-rows-12">
          <div className="col-span-12 px-4 pt-2 pb-1 rounded-lg ">
            <h1 className="text-xl text-[#242423] font-[700]">
              ¡Ya casi estás! Completá tus datos y nos comunicaremos con vos.
            </h1>
          </div>
          <div className="relative col-span-6 flex flex-col gap-4 mr-2">
            {/* Pasajeros */}
            {(travelerPricings || formattedNumberOfPassengers() || []).map(
              (v, index) => {
                const title = `Pasajero ${index + 1} (${
                  passengerTypes?.[v?.travelerType] || "Adulto"
                })`;

                return (
                  <div
                    key={v?.travelerId || index}
                    className="border border-gray2 rounded-lg"
                  >
                    <PassengerHeader
                      first={index === 0}
                      key={v?.travelerId || index}
                      row={6}
                      title={title}
                      icon={userIcon}
                      handleDisplay={handleDisplay}
                    />
                    <FormUserData
                      id={v?.travelerId}
                      index={index}
                      idBody={`${title}-body`}
                      isFlight={type === "flights"}
                      passengerState={[passengerData, setPassengerData]}
                      error={[errors, setErrors]}
                    />
                  </div>
                );
              },
            )}
          </div>

          <div className="col-span-6 ml-2 row-span-12 ">
            <div className="sticky top-2 flex flex-col gap-4">
              <section
                className={`col-span-2 row-span-2 rounded-lg border border-gray2 overflow-hidden relative`}
              >
                <PurchaseDetail
                  type={type}
                  selectedAmenities={selectedAmenities}
                  commissions={commissions}
                  currencies={currencies}
                  numberOfPassengers={formattedNumberOfPassengers()}
                />
              </section>

              <section className="col-span-2 row-span-2 rounded-lg border border-gray2 relative">
                <header className="w-full bg-lightBlue rounded-lg flex justify-between">
                  <p className="font-medium text-xl px-8 py-3">
                    {type === "flights"
                      ? ciudadesFormatted?.[flArrival?.iataCode]?.city
                      : packageSelected?.title}
                  </p>
                  <div className="flex gap-4 items-center">
                    {type === "flights" && (
                      <div className="flex flex-col justify-center">
                        <span className="flex items-center gap-1 text-brightBlue font-medium">
                          <img src={userIcon} alt="user_icon" className="h-3" />
                          <p className="text-sm">
                            {travelerPricings?.length}{" "}
                            {travelerPricings?.length === 1
                              ? "pasajero"
                              : "pasajeros"}
                          </p>
                        </span>
                        <p className="text-sm font-medium text-brightBlue capitalize">
                          Clase{" "}
                          {translateCabin(
                            travelerPricings?.[0]?.fareDetailsBySegment?.[0]
                              ?.cabin,
                          )}
                        </p>
                      </div>
                    )}
                    <div className="grid items-center bg-white h-full px-4 border-2 border-brightBlue text-brightBlue font-semibold rounded-md">
                      {(flight || packageSelected) &&
                        format(
                          new Date(
                            type === "flights"
                              ? departureDate && departureDate
                              : packageSelected?.departure_date,
                          ),
                          "dd/MM",
                        )}
                      {" > "}
                      {(flight || packageSelected) &&
                        format(
                          new Date(
                            type === "flights"
                              ? hasReturnDate && arrivalDate
                              : packageSelected?.return_date,
                          ),
                          "dd/MM",
                        )}
                    </div>
                  </div>
                </header>
                <div className="grid px-8">
                  {type === "flights" ? (
                    <>
                      <Tramo
                        type={"IDA"}
                        travelerPricing={travelerPricings}
                        ciudadesFormatted={ciudadesFormatted}
                        airlinesFormatted={airlinesFormatted}
                        title={
                          ciudadesFormatted?.[flArrival?.iataCode]?.airport
                        }
                      />
                      {hasReturnDate && (
                        <Tramo
                          type={"Vuelta"}
                          travelerPricing={travelerPricings}
                          ciudadesFormatted={ciudadesFormatted}
                          airlinesFormatted={airlinesFormatted}
                          title={
                            ciudadesFormatted?.[flArrival?.iataCode]?.airport
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="font-semibold">Origen:</span>{" "}
                        {packageSelected?.oricode}
                      </p>
                      <p>
                        <span className="font-semibold">Destino:</span>{" "}
                        {packageSelected?.descode}
                      </p>
                      <p>
                        <span className="font-semibold">Descripción:</span>
                      </p>
                      <div>
                        {packageSelected?.description
                          ?.split("<br>")
                          .map((item, index) => (
                            <p key={index}>{item}</p>
                          ))}
                      </div>
                      <img
                        src={packageSelected?.portrait}
                        alt={packageSelected?.title}
                        className="h-40 pb-4"
                      />
                    </>
                  )}
                </div>
              </section>

              <button
                id="reserve_btn"
                className={`relative col-span-2 row-span-2 text-darkBlue text-center py-4 border-2 border-darkBlue text-2xl font-semibold w-full rounded-md mb-2 cursor-pointer hover:bg-darkBlue hover:text-white active:opacity-75 transition-all`}
                onClick={book}
              >
                RESERVAR
              </button>
            </div>
          </div>
        </div>
      </main>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <Login keepRoute closeModal={toggleVisibility} />
      </ModalGeneric>
    </section>
  );
}

const PassengerHeader = ({ icon, title, first, handleDisplay }) => (
  <div
    className="py-3 px-8 flex justify-between items-center rounded-md bg-lightBlue cursor-pointer"
    onClick={() => handleDisplay(title)}
  >
    <span className="flex items-center gap-2">
      <img src={icon} alt="passenger" className="h-6" />
      <h2 className="font-medium text-xl">{title}</h2>
    </span>
    <img
      src={down_arrow}
      alt="arrow"
      className={`h-4 transition-transform ${
        first ? "-rotate-180" : "rotate-0"
      }`}
      id={`${title}-arrow`}
    />
  </div>
);
