import { useTranslation } from "react-i18next";
import useGetCurrencies from "../../../services/useGetCurrencies";

export default function CurrencyFilter({
  capitalizeFirstLetter,
  handleDisplay,
  arrow,
  filters,
  handleChecks,
}) {
  const { currencies } = useGetCurrencies();
  const { t } = useTranslation();
  return (
    <div>
      <section
        onClick={() => handleDisplay("currency")}
        id="currency-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">{t(`global.Currency.currency`)}</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="currency-arrow"
          />
        </header>
      </section>
      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
        id="currency-body"
      >
        {currencies.map((el) => {
          const capitalizedCurrencyName = capitalizeFirstLetter(el.name);
          const checkboxId = `currency-checkbox-${el.id}`; // Generate a unique id for each checkbox

          return (
            <div className="flex items-center" key={el.id}>
              <input
                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                type="checkbox"
                id={checkboxId} // Unique ID for each checkbox
                name="currency"
                checked={filters.currency.includes(el.id)} // Check if the currency is selected in the filter
                onChange={(evt) => handleChecks(evt, el.id, "currency")}
              />
              <label
                htmlFor={checkboxId} // Correctly associate the label with the checkbox using the unique id
                className="flex gap-2 items-center cursor-pointer"
              >
                <p className="max-w-[15ch] ml-2">
                  {t(`global.Currency.${capitalizedCurrencyName}`)}
                </p>
              </label>
            </div>
          );
        })}
      </section>
    </div>
  );
}
