import { useRef } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { AiOutlineClose } from "react-icons/ai";

export const ModalGeneric = ({
  show,
  toggleOutside = true,
  toggle,
  children,
}) => {
  const ref = useRef(null);
  useClickOutside(ref, toggle);

  return (
    <>
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
            <div
              ref={toggleOutside ? ref : null}
              className="relative w-auto my-6 mx-auto max-w-4xl"
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="ml-auto flex items-end justify-between p-2 border-slate-200 rounded-t">
                  <button
                    onClick={toggle}
                    className="text-slate-950 h-6 w-6 text-2xl block outline-none focus:outline-none cursor-pointer z-50"
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                <div className="relative p-4 pt-0 flex-auto">{children}</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
};
