import axios from "axios";
import { useState } from "react";

export const useGetAirlines = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [airlines, setAirlines] = useState({});

  const getAirlines = async () => {
    try {
      const response = await axios.get(BASE_URL + "v2/airlines");
      setAirlines(response.data?.response);
      return response.data;
    } catch (err) {
      throw new Error(`Error fetching airlines: ${err.message}`);
    }
  };
  return { airlines, getAirlines };
};
